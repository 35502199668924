import * as React from 'react';
import styles from './NotFound.module.scss';

const NotFound = () => {
  return (
    <div className={styles.container}>
      Something went wrong. We cannot find the target page. Please check the link and your permissions with the admin.
    </div>
  );
};

export default NotFound;
