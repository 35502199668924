// import { rest } from 'msw';
// import { clientAPIBase } from 'test-utils';

export const handlers = [
  // rest.post(`${clientAPIBase}/actions/AuthorizeSsoService`, (req, res, ctx) => {
  //   return res(
  //     ctx.delay(500),
  //     ctx.status(200),
  //     ctx.json({
  //       actionToken: 'actionToken'
  //     }),
  //   )
  // }),
  // rest.get(`${clientAPIBase}/users/me/devices`, (req, res, ctx) => {
  //   return res(
  //     ctx.delay(500),
  //     ctx.status(200),
  //     ctx.json([
  //       {
  //         deviceId: 'deviceId',
  //         notificationsEnabled: false
  //       },
  //       {
  //         deviceId: 'deviceId',
  //         notificationsEnabled: false
  //       }
  //     ]),
  //   );
  // }),
];
