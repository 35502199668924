import React, { InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import { FieldProps } from "formik";
import styles from './Input.module.scss';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'form'>, Partial<FieldProps> {}

const Input = ({ className, field, form, ...props }: Props) => <input
  {...props}
  className={classNames(styles.input, className)}
/>;

export default Input;
