export const STORE_ROUTER = 'router';
export const STORE_USER = 'user';
export const STORE_WORKSPACE = 'workspace';
export const STORE_UI = 'uiStore';
export const STORE_USER_ATTRIBUTES = 'userAttributesStore';
export const STORE_DESCRIPTOR = 'descriptorStore';
export const STORE_REPOSITORY = 'repositoryStore';
export const STORE_GROUP = 'groupsStore';
export const STORE_SERVICES = 'servicesStore';
export const STORE_INBOX = 'inboxStore';
export const STORE_TENANTS = "tenantsStore";
export const STORE_SSO = 'ssoStore';
export const STORE_REMOTE_CONFIG = 'remoteConfigStore';
export const STORE_WEBSOCKET = 'webSocketStore';
export const STORE_CURRENT_SERVICE = 'currentServiceStore';
export const STORE_CURRENT_WORKSPACE = 'currentWorkSpaceStore';
export const STORE_CURRENT_GROUP = 'groupStore';
export const STORE_GROUP_FILE = 'groupFileStore';
export const STORE_SIGN_IN = 'signInStore';
export const STORE_DEVICES = 'devicesStore';
