import { SelectOption } from 'interfaces';

export * from './sso';
export * from './stores';
export * from './image';
export * from './service';
export * from './messages';
export * from './matrix';
export * from "./modal";
export * from "./group";
export * from './actions';

export const APP_ID_SEPARATOR = ':';
export const CLIENT_API_NAME = "ClientAPI";
export const PUBLIC_API_NAME = "PublicAPI";
export const ADMIN_API_NAME = "AdminAPI";

export const SYSTEM_MESSAGE_USER_ID = '0';

export const WS_RECONNECT_ATTEMPTS = 1;
// The amount of time (in ms) to wait in between reconnection attempts
export const WS_TIMEOUT = 1 * 1000;
// The number of milliseconds to delay debounce
export const DEBOUNCE_DELAY = 500;

export const PAGINATION_FIRST_PAGE_NUMBER = 1;

export const TABLE_BUTTON_WIDTH = 80;
export const FORM_MODAL_WIDTH = {
  LARGE: 1045,
  XMEDIUM: 900,
  MEDIUM: 780,
  SMALL: 512,
  XSMALL: 400,
};

export enum Env {
  DEV = 'development',
  PROD = 'production',
}

export enum AppPaths {
  SAML_SSO_LOGIN_PATH = "/sso/saml/",
  REPOSITORIES_PATH = "repositories",
  MANAGE_PATH = "manage",
  SETTING_PATH = "settings",
  INBOX_PATH = "inbox",
  WORKSPACE = "workspace",
}

export const DEFAULT_DATE_FORMAT = "dd/MM/yyyy";

export enum AuthState {
  SIGN_IN = 'signIn',
  SIGNED_IN = 'signedIn',
  LOADING = 'loading',
}
/**
 * @deprecated
 * use AsyncStatus instead
 */
export enum FetchStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export enum AsyncStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILURE = 'failure',
  CANCELLED = 'cancelled',
}

export enum AppStoreItemType {
  WORKSPACE = 'workspace',
  MICRO_APP = 'microApp',
}

export enum WorkspaceAccessType {
  PRIVATE = 'private',
  PUBLIC = 'public',
}

export enum ConnectionStatus {
  PENDING = 'pending',
  RECONNECTING = 'reconnecting',
  OPENED = 'opened',
  CLOSED = 'closed',
  FAILED = 'failed',
}

export enum GroupType {
  USER = "user",
  ADMIN = "admin",
}

export enum SsoProviderType {
  MICROSOFT = 'microsoft',
  GOOGLE = 'google',
}

export const SSO_EMAIL_ATTRIBUTES = ['workEmail', 'privateEmail'];

export enum MembershipStatus {
  BAN = 'ban',
  INVITE = 'invite',
  JOINED = 'joined',
  JOIN = 'join',
  LEAVE = 'leave',
}

export enum ConfirmMessageType {
  GROUP = 'group',
  USER = 'user',
}

export enum MatrixRoomVisibility {
  PRIVATE = 'private',
  PUBLIC = 'public',
}

export enum WebSocketAction {
  START = 'StartProfileDataRequest',
  AUTH = 'AuthorizeSsoService',
}

export const providerOptions: SelectOption[] = [
  {
    label: 'Microsoft', value: SsoProviderType.MICROSOFT,
  },
  {
    label: 'Google', value: SsoProviderType.GOOGLE,
  },
];

export const workspaceAccessTypeOptions: SelectOption[] = [
  {
    label: 'Private', value: WorkspaceAccessType.PRIVATE,
  },
  {
    label: 'Public', value: WorkspaceAccessType.PUBLIC,
  },
];

export const ROOT_WORKSPACE_ID = 'root';
export const CREATE_SERVICE_TITLE = 'Create service';
export const NO_ITEMS_TEXT = 'Sorry, no items match your search request';

export enum AppStoreType {
  root = "root",
  repository = "repository",
  unu = "unu",
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum SSO_PROTOCOLS {
  SAML = "saml",
}

export enum AnalyticsEventType {
  SELECT_WORKPSACE = 'select_workspace',
  SELECT_SERVICE = 'select_service',
}

export const GLOBAL_TENANT_ID = "global";

// cognito login
export const COGNITO_USER_NOTFOUND_CODE = "UserNotFoundException";

export enum FeatureFlags {
  SUPPORT_CHAT_ENABLED = 'SUPPORT_CHAT_ENABLED',
  ADD_EMPLOYER_ENABLED = 'ADD_EMPLOYER_ENABLED',
}
