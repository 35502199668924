import React from "react";
import { InputHTMLAttributes } from "react";
import classNames from "classnames";
import { FieldProps } from "formik";
import styles from "./CustomCheckbox.module.scss";

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'form'>, Partial<FieldProps> {}

const CustomCheckbox = ({ className, checked, disabled, title, form, field, ...props }: Props) => (
  <div className={styles.checkboxHolder}>
    <label
      htmlFor={props.id}
      className={classNames(
        styles.checkbox, className,
        { [styles.checked]: checked },
        { [styles.disabled]: disabled },
      )}
    >
      <input
        {...props}
        checked={checked}
        disabled={disabled}
        hidden
        type="checkbox"
      />
    </label>
    <span className={styles.title}>{title}</span>
  </div>
);

export default CustomCheckbox;
