/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import ReactTags, { ReactTagsProps } from 'react-tag-autocomplete';
import "./CustomAutocomplete.scss";
import { FormikProps } from 'formik';

interface Props extends ReactTagsProps {
  label?: string;
  form: FormikProps<any>
  name: string;
}

const ReactTag = (props: Props) => (
  <>
    <label>{props.label}</label>
    <ReactTags
      {...props}
    />
  </>
);

export default ReactTag;
