import * as React from 'react';
import { CustomButton, Table, Pagination, CustomTab } from 'components';
import { UIStore } from 'stores';
import GroupsStore from "stores/GroupsStore";
import { RouteComponentProps } from "react-router";
import {
  CREATE_GROUP_MODAL,
  DELETE_GROUP_MODAL,
  STORE_GROUP,
  STORE_UI,
  GROUP_MESSAGE,
  FORM_MODAL_WIDTH,
  GroupType,
} from "appConstants";
import { inject, observer } from "mobx-react";
import { TabList, TabPanel, Tabs } from "react-tabs";
import Group from "../../models/Group";
import styles from './Groups.module.scss';
import { getGroupsColumns, getSharedGroupsColumns } from "./GroupsColumns";

export interface Props extends RouteComponentProps<{ tenantId: string }> {
  [STORE_GROUP]: GroupsStore;
  [STORE_UI]: UIStore;
}

interface State {
  selectedItems: Group[];
}

@inject(STORE_GROUP, STORE_UI)
@observer
class Groups extends React.PureComponent<Props, State> {
  state = {
    selectedItems: [],
  };

  componentDidMount() {
    this.props[STORE_GROUP].listAdminGroups();
    this.props[STORE_GROUP].listSharedGroups();
  }

  componentWillUnmount() {
    this.setState({ selectedItems: [] });
    this.props[STORE_GROUP].clear();
  }

  handleSelectionChange = (groups: Group[]) => {
    this.setState({ selectedItems: groups });
  };

  handleDeleteClick = () => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH.SMALL,
      componentKey: DELETE_GROUP_MODAL,
      title: "Delete Group(s)?",
      bodyText: GROUP_MESSAGE,
      eventProps: {
        onSubmit: () => this.props[STORE_GROUP].deleteGroups(this.state.selectedItems),
      },
      props: {
        withLoading: true,
      },
    });
  };

  showCreateModal = () => {
    this.props[STORE_UI].openModal({
      componentKey: CREATE_GROUP_MODAL,
      title: 'Create group',
      eventProps: {
        onSubmit: this.props[STORE_GROUP].createGroup,
      },
      props: {
        withLoading: true,
      },
    });
  };

  allowGroupCheck = (item: Group) => item.type === GroupType.USER;

  onPageAdminGroupsChange = (selectedItem: { selected: number }) => {
    this.props[STORE_GROUP].goToNextAdminGroupPage(selectedItem.selected);
  };

  onPageSharedGroupsChange = (selectedItem: { selected: number }) => {
    this.props[STORE_GROUP].goToNextSharedGroupPage(selectedItem.selected);
  };

  render() {
    const {
      isAdminGroupsLoading,
      isSharedGroupsLoading,
      groups,
      sharedGroups,
      groupsPaginationConfig,
      sharedGroupsPaginationConfig,
    } = this.props[STORE_GROUP];
    const selectedCount = this.state.selectedItems.length;
    const hasSelectedItems = selectedCount > 0;
    const columns = getGroupsColumns(this.showCreateModal, this.props.match.params.tenantId);
    const sharedColumns = getSharedGroupsColumns();

    return (
      <>
        <h1>Groups</h1>
        <div className={styles.container}>
          <Tabs>
            <TabList>
              <CustomTab>My Groups</CustomTab>
              <CustomTab>Shared with me</CustomTab>
            </TabList>
            <TabPanel>
              <Table<Group>
                isLoading={isAdminGroupsLoading}
                columns={columns}
                values={groups}
                onSelectionChange={this.handleSelectionChange}
                allowItemCheck={this.allowGroupCheck}
              />
              { hasSelectedItems && (
              <div>
                <CustomButton className={styles.delete} onClick={this.handleDeleteClick}>
                  Delete selected (
                  {selectedCount}
                  )
                </CustomButton>
              </div>)}
              {groupsPaginationConfig && groupsPaginationConfig.pageCount > 1 &&
              <Pagination
                pageCount={groupsPaginationConfig.pageCount}
                onPageChange={this.onPageAdminGroupsChange}
              />}
            </TabPanel>
            <TabPanel>
              <Table<Group>
                isLoading={isSharedGroupsLoading}
                columns={sharedColumns}
                values={sharedGroups}
                hasCheckboxes={false}
              />
              {sharedGroupsPaginationConfig && sharedGroupsPaginationConfig.pageCount > 1 &&
              <Pagination
                pageCount={sharedGroupsPaginationConfig.pageCount}
                onPageChange={this.onPageSharedGroupsChange}
              />}
            </TabPanel>
          </Tabs>
        </div>
      </>
    );
  }
}

export default Groups;
