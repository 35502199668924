/* eslint-disable no-underscore-dangle */
import React from 'react';
import { inject, observer } from "mobx-react";
import { STORE_INBOX, STORE_USER } from "appConstants";
import { InboxStore, UserStore } from "stores";
import { Message, Popup } from 'components';
import classNames from "classnames";
import styles from './ChatHolder.module.scss';
import ChatInput from './ChatInput';

export interface Props {
  [STORE_INBOX]: InboxStore;
  [STORE_USER]: UserStore;
}

@inject(STORE_INBOX, STORE_USER)
@observer
export default class ChatHolder extends React.PureComponent<Props> {
  protected chatHolder = React.createRef<HTMLDivElement>();

  componentDidUpdate = () => {
    const chatHolder = this.chatHolder.current;
    if (chatHolder) {
      chatHolder.scrollTop = chatHolder.scrollHeight;
    }
  };

  popupTrigger = () => <i className={classNames("icon-dots-vertical", styles.chatButton)} />;

  handleLeaveClick = () => this.props[STORE_INBOX].leaveCurrentRoom();

  render() {
    const { userGivenName } = this.props[STORE_USER];
    const { roomMessages, currentRoomName, matrixCredentials, sendMessage, currentRoomId } = this.props[STORE_INBOX];
    if (!matrixCredentials || !userGivenName) {
      return null;
    }
    const revertedMessages = roomMessages.reverse();

    return (
      <div className={styles.chatHolder}>
        <div className={styles.header}>
          {currentRoomName &&
            <>
              {currentRoomName}
              <Popup trigger={this.popupTrigger}>
                <ul className={styles.list}>
                  <li>
                    <button
                      type="button"
                      onClick={this.handleLeaveClick}
                    >
                      Leave
                    </button>
                  </li>
                </ul>
              </Popup>
            </>}
        </div>
        {currentRoomId ?
          <>
            <div className={styles.chat} ref={this.chatHolder}>
              {revertedMessages.map(msg => <Message
                key={msg._id}
                msg={msg}
                userId={matrixCredentials.matrixUserId}
                username={userGivenName}
                sendMessage={this.props[STORE_INBOX].sendMessage}
              />)}
            </div>
            <div className={styles.inputHolder}>
              <ChatInput
                sendMessage={sendMessage}
              />
            </div>
          </> :
          <div className={styles.emptyRoom}> Please, select chat. </div>}
      </div>
    );
  }
}
