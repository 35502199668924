import { observable, action, runInAction, computed } from "mobx";
import queryString from "query-string";
import {
  SelectOption,
} from "interfaces";
import localStorageService from 'services/LocalStoreService';
import isAfter from 'date-fns/isAfter';
import addSeconds from 'date-fns/addSeconds';
import ssoService, { SsoSamlAuthorize } from "../services/SSOService";
import { RootStore } from './RootStore';
import actionsService, { GetSsoAuthStateResponse } from "../services/actionsService";
import { catchXhr } from "../services/errorService";
import { DEFAULT_SERVER_ERROR_MESSAGE, STORE_UI, AsyncStatus, STORE_WEBSOCKET } from "../appConstants";
import { delay } from "../utils/delay";

export const SSO_AUTH_TIME_LS_KEY = 'ssoAuthTime';

// TODO: support [key in SsoAttributeKey] instead of string
export interface SSOAttributes {
  [key: string]: string | SelectOption
}

const SSO_AUTH_POOLING_DELAY_SEC = process.env.REACT_APP_SSO_AUTH_POOLING_DELAY_SEC || '2';

export class SSOStore {
  rootStore: RootStore;

  @observable ssoAuthTime: number | null = null; // need observable because use it in

  @observable samlAuthResponseForm: string = "";

  @observable samlReqResponseForm: string = "";

  @observable samlRequestFetchStatus: AsyncStatus = AsyncStatus.IDLE;

  @observable samlAuthorizeStatus: AsyncStatus = AsyncStatus.IDLE;

  @observable ssoAuthConfigFetchStatus: AsyncStatus = AsyncStatus.IDLE;

  // fallback login
  poolingStartMills?: number;

  ssoAuthPoolingDelay = window.parseInt(SSO_AUTH_POOLING_DELAY_SEC, 10) * 1000;

  @observable ssoAuthPoolingTimeoutMillis?: number;

  @observable actionToken?: string;

  @observable ssoAuthToken?: string | null = null;

  appUrl: string = "";

  samlRequest: string = "";

  relayState: string = "";

  samlServiceId: string = "";

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const ssoAuthTime = localStorageService.getItem(SSO_AUTH_TIME_LS_KEY);
    this.ssoAuthTime = ssoAuthTime ? +ssoAuthTime : null;
  }

  @computed
  get isSamlRequestLoading(): boolean {
    return this.samlRequestFetchStatus === AsyncStatus.IDLE || this.samlRequestFetchStatus === AsyncStatus.PENDING;
  }

  @computed
  get isSamlRequestFailed(): boolean {
    return this.samlRequestFetchStatus === AsyncStatus.FAILURE;
  }

  @computed
  get isSamlAuthorizeCancelled(): boolean {
    return this.samlAuthorizeStatus === AsyncStatus.CANCELLED;
  }

  @computed
  get isLoading(): boolean {
    return this.isSamlRequestLoading ||
            this.ssoAuthConfigFetchStatus === AsyncStatus.PENDING ||
            this.samlAuthorizeStatus === AsyncStatus.PENDING;
  }

  @computed
  get hasSession() {
    if (!this.poolingStartMills || !this.ssoAuthPoolingTimeoutMillis) return false;
    return Date.now() - this.poolingStartMills <= this.ssoAuthPoolingTimeoutMillis;
  }

  @computed
  get isAuthAllowed(): boolean {
    if (!this.ssoAuthPoolingTimeoutMillis || !this.ssoAuthTime) {
      return true;
    }
    return isAfter(new Date(), addSeconds(this.ssoAuthTime, this.ssoAuthPoolingTimeoutMillis / 1000));
  }

  @computed
  get authAllowedFrom(): number | undefined {
    if (!this.ssoAuthPoolingTimeoutMillis || !this.ssoAuthTime) {
      return undefined;
    }
    return this.ssoAuthTime + this.ssoAuthPoolingTimeoutMillis;
  }

  @action
  setSsoAuthTime(): void {
    const time = Date.now();
    this.ssoAuthTime = time;
    localStorageService.setItem(SSO_AUTH_TIME_LS_KEY, time);
  }

  retrieveSsoAuthConfig = async (): Promise<void> => {
    runInAction(() => this.ssoAuthConfigFetchStatus = AsyncStatus.PENDING);
    try {
      const config = await actionsService.authorizeSsoService();
      this.rootStore[STORE_WEBSOCKET].setWsAuthConfig(config);
      runInAction(() => {
        this.ssoAuthPoolingTimeoutMillis = config.retryTimeoutSeconds * 1000;
        this.ssoAuthConfigFetchStatus = AsyncStatus.SUCCESS;
      });
    } catch (error) {
      runInAction(() => this.ssoAuthConfigFetchStatus = AsyncStatus.FAILURE);
    }
  };

  startSsoAuthAction = async (): Promise<void> => {
    try {
      const { actionToken } = await actionsService.startSsoAuth(this.samlServiceId);
      this.setSsoAuthTime();
      runInAction(() => {
        this.poolingStartMills = Date.now();
        this.actionToken = actionToken;
      });
      this.waitForMobileSsoAuth();
    } catch (error) {
      catchXhr(error);
    }
  };

  waitForMobileSsoAuth = async (): Promise<void> => {
    try {
      if (!this.hasSession) {
        this.clearFallbackSsoAuth();
        return;
      }
      await delay(this.ssoAuthPoolingDelay);
      const result = await this.checkSsoAuthState();
      if (!result) return;
      if (result.ssoAuthToken === null) {
        await this.waitForMobileSsoAuth();
      } else if (result.authorizeService === true && result.ssoAuthToken) {
        // user confirmed auth
        await this.doSamlAuthorize({
          authorizeService: result.authorizeService,
          ssoAuthToken: result.ssoAuthToken,
        });
      } else {
        // auth was canceled
        this.cancelSamlAuthorize();
      }
    } catch (error) {
      catchXhr(error);
    }
  };

  checkSsoAuthState = async (): Promise<GetSsoAuthStateResponse | undefined> => {
    try {
      if (!this.actionToken) throw new Error(DEFAULT_SERVER_ERROR_MESSAGE);
      const result = await actionsService.getSsoAuthState(this.actionToken);
      runInAction(() => {
        this.ssoAuthToken = result.ssoAuthToken;
      });
      return result;
    } catch (error) {
      this.rootStore[STORE_UI].showErrorNotification(DEFAULT_SERVER_ERROR_MESSAGE);
    }
  };

  getUrlParams(samlServiceId: string, urlString: string): void {
    const urlParams = queryString.parse(urlString);
    this.samlServiceId = samlServiceId;

    if (urlParams.SAMLRequest && typeof urlParams.SAMLRequest === "string") {
      this.samlRequest = urlParams.SAMLRequest;
    }

    if (urlParams.RelayState && typeof urlParams.RelayState === "string") {
      this.relayState = urlParams.RelayState;
    }

    if (urlParams.appUrl && typeof urlParams.appUrl === "string") {
      this.appUrl = urlParams.appUrl;
    }
  }

  sendSamlRequest() {
    return ssoService.doSamlRequest(this.relayState, this.samlRequest, this.samlServiceId);
  }

  @action
  async doSamlRequest(): Promise<void> {
    this.samlRequestFetchStatus = AsyncStatus.PENDING;
    try {
      const response = await this.sendSamlRequest();

      if (response.headers["content-type"] === "text/html") {
        runInAction(() => {
          this.samlReqResponseForm = response.data as string;
          this.samlRequestFetchStatus = AsyncStatus.SUCCESS;
        });
        return;
      }
    } catch (err) {
      console.error(err);
      runInAction(() => this.samlRequestFetchStatus = AsyncStatus.FAILURE);
    } finally {
      runInAction(() => this.samlRequestFetchStatus = AsyncStatus.SUCCESS);
    }
  }

  @action
  async doSamlAuthorize({
    authorizeService,
    attributes,
    ssoAuthToken,
  }: Pick<SsoSamlAuthorize, 'authorizeService' | 'attributes' | 'ssoAuthToken'>): Promise<void> {
    runInAction(() => this.samlAuthorizeStatus = AsyncStatus.PENDING);
    try {
      const samlAuthorizeResponseData = await ssoService.doSamlAuthorize({
        relayState: this.relayState,
        samlRequest: this.samlRequest,
        authorizeService,
        attributes,
        samlServiceId: this.samlServiceId,
        ssoAuthToken,
      });
      runInAction(() => {
        this.samlAuthResponseForm = samlAuthorizeResponseData;
        this.samlAuthorizeStatus = AsyncStatus.SUCCESS;
      });
    } catch (err) {
      console.error(err);
      runInAction(() => this.samlAuthorizeStatus = AsyncStatus.FAILURE);
    }
  }

  @action
  cancelSamlAuthorize(): void {
    this.samlAuthorizeStatus = AsyncStatus.CANCELLED;
  }

  @action
  clearFallbackSsoAuth(): void {
    this.poolingStartMills = undefined;
    this.actionToken = undefined;
    this.ssoAuthToken = null;
  }
}

export default SSOStore;
