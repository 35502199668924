import React from "react";
import { FormikProps } from 'formik';
import objectPath from "object-path";
import { ServiceFormValues } from "../../../../interfaces";
import CustomCheckbox from "../../../CustomCheckbox/CustomCheckbox";
import Label from "../../../Label/Label";
import styles from './CreateServiceModal.module.scss';

interface Props {
  className?: string;
  form: FormikProps<ServiceFormValues>;
  name: string;
  title: string;
}

export default function AttributeCheckbox({ className, form, title, name }: Props) {
  // need to use objectPath because here we might get name="attributesMapping.0.required"
  const value = !!objectPath.get(form.values, name);
  return (
    <div className={className}>
      <Label title={title} />
      <CustomCheckbox
        data-testid={name}
        className={styles.requiredCheckbox}
        name={name}
        checked={value}
        onChange={form.handleChange}
      />
    </div>
  );
}
