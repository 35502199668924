import React from 'react';
import ReactPopup, { Props } from "reactjs-popup";
import classNames from 'classnames';

export interface PopupProps extends Props {
  reverseColors?: boolean;
}

// added click handler to close popup on children click by default
const Popup = ({ reverseColors, ...props }: PopupProps) => (
  <ReactPopup
    position="bottom right"
    {...props}
    closeOnDocumentClick
    className={classNames({ reverseColors })}
  >
    {close => (
      <div
        role="button"
        onClick={close}
      >
        {props.children}
      </div>)}
  </ReactPopup>
);

export default Popup;
