import * as React from 'react';
import { CustomButton, ButtonHolder, CustomField } from 'components';
import * as Yup from 'yup';
import { Form, Formik } from "formik";
import { VALIDATION_RULES } from "validation";

export interface Props {
  onSubmit: (name: string) => void;
}

interface FormValues {
  name: string;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  name: VALIDATION_RULES.name,
});

export default class CreateGroupModal extends React.Component <Props> {
  onSubmit = ({ name }: FormValues) => {
    this.props.onSubmit(name);
  };

  render() {
    return (
      <Formik
        initialValues={{ name: '' }}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={this.onSubmit}
      >
        {form => {
          const isDisabled = !form.dirty || !form.isValid;
          return (
            <Form>
              <CustomField
                withSeparator
                name="name"
                title="Name"
                placeholder="Enter name..."
                form={form}
                onChange={form.handleChange}
              />
              <ButtonHolder>
                <CustomButton
                  disabled={isDisabled}
                  buttonType="primary"
                  type="submit"
                >
                  Create
                </CustomButton>
              </ButtonHolder>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
