export const max70SymbolsMessage = "Seventy symbols maximum.";
export const latinAndOthersMessage = "You can use Latin letters, spaces, accents, dashes, characters with accents only.";
export const latinAndSymbolsOnlyMessage = "You can use Latin letters and special symbols only.";
export const requiredFirstNameMessage = "Please input first name.";
export const requiredLastNameMessage = "Please input last name.";
export const latinLettersErrorMessage = "You can use only latin letters, spaces, accents, dashes, characters with accents.";

export const requiredErrorMessage = 'The field is required';
export const emptyTenantNameErrorMessage = 'Please input tenant name';
export const alphaNumericErrorMessage = "Sorry, the symbol is restricted";
export const urlFormatErrorMessage = 'The field must be in URL format. Please check and edit to fit the format';
export const urlFormatShortErrorMessage = "The value must fit URL format";
export const twoSymbolsErrorMessage = 'Two symbols minimum';
export const latinLettersShortErrorMessage = "You can use only latin letters";
export const emptyFieldErrorMessage = 'This field cannot be empty.';
export const domainErrorMessage = "Please check the format";
