import { AttributeMapping, ServiceConfig, ServiceData, ServiceConfigSso } from 'interfaces';
import { SSO_PROTOCOLS, UnuActions, UnuSupportActionType } from 'appConstants';
import { unuActionsService } from 'services';

type PreparedConfigData = Omit<ServiceConfig, "iconUrl">;

export default function serviceFormToConfig(serviceData: ServiceData): PreparedConfigData {
  const {
    title,
    description,
    appUrl,
    unuSupportActionType,
    supportMessage,
    flowInitiator,
    entityId,
    ssoRedirectUrlPost,
    encryptAssertion,
    spCertificate,
    attributeMapping,
    nameIdAttribute,
  } = serviceData.formValues;
  const { iconName, iconDataUrl, samlServiceId } = serviceData;
  const icon = iconName && iconDataUrl ? { name: iconName, dataUrl: iconDataUrl } : null;
  const isSSOEnabled = !!flowInitiator && !!flowInitiator.value;

  const serviceConfigData: PreparedConfigData = {
    title,
    description,
    appUrl,
    icon,
  };

  if (isSSOEnabled) {
    const requestAttributeMapping: AttributeMapping[] = attributeMapping.map(att => ({
      serviceAttributeName: att.name,
      profileAttributeKey: att.attributeValue.value,
      name: att.attributeValue.label,
      defaultValue: '',
      description: att.description,
      required: att.required,
      verifiedOnly: att.verifiedOnly,
      attributeType: att.attributeType,
      compositeAttributePath: att.compositeAttributePath
    }));

    const sso: ServiceConfigSso = {
      flowInitiator: flowInitiator.value,
      // for current implementation, there is only one protocol, but it will be extended in future
      protocol: SSO_PROTOCOLS.SAML,
      entityId,
      ssoRedirectUrlPost,
      encryptAssertion,
      attributeMapping: requestAttributeMapping,
      samlServiceId,
      nameIdAttribute: nameIdAttribute.value,
    };
    if (encryptAssertion) {
      sso.spCertificate = spCertificate;
    }

    serviceConfigData.sso = sso;
  }

  const actions = unuSupportActionType.value !== UnuSupportActionType.NO_SUPPORT ? {
    [UnuActions.unuSupport]: unuActionsService.createSupportAction(unuSupportActionType.value, supportMessage),
  } : {};

  return {
    ...serviceConfigData,
    actions,
  };
}
