import * as React from 'react';
import { RouterStore, UserAttributesStore } from 'stores';
import { RouteComponentProps, Route, Switch, withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import { NavLink } from "react-router-dom";
import {
  STORE_ROUTER,
  STORE_CURRENT_GROUP,
  STORE_USER_ATTRIBUTES
} from "appConstants";
import { GroupStore } from "stores";
import Domains from './components/Domains';
import ActiveUsers from './components/ActiveUsers';
import ApproveUsers from './components/ApproveUsers';
import styles from "./Group.module.scss";
import ShareGroup from "./components/ShareGroup";
import MembershipRules from "./components/MembershipRules/MembershipRules";
import { Loader } from '../../components';

export enum ManageTabNames {
  DOMAINS = "domains",
  RULES = "rules",
  USER_APPROVAL = "user-approval",
  ACTIVE_USERS = "active-users",
  SHARE_GROUP = "share-group",
}

export const MANAGE_TAB_TITLES = {
  [ManageTabNames.RULES]: 'Rules',
  [ManageTabNames.DOMAINS]: 'Domains',
  [ManageTabNames.USER_APPROVAL]: 'User approval',
  [ManageTabNames.ACTIVE_USERS]: 'Active users',
  [ManageTabNames.SHARE_GROUP]: 'Sharing',
};

function getManageTabs(params: { isRuleBased: boolean }): { name: ManageTabNames, title: string }[] {
  const manageTabs = [
    { name: ManageTabNames.RULES, title: MANAGE_TAB_TITLES[ManageTabNames.RULES] },
  ];
  if (!params.isRuleBased) {
    manageTabs.push(
      { name: ManageTabNames.DOMAINS, title: MANAGE_TAB_TITLES[ManageTabNames.DOMAINS] },
      { name: ManageTabNames.USER_APPROVAL, title: MANAGE_TAB_TITLES[ManageTabNames.USER_APPROVAL] },
    );
  }

  manageTabs.push(
    { name: ManageTabNames.ACTIVE_USERS, title: MANAGE_TAB_TITLES[ManageTabNames.ACTIVE_USERS] },
    { name: ManageTabNames.SHARE_GROUP, title: MANAGE_TAB_TITLES[ManageTabNames.SHARE_GROUP] }
  );

  return manageTabs;
}

export interface Props extends RouteComponentProps<{ tenantId: string, groupId: string, manageTabName: string }> {
  [STORE_ROUTER]: RouterStore;
  [STORE_CURRENT_GROUP]: GroupStore;
  [STORE_USER_ATTRIBUTES]: UserAttributesStore;
}

@inject(STORE_ROUTER, STORE_CURRENT_GROUP, STORE_USER_ATTRIBUTES)
@observer
class Group extends React.Component<Props> {
  componentDidMount() {
    const { groupId } = this.props.match.params;
    this.props[STORE_CURRENT_GROUP].loadGroup(groupId);
    this.props[STORE_CURRENT_GROUP].loadTenantsForSharing(groupId);
    this.props[STORE_USER_ATTRIBUTES].listUserAttributes();
  }

  componentWillUnmount() {
    this.props[STORE_CURRENT_GROUP].clearGroup();
  }

  render() {
    const {
      currentGroup,
      isGroupLoaded,
      isRuleBased
    } = this.props[STORE_CURRENT_GROUP];
    const { groupId, tenantId } = this.props.match.params;

    return (isGroupLoaded && currentGroup ? (
      <>
        <h1>{currentGroup.name}</h1>
        <div className={styles.container}>
          <div className={styles.tabbar}>
            {getManageTabs({
              isRuleBased
            }).map(tab => (
              <NavLink
                key={tab.title}
                activeClassName={styles.active}
                to={`/tenants/${tenantId}/manage/groups/${groupId}/${tab.name}`}
              >
                {tab.title}
              </NavLink>
            ))}
          </div>
          <div>
            <Switch>
              <Route
                path={`/tenants/:tenantId/manage/groups/:groupId/${ManageTabNames.RULES}`}
                component={withRouter(MembershipRules)}
              />
              <Route
                path={`/tenants/:tenantId/manage/groups/:groupId/${ManageTabNames.DOMAINS}`}
                component={withRouter(Domains)}
              />
              <Route
                path={`/tenants/:tenantId/manage/groups/:groupId/${ManageTabNames.USER_APPROVAL}`}
                component={withRouter(ApproveUsers)}
              />
              <Route
                path={`/tenants/:tenantId/manage/groups/:groupId/${ManageTabNames.ACTIVE_USERS}`}
                component={withRouter(ActiveUsers)}
              />
              <Route
                path={`/tenants/:tenantId/manage/groups/:groupId/${ManageTabNames.SHARE_GROUP}`}
                component={withRouter(ShareGroup)}
              />
            </Switch>
          </div>
        </div>
      </>) : (
        <Loader />
    ));
  }
}

export default Group;
