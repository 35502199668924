import * as React from "react";
import { CustomButton } from 'components';
import styles from './CreateServiceModal.module.scss';

interface Props {
  onPublish: () => Promise<void>;
  onSubmit: () => Promise<void>;
  isUnuPublishDisabled: boolean;
  isSaveDisabled: boolean;
}

export default function CreateServiceFooter(props: Props) {
  const { isSaveDisabled, isUnuPublishDisabled, onSubmit, onPublish } = props;
  return (
    <div className={styles.buttonRow}>
      <CustomButton
        type="button"
        onClick={onPublish}
        disabled={isUnuPublishDisabled}
      >
        Save and publish to Coll8 store
      </CustomButton>
      <CustomButton type="button" onClick={onSubmit} buttonType="primary" disabled={isSaveDisabled}>Save</CustomButton>
    </div>
  );
}
