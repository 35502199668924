/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Switch, CustomButton, Select } from 'components';
import { withRouter, RouteComponentProps, Route } from "react-router-dom";
import { hasPublishButton } from "utils/hasPublishButton";
import { UIStore, UserStore, RouterStore } from 'stores';
import { observer, inject } from 'mobx-react';
import { STORE_UI, STORE_USER, STORE_ROUTER } from 'appConstants';
import { SelectOption } from 'interfaces';
import getOptionFromEntity from 'utils/getOptionFromEntity';
import styles from './Header.module.scss';

export interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore,
  [STORE_USER]: UserStore,
  [STORE_ROUTER]: RouterStore,
  publish: () => void;
}

// TODO: switch   https://github.com/mobxjs/mobx-react/issues/256
@inject(STORE_UI, STORE_USER, STORE_ROUTER)
@observer
class Header extends React.Component<Props, any> {
  static defaultProps = {
    [STORE_UI]: (null as unknown) as UIStore,
    [STORE_USER]: (null as unknown) as UserStore,
    [STORE_ROUTER]: (null as unknown) as RouterStore,
  };

  updateTenant = (option: SelectOption) => this.props[STORE_USER].setCurrentTenant(option.value);

  renderBackArrow = () => (
    <img className={styles.backArrow} src="/images/back.svg" alt="" onClick={this.props[STORE_ROUTER].goBack} />
  );

  render() {
    const { isAdminMode, switchMode } = this.props[STORE_UI];
    const { tenants, currentTenant, hasAdminAccess } = this.props[STORE_USER];
    const { match, publish } = this.props;
    const tenantOptions = tenants.map(getOptionFromEntity);
    const currentTenantOption = currentTenant ? getOptionFromEntity(currentTenant) : null;

    return (
      <div className={styles.header}>
        <div>
          <Route path="/tenants/:tenantId/manage/groups/:groupId" render={this.renderBackArrow} />
          <Route exact path="/tenants/:tenantId/workspace/:workspaceId/more-services" render={this.renderBackArrow} />
          <Route exact path="/tenants/:tenantId/workspace/:workspaceId/admin-services" render={this.renderBackArrow} />
          <Route exact path="/tenants/:tenantId/repositories" render={this.renderBackArrow} />
          <Route exact path="/tenants/:tenantId/manage/groups" render={this.renderBackArrow} />
          <Route exact path="/tenants/:tenantId/manage/tenants" render={this.renderBackArrow} />
        </div>
        {hasAdminAccess && (
          <div className={styles.adminBlock}>
            {hasPublishButton(isAdminMode, match.path) && (
              <CustomButton
                className="hidden"
                buttonType="primary"
                size="xs"
                onClick={publish}
              >
                Publish
              </CustomButton>
            )}
            <span className={styles.title}>Admin mode</span>
            <Switch
              checked={isAdminMode}
              onChange={switchMode}
            />
            {isAdminMode && <Select
              className={styles.tenantSelect}
              value={currentTenantOption}
              placeholder="Select current tenant"
              options={tenantOptions}
              onChange={this.updateTenant}
            />}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Header);
