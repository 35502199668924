export const CREATE_SERVICE_MODAL = 'CreateServiceModal';
export const CREATE_WORKSPACE_MODAL = 'CreateWorkspaceModal';
export const DELETE_REPOSITORY_MODAL = 'DeleteRepositoryModal';
export const CREATE_GROUP_MODAL = 'CreateGroupModal';
export const DELETE_GROUP_MODAL = 'DeleteGroupModal';
export const CREATE_GROUP_USER_MODAL = 'CreateGroupUserModal';
export const CREATE_GROUP_DOMAIN_MODAL = 'CreateGroupDomainModal';
export const SHARE_GROUP_TENANT_MODAL = 'ShareGroupTenantModal';
export const DELETE_GROUP_USER_MODAL = 'DeleteGroupUserModal';
export const DELETE_GROUP_TENANTS_MODAL = 'DeleteGroupTenantModal';
export const APPROVE_ALL_GROUP_USERS_MODAL = 'ApproveAllGroupUsersModal';
export const CREATE_EDIT_TENANT_MODAL = 'CreateEditTenantModal';
export const ADD_MICRO_APP_MODAL = 'AddMicroAppmodal';
export const MY_EPMLOYER_MODAL = 'MyEmployerModal';
export const GROUP_USERS_CSV_MODAL = 'GroupUsersCsvModal';
export const SUPPORT_MODAL = "SupportModal";
export const ONBOARDING_SVC_MODAL = "OnboardingSvcModal";
