import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import {
  STORE_DESCRIPTOR,
  STORE_UI,
  ADD_MICRO_APP_MODAL,
  MY_EPMLOYER_MODAL,
  SUPPORT_MODAL,
  ONBOARDING_SVC_MODAL,
  STORE_REMOTE_CONFIG,
} from 'appConstants';
import { Loader, WorkspaceDescriptorItem, TilesWrapper, NotFound, TileItem, TileContent } from 'components';
import { UIStore, DescriptorStore, RemoteConfigStore } from 'stores';
import styles from './WorkspaceDescriptor.module.scss';
import { UnuSupportAction } from '../../interfaces';

export interface Props extends RouteComponentProps<any> {
  [STORE_DESCRIPTOR]: DescriptorStore;
  [STORE_UI]: UIStore;
  [STORE_REMOTE_CONFIG]: RemoteConfigStore;
}

@inject(STORE_DESCRIPTOR, STORE_UI, STORE_REMOTE_CONFIG)
@observer
export class WorkspaceDescriptor extends React.Component<Props> {
  componentDidMount() {
    const { workspaceId } = this.props.match.params;
    this.props[STORE_DESCRIPTOR].getWorkspaceDescriptor(workspaceId);
  }

  componentWillUnmount() {
    this.props[STORE_DESCRIPTOR].resetAvailableApps();
    this.props[STORE_DESCRIPTOR].cleanDescriptor();
  }

  openAppsModal = () => {
    this.props[STORE_UI].openModal({
      componentKey: ADD_MICRO_APP_MODAL,
      props: {
        workspaceId: this.props.match.params.workspaceId,
      },
    });
  };

  openEmployerModal = () => {
    this.props[STORE_UI].openModal({
      componentKey: MY_EPMLOYER_MODAL,
      props: {
        workspaceId: this.props.match.params.workspaceId,
      },
    });
  };

  showFeedbackModal = (itemId: string, title: string, action: UnuSupportAction) => {
    this.props[STORE_UI].openModal({
      componentKey: SUPPORT_MODAL,
      title,
      props: {
        action,
        itemId,
        title,
      },
    });
  };

  removeInstalledApp = (appId: string) => {
    const { workspaceId } = this.props.match.params;
    const { removeInstalledApp } = this.props[STORE_DESCRIPTOR];
    removeInstalledApp(appId, workspaceId);
  };

  showOnboardingSvcModal = async (title: string, mobileAppLink?: string) => {
    this.props[STORE_UI].openModal({
      componentKey: ONBOARDING_SVC_MODAL,
      title,
      props: {
        mobileAppLink,
      },
    });
  };

  render() {
    const { workspaceDescriptor, isLoading, isNotExist, sortedApps, isMoreServicesVisible } = this.props[STORE_DESCRIPTOR];
    const { isAddEmployerEnabled } = this.props[STORE_REMOTE_CONFIG];
    if (isNotExist) {
      return <NotFound />;
    }
    if (workspaceDescriptor === null || isLoading) {
      return <Loader />;
    }

    const isRootWorkspace = !this.props.match.params.workspaceId;

    return (
      <>
        <h1>{workspaceDescriptor.config.properties.title}</h1>
        <TilesWrapper>
          {isRootWorkspace && isAddEmployerEnabled &&
            <TileItem>
              <TileItem.Content>
                <button
                  type="button"
                  onClick={this.openEmployerModal}
                  className={styles.moreAppsButton}
                >
                  <TileContent
                    title="Add Employer"
                    iconUrl="/images/add-employer.jpg"
                  />
                </button>
              </TileItem.Content>
            </TileItem>}
          {
            sortedApps.map(item => (
              <WorkspaceDescriptorItem
                key={item.appId}
                item={item}
                wsHostTenantId={workspaceDescriptor.tenantId}
                sendFeedback={this.showFeedbackModal}
                removeInstalledApp={this.removeInstalledApp}
                showOnboardingSvcModal={this.showOnboardingSvcModal}
              />
            ))
          }
          {isMoreServicesVisible &&
          <TileItem>
            <TileItem.Content>
              <button
                type="button"
                onClick={this.openAppsModal}
                className={styles.moreAppsButton}
              >
                <TileContent
                  description="Add more services"
                  title="More services"
                  iconUrl="/images/addservices.svg"
                />
              </button>
            </TileItem.Content>
          </TileItem>}
        </TilesWrapper>
      </>
    );
  }
}

export default WorkspaceDescriptor;
