/* eslint-disable react/no-array-index-key */
import React from "react";

interface Props<T> {
  columns: TableColumn<T>[];
  hasCheckboxes?: boolean;
}

export function ColGroup<T>({ columns, hasCheckboxes }: Props<T>) {
  return (
    <colgroup>
      {hasCheckboxes && (<col span={1} style={{ width: 30 }} />) }
      { columns.map(({ width }, index) => <col key={index} span={1} style={{ width }} />)}
    </colgroup>
  );
}
