import * as React from 'react';
import { inject } from 'mobx-react';
import Linkify from 'react-linkify';
import { UnuSupportAction } from 'interfaces';
import { CustomButton, ButtonHolder } from 'components';
import { InboxStore, UIStore } from 'stores';
import { UnuSupportActionType, STORE_INBOX, STORE_UI } from 'appConstants';
import styles from './SupportModal.module.scss';

interface DefaultProps {
  [STORE_INBOX]: InboxStore,
  [STORE_UI]: UIStore
}

export interface Props extends Partial<DefaultProps> {
  action: UnuSupportAction;
  title: string;
  itemId: string;
}

@inject(STORE_INBOX, STORE_UI)
export default class SupportModal extends React.PureComponent<Props> {
  handleSupportChat = () => {
    const { title, itemId } = this.props;
    this.props[STORE_UI]?.closeModal();
    this.props[STORE_INBOX]?.sendFeedback(itemId, title);
  };

  render() {
    const { action } = this.props;
    const hasChatOption = action.type === UnuSupportActionType.SUPPORT_CHAT;

    return (
      <>
        <p className={styles.supportText}>
          <Linkify>
            {action.publicAttributes.welcomeMessage}
          </Linkify>
        </p>

        {hasChatOption && (
          <>
            <h2>Talk to us about Coll8</h2>
            <p>If your support request or feedback is about the use of Coll8 and not this service, you can chat with the Coll8 team.</p>
            <ButtonHolder className={styles.buttonHolder}>
              <CustomButton buttonType="primary" onClick={this.handleSupportChat}>Chat about Coll8</CustomButton>
            </ButtonHolder>
          </>
        )}
      </>
    );
  }
}
