/* eslint-disable no-prototype-builtins */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import { observable, action, computed, runInAction } from 'mobx';
import { formatDistance, compareDesc } from 'date-fns';
import Catch from 'catch-decorator';
import {
  STORE_ROUTER,
  MATRIX_BOT_TOPIC,
  MembershipStatus,
  MatrixRoomVisibility,
  PendingEventOrdering,
} from 'appConstants';
import { toJS } from 'mobx';
import {
  MatrixRoom,
  MatrixCredentials,
  MatrixMessage,
  MatrixRoomFullInfo,
  MatrixTimelineEvent,
} from 'interfaces';
import { safeJsonParse } from 'utils/safeJsonParse';
import {
  SYSTEM_MESSAGE_USER_ID,
  MATRIX_WELCOME_MESSAGE,
  MATRIX_INVITATION_MESSAGE,
  MATRIX_INVITATION_ACCEPTED_MESSAGE,
} from 'appConstants';
import { matrixService } from 'services';
import { feedbackIdByAppId } from 'utils/getFeedbackIdByAppId';
import { RootStore } from './RootStore';
import { catchXhr } from '../services/errorService';

const botId = process.env.REACT_APP_BOT_ID;
const MATRIX_MESSAGE_EVENT_TYPE = "m.room.message";
export const MATRIX_ROOM_MEMBER_EVENT_TYPE = 'm.room.member';

export function getChatBotUtteranceMessage(title: string): string {
  return `Hi, I would like to leave feedback about ${title}`;
}

export class InboxStore {
  rootStore: RootStore;

  @observable allMessages: MatrixMessage[] = [];

  @observable roomMessages: MatrixMessage[] = [];

  @observable RoomsAndMessages = [];

  @observable rooms: MatrixRoom[] = [];

  @observable currentRoomId: string | null = null;

  @observable currentRoomName: string | null = null;

  @observable matrixCredentials: MatrixCredentials | null = null;

  @observable totalChatsUnread = 0;

  @observable totalChatsInvited = 0;

  @observable userCanSendMessages = true;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed get totalUnreadAndInvited(): number {
    return this.totalChatsUnread + this.totalChatsInvited;
  }

  @computed get sortedRooms() {
    const rooms = toJS(this.rooms);
    return rooms.sort((a, b) => compareDesc(+a.dateTime, +b.dateTime));
  }

  setCurrentRoomId = (roomId: string) => {
    runInAction(() => {
      this.currentRoomId = roomId;
    });
  };

  async matrixLogin() {
    try {
      const matrixCredentials = await matrixService.login();
      runInAction(() => {
        this.matrixCredentials = matrixCredentials;
      });
      await this.matrixInit();
    } catch (e) {
      console.error(e);
    }
  }

  @action
  async matrixLogout() {
    try {
      await matrixService.logout();
      runInAction(() => {
        this.allMessages = [];
        this.roomMessages = [];
        this.RoomsAndMessages = [];
        this.rooms = [];
        this.currentRoomId = null;
        this.currentRoomName = null;
        this.matrixCredentials = null;
        this.totalChatsUnread = 0;
        this.totalChatsInvited = 0;
      });
    } catch (e) {
      console.error(e);
    }
  }

  matrixInit = async () => {
    if (!this.matrixCredentials) {
      return;
    }
    try {
      await matrixService.startClient({
        pendingEventOrdering: PendingEventOrdering.DETACHED,
      });
      this.syncTimeline();
      matrixService.onSync(this.updateRoomsList);
    } catch (e) {
      console.error(e);
    }
  };

  @action
  async syncTimeline() {
    this.allMessages = [];
    try {
      matrixService.onInvite(this.updateRoomsList, this.matrixCredentials);
      const updateData = (event: MatrixTimelineEvent) => {
        if (event.getType() !== MATRIX_MESSAGE_EVENT_TYPE) {
          return; // only use messages
        }
        const message = {
          _id: event.event.event_id,
          text: event.event.content.body,
          createdAt: (new Date(event.event.origin_server_ts)).toUTCString(),
          user: {
            _id: event.sender.userId,
            name: event.sender.name,
          },
          roomId: event.event.room_id,
        };
        this.allMessages.unshift(message);
        this.updateRoomsList();
        if (this.currentRoomId === event.event.room_id) {
          this.updateCurrRoomMessages();
        }
      };

      matrixService.onSyncTimeline(this.currentRoomId, updateData);
    } catch (e) {
      console.error(e);
    }
  }

  @action
  updateRoomsList = () => {
    let totalChatsInvited = 0;
    let totalChatsUnread = 0;
    try {
      const rooms: MatrixRoomFullInfo[] = matrixService.getRooms();
      this.rooms = [];
      rooms.forEach(room => {
        if (room._selfMembership !== MembershipStatus.LEAVE) {
          let lastMessage = "";
          let ts = 0;
          room.timeline.forEach((item: MatrixTimelineEvent) => {
            if (item.event.type === MATRIX_MESSAGE_EVENT_TYPE) {
              if (+ts <= +item.event.origin_server_ts) {
                if (room.roomId === this.currentRoomId) {
                  // for current room, each new message should have status "read"
                  matrixService.sendReadReceipt(item);
                }
                ts = item.event.origin_server_ts;
                lastMessage = item.event.content.body;
                const parsedMessage = safeJsonParse<{ prompt: string }>(lastMessage);
                if (parsedMessage !== null) {
                  lastMessage = parsedMessage.prompt;
                }
              }
            }
          });
          let lmTime = "";
          let lmDateTime = "";
          if (ts) {
            lmTime = formatDistance(ts, new Date());
            lmDateTime = ts.toString();
          }
          const unreadMessagesCount = room.getUnreadNotificationCount();
          if (room._selfMembership === MembershipStatus.INVITE) {
            totalChatsInvited++;
          }
          if (unreadMessagesCount > 0 && room.roomId !== this.currentRoomId) {
            totalChatsUnread++;
          }
          this.rooms.push({
            id: room.roomId,
            name: room.name,
            lastMessage: room._selfMembership === MembershipStatus.INVITE ?
              MATRIX_INVITATION_MESSAGE :
              lastMessage,
            time: lmTime,
            unreadCount: unreadMessagesCount,
            img: '',
            dateTime: lmDateTime,
            membershipStatus: room._selfMembership,
          });
        }
      });
      this.totalChatsInvited = totalChatsInvited;
      this.totalChatsUnread = totalChatsUnread;
    } catch (e) {
      console.error(e);
    }
  };

  @action
  updateCurrRoomMessages() {
    this.roomMessages = this.allMessages.filter(item => item.roomId === this.currentRoomId);
    const index = this.rooms.findIndex(r => r.id === this.currentRoomId);
    this.rooms[index].unreadCount = 0;
  }

  joinRoom = (roomId: string) => {
    matrixService.joinRoom(roomId).done(() => {
      runInAction(() => {
        const index = this.rooms.findIndex(r => r.id === roomId);
        this.rooms[index].membershipStatus = MembershipStatus.JOIN;
        this.rooms[index].lastMessage = MATRIX_INVITATION_ACCEPTED_MESSAGE;
        this.totalChatsInvited -= 1;
      });
    });
  };

  leaveCurrentRoom() {
    if (this.currentRoomId) {
      this.leaveRoom(this.currentRoomId);
      this.clearCurrentRoom();
    }
  }

  @action
  async leaveRoom(roomId: string) {
    await matrixService.leaveRoom(roomId);
    this.rooms.forEach((item, index) => {
      if (item.id === roomId) {
        runInAction(() => this.rooms.splice(index, 1));
      }
    });
  }

  @action
  getRoom = async (currentRoomId: string) => {
    if (!this.matrixCredentials) {
      return;
    }
    try {
      const room = matrixService.getRoom(currentRoomId);
      if (room) {
        this.setSendMessagesPermission(room);
      }
      this.currentRoomId = room.roomId;
      this.currentRoomName = room.name;
      this.updateCurrRoomMessages();
      const eventDateTimeline = room.currentState.events.get(MATRIX_ROOM_MEMBER_EVENT_TYPE);
      const userEventDateTimeline = eventDateTimeline.get(this.matrixCredentials.matrixUserId);
      const eventDateTime = userEventDateTimeline.event.origin_server_ts;
      const message = {
        _id: room.currentState.events.get(MATRIX_ROOM_MEMBER_EVENT_TYPE).get(this.matrixCredentials.matrixUserId).event.event_id,
        text: MATRIX_WELCOME_MESSAGE,
        createdAt: (new Date(eventDateTime)).toUTCString(),
        user: {
          _id: SYSTEM_MESSAGE_USER_ID,
          name: '',
        },
        roomId: room.roomId,
      };
      this.roomMessages.push(message);
      await Promise.all(room.timeline.map((item: MatrixTimelineEvent) => matrixService.sendReadReceipt(item)));
    } catch (e) {
      console.error(e);
    }
  };

  setSendMessagesPermission(room: any) {
    const roomEventsDefault = room.currentState.events['m.room.power_levels'] ?
      room.currentState.events['m.room.power_levels'][""].event.content.events_default :
      0;
    const userPowerLevel = room.currentState.members[room.myUserId].powerLevel;
    if (userPowerLevel >= roomEventsDefault) {
      this.userCanSendMessages = true;
    } else {
      this.userCanSendMessages = false;
    }
  }

  @action
  setReadMessagesForRoom = () => {
    const { currentRoomId } = this;
    if (!currentRoomId) {
      return;
    }
    const totalChatsUnread = toJS(this.rooms)
      .filter(room => room.unreadCount && room.unreadCount > 0 && room.id !== this.currentRoomId).length;
    runInAction(() => {
      this.totalChatsUnread = totalChatsUnread;
    });
  };

  sendMessage = (message: string) => matrixService.sendMessage(message, this.currentRoomId);

  sendFeedback = (appId: string, title: string) => {
    this.rootStore[STORE_ROUTER].push('/inbox');
    const roomId = this.getRoomIdWithFeedback(appId);
    const message = getChatBotUtteranceMessage(title);
    if (roomId) {
      this.loadRoomWithMessage(roomId, message);
    } else {
      this.createBotRoom(appId, title, message);
    }
  };

  @action
  async createBotRoom(appId: string, appName: string, message: string) {
    this.clearCurrentRoom();
    const feedbackId = feedbackIdByAppId(appId, true);
    const options = {
      room_alias_name: feedbackId,
      visibility: MatrixRoomVisibility.PRIVATE,
      invite: [botId],
      name: `${appName} feedback`,
      topic: MATRIX_BOT_TOPIC,
    };
    const createResponse = await matrixService.createRoom(options);
    this.updateRoomsList();
    if (createResponse && createResponse.room_id) {
      this.loadRoomWithMessage(createResponse.room_id, message);
    }
  }

  @Catch(Error, catchXhr)
  loadRoomWithMessage = (roomId: string, message: string) => {
    this.setCurrentRoomId(roomId);
    setTimeout(() => {
      this.getRoom(roomId);
    });
    return this.sendMessage(message);
  };

  @action
  getRoomIdWithFeedback(appId: string) {
    const rooms = matrixService.getRooms();
    const feedbackId = feedbackIdByAppId(appId);
    let roomId: string | null = null;
    rooms.forEach(async (room: any) => {
      const roomState = room.currentState.events.get('m.room.canonical_alias').get("");
      if (
        roomState.event.content.alias.indexOf(feedbackId) !== -1 &&
        room._selfMembership !== MembershipStatus.LEAVE
      ) {
        // check bot in room
        if (room.currentState.members.hasOwnProperty(botId)) {
          return roomId = room.roomId;
        }
        await matrixService.leaveRoom(room.roomId);
        return null;
      }
    });
    return roomId;
  }

  @action
  clearCurrentRoom() {
    runInAction(() => {
      this.currentRoomId = null;
      this.currentRoomName = null;
      this.roomMessages = [];
    });
  }
}

export default InboxStore;
