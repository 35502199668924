/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import { FormikProps, FieldArrayRenderProps } from 'formik';
import classNames from "classnames";
import { CustomField, CustomSelectField } from 'components';
import { SelectOption, ServiceFormAttributeMapping, ServiceFormValues } from 'interfaces';
import { UserAttributeDefinitionType } from "interfaces/userAttributes";
import AttributeCheckbox from "./AttributeCheckbox";
import styles from './CreateServiceModal.module.scss';
import { SSOAttributeType } from "../../../../appConstants";

export interface AttributeSelectOption extends SelectOption {
  description: string;
  type: UserAttributeDefinitionType;
}

interface Props {
  form: FormikProps<ServiceFormValues>;
  ssoAttributeOptions: AttributeSelectOption[];
  arrayHelpers: FieldArrayRenderProps;
  attribute: ServiceFormAttributeMapping;
  index: number;
  onRemoveAssertionItem: (index: number) => void;
}

export default class AttributeField extends React.Component<Props> {
  onRemove = () => {
    this.props.onRemoveAssertionItem(this.props.index);
    this.props.arrayHelpers.remove(this.props.index);
  };

  onAttributeDefinitionSelect = (name: string, option: AttributeSelectOption) => {
    this.props.form.setFieldValue(`attributeMapping[${this.props.index}].description`, option.description);
    this.props.form.setFieldValue(`attributeMapping[${this.props.index}].attributeType`, option.type);
  };

  render() {
    const { form, ssoAttributeOptions, index, attribute } = this.props;
    const selectedAttributes = form.values.attributeMapping.map(att => att.attributeValue.value);
    const availableOptions = ssoAttributeOptions.filter(option => !selectedAttributes.includes(option.value));
    const isFirstItem = index === 0;
    const nameTitle = isFirstItem ? "Attribute name" : "";
    const valueTitle = isFirstItem ? "Attribute value" : "";
    const descriptionTitle = isFirstItem ? "Description" : "";
    return (
      <div className={classNames(styles.paneHolder, styles.attrRowHolder)} key={index}>
        <AttributeCheckbox
          className={styles.verifiedCheckboxHolder}
          form={form}
          name={`attributeMapping.${index}.verifiedOnly`}
          title={index === 0 ? "Verified" : ""}
        />
        <AttributeCheckbox
          className={styles.requiredCheckboxHolder}
          form={form}
          name={`attributeMapping.${index}.required`}
          title={index === 0 ? "Required" : ""}
        />
        <div className={styles.col4}>
          <CustomField
            data-testid={`attribute${index}Name`}
            name={`attributeMapping.${index}.name`}
            title={nameTitle}
            placeholder="Enter attribute name..."
            form={form}
            onChange={form.handleChange}
          />
        </div>
        <div className={styles.col4}>
          <CustomField<AttributeSelectOption>
            data-testid={`attribute${index}Value`}
            name={`attributeMapping.${index}.attributeValue`}
            title={valueTitle}
            component={CustomSelectField}
            options={availableOptions}
            form={form}
            onSelectChange={this.onAttributeDefinitionSelect}
            menuPlacement="top"
          />
          {attribute.attributeType === SSOAttributeType.COMPOSITE && (
            <CustomField
              data-testid={`attribute${index}CompositeAttributePath`}
              name={`attributeMapping.${index}.compositeAttributePath`}
              placeholder="Enter JSON path..."
              form={form}
              onChange={form.handleChange}
            />
          )}
        </div>
        <div className={styles.col4}>
          <CustomField
            data-testid={`attribute${index}Desc`}
            name={`attributeMapping.${index}.description`}
            type="text"
            title={descriptionTitle}
            form={form}
            onChange={form.handleChange}
          />
        </div>
        {!isFirstItem &&
        <div className={styles.removeHolder}>
          <i role="button" title="Close" onClick={this.onRemove} className={classNames("icon-close", styles.close)} />
        </div>}
      </div>
    );
  }
}
