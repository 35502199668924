import * as React from 'react';
import styles from './NotFoundPage.module.scss';

export const NotFoundPage = () => {
  return (
    <div className={styles.container}>
      Page not found.
    </div>
  );
};
