import { Workspace } from "models/Workspace";
import { unuActionsService } from "services";
import { WorkspaceFormValues } from "interfaces";
import { UnuSupportActionType, UnuActions } from "appConstants";

export default function workspaceFormToConfig({
  title,
  description,
  tenantId,
  clientId,
  sso,
  provider,
  accessType,
  unuSupportActionType,
  supportMessage,
}: WorkspaceFormValues, currentWorkspace: Workspace | null, dataUrl: string | null, name: string | null) {
  const icon = name && dataUrl ? { name, dataUrl } : null;
  const actions = unuSupportActionType.value !== UnuSupportActionType.NO_SUPPORT ? {
    [UnuActions.unuSupport]: unuActionsService.createSupportAction(unuSupportActionType.value, supportMessage),
  } : {};
  return Object.assign({}, currentWorkspace, {
    config: {
      props: {
        title,
        description,
        icon,
        sso,
        provider: provider ? provider.value : provider,
        clientId,
        tenantId,
        actions,
      },
    },
    name: title,
    accessType: accessType ? accessType.value : accessType,
  });
}
