import React from 'react';
import * as Yup from 'yup';
import { Formik } from "formik";
import { VALIDATION_RULES, getNameValidationRules } from "validation";
import CreateGroupUserForm from './CreateGroupUserForm';
import GroupUser from '../../../models/GroupUser';
import { requiredFirstNameMessage, requiredLastNameMessage } from '../../../validation/messages';

export interface Props {
  submitError: null | string;
  onSubmit: (groupId:string, user: GroupUser) => void | string;
  groupId: string;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  email: VALIDATION_RULES.email,
  firstName: getNameValidationRules({
    label: "First name",
    requiredMessage: requiredFirstNameMessage,
  }),
  lastName: getNameValidationRules({
    label: "First name",
    requiredMessage: requiredLastNameMessage,
  }),
});

const initialValues = { email: '', firstName: '', lastName: '' };

export default class CreateGroupUserModal extends React.Component <Props> {
  onSubmit = ({ email, firstName, lastName }: GroupUser) => {
    this.props.onSubmit(this.props.groupId, { email, firstName, lastName });
  };

  render() {
    const { submitError } = this.props;
    return (
      <Formik<GroupUser>
        initialValues={initialValues}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={this.onSubmit}
      >
        {form => (
          <CreateGroupUserForm
            form={form}
            submitError={submitError}
            setErrors={form.setErrors}
          />
        )}
      </Formik>);
  }
}
