import React from 'react';
import classNames from 'classnames';
import styles from './Loader.module.scss';

interface LoaderProps {
  className?: string;
  isFullscreen?: boolean;
  isCentered?: boolean;
  isPadded?: boolean;
}

const Loader = ({ className, isFullscreen, isCentered, isPadded }: LoaderProps) => (
  <div
    data-testid="loader"
    className={classNames(styles.loader, {
      [styles.isFullscreen]: isFullscreen,
      [styles.centered]: isCentered,
      [styles.padded]: isPadded,
    }, className)}
  >
    Loading...
  </div>
);

export default Loader;
