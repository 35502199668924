import { observable, action, runInAction, IObservableArray } from 'mobx';
import Catch from 'catch-decorator';
import repositoryService from 'services/RepositoryService';
import { ServerError } from 'errors';
import { Repository } from 'models/Repository';
import {
  AppStoreType,
  CREATE_SERVICE_SUCCESS,
  SERVICE_TYPES,
  STORE_UI,
  STORE_WORKSPACE,
  STORE_USER, UPDATE_SERVICE_SUCCESS,
  SERVICE_RELEASE_SUCCESS,
  AppStoreItemType,
  PAGINATION_FIRST_PAGE_NUMBER,
} from "appConstants";
import { ServiceData } from 'interfaces';
import { catchServerError } from 'services/errorService';
import { RootStore } from './RootStore';
import serviceFormToConfig from '../services/dataMappers/serviceFormToConfig';

export class RepositoryStore {
  rootStore: RootStore;

  @observable repositories: IObservableArray<Repository> = observable([]);

  @observable repositoriesPaginationConfig: PaginationConfig | null = null;

  @observable isLoading = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  loadNextRepositoryPage = async (page: number = PAGINATION_FIRST_PAGE_NUMBER, term?: string) => {
    const shouldConcatElements = page !== PAGINATION_FIRST_PAGE_NUMBER;
    const { elements, ...paginationData } = await repositoryService.retrieveRepositories({ page, term });
    const repositories = elements.filter(r => r.config && r.config.props);
    const updatedRepositories = shouldConcatElements ?
      this.repositories.concat(repositories) :
      repositories;
    runInAction(() => {
      this.repositories.replace(updatedRepositories);
      this.repositoriesPaginationConfig = paginationData;
    });
  };

  @action
  retrieveRepositories = async (page: number = PAGINATION_FIRST_PAGE_NUMBER, term?: string) => {
    runInAction(() => this.isLoading = true);
    try {
      const { elements, ...paginationData } = await repositoryService.retrieveRepositories({ page, term });
      // filtering repositories with empty config or props
      const repositories = elements.filter(r => r.config && r.config.props);
      runInAction(() => {
        this.repositories.replace(repositories);
        this.repositoriesPaginationConfig = paginationData;
      });
    } catch (e) {
      console.error(`Error fetching repositories: ${e}`);
    } finally {
      runInAction(() => this.isLoading = false);
    }
  };

  @Catch(Error, catchServerError)
  removeService = async (id: string) => {
    await repositoryService.removeService(id);
    runInAction(() => {
      this.repositories.replace(this.repositories.filter(r => r.id !== id));
    });
  };

  @action
  createService = async (serviceData: ServiceData, shouldPublishToUnu: boolean, item?: Repository, shouldBeAddedToCurrentWS?: boolean) => {
    try {
      const serviceConfig = serviceFormToConfig(serviceData);
      const app = await repositoryService.addRepository({ name: serviceConfig.title, type: SERVICE_TYPES.DeepLink });
      const config = await repositoryService.addConfig(app.id, serviceConfig);

      app.config = config;
      runInAction(() => {
        this.repositories.push(app);
      });
      await this.addRelease(app.id, AppStoreType.repository);
      if (shouldPublishToUnu) {
        await this.addRelease(app.id, AppStoreType.unu);
      }
      if (shouldBeAddedToCurrentWS) {
        await this.rootStore[STORE_WORKSPACE].addItem(AppStoreType.repository, AppStoreItemType.MICRO_APP, app.id);
      }
      this.rootStore[STORE_UI].showMessageNotification(CREATE_SERVICE_SUCCESS);
    } catch (e) {
      console.error(e);
      throw new ServerError(e.response.data.message);
    }
  };

  @action
  editService = async (serviceData: ServiceData, shouldPublishToUnu: boolean, item?: Repository) => {
    try {
      if (!item) throw new Error("Cannot edit service");
      const serviceConfig = serviceFormToConfig(serviceData);
      const config = await repositoryService.editConfig(item.id, serviceConfig);
      runInAction(() => {
        const index = this.repositories.findIndex(r => r.id === item.id);
        this.repositories[index].config = config;
      });
      await this.addRelease(item.id, AppStoreType.repository);
      if (shouldPublishToUnu) {
        await this.addRelease(item.id, AppStoreType.unu);
      }
      this.rootStore[STORE_UI].showMessageNotification(UPDATE_SERVICE_SUCCESS);
    } catch (e) {
      console.error(e);
    }
  };

  // publish service to specific store
  @action
  addRelease = async (serviceId: string, appStoreType: AppStoreType = AppStoreType.unu) => {
    try {
      const appStoreId = this.rootStore[STORE_USER].getAppStoreIdByType(appStoreType);
      await repositoryService.addRelease(serviceId, appStoreId);
      this.rootStore[STORE_UI].showMessageNotification(SERVICE_RELEASE_SUCCESS);
    } catch (error) {
      console.error(error);
      this.rootStore[STORE_UI].showMessageNotification("Create release failed");
    }
  };
}

export default RepositoryStore;
