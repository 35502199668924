import React from 'react';
import * as Yup from 'yup';
import { Formik } from "formik";
import { VALIDATION_RULES } from "validation";
import CreateGroupDomainForm, { FormValues } from './CreateGroupDomainForm';

export interface Props {
  submitError: null | string;
  onSubmit: (groupId:string, email: string) => void | string;
  groupId: string;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  domain: VALIDATION_RULES.domain,
});

export default function CreateGroupDomainModal(props: Props) {
  const { submitError } = props;

  const onSubmit = ({ domain }: FormValues) => {
    props.onSubmit(props.groupId, domain.toLowerCase());
  };

  return (
    <Formik
      initialValues={{ domain: '' }}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {form => (
        <CreateGroupDomainForm
          form={form}
          submitError={submitError}
          setErrors={form.setErrors}
        />
      )}
    </Formik>
  );
}
