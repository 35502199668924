import * as React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { CustomCheckbox, Loader } from 'components';
import { TableRow, ColGroup, Props as TableRowProps } from "./components";
import styles from './Table.module.scss';
import Pagination from "../Pagination/Pagination";

interface Props<T> {
  title?: string;
  // function for setting checkboxes as disabled
  allowItemCheck?: (item: T) => boolean;
  // rowKey should be key of T Object and used as key for react row render
  rowKey?: string;
  className?: string;
  columns: Array<TableColumn<T>>;
  values: T[];
  selectedItems?: T[],
  hasCheckboxes?: boolean;
  onSelectionChange?: (items: T[]) => void;
  rowProps?: (item: T, index: number) => Partial<TableRowProps<T>>;
  isLoading?: boolean;
  isStriped?: boolean;
  paginationConfig?: PaginationConfig | null;
  onPageChange?: (selectedItem: { selected: number }) => void;
}

const initialState = { checked: [] as boolean[] };
type State = typeof initialState;

@observer
export class Table<T> extends React.Component<Props<T>, State> {
  readonly state = initialState;

  static getDerivedStateFromProps(nextProps: any) {
    // don't update state in case we don't control selection
    if (!nextProps.selectedItems) return null;
    const checked = new Array(nextProps.values.length);
    nextProps.selectedItems.forEach((item: any) => {
      const checkIndex = nextProps.values.findIndex((value: any) => value[nextProps.rowKey] === item[nextProps.rowKey]);
      checked[checkIndex] = checkIndex !== -1;
    });
    return {
      checked,
    };
  }

  componentDidUpdate() {
    if (this.props.values.length !== this.state.checked.length) {
      const resized = new Array(this.props.values.length);
      this.setState({ checked: resized }); // eslint-disable-line react/no-did-update-set-state
      this.triggerSelectionChange(resized);
    }
  }

  checkIndex = (index: number) => {
    const { checked } = this.state;
    checked[index] = checked[index] ? !checked[index] : true;
    this.setState({ checked });
    this.triggerSelectionChange(checked);
  };

  toggleAll = () => {
    const countSelected = this.state.checked.filter(i => i).length;
    if (countSelected > 0) {
      this.setState({ checked: [] });
      this.triggerSelectionChange([]);
    } else {
      const checked = this.props.values.map(this.allowItemCheck);
      this.setState({ checked });
      this.triggerSelectionChange(checked);
    }
  };

  triggerSelectionChange = (checked: Array<boolean>) => {
    const result = checked.map((checkmark, index) => {
      if (checkmark) {
        return this.props.values[index];
      }
      return undefined;
    }).filter(item => !!item) as T[];
    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(result);
    }
  };

  allowItemCheck = (item: T) => (this.props.allowItemCheck ? this.props.allowItemCheck(item) : true);

  render() {
    const countChecked = this.state.checked.filter(i => i).length;
    const { values, columns, hasCheckboxes = true, rowKey = 'id', isLoading, isStriped, paginationConfig, className } = this.props;
    const tableTitle = this.props.title;
    const hasDisabledCheckbox = this.props.values.some(item => !this.allowItemCheck(item));
    if (isLoading) {
      return <Loader />;
    }

    return (
      <>
        {tableTitle && (
        <strong className={styles.title}>
          {tableTitle}
        </strong>)}
        <table className={classNames(styles.host, className, { [styles.withCheckbox]: hasCheckboxes })}>
          <ColGroup<T> columns={columns} hasCheckboxes={hasCheckboxes} />
          <thead>
            <tr>
              { hasCheckboxes && (
                <td className={styles.checkboxCell}>
                  <CustomCheckbox
                    className={styles.checkbox}
                    disabled={hasDisabledCheckbox}
                    checked={countChecked > 0}
                    onChange={this.toggleAll}
                  />
                </td>)}
              { columns.map(({ title, element }) => <td key={title}>{element ? <div>{element}</div> : title }</td>)}
            </tr>
          </thead>
        </table>
        <div className={styles.stylesOverlap}>
          { values.length ? (
            <table className={classNames(styles.host, { [styles.striped]: isStriped, [styles.withCheckbox]: hasCheckboxes }, className)}>
              <ColGroup<T> columns={columns} hasCheckboxes={hasCheckboxes} />
              <tbody>
                {this.props.values.map((item, index) => {
                  const allowCheck = this.allowItemCheck(item);
                  const otherProps = this.props.rowProps ? this.props.rowProps(item, index) : {};
                  const key = (item as any)[rowKey];
                  return <TableRow<T>
                    key={key}
                    hasCheckboxes={hasCheckboxes}
                    checkboxDisabled={!allowCheck}
                    index={index}
                    value={item}
                    checked={this.state.checked[index]}
                    onChange={this.checkIndex}
                    columns={this.props.columns}
                    {...otherProps}
                  />;
                })}
              </tbody>
            </table>) : <div className={styles.emptyState}>No data to display</div> }
        </div>
        {paginationConfig && paginationConfig.pageCount > 1 && (
        <Pagination
          pageCount={paginationConfig.pageCount}
          onPageChange={this.props.onPageChange}
          forcePage={paginationConfig.page - 1}
        />
        )}
      </>);
  }
}
