import * as Yup from 'yup';
import isValidDomain from 'is-valid-domain';
import { format, isValid } from 'date-fns';
import { UserAttributeDefinition } from '../interfaces/userAttributes';
import { groupRulesService } from '../services';
import { domainErrorMessage } from './messages';

// to extend types please refer to @types/yup

const dobRegularExpressionRule = /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/;

const invalidDate = new Date("");
Yup.addMethod<Yup.DateSchema>(Yup.date, "format", (dateFormat: string) => {
  return Yup.date().transform((currentValue: Date, originalValue: Date | string) => {
    try {
      if (currentValue === originalValue) {
        return new Date();
      }
      const originalStringValue = String(originalValue);
      const formatValidationResult: RegExpMatchArray = originalStringValue.match(dobRegularExpressionRule) || [];
      if (!formatValidationResult.length) {
        return invalidDate;
      }
      const splittedDate = originalStringValue.split('/');
      const radix = 10;
      const resultDate = new Date(parseInt(splittedDate[2], radix), parseInt(splittedDate[1], radix) - 1, parseInt(splittedDate[0], radix));

      if (!isValid(resultDate)) {
        return invalidDate;
      }

      const result: string = format(resultDate, dateFormat);

      if (result !== originalValue) {
        return invalidDate;
      }

      return resultDate;
    } catch (err) {
      return invalidDate;
    }
  });
});

Yup.addMethod(Yup.string, 'hasAttrDefinitionForPath', function checkDefinitionForPath(
  message: string,
  definitionByNameMap: Record<string, UserAttributeDefinition>
) {
  return this.test({
    message,
    test: (jsonPath: string) => {
      if (!jsonPath) return true; // allow empty path
      try {
        const name = groupRulesService.getAttributeName(jsonPath);
        return !!definitionByNameMap[name];
      } catch (error) {
        return false;
      }
    }
  });
});

Yup.addMethod(Yup.string, 'domain', function pattern(name, message = domainErrorMessage) {
  return this.test({
    message,
    test: value => (value === null || value === '' || value === undefined) || isValidDomain(value),
  });
});

Yup.addMethod(Yup.string, 'uniqueStringInArray', function uniqueStringInArray(message) {
  return this.test('unique', message, function test(value) {
    if (!value) {
      return true;
    }
    const { path } = this;
    const options = [...this.parent];
    const currentIndex = options.indexOf(value);
    options.splice(currentIndex, 1);
    if (options.some(option => option === value)) {
      throw this.createError({
        path,
        message,
      });
    }
    return true;
  });
});

Yup.addMethod(Yup.object, 'uniqueObjectKeyInArray', function uniqueObjectKeyInArray(message: string, key: string) {
  return this.test('unique', message, function test(value) {
    if (!value) {
      return true;
    }
    const { path } = this;
    const options = [...this.parent];
    const currentIndex = options.indexOf(value);
    options.splice(currentIndex, 1);
    if (options.some(option => option[key] === value[key])) {
      throw this.createError({
        path: `${path}.${key}`,
        message,
      });
    }
    return true;
  });
});
