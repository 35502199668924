import lscache from 'lscache';
import queryString from 'query-string';
import providers from "./providers";
import { SSOProvider, WorkspaceDescriptorConfigProps } from "../../interfaces";
import isMobile from "../../utils/isMobile";
import { SSO_SIGN_IN_POPUP_TITLE } from "../../stores";

const DEFAULT_WINDOW_POPUP_WIDTH = 800;
const DEFAULT_WINDOW_POPUP_HEIGHT = 450;

export const SSO_LANDING_DATA_SOURCE = "sso-landing";

type Func = (message?: string) => any;

type PostMessageEvent = {
  origin: string;
  data: any;
};

type SsoProvidersMap = {
  [name: string]: SSOProvider
};

class SsoProviderService {
  private providers: SsoProvidersMap;

  private newWindow: any;

  private currentProvider: SSOProvider | null = null;

  private curResolver: Func | null = null;

  private curRejector: Func | null = null;

  constructor(ssoProviders: SsoProvidersMap) {
    this.providers = ssoProviders;
    window.addEventListener("message", this.handleSSOFrameMessage, false);
    window.addEventListener("storage", this.handleSSOFrameMessageFromStorage);
  }

  setCurrentProvider(providerKey: string) {
    if (providerKey in this.providers) {
      this.currentProvider = this.providers[providerKey];
    }
  }

  ssoResolving = (tokenName: string, response: KV) => {
    if (!(tokenName in response)) {
      this.curRejector && this.curRejector("No token in response.");
      return;
    }
    this.curResolver && this.curResolver();

    this.curResolver = null;
    this.curRejector = null;
    if (this.newWindow) {
      this.newWindow.close();
    }
  };

  handleSSOFrameMessage = (event: PostMessageEvent) => {
    if (event &&
        event.data &&
        event.data.source === SSO_LANDING_DATA_SOURCE &&
        event.origin === process.env.REACT_APP_SSO_LANDING_ORIGIN) {
      if (!this.currentProvider) {
        this.curRejector && this.curRejector("No current provider.");
        return;
      }
      const response = this.currentProvider.parseAuthResponse(event.data.locationHash);
      const { tokenName } = this.currentProvider;

      this.ssoResolving(tokenName, response);
    }
  };

  handleSSOFrameMessageFromStorage = (e: StorageEvent) => {
    // use storage to communicate with
    const params = queryString.parse(e.url.split('?')[1]);
    // eslint-disable-next-line no-underscore-dangle
    const hash = params._ssoResponse;
    if (!hash || !this.currentProvider || typeof hash !== 'string') return;
    const response = this.currentProvider.parseAuthResponse(hash);
    const { tokenName } = this.currentProvider;
    this.ssoResolving(tokenName, response);
    // to notify iframe to close the window
    lscache.set('sso_success', Date.now());
  };

  /**
   * Set current provider, get sso url, open popup window
   * @param param0
   */
  handleSsoAuth({ tenantId, clientId, provider }: WorkspaceDescriptorConfigProps): Promise<string | undefined> {
    this.setCurrentProvider(provider);
    const url = this.getAuthUrl(provider, clientId, { tenantId });
    // set some value to fire storage event from iframe (for IE);
    lscache.set('ssoEnabledForIE', Date.now());
    this.popupCenter(url, SSO_SIGN_IN_POPUP_TITLE);
    return new Promise((resolve: Func, reject: Func) => {
      this.curResolver = resolve;
      this.curRejector = reject;
    });
  }

  /**
 * Get auth url for given provider
 * @param provider Provider name
 * @param clientId Provider's client id
 * @param providerSpecificData contains tenant id for microsoft sso
 */
  getAuthUrl(provider: string, clientId: string, providerSpecificData?: { tenantId: string }): string {
    if (provider in this.providers) {
      return this.providers[provider].getAuthUrl(clientId, window.location, providerSpecificData);
    }

    throw new Error(`No provider was found for key ${provider}`);
  }

  /**
 * Open new window in center existing, in mobile existing will be covered by this new one
 * @param url - new window url
 * @param title
 * @param w - width
 * @param h - height
 */
  popupCenter(url: string, title: string, w: number = DEFAULT_WINDOW_POPUP_WIDTH, h: number = DEFAULT_WINDOW_POPUP_HEIGHT): void {
    const screenX = typeof window.screenX !== 'undefined' ? window.screenX : window.screenLeft;
    const screenY = typeof window.screenY !== 'undefined' ? window.screenY : window.screenTop;
    const outerWidth = typeof window.outerWidth !== 'undefined' ? window.outerWidth : document.documentElement.clientWidth;
    const outerHeight = typeof window.outerHeight !== 'undefined' ? window.outerHeight : document.documentElement.clientHeight - 22;
    const targetWidth = isMobile() ? outerWidth : w;
    const targetHeight = isMobile() ? outerHeight : h;
    const V = screenX < 0 ? window.screen.width + screenX : screenX;
    const left = parseInt((V + (outerWidth - targetWidth) / 2).toString(), 10);
    const right = parseInt((screenY + (outerHeight - targetHeight) / 2.5).toString(), 10);
    const features = [];
    if (targetWidth !== null) {
      features.push(`width=${targetWidth}`);
    }
    if (targetHeight !== null) {
      features.push(`height=${targetHeight}`);
    }
    features.push(`left=${left}`);
    features.push(`top=${right}`);
    features.push('scrollbars=1');

    if (!this.newWindow || this.newWindow.closed) {
      this.newWindow = window.open(url, title, features.join(','));
    }

    if (this.newWindow && window.focus) {
      this.newWindow.focus();
    }
  }
}

export default new SsoProviderService(providers);
