import { RetrieveUsersRequest, UserGroupApprovalStatus } from 'interfaces';
import { HttpService } from "./http";
import Group from "../models/Group";
import GroupUser from "../models/GroupUser";
import ApproveUser from '../models/ApproveUser';
import { CsvProcessingStatus } from '../appConstants';

export type ApprovalStatusModificator = {
  id: string,
  status: UserGroupApprovalStatus
};

export type CsvUploadData = {
  objectKey: string,
  uploadUrl: string
};

export type CsvUploadStatus = {
  status: CsvProcessingStatus,
  error: string | null
};

export class GroupService {
  http: HttpService;

  httpAdmin: HttpService;

  constructor(http: HttpService, httpAdmin: HttpService) {
    this.http = http;
    this.httpAdmin = httpAdmin;
  }

  loadGroup = (groupId: string): Promise<Group> => this.http.get<Group>(`/groups/${groupId}`);

  updateGroup = (groupId: string, body: Partial<Group>): Promise<Group> => this.http.put(`/groups/${groupId}`, { body });

  retrieveGroupUsers = ({ groupId, ...params }: RetrieveUsersRequest): Promise<PagedResponse<GroupUser>> => {
    return this.http.get<PagedResponse<GroupUser>>(`/groups/${groupId}/users`, { queryStringParameters: params });
  };

  addUser = (groupId: string, user: GroupUser): Promise<GroupUser> => {
    return this.http.post<GroupUser>(`/groups/${groupId}/users`, { body: user });
  };

  deleteUser = (groupId: string, email: string): Promise<void> => {
    return this.http.delete<void>(`/groups/${groupId}/users/${encodeURIComponent(email)}`);
  };

  addDomain = (groupId: string, domain: string): Promise<string> => {
    return this.http.post<string>(`/groups/${groupId}/domains`, { body: { domain } });
  };

  deleteDomains = (groupId: string, domains: string[]): Promise<void> => {
    return this.http.delete<void>(`/groups/${groupId}/domains`, { body: { domains } });
  };

  loadTenants = (groupId: string, params: PagedRequest): Promise<PagedResponse<string>> => {
    return this.http.get<PagedResponse<string>>(`/groups/${groupId}/sharing/tenants`, { queryStringParameters: params });
  };

  shareGroupWithTenant = (groupId: string, tenantName: string): Promise<void> => {
    return this.http.post<void>(`/groups/${groupId}/sharing/tenants`, { body: { actions: ['Read'], tenantName } });
  };

  unshareTenant = (groupId: string, tenantId: string): Promise<void> => {
    return this.http.delete<void>(`/groups/${groupId}/sharing/tenants/${tenantId}`);
  };

  loadUsersToApprove = ({ groupId, ...params }: RetrieveUsersRequest): Promise<PagedResponse<ApproveUser>> => {
    return this.http.get<PagedResponse<ApproveUser>>(`/groups/${groupId}/approvals`, { queryStringParameters: params });
  };

  modifyApprovals = (groupId: string, body: ApprovalStatusModificator[]): Promise<PagedResponse<ApproveUser>> => {
    return this.http.put<PagedResponse<ApproveUser>>(`/groups/${groupId}/approvals`, { body });
  };

  getCsvPreSignUpUrl = (groupId: string): Promise<{ downloadUrl: string }> => {
    return this.http.get(`/groups/${groupId}/users/csv`);
  };

  getUploadUrl = () => this.httpAdmin.post<CsvUploadData>('/uploads', {});

  checkUserListAddStatus = (groupId: string, objectKey: string) => {
    return this.http.post<CsvUploadStatus>(`/groups/${groupId}/users/uploads/${objectKey}`, {});
  };

  checkUserListRemoveStatus = (groupId: string, objectKey: string) => {
    return this.http.delete<CsvUploadStatus>(`/groups/${groupId}/users/uploads/${objectKey}`);
  };
}
