import { Repository } from "models/Repository";
import {
  SAML_SSO_PRIVATE_EMAIL_LABEL,
  SAML_SSO_PROFILE_KEYS,
  SSOFlowOptions,
  UnuActions,
  UnuSupportActionType,
  UNU_SUPPORT_ACTION_LABEL_BY_TYPE,
} from 'appConstants';
import { ServiceFormValues, UnuSupportAction, SelectOption, ServiceFormAttributeMapping } from 'interfaces';
import ServiceConfiguration from "models/ServiceConfiguration";
import makeSelectOption from "../../../../utils/makeSelectOption";

export const defaultSamlSSONameIdAttribute = {
  value: SAML_SSO_PROFILE_KEYS.PRIVATE_EMAIL,
  label: SAML_SSO_PRIVATE_EMAIL_LABEL,
};

export const emptyAttribute = {
  attributeValue: {
    label: "- Please select -",
    value: '',
  },
  name: '',
  required: true,
  verifiedOnly: false,
  description: "",
};

export const defaultConfig = {
  title: '',
  description: '',
  appUrl: '',
  flowInitiator: SSOFlowOptions[0],
  entityId: '',
  ssoRedirectUrlPost: '',
  encryptAssertion: false,
  spCertificate: '',
  attributeMapping: [emptyAttribute],
  nameIdAttribute: defaultSamlSSONameIdAttribute,
};

export default (item: Repository | undefined, config: ServiceConfiguration | null): ServiceFormValues => {
  const itemConfig = item ? item.config.props : {};
  const unuSupportAction: UnuSupportAction | null | undefined = itemConfig.actions && itemConfig.actions[UnuActions.unuSupport];
  const unuSupportActionType = unuSupportAction?.type ?? UnuSupportActionType.NO_SUPPORT;
  const values: ServiceFormValues = {
    ...defaultConfig,
    ...itemConfig,
    unuSupportActionType: makeSelectOption<UnuSupportActionType>(
      unuSupportActionType,
      UNU_SUPPORT_ACTION_LABEL_BY_TYPE[unuSupportActionType],
    ),
    supportMessage: unuSupportAction?.publicAttributes?.welcomeMessage || '',
  };
  if (typeof values.description !== 'string') {
    // in came null from BE - set it to empty string
    values.description = '';
  }
  if (config && config.props.sso) {
    const { sso } = config.props;
    const { entityId, ssoRedirectUrlPost, encryptAssertion, spCertificate, nameIdAttribute } = sso;
    const attributeMapping: ServiceFormAttributeMapping[] = sso.attributeMapping.map(att => ({
      name: att.serviceAttributeName,
      description: att.description,
      attributeValue: {
        value: att.profileAttributeKey,
        label: att.name,
      },
      required: att.required,
      verifiedOnly: att.verifiedOnly,
      attributeType: att.attributeType,
      compositeAttributePath: att.compositeAttributePath
    }));
    if (nameIdAttribute) {
      // is nameIdAttribute is set - override default
      const attribute = attributeMapping.find(attr => attr.attributeValue.value === nameIdAttribute);
      if (attribute) {
        values.nameIdAttribute = makeSelectOption(attribute.attributeValue.value, attribute.attributeValue.label);
      }
    }
    const flowInitiator = SSOFlowOptions.find(opt => opt.value === sso.flowInitiator);
    values.flowInitiator = flowInitiator as SelectOption; // TODO: remove this assertion
    values.entityId = entityId;
    values.ssoRedirectUrlPost = ssoRedirectUrlPost;
    values.encryptAssertion = encryptAssertion;
    values.spCertificate = spCertificate as string; // TODO: remove this assertion
    values.attributeMapping = attributeMapping;
  }
  return values;
};
