import { observable, action, computed, runInAction } from 'mobx';
import { FetchStatus } from 'appConstants';
import repositoryService from 'services/RepositoryService';
import ServiceConfiguration from "models/ServiceConfiguration";
import { RootStore } from './RootStore';
import { UserAttributeDefinition } from '../interfaces/userAttributes';

export type UserAttr = Pick<UserAttributeDefinition, 'description' | 'displayName' | 'name' | 'archived' | 'type' | 'adminTenantId'>;
export class CurrentServiceStore {
  rootStore: RootStore;

  @observable fetchStatus = FetchStatus.IDLE;
  @observable config: ServiceConfiguration | null = null;
  @observable userAttrs: UserAttr[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed get isLoading(): boolean {
    return this.fetchStatus === FetchStatus.PENDING;
  }

  @action
  resetConfig = () => {
    this.config = null;
    this.fetchStatus = FetchStatus.IDLE;
  };

  loadServiceConfig = async (appId: string): Promise<void> => {
    runInAction(() => this.fetchStatus = FetchStatus.PENDING);
    try {
      const config = await repositoryService.loadConfig(appId);
      runInAction(() => {
        this.config = config;
        this.fetchStatus = FetchStatus.SUCCESS;
      });
    } catch (e) {
      console.error(`Error fetching config: ${e}`);
      runInAction(() => {
        this.fetchStatus = FetchStatus.FAILURE;
      });
    }
  };

  loadUserAttributes = async (): Promise<void> => {
    try {
      const { global, custom } = await repositoryService.loadAvailableUserAttrs();
      const userAttrs = [...global, ...custom];
      runInAction(() => {
        this.userAttrs = userAttrs.map(({
          name,
          type,
          displayName,
          description,
          archived,
          adminTenantId
        }) => ({ name, displayName, description, archived, type, adminTenantId }));
      });
    } catch (error) {
      this.rootStore.uiStore.showErrorNotification();
    }
  };
}

export default CurrentServiceStore;
