import React from 'react';
import { Repository } from 'models/Repository';
import { CustomButton, TileItem, TileContent } from 'components';
import { observer } from 'mobx-react';
import styles from '../Repository.module.scss';

interface Props {
  item: Repository;
  showDeleteModal: (id: string) => void;
  showEditModal: (item: Repository) => void;
}

@observer
class RepositoryServiceItem extends React.Component<Props> {
  showModal = () => this.props.showDeleteModal(this.props.item.id);

  showEdit = () => this.props.showEditModal(this.props.item);

  render() {
    const { item } = this.props;

    return (
      <TileItem>
        <TileItem.Content>
          <TileContent
            title={item.config.props.title}
            iconUrl={item.config.props.iconUrl}
            description={item.config.props.description}
          />
        </TileItem.Content>
        <TileItem.Footer>
          <div className={styles.buttonHolder}>
            <CustomButton
              size="s"
              buttonType="link"
              onClick={this.showEdit}
            >
              Edit
            </CustomButton>
            <CustomButton
              size="s"
              buttonType="linkAlt"
              onClick={this.showModal}
            >
              Delete
            </CustomButton>
          </div>
        </TileItem.Footer>
      </TileItem>
    );
  }
}

export default RepositoryServiceItem;
