import React from "react";
import { FormikProps } from 'formik';
import { ReactTagsProps, Tag } from 'react-tag-autocomplete';
import ReactTag from './ReactTag';

interface FieldProps extends Partial<ReactTagsProps> {
  label?: string;
  form: FormikProps<any>
  name: string;
}

class CustomAutocomplete extends React.Component <FieldProps> {
  handleAddition = (tag: Tag) => {
    const { form, name } = this.props;
    form.setFieldValue(name, form.values[name].concat([tag]));
  };

  handleDelete = (i: number) => {
    const { form, name } = this.props;
    const tags = form.values[name].slice(0);
    tags.splice(i, 1);
    form.setFieldValue(name, tags);
  };

  render() {
    return (
      <ReactTag
        {...this.props}
        handleAddition={this.handleAddition}
        handleDelete={this.handleDelete}
      />
    );
  }
}

export default CustomAutocomplete;
