import React from 'react';
import { Input, Loader, MoreAppsRow } from 'components';
import { Descriptor } from 'models/Descriptor';
import { STORE_DESCRIPTOR, STORE_ROUTER } from 'appConstants';
import { inject, observer } from 'mobx-react';
import { DescriptorStore, RouterStore } from 'stores';
import styles from './MoreApps.module.scss';

export interface MoreAppsProps {
  className: string;
  [STORE_DESCRIPTOR]: DescriptorStore;
  [STORE_ROUTER]: RouterStore;
  workspaceId: string;
}

interface State {
  searchTerm: string;
}

const initialState = {
  searchTerm: '',
};

@inject(STORE_DESCRIPTOR, STORE_ROUTER)
@observer
export class MoreApps extends React.Component<MoreAppsProps, State> {
  state: State = initialState;

  componentDidMount() {
    this.retrieveavailableApps();
    this.props[STORE_DESCRIPTOR].getInstalledApps(this.props.workspaceId);
  }

  retrieveavailableApps = () => {
    const { workspaceDescriptor } = this.props[STORE_DESCRIPTOR];
    if (!workspaceDescriptor) {
      return;
    }
    this.props[STORE_DESCRIPTOR].getAvailableApps(workspaceDescriptor.appId);
  };

  onSearchChange = (e: React.FormEvent<HTMLInputElement>) => this.setState({ searchTerm: e.currentTarget.value });

  onAddApp = (appDescriptor: Descriptor) => this.props[STORE_DESCRIPTOR].addInstalledApp(appDescriptor, this.props.workspaceId);

  render() {
    const { isLoading, availableToInstallApps } = this.props[STORE_DESCRIPTOR];
    const re = new RegExp(`^${this.state.searchTerm}`, 'i');
    const apps = availableToInstallApps ?
      availableToInstallApps.filter(item => re.test(item.config.properties.title)) :
      [];
    return (
      <>
        <div className={styles.inputHolder}>
          <Input placeholder="Search" onChange={this.onSearchChange} />
        </div>
        <div className={styles.appContainer}>
          {isLoading ? <Loader /> :
          <>
            {apps.length === 0 ? <div className={styles.noItems}>There are no items</div> :
            <table className={styles.apps}>
              <tbody>
                {apps.map((app: Descriptor) => <MoreAppsRow key={app.appId} app={app} onAddApp={this.onAddApp} />)}
              </tbody>
            </table>}
          </>}
        </div>
      </>
    );
  }
}
