import * as React from 'react';
import CustomButton from '../CustomButton/CustomButton';
import styles from "./ErrorBoundary.module.scss";
import { DEFAULT_SERVER_ERROR_MESSAGE, Env } from '../../appConstants';

interface Props {}

interface State {
  error: Error | null;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
    });
    if (process.env.NODE_ENV === Env.PROD) {
      // don't need to log this error for dev envs
      // eslint-disable-next-line no-console
      console.error(error);
    }
    // You can also log error messages to an error reporting service here
  }

  handleGoHome = () => {
    window.location.href = "/";
  };

  render() {
    if (this.state.error) {
      // Error path
      return (
        <div className={styles.wrapper}>
          <h2>{ DEFAULT_SERVER_ERROR_MESSAGE }</h2>
          <CustomButton onClick={this.handleGoHome}>
            Go Home
          </CustomButton>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
