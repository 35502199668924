import { observable, action, runInAction, computed, IObservableArray } from 'mobx';
import workspaceService from 'services/WorkspaceService';
import repositoryService from "services/RepositoryService";
import { Workspace } from 'models/Workspace';
import {
  ChangeItemsOrderParams,
  GroupDiff,
  ServiceData,
} from "interfaces";
import {
  WorkspaceAccessType,
  AppStoreType,
  AppStoreItemType,
  STORE_DESCRIPTOR,
  STORE_GROUP,
  STORE_UI,
  CHANGE_ORDER_ERROR,
  CREATE_RELEASE_ERROR,
  REMOVE_WORKSPACE_ITEM_ERROR,
  ADD_WORKSPACE_ITEM_ERROR,
  WORKSPACE_RELEASE_SUCCESS,
  STORE_USER,
  STORE_ROUTER,
  STORE_REPOSITORY,
  UPDATE_SERVICE_SUCCESS,
  PAGINATION_FIRST_PAGE_NUMBER,
  SSOFlowInitiatorType,
} from 'appConstants';
import { WorkspaceItem } from 'models/WorkspaceItem';
import extractItemId from "utils/extractItemId";
import { catchServerError } from 'services/errorService';
import serviceFormToConfig from 'services/dataMappers/serviceFormToConfig';
import { ServerError } from 'errors';
import { RootStore } from './RootStore';

export const sortByOrder = (a: WorkspaceItem, b: WorkspaceItem): number => a.order - b.order;

export const getItemsToChangeOrderFilterFunc = (destinationOrder: number, sourceOrder: number): (item: WorkspaceItem) => boolean => (
  destinationOrder > sourceOrder ?
    (i: WorkspaceItem) => i.order <= destinationOrder && i.order > sourceOrder :
    (i: WorkspaceItem) => i.order >= destinationOrder && i.order < sourceOrder
);

// TODO: split store into Workspace / Workspaces
export class WorkspaceStore {
  rootStore: RootStore;

  @observable workspace?: Omit<Workspace, "items">;

  @observable workspaces: IObservableArray<Workspace> = observable([]);

  @observable workspacesPaginationConfig: PaginationConfig | null = null;

  @observable items: IObservableArray<WorkspaceItem> = observable([]);

  @observable isLoading: boolean = false;

  @observable isDropDisabled = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get isTenantRoot(): boolean {
    if (!this.workspace) return false;
    return this.workspace.id === this.rootStore[STORE_USER].curTenantRootWSId;
  }

  @computed get predefinedItems(): WorkspaceItem[] {
    return this.items.filter(i => i.predefined).sort(sortByOrder);
  }

  @computed get additionalItems(): WorkspaceItem[] {
    return this.items.filter(i => !i.predefined).sort(sortByOrder);
  }

  @computed get highestOrderNumber(): number {
    return Math.max(...this.items.map(item => item.order));
  }

  @action
  retrieveWorkspaceDataByItemId = (
    tenantId: string, itemId: string
  ): Promise<Workspace> => workspaceService.loadWorkspace(tenantId, itemId);

  @action
  retrieveAllWorkspaces = async (page: number = PAGINATION_FIRST_PAGE_NUMBER): Promise<void> => {
    runInAction(() => this.isLoading = true);
    try {
      const { elements, ...paginationData } = await workspaceService.retrieveAllWorkspaces({ page });

      runInAction(() => {
        this.workspaces.replace(elements.filter(item => item.config));
        this.workspacesPaginationConfig = paginationData;
      });
    } catch (err) {
      console.error(err);
    } finally {
      runInAction(() => this.isLoading = false);
    }
  };

  removeWorkspace = async (id: string) => {
    try {
      await repositoryService.removeWorkspace(id);
      runInAction(() => {
        this.workspaces.replace(this.workspaces.filter(r => r.id !== id));
      });
    } catch (error) {
      catchServerError(error);
    }
  };

  @action
  loadNextWorspacePage = async (page: number, term?: string) => {
    const shouldConcatElements = page !== PAGINATION_FIRST_PAGE_NUMBER;
    const { elements, ...paginationData } = await workspaceService.retrieveAllWorkspaces({ page, term });
    const workspaces = elements.filter(item => item.config);
    const updatedWorkspaces = shouldConcatElements ?
      this.workspaces.concat(workspaces) :
      workspaces;
    runInAction(() => {
      this.workspaces.replace(updatedWorkspaces);
      this.workspacesPaginationConfig = paginationData;
    });
  };

  @action
  loadWorkspaceDatabyItemId = async (tenantId: string, itemId: string) => {
    runInAction(() => {
      this.isLoading = true;
    });
    const workspace = await workspaceService.loadWorkspace(tenantId, itemId);
    runInAction(() => {
      const { items, ...rest } = workspace;
      this.workspace = { ...rest };
      this.items.replace(items.sort(sortByOrder));
      this.isLoading = false;
    });
  };

  @action
  getWorkspaceIdByAppId = (appId?: string) => {
    if (!appId) {
      // empty appId means we are requesting root workspace
      return this.rootStore[STORE_USER].curTenantRootWSId;
    }
    this.rootStore[STORE_DESCRIPTOR].cleanDescriptor();
    return extractItemId(appId);
  };

  @action
  loadWorkspaceDataByAppId = (tenantId: string, appId?: string) => {
    const itemId = this.getWorkspaceIdByAppId(appId);
    this.loadWorkspaceDatabyItemId(tenantId, itemId);
  };

  @action
  updateWorkspaceItem = async (workspaceItem: WorkspaceItem) => {
    if (!this.workspace) {
      return;
    }
    const item = {
      ...workspaceItem,
      predefined: !workspaceItem.predefined,
    };
    const wItem = await workspaceService.updateWorkspaceItem(item);
    await this.addRelease();
    runInAction(() => {
      if (this.workspace) {
        const index = this.items.findIndex(i => (i.id === workspaceItem.id));
        this.items[index].predefined = wItem.predefined;
        this.workspace.revision += 1;
      }
    });
  };

  @action
  addWorkspace = async (params: Workspace, { groupIdsToAdd } : GroupDiff) => {
    if (!this.workspace) {
      return;
    }
    const { accessType, name, config } = params;
    const createWorkspaceParams = {
      accessType,
      name,
      config: config.props,
    };

    try {
      const createdWorkspace = await workspaceService.addWorkspace(createWorkspaceParams);
      const workspaceId = createdWorkspace.id;
      const addGroupPromises = groupIdsToAdd.map(id => this.addGroup(id, workspaceId));
      await Promise.all(addGroupPromises);
      await this.addWorkspaceReleaseToRepository(workspaceId);
      const workspaceServiceItem = {
        type: AppStoreItemType.WORKSPACE,
        item: createdWorkspace,
        isAdded: true,
      };
      await this.addItem(AppStoreType.repository, workspaceServiceItem.type, workspaceServiceItem.item.id);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Edit workspace from repository page
   */
  @action
  editRepositoryWorkspace = async (params: Workspace, { groupIdsToAdd, groupIdsToRemove }: GroupDiff, workspaceId: string) => {
    try {
      const { name, accessType, config } = params;
      const workspaceData = { name, accessType, config: config.props };
      await workspaceService.editWorkspace(workspaceId, workspaceData);
      const addGroupPromises = groupIdsToAdd.map(id => this.addGroup(id, workspaceId));
      const removeGroupPromises = groupIdsToRemove.map(id => this.removeGroup(id, workspaceId));
      await Promise.all(addGroupPromises);
      await Promise.all(removeGroupPromises);
      await this.addWorkspaceReleaseToRepository(workspaceId);
      this.retrieveAllWorkspaces();
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Edit current workspace
   */
  @action
  editWorkspace = async (params: Workspace, groupDiff: GroupDiff, workspaceId: string, workspaceItem?: WorkspaceItem) => {
    try {
      if (!workspaceItem) {
        await this.editCurrentWorkspace(params, groupDiff);
      } else {
        await this.editWorkspaceItem(params, workspaceId, workspaceItem, groupDiff);
      }
    } catch (e) {
      console.error(e);
    }
  };

  @action
  addGroup = async (groupId: string, workspaceId: string) => {
    try {
      await workspaceService.addGroup(workspaceId, groupId);
      runInAction(() => {
        const { groups } = this.rootStore[STORE_GROUP];
        if (this.workspace && groups) {
          // TODO: review is name important here
          this.workspace.groups.push({ id: groupId, name: groupId });
          this.workspace.revision += 1;
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  @action
  removeGroup = async (groupId: string, workspaceId: string) => {
    try {
      await workspaceService.deleteGroup(workspaceId, groupId);
      runInAction(() => {
        const { groups } = this.rootStore[STORE_GROUP];
        if (this.workspace && groups) {
          const index = this.workspace.groups.findIndex(gr => (gr.id === groupId));
          this.workspace.groups.splice(index, 1);
          this.workspace.revision += 1;
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  @action
  editCurrentWorkspace = async (params: Workspace, groupDiff: GroupDiff) => {
    if (!this.workspace) {
      return;
    }
    const { config, accessType } = params;
    const { groupIdsToAdd, groupIdsToRemove } = groupDiff;
    const { name } = this.workspace;
    const workspaceId = this.workspace.id;
    const workspaceData = { name, accessType, config: config.props };
    // order is important: change access type > change groups > release
    await workspaceService.editWorkspace(workspaceId, workspaceData);
    if (accessType === WorkspaceAccessType.PRIVATE) {
      const addGroupPromises = groupIdsToAdd.map(id => this.addGroup(id, workspaceId));
      const removeGroupPromises = groupIdsToRemove.map(id => this.removeGroup(id, workspaceId));
      await Promise.all(addGroupPromises);
      await Promise.all(removeGroupPromises);
    }
    await this.addRelease();
    runInAction(() => {
      if (this.workspace && config) {
        this.workspace.config = config;
        this.workspace.revision += 1;
      }
    });
  };

  @action
  editWorkspaceItem = async (params: Workspace, workspaceId: string, workspaceItem: WorkspaceItem, { groupIdsToAdd, groupIdsToRemove }: GroupDiff) => {
    try {
      const { name, accessType, config } = params;
      const workspaceData = { name, accessType, config: config.props };
      // order is important: change access type > change groups > release
      await workspaceService.editWorkspace(workspaceId, workspaceData);
      if (params.accessType === WorkspaceAccessType.PRIVATE) {
        const addGroupPromises = groupIdsToAdd.map(id => this.addGroup(id, workspaceId));
        const removeGroupPromises = groupIdsToRemove.map(id => this.removeGroup(id, workspaceId));
        await Promise.all(addGroupPromises);
        await Promise.all(removeGroupPromises);
      }
      await this.addWorkspaceReleaseToRepository(workspaceId);
      const index = this.items.findIndex(item => item.id === workspaceItem.id);
      if (index >= 0) {
        runInAction(() => {
          const { props } = params.config;
          this.items[index].config = {
            title: props.title,
            description: props.description,
            iconUrl: props.icon ? props.icon.dataUrl : '',
            actions: props.actions,
          };
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  // item has WorkspaceItem fields (provided from workspace page), and extended by Repository item props for editing)
  @action
  editServiceItem = async (serviceData: ServiceData, shouldPublishToUnu: boolean, item: WorkspaceItem) => {
    try {
      const serviceConfig = serviceFormToConfig(serviceData);
      const config = await repositoryService.editConfig(item.id, serviceConfig);
      const index = this.items.findIndex(i => i.itemId === item.id && i.appStoreId === item.appStoreId);
      runInAction(() => {
        const { props } = config;
        this.items[index].config = {
          title: props.title,
          description: props.description,
          iconUrl: props.iconUrl ? props.iconUrl : '',
          appUrl: props.appUrl,
          sso: props.sso ? {
            protocol: props.sso.protocol,
            shouldInitFlow: props.sso.flowInitiator === SSOFlowInitiatorType.SP,
            samlServiceId: props.sso.samlServiceId as string, // the samlServiceId always exist in case existing sso
          } : undefined,
          actions: props.actions,
        };
      });
      await this.rootStore[STORE_REPOSITORY].addRelease(item.id, AppStoreType.repository);
      if (shouldPublishToUnu) {
        await this.rootStore[STORE_REPOSITORY].addRelease(item.id, AppStoreType.unu);
      }
      this.rootStore[STORE_UI].showMessageNotification(UPDATE_SERVICE_SUCCESS);
    } catch (e) {
      console.error(e);
      throw new ServerError(e.response.data.message);
    }
  };

  updateOrder = (newI: WorkspaceItem) => {
    // update order only in one place because other arrays keep reference to it
    const newItemsIndex = this.items.findIndex(oldI => oldI.id === newI.id);
    if (newItemsIndex !== -1) {
      this.items[newItemsIndex].order = newI.order;
    }
  };

  @action
  changeItemsOrder = async ({ destinationOrder, sourceOrder, workspaceId }: ChangeItemsOrderParams, isDestinationChanged = false) => {
    const sourceItem = this.items.find(i => i.order === sourceOrder);
    try {
      runInAction(() => {
        this.isDropDisabled = true;
        if (sourceItem && isDestinationChanged) {
          sourceItem.predefined = !sourceItem.predefined;
        }
      });
      const newItems = await workspaceService.updateItemOrder(destinationOrder, sourceOrder, workspaceId, isDestinationChanged);
      if (isDestinationChanged && sourceItem) {
        await workspaceService.updateWorkspaceItem(sourceItem);
      }
      runInAction(() => {
        newItems.forEach(this.updateOrder);
        if (this.workspace) {
          this.workspace.revision += 1;
        }
      });
      await this.addRelease();
    } catch (error) {
      console.error(error);
      const { location, push } = this.rootStore[STORE_ROUTER];
      // refresh the page in case BE error
      push(location.pathname);
      this.rootStore[STORE_UI].showErrorNotification(CHANGE_ORDER_ERROR);
    } finally {
      runInAction(() => this.isDropDisabled = false);
    }
  };

  @action
  addWorkspaceReleaseToRepository = async (workspaceId: string) => {
    try {
      const appStoreId = await this.rootStore[STORE_USER].getAppStoreIdByType(AppStoreType.repository);
      await workspaceService.addRelease(workspaceId, appStoreId);
      this.rootStore[STORE_UI].showMessageNotification(WORKSPACE_RELEASE_SUCCESS);
    } catch (e) {
      console.error(e);
    }
  };

  @action
  addRelease = async () => {
    try {
      const { currentTenant, curTenantRootWSId } = this.rootStore[STORE_USER];
      if (!currentTenant) {
        throw new Error("No tenant.");
      }
      let workspaceId;
      let appStoreId;
      const descriptor = this.rootStore[STORE_DESCRIPTOR].workspaceDescriptor;

      if (!descriptor && this.workspace) {
        workspaceId = this.workspace.id;
      } else if (descriptor) {
        workspaceId = descriptor.itemId;
        appStoreId = descriptor.appStoreId;
      } else {
        throw new Error("Workspace is unavailable");
      }

      if (!appStoreId) {
        // release tenant root workspace only to root appStore
        const appStoreType = workspaceId === curTenantRootWSId ?
          AppStoreType.root :
          AppStoreType.repository;
        appStoreId = this.rootStore[STORE_USER].getAppStoreIdByType(appStoreType);
      }

      await workspaceService.addRelease(workspaceId, appStoreId);
      this.rootStore[STORE_UI].showMessageNotification(WORKSPACE_RELEASE_SUCCESS);
    } catch (error) {
      console.error(error);
      this.rootStore[STORE_UI].showMessageNotification(CREATE_RELEASE_ERROR);
    }
  };

  @action
  addItem = async (appStoreType: AppStoreType, type: AppStoreItemType, itemId: string) => {
    if (!this.workspace) {
      return;
    }
    const workspaceId = this.workspace.id;
    try {
      const appStoreId = this.rootStore[STORE_USER].getAppStoreIdByType(appStoreType);
      const itemData = {
        workspaceId,
        type,
        appStoreId,
        predefined: false,
        itemId,
      };
      const workspaceItem = await workspaceService.addItem(itemData);
      await this.addRelease();
      runInAction(() => {
        this.items.push(workspaceItem);
      });
    } catch (error) {
      console.error(error);
      this.rootStore[STORE_UI].showErrorNotification(ADD_WORKSPACE_ITEM_ERROR);
      throw error;
    }
  };

  @action
  removeItem = async (itemId: string, appStoreId: string) => {
    try {
      const index = this.items.findIndex(item => (item.itemId === itemId && item.appStoreId === appStoreId));
      if (index === -1) {
        throw new Error("Could not delete non existing item.");
      }
      const item = this.items[index];
      await workspaceService.removeItem(item.workspaceId, item.id);
      await this.addRelease();
      runInAction(() => {
        this.items.remove(item);
      });
    } catch (error) {
      console.error(error);
      this.rootStore[STORE_UI].showMessageNotification(REMOVE_WORKSPACE_ITEM_ERROR);
    }
  };

  @action
  reset() {
    runInAction(() => {
      this.workspace = undefined;
      this.items = observable([]);
      this.isLoading = false;
    });
  }
}

export default WorkspaceStore;
