import { httpTenantService, httpAdminService, httpClientService } from "./http";
import { GroupService } from "./groupService";
import { UnuActionsService } from "./UnuActionsService";
import { MatrixService } from "./matrixService";
import { DeviceService } from "./DeviceService";
import { GroupRulesService } from "./group/GroupRulesService";

export const groupService = new GroupService(httpTenantService, httpAdminService);
export const unuActionsService = new UnuActionsService();
export const matrixService = new MatrixService(httpClientService);
export const deviceService = new DeviceService(httpClientService);
export const groupRulesService = new GroupRulesService();
