export type S3FileMeta = {
  file: string,
  name: string,
  objectKey: string,
  [key: string]: string
};

export default function uploadFileToS3(url: string, file: File) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", url, true);
    xhr.onload = function onload() {
      if (this.status === 204 || this.status === 200) {
        resolve();
      } else {
        reject(this.responseText);
      }
    };
    xhr.onerror = reject;
    xhr.send(file);
  });
}
