import { observable, action, runInAction, computed, IObservableArray } from 'mobx';
import appStoreService from 'services/appStoreService';
import { AppStoreType, STORE_WORKSPACE, STORE_USER } from 'appConstants';
import { WorkspaceServiceItem } from 'interfaces';
import { RootStore } from './RootStore';
import makeAppId from '../utils/makeAppId';

export class ServicesStore {
  rootStore: RootStore;

  @observable services: { [key: string]: IObservableArray<WorkspaceServiceItem> } = {
    [AppStoreType.repository]: observable([]),
    [AppStoreType.unu]: observable([]),
  };

  @observable infinityTokenConfigs: { [key: string]: string | null } = {
    [AppStoreType.repository]: null,
    [AppStoreType.unu]: null,
  };

  @observable loadingServices: string[] = [];

  @observable isLoading: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  getIsAssignStatePending = (id: string) => computed(() => this.loadingServices.includes(id)).get();

  @action
  load = async (appStoreType: AppStoreType, workspaceId: string) => {
    runInAction(() => this.isLoading = true);
    try {
      const workspaceStore = this.rootStore[STORE_WORKSPACE];
      const { curTenantId } = this.rootStore[STORE_USER];
      const appStoreId = await this.rootStore[STORE_USER].getAppStoreIdByType(appStoreType);
      const { elements, next } = await appStoreService.retrieveServices({ appStoreId });
      if (!workspaceStore.workspace) {
        // if we didn't load workspace data yet - we have to request it
        await workspaceStore.loadWorkspaceDatabyItemId(curTenantId, workspaceId);
      }
      const { items } = this.rootStore[STORE_WORKSPACE];
      const workspaceItemsIds = items.map(item => makeAppId(item.itemId, item.appStoreId));
      runInAction(() => {
        this.services[appStoreType].replace(elements.map((appItem): WorkspaceServiceItem => ({
          ...appItem,
          isAdded: workspaceItemsIds.indexOf(makeAppId(appItem.item.id, appStoreId)) !== -1,
        })));
        this.infinityTokenConfigs[appStoreType] = next;
      });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => this.isLoading = false);
    }
  };

  // retrieveFromScratch used if searchTerm was updated: first banch of items should be retrieved
  @action
  loadNextRepoServices = async (appStoreType: AppStoreType, workspaceId: string, term?: string, retrieveFromScratch: boolean = false) => {
    const nextToken = retrieveFromScratch ? null : this.infinityTokenConfigs[appStoreType];
    try {
      const workspaceStore = this.rootStore[STORE_WORKSPACE];
      const { curTenantId } = this.rootStore[STORE_USER];
      const appStoreId = await this.rootStore[STORE_USER].getAppStoreIdByType(appStoreType);
      const { elements, next } = await appStoreService.retrieveServices({ appStoreId, next: nextToken, term });
      if (!workspaceStore.workspace) {
        // if we didn't load workspace data yet - we have to request it
        await workspaceStore.loadWorkspaceDatabyItemId(curTenantId, workspaceId);
      }
      const { items } = this.rootStore[STORE_WORKSPACE];
      const workspaceItemsIds = items.map(item => makeAppId(item.itemId, item.appStoreId));
      const services = elements.map((appItem): WorkspaceServiceItem => ({
        ...appItem,
        isAdded: workspaceItemsIds.indexOf(makeAppId(appItem.item.id, appStoreId)) !== -1,
      }));
      const updatedServices = retrieveFromScratch ?
        services :
        this.services[appStoreType].concat(services);
      runInAction(() => {
        this.services[appStoreType].replace(updatedServices);
        this.infinityTokenConfigs[appStoreType] = next;
      });
    } catch (error) {
      console.error(error);
    }
  };

  serviceAction = async (serviceAction: Promise<void>, id: string) => {
    runInAction(() => this.loadingServices.push(id));
    await serviceAction;
    const index = this.loadingServices.findIndex(item => item === id);
    if (index > -1) {
      runInAction(() => this.loadingServices.splice(index, 1));
    }
  };

  @action addToWorkspaceItems = async (appStoreType: AppStoreType, service: WorkspaceServiceItem) => {
    try {
      const services = this.services[appStoreType];
      await this.serviceAction(
        this.rootStore[STORE_WORKSPACE].addItem(appStoreType, service.type, service.item.id),
        service.item.id,
      );
      const index = services.findIndex(app => (app.item.id === service.item.id));
      if (services[index]) {
        runInAction(() => {
          services[index].isAdded = true;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  @action
  removeFromWorkspaceItems = async (appStoreType: AppStoreType, service: WorkspaceServiceItem) => {
    try {
      const appStoreId = await this.rootStore[STORE_USER].getAppStoreIdByType(appStoreType);
      const services = this.services[appStoreType];
      await this.serviceAction(
        this.rootStore[STORE_WORKSPACE].removeItem(service.item.id, appStoreId),
        service.item.id,
      );
      const index = services.findIndex(app => (app.item.id === service.item.id));
      if (services[index]) {
        runInAction(() => {
          services[index].isAdded = false;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export default ServicesStore;
