import Sockette from "sockette";
import { WS_RECONNECT_ATTEMPTS, WS_TIMEOUT, WebSocketAction } from 'appConstants';

class WebSocketService {
  private ws: Sockette | null = null;

  connect = (
    jwtToken: string,
    onConnect: (e: Event) => void,
    onMessage: (e: MessageEvent) => void,
    onConnectionFailed?: (e: Event) => void,
    onReconnect?: (e: Event) => void,
    onError?: (e: Event) => void,
  ) => {
    const ws = new Sockette(
      `${process.env.REACT_APP_WS_URL}?authorization=${jwtToken}`,
      {
        // The amount of time (in ms) to wait in between reconnection attempts
        timeout: WS_TIMEOUT,
        maxAttempts: WS_RECONNECT_ATTEMPTS,
        onopen: onConnect,
        onmessage: onMessage,
        onreconnect: onReconnect,
        onmaximum: onConnectionFailed,
        onclose: e => console.log("Closed!", e),
        onerror: onError,
      },
    );
    this.ws = ws;
  };

  // TODO: check readyState before ONA-3060
  send = (msg: string) => {
    if (this.ws) {
      this.ws.json({
        action: WebSocketAction.START,
        data: msg,
      });
    }
  };

  sendAuthNotification = (authorizeId: WebSocketAction.AUTH, ssoServiceId: string, username: string) => {
    if (this.ws) {
      this.ws.json({
        action: authorizeId,
        payload: {
          ssoServiceId,
          username,
        },
      });
    }
  };

  close = () => {
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
  };
}

export default new WebSocketService();
