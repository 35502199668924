/* eslint-disable react/no-danger */
import React from 'react';

export interface Props {
  samlForm: string;
}

class SSOSamlResponseForm extends React.Component<Props> {
  private samlResponseRef: React.RefObject<HTMLInputElement> = React.createRef();

  componentDidMount() {
    if (!this.props.samlForm) {
      return;
    }

    if (!this.samlResponseRef.current) {
      return;
    }

    const form = this.samlResponseRef.current.getElementsByTagName("form");
    if (!form.length) {
      return;
    }

    form[0].submit();
  }

  render() {
    return (
      <div ref={this.samlResponseRef} dangerouslySetInnerHTML={{ __html: this.props.samlForm }} />
    );
  }
}

export default SSOSamlResponseForm;
