import { AppStoreItem, RetrieveServicesRequest } from "interfaces";
import { HttpService, httpAdminService } from "./http";

class AppStoreService {
  http: HttpService;

  constructor(http: HttpService) {
    this.http = http;
  }

  retrieveServices = ({ appStoreId, ...params }: RetrieveServicesRequest): Promise<InfinityPagedResponse<AppStoreItem>> => {
    return this.http.get<InfinityPagedResponse<AppStoreItem>>(`/appstores/${appStoreId}/items`, { queryStringParameters: params });
  };
}

export default new AppStoreService(httpAdminService);
