import * as React from 'react';
import { Form, Formik } from "formik";
import { observer, inject } from 'mobx-react';
import { GroupFileStore, UIStore } from 'stores';
import { CustomButton, ButtonHolder, Loader, Alert } from 'components';
import { STORE_GROUP_FILE, CsvUserUploadAction, STORE_UI } from 'appConstants';
import CustomFileField from '../../CustomFileField/CustomFileField';

export interface Props {
  [STORE_GROUP_FILE]: GroupFileStore;
  [STORE_UI]: UIStore;
  action: CsvUserUploadAction;
}

const initialValues = {
  file: null as File | null,
};

type FormValues = typeof initialValues;

@inject(STORE_GROUP_FILE, STORE_UI)
@observer
export default class CsvModal extends React.Component <Props> {
  componentDidMount() {
    this.props[STORE_GROUP_FILE].setS3Metadata();
  }

  componentWillUnmount() {
    this.props[STORE_GROUP_FILE].reset();
  }

  onSubmit = ({ file }: FormValues) => {
    if (file) {
      this.props[STORE_GROUP_FILE].uploadFile(file, this.props.action);
    }
  };

  render() {
    const { isUploadUrlLoading, isCsvUploading, csvUploadError } = this.props[STORE_GROUP_FILE];
    if (isUploadUrlLoading) {
      return <Loader />;
    }
    return (
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={this.onSubmit}
      >
        {form => {
          const isDisabled = !form.values.file;
          return (
            <Form>
              <CustomFileField
                name="file"
                buttonText="Upload csv"
                form={form}
                label="File upload"
                accept=".csv"
              />
              {csvUploadError && form.values.file && <Alert message={csvUploadError} />}
              <ButtonHolder>
                {
                    csvUploadError && form.values.file ? (
                      <CustomButton
                        buttonType="primary"
                        onClick={this.props[STORE_UI].closeModal}
                      >
                        Ok, noted
                      </CustomButton>
                    ) : (
                      <CustomButton
                        disabled={isDisabled}
                        isLoading={isCsvUploading}
                        buttonType="primary"
                        type="submit"
                      >
                        Submit
                      </CustomButton>
                    )
                  }
              </ButtonHolder>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
