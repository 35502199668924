import { action, observable, runInAction, IObservableArray, computed } from "mobx";
import tenantsService from "services/tenantsService";
import { CreateTenantData, TenantWithAdmins } from "interfaces";
import { STORE_USER, PAGINATION_FIRST_PAGE_NUMBER, STORE_GROUP } from "appConstants";
import { ServerError } from 'errors';
import { RootStore } from "./RootStore";

export class TenantsStore {
  rootStore: RootStore;

  @observable isLoading = false;

  @observable all: IObservableArray<TenantWithAdmins> = observable([]);

  @observable tenantsPaginationConfig: PaginationConfig | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get currentTenant() {
    return this.rootStore[STORE_USER].currentTenant;
  }

  @action
  loadTenants = async (page: number = PAGINATION_FIRST_PAGE_NUMBER): Promise<void> => {
    try {
      runInAction(() => this.isLoading = true);
      const response = await tenantsService.retrieveTenants({ page });
      const { elements, ...paginationData } = response;
      runInAction(() => {
        this.all.replace(elements);
        this.tenantsPaginationConfig = { ...paginationData };
      });
    } catch (error) {
      this.rootStore.uiStore.showErrorNotification();
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  goToNextTenantPage = (pageNumber: number) => this.loadTenants(pageNumber + 1);

  @action
  createTenant = async (data: CreateTenantData) => {
    try {
      if (!this.currentTenant) {
        throw new Error("No current tenant.");
      }

      const tenant = await tenantsService.createTenant({
        ...data,
        id: data.alias, // in future id can differ from alias
        parentTenantId: this.currentTenant.id,
      });
      runInAction(() => {
        this.all.push({ ...tenant, admins: data.admins });
        this.rootStore[STORE_GROUP].groups.push(tenant.adminGroup);
      });
      // need to sync current user tenant with BE
      this.rootStore[STORE_USER].loadTenants();
    } catch (error) {
      console.error(error);
      throw new ServerError(error.response.data.message);
    }
  };

  @action
  updateTenant = async (data: CreateTenantData & { id: string }) => {
    try {
      if (!this.currentTenant) {
        throw new Error("No current tenant.");
      }

      const tenant = await tenantsService.updateTenant(data.id, {
        ...data,
        parentTenantId: this.currentTenant.id,
      });
      runInAction(() => {
        const index = this.all.findIndex(t => t.id === tenant.id);
        this.all.splice(index, 1, { ...tenant, admins: data.admins });
      });
      // need to sync current user tenant with BE
      this.rootStore[STORE_USER].loadTenants();
    } catch (error) {
      console.error(error);
      throw new ServerError(error.response.data.message);
    }
  };

  @action
  deleteTenant = async (id: string) => {
    try {
      await tenantsService.deleteTenant(id);
      const tenants = this.all.find(t => t.id === id);
      if (tenants) {
        runInAction(() => this.all.remove(tenants));
        this.rootStore[STORE_USER].removeTenant(id);
      }
      this.loadTenants();
    } catch (error) {
      console.error(error);
    }
  };
}

export default TenantsStore;
