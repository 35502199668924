import { UnuActions, UnuSupportActionType } from "../appConstants";

export interface UnuAction<PublicAttrs = { [key: string]: string }, T = string> {
  type: T;
  name: string;
  publicAttributes: PublicAttrs
}

export type UnuSupportAction = UnuAction<{ welcomeMessage: string }, UnuSupportActionType>;
export type UnuActionsMap = { [UnuActions.unuSupport]: UnuSupportAction };
