import * as React from 'react';
import { inject, observer } from 'mobx-react';
import {
  STORE_REPOSITORY,
  STORE_WORKSPACE,
  STORE_ROUTER,
  STORE_UI,
  DELETE_REPOSITORY_MODAL,
  CREATE_SERVICE_MODAL,
  CREATE_WORKSPACE_MODAL,
  CREATE_SERVICE_TITLE,
  NO_ITEMS_TEXT,
  FORM_MODAL_WIDTH,
  STORE_USER,
  DEBOUNCE_DELAY,
  PAGINATION_FIRST_PAGE_NUMBER,
} from 'appConstants';
import { SearchInput, Loader, TileContent, TileItem, TilesWrapper, CustomTab } from 'components';
import { RouteComponentProps } from 'react-router';
import RepositoryStore from 'stores/RepositoryStore';
import { UserStore, RouterStore, UIStore, WorkspaceStore } from 'stores';
import { Repository as RepositoryInterface } from 'models/Repository';
import { Workspace } from 'models/Workspace';
import { Tabs, TabList, TabPanel } from 'react-tabs';
import InfiniteScroll from 'react-infinite-scroller';
import debounce from 'lodash.debounce';
import WorkspaceItem from './components/WorkspaceItem';
import ServiceItem from './components/ServiceItem';

import styles from "./Repository.module.scss";

const REPOSITORY_TAB_INDEX = 0;

export interface Props extends RouteComponentProps<{ tenantId: string }> {
  [STORE_REPOSITORY]: RepositoryStore;
  [STORE_ROUTER]: RouterStore;
  [STORE_UI]: UIStore;
  [STORE_USER]: UserStore;
  [STORE_WORKSPACE]: WorkspaceStore;
  // passing ref for scrolling area for synchronizing of infinity scroll
  routeProps: { homeRef: React.RefObject<HTMLDivElement> }
}

const initialState = {
  searchText: '',
  activeTabIndex: REPOSITORY_TAB_INDEX,
  // this basicaly is required for reseting the counter for Infinity component
  isSearchLoading: false,
};

type State = typeof initialState;

@inject(STORE_REPOSITORY, STORE_ROUTER, STORE_UI, STORE_USER, STORE_WORKSPACE)
@observer
export class Repository extends React.Component<Props, State> {
  state = initialState;

  loadItemsOnSearchChange = debounce(async (searchText: string) => {
    const loadAction = this.state.activeTabIndex === REPOSITORY_TAB_INDEX ?
      this.props[STORE_REPOSITORY].loadNextRepositoryPage :
      this.props[STORE_WORKSPACE].loadNextWorspacePage;
    await loadAction(PAGINATION_FIRST_PAGE_NUMBER, searchText);
    this.setState({ isSearchLoading: false });
  }, DEBOUNCE_DELAY);

  componentDidMount() {
    this.props[STORE_REPOSITORY].retrieveRepositories();
    this.props[STORE_WORKSPACE].retrieveAllWorkspaces();
  }

  showDeleteServiceModal = (id: string) => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH.MEDIUM,
      componentKey: DELETE_REPOSITORY_MODAL,
      title: 'Delete service',
      bodyText: 'Are you sure you want to delete the service? It will remove it from everyone currently using this service. It will not be possible to undo this action.',
      eventProps: {
        onSubmit: this.props[STORE_REPOSITORY].removeService,
      },
      props: {
        id,
      },
    });
  };

  showDeleteWorkspaceModal = (id: string) => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH.MEDIUM,
      componentKey: DELETE_REPOSITORY_MODAL,
      title: 'Delete workspace',
      bodyText: 'Are you sure you want to delete the workspace? It will remove it from everyone currently using this workspace. It will not be possible to undo this action.',
      eventProps: {
        onSubmit: this.props[STORE_WORKSPACE].removeWorkspace,
      },
      props: {
        id,
      },
    });
  };

  showCreateModal = () => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH.LARGE,
      componentKey: CREATE_SERVICE_MODAL,
      title: 'Create Service',
      eventProps: {
        onSubmit: this.props[STORE_REPOSITORY].createService,
      },
    });
  };

  showEditServiceModal = (item: RepositoryInterface) => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH.LARGE,
      componentKey: CREATE_SERVICE_MODAL,
      title: 'Edit Service',
      eventProps: {
        onSubmit: this.props[STORE_REPOSITORY].editService,
      },
      props: {
        editMode: true,
        item,
      },
    });
  };

  showEditWorkspaceModal = (workspaceId: string) => {
    const { tenantId } = this.props.match.params;
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH.LARGE,
      componentKey: CREATE_WORKSPACE_MODAL,
      title: 'Edit workspace',
      eventProps: {
        onSubmit: this.props[STORE_WORKSPACE].editRepositoryWorkspace,
      },
      props: {
        workspaceId,
        editMode: true,
        tenantId,
      },
    });
  };

  handleSearchTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    const searchText = e.currentTarget.value;
    this.setState({ searchText, isSearchLoading: true });
    this.loadItemsOnSearchChange(searchText);
  };

  onTabSelect = (tabIndex: number) => {
    this.setState({ searchText: '', activeTabIndex: tabIndex });
    const loadAction = tabIndex === REPOSITORY_TAB_INDEX ?
      this.props[STORE_REPOSITORY].loadNextRepositoryPage :
      this.props[STORE_WORKSPACE].loadNextWorspacePage;
    loadAction(PAGINATION_FIRST_PAGE_NUMBER);
  };

  loadNextRepositoryPage = (page: number) => this.props[STORE_REPOSITORY].loadNextRepositoryPage(page, this.state.searchText);

  loadNextWorspacePage = (page: number) => this.props[STORE_WORKSPACE].loadNextWorspacePage(page, this.state.searchText);

  getScrollParent = () => this.props.routeProps.homeRef.current;

  renderServices() {
    const { searchText } = this.state;
    const { repositoriesPaginationConfig, repositories, isLoading } = this.props[STORE_REPOSITORY];
    const isCreateServiceVisible = !searchText || CREATE_SERVICE_TITLE.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
    const hasMoreRepositories = !!repositoriesPaginationConfig && repositoriesPaginationConfig.page < repositoriesPaginationConfig.pageCount;
    return !this.state.isSearchLoading && !isLoading ? (
      <TilesWrapper>
        <InfiniteScroll
          pageStart={PAGINATION_FIRST_PAGE_NUMBER}
          loadMore={this.loadNextRepositoryPage}
          hasMore={hasMoreRepositories}
          loader={<Loader key="loader" />}
          useWindow={false}
          getScrollParent={this.getScrollParent}
        >
          {isCreateServiceVisible &&
            <TileItem>
              <TileItem.Content>
                <button type="button" onClick={this.showCreateModal}>
                  <TileContent
                    title={CREATE_SERVICE_TITLE}
                    iconUrl="/images/createWorkspace.svg"
                  />
                </button>
              </TileItem.Content>
            </TileItem>}
          {
            repositories.map((item: RepositoryInterface) => <ServiceItem
              key={item.id}
              item={item}
              showDeleteModal={this.showDeleteServiceModal}
              showEditModal={this.showEditServiceModal}
            />)
          }
        </InfiniteScroll>
      </TilesWrapper>
    ) : (
      <Loader />
    );
  }

  renderWorkspaces() {
    const { workspacesPaginationConfig, workspaces, isLoading } = this.props[STORE_WORKSPACE];
    const hasMoreWorkspaces = !!workspacesPaginationConfig && workspacesPaginationConfig.page < workspacesPaginationConfig.pageCount;
    return !this.state.isSearchLoading && !isLoading ? (
      <TilesWrapper>
        <InfiniteScroll
          pageStart={PAGINATION_FIRST_PAGE_NUMBER}
          loadMore={this.loadNextWorspacePage}
          hasMore={hasMoreWorkspaces}
          loader={<Loader key="loader" />}
          useWindow={false}
          getScrollParent={this.getScrollParent}
        >
          {
            workspaces.map((item: Workspace) => <WorkspaceItem
              item={item}
              key={item.id}
              onEdit={this.showEditWorkspaceModal}
              onDelete={this.showDeleteWorkspaceModal}
            />)
          }
        </InfiniteScroll>
      </TilesWrapper>
    ) : (
      <Loader />
    );
  }

  render() {
    const { searchText } = this.state;
    const { repositories } = this.props[STORE_REPOSITORY];
    const { workspaces } = this.props[STORE_WORKSPACE];
    const isCreateServiceVisible = !searchText || CREATE_SERVICE_TITLE.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;

    return (
      <>
        <div className={styles.topPanel}>
          <h1>Repository</h1>
          <SearchInput value={this.state.searchText} handleSearchTextChange={this.handleSearchTextChange} title="repository" />
        </div>
        <Tabs onSelect={this.onTabSelect}>
          <TabList>
            <CustomTab>Services</CustomTab>
            <CustomTab>Workspaces</CustomTab>
          </TabList>
          <TabPanel>
            {
              !isCreateServiceVisible && repositories.length === 0 ? (
                <div className={styles.emptyPlaceholder}>{NO_ITEMS_TEXT}</div>
              ) : this.renderServices()
            }
          </TabPanel>
          <TabPanel>
            {
              workspaces.length === 0 ? (
                <div className={styles.emptyPlaceholder}>{NO_ITEMS_TEXT}</div>
              ) : this.renderWorkspaces()
            }
          </TabPanel>
        </Tabs>
      </>
    );
  }
}

export default Repository;
