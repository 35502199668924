import { observable, action, runInAction } from 'mobx';
import { STORE_ROUTER, STORE_USER, DEFAULT_SERVER_ERROR_MESSAGE } from 'appConstants';
import { ToastsStore } from 'react-toasts';
import localStorageService from 'services/LocalStoreService';
import { ServerError } from 'errors';
import { RootStore } from './RootStore';

interface ModalEventProps {
  onSubmit?: (...args: any[]) => void;
}

interface ModalExtraProps {
  [key: string]: any;
}

export interface ModalProps {
  componentKey: string;
  title?: string;
  bodyText?: string;
  width?: number;
  minHeight?: number;
  props?: ModalExtraProps;
  eventProps?: ModalEventProps;
  onClose?: () => void;
}

export class UIStore {
  rootStore: RootStore;

  modal: ModalProps | null = null;

  @observable serverError: string | null = null;

  @observable isModalShown = false;

  @observable isAdminMode = false;

  @observable toastStore: any = ToastsStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.isAdminMode = localStorageService.getAdminMode();
  }

  @action
  openModal = (payload: ModalProps) => {
    runInAction(() => {
      this.isModalShown = true;
      this.modal = {
        ...payload,
      };
    });
  };

  @action
  switchMode = async () => {
    const userStore = this.rootStore[STORE_USER];
    runInAction(() => this.isAdminMode = !this.isAdminMode);
    localStorageService.setAdminMode(this.isAdminMode);

    try {
      if (this.isAdminMode) {
        if (!userStore.currentTenant) {
          await userStore.loadTenants();
        }
        this.rootStore[STORE_ROUTER].goToTenantUrl();
      } else {
        this.rootStore[STORE_ROUTER].goToUserUrl();
      }
    } catch (error) {
      console.error(error);
    }
  };

  @action
  submitModal = async (...args: [any]) => {
    if (this.modal && this.modal.eventProps && this.modal.eventProps.onSubmit) {
      runInAction(() => { this.serverError = null; });
      try {
        await this.modal.eventProps.onSubmit(...args);
      } catch (e) {
        if (e instanceof ServerError) {
          runInAction(() => this.serverError = e.message);
        }
        return;
      }
      this.closeModal();
    }
  };

  @action
  closeModal = () => {
    if (this.modal && this.modal.onClose) {
      this.modal.onClose();
    }
    runInAction(() => {
      this.isModalShown = false;
      this.serverError = null;
    });
  };

  @action
  showMessageNotification = (message: string) => {
    this.toastStore.info(message);
  };

  @action
  showErrorNotification = (errorMessage: string = DEFAULT_SERVER_ERROR_MESSAGE) => {
    this.toastStore.error(errorMessage);
  };
}

export default UIStore;
