import {
  AppStoreItemType,
  MembershipStatus,
  SSOFlowInitiatorType,
  SSO_PROTOCOLS,
  CLIENT_API_NAME,
  ADMIN_API_NAME,
  SAML_SSO_PROFILE_KEYS,
  PUBLIC_API_NAME,
  SSOAttributeType,
} from "appConstants";
import { Workspace } from "models/Workspace";
import { MicroApp } from "models/MicroApp";
import { WorkspaceConfigProps } from 'models/WorkspaceConfig';
import { AppsItemConfigProperties } from "models/AppsItemConfigProperties";

export * from "./sso";
export * from "./service";
export * from './workspace';
export * from "./group";
export * from "./tenant";
export * from './actions';
export * from './unuItem';

/**
 * Auth providers interface
 */
export interface SSOProvider {
  tokenName: string;
  /**
   * Get auth url to redirect, based on client Id
   */
  getAuthUrl: (clientId: string, location: Location, providerSpecificData?: { tenantId: string }) => string;
  /**
   * Parse Oauth redirect params into key-value map
   */
  parseAuthResponse: (locationHash: string) => KV
}

export type MicroAppDescriptorConfigProps = AppsItemConfigProperties;

export type WorkspaceDescriptorConfigProps = AppsItemConfigProperties & {
  sso: boolean;
  provider: string;
  tenantId: string;
  clientId: string;
};

export interface SelectOption<T = string, TData = undefined> {
  value: T;
  label: string;
  data?: TData
}

export interface ChangeItemsOrderParams {
  sourceOrder: number;
  destinationOrder: number;
  workspaceId: string;
}

export interface AppStoreItem {
  type: AppStoreItemType;
  item: Omit<Workspace, "items"> | MicroApp;
}

export interface WorkspaceServiceItem extends AppStoreItem {
  isAdded: boolean;
}
export interface CreateWorkspaceRequest {
  accessType: string;
  name: string;
  config: WorkspaceConfigProps;
}

export interface GroupDiff {
  groupIdsToAdd: string[];
  groupIdsToRemove: string[];
}

export interface MatrixRoom {
  dateTime: string;
  id: string;
  img: string;
  lastMessage: string;
  membershipStatus: MembershipStatus;
  name: string
  time: string
  unreadCount?: number;
}

export interface MatrixRoomFullInfo {
  _selfMembership: MembershipStatus;
  name: string;
  roomId: string;
  getUnreadNotificationCount: () => number;
  timeline: any;
  // there are some other of unused fileds
}

export interface MatrixCredentials {
  accessToken: string;
  matrixUserId: string;
  matrixServerUrl: string;
}

export interface MatrixTimelineEvent {
  event: MatrixEvent;
  sender: {
    userId: string;
    name: string;
  }
  getType: () => string;
  // there are some other of unused fields
}

export interface MatrixEvent {
  type: string;
  user_id: string;
  origin_server_ts: number;
  event_id: string;
  room_id: string;
  content: {
    body: string;
    membership: string;
  }
  // there are some other of unused fields
}

export interface MatrixMessage {
  _id: string;
  text: string;
  createdAt: string;
  user: {
    _id: string,
    name: string;
  },
  roomId: string;
}

export interface MatrixMember {
  membership: MembershipStatus;
  userId: string;
  // there are some other of unused fileds
}

export interface AttributeMapping {
  attributeType: SSOAttributeType;
  serviceAttributeName: string;
  profileAttributeKey: string;
  description: string;
  name: string;
  defaultValue: string;
  required: boolean;
  verifiedOnly: boolean;
  compositeAttributePath?: string;
}

export interface SsoProps {
  protocol: SSO_PROTOCOLS,
  entityId: string,
  ssoRedirectUrlPost: string,
  flowInitiator: SSOFlowInitiatorType,
  encryptAssertion: boolean,
  spCertificate: string,
  attributeMapping: AttributeMapping[]
}

export interface SSOServiceAttribute {
  profileAttributeKey: string,
  name: string
}

export type SsoAttributeKey = typeof SAML_SSO_PROFILE_KEYS[keyof typeof SAML_SSO_PROFILE_KEYS];

export interface SSORequiredAttributes {
  key: SsoAttributeKey;
  name: string;
  defaultValue: string;
  description: string;
  readOnly: boolean;
  optionValues?: string[]
}

export interface SSOSamlRequestResponse {
  service: string;
  requiredAttributes: SSORequiredAttributes[];
}

export interface TenantMetadata {
  canDelete: boolean;
  canEdit: boolean;
}

export interface WithTenantMetadata {
  metadata: TenantMetadata
}

export interface VerifyCodeResponse {
  assignedToWorkspaceId: string | null;
}

export interface RetrieveServicesRequest extends InfinityPagedRequest {
  appStoreId: string;
}

export interface WsData<T> {
  action: string;
  payload: T;
}

export type ApiName = typeof CLIENT_API_NAME | typeof ADMIN_API_NAME | typeof PUBLIC_API_NAME;
