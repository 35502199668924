import { History } from 'history';
import {
  STORE_DESCRIPTOR,
  STORE_REPOSITORY,
  STORE_USER,
  STORE_WORKSPACE,
  STORE_ROUTER,
  STORE_UI,
  STORE_GROUP,
  STORE_WEBSOCKET,
  STORE_SERVICES,
  STORE_INBOX,
  STORE_TENANTS,
  STORE_CURRENT_SERVICE,
  STORE_CURRENT_WORKSPACE,
  STORE_SSO,
  STORE_CURRENT_GROUP,
  STORE_REMOTE_CONFIG,
  STORE_GROUP_FILE,
  STORE_SIGN_IN,
  STORE_USER_ATTRIBUTES,
  STORE_DEVICES
} from 'appConstants';
import {
  InboxStore,
  ServicesStore,
  GroupsStore,
  RepositoryStore,
  DescriptorStore,
  WebSocketStore,
  UIStore,
  CurrentServiceStore,
  CurrentWorkspaceStore,
  RouterStore,
  WorkspaceStore,
  UserStore,
  SSOStore,
  GroupStore,
  TenantsStore,
  RemoteConfigStore,
  GroupFileStore,
  SignInStore,
  UserAttributesStore,
} from 'stores';
import { DevicesStore } from './DevicesStore';

export class RootStore {
  [STORE_DESCRIPTOR]: DescriptorStore;
  [STORE_WEBSOCKET]: WebSocketStore;
  [STORE_REPOSITORY]: RepositoryStore;
  [STORE_ROUTER]: RouterStore;
  [STORE_USER]: UserStore;
  [STORE_WORKSPACE]: WorkspaceStore;
  [STORE_GROUP]: GroupsStore;
  [STORE_UI]: UIStore;
  [STORE_CURRENT_SERVICE]: CurrentServiceStore;
  [STORE_CURRENT_WORKSPACE]: CurrentWorkspaceStore;
  [STORE_SERVICES]: ServicesStore;
  [STORE_REMOTE_CONFIG]: RemoteConfigStore;
  [STORE_INBOX]: InboxStore;
  [STORE_TENANTS]: TenantsStore;
  [STORE_USER_ATTRIBUTES]: UserAttributesStore;
  [STORE_SSO]: SSOStore;
  [STORE_CURRENT_GROUP]: GroupStore;
  [STORE_GROUP_FILE]: GroupFileStore;
  [STORE_SIGN_IN]: SignInStore;
  [STORE_DEVICES]: DevicesStore;

  constructor(history: History) {
    Object.assign(this, {
      [STORE_DESCRIPTOR]: new DescriptorStore(this),
      [STORE_REPOSITORY]: new RepositoryStore(this),
      [STORE_ROUTER]: new RouterStore(this, history),
      [STORE_USER]: new UserStore(this),
      [STORE_CURRENT_SERVICE]: new CurrentServiceStore(this),
      [STORE_CURRENT_WORKSPACE]: new CurrentWorkspaceStore(this),
      [STORE_WEBSOCKET]: new WebSocketStore(this),
      [STORE_WORKSPACE]: new WorkspaceStore(this),
      [STORE_GROUP]: new GroupsStore(this),
      [STORE_CURRENT_GROUP]: new GroupStore(this),
      [STORE_GROUP_FILE]: new GroupFileStore(this),
      [STORE_UI]: new UIStore(this),
      [STORE_SERVICES]: new ServicesStore(this),
      [STORE_USER_ATTRIBUTES]: new UserAttributesStore(this),
      [STORE_REMOTE_CONFIG]: new RemoteConfigStore(this),
      [STORE_INBOX]: new InboxStore(this),
      [STORE_TENANTS]: new TenantsStore(this),
      [STORE_SSO]: new SSOStore(this),
      [STORE_SIGN_IN]: new SignInStore(this),
      [STORE_DEVICES]: new DevicesStore(this)
    });
  }
}
