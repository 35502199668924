import { buildQuery, parseOauthQuery } from 'utils/url';

const TOKEN_NAME = "id_token";

export default {
  tokenName: TOKEN_NAME,
  getAuthUrl: (clientId: string, location: Location) => {
    /**
     * Send an authentication request to google
     * @see https://developers.google.com/identity/protocols/OpenIDConnect#sendauthrequest
     */
    const baseUrl = `https://accounts.google.com/o/oauth2/auth`;
    const parts:KV = {
      redirect_uri: process.env.REACT_APP_SSO_LANDING_ORIGIN as string,
      ss_domain: process.env.REACT_APP_SSO_LANDING_ORIGIN as string,
      response_type: 'permission id_token',
      scope: 'email profile openid',
      'openid.realm': '',
      client_id: clientId,
      fetch_basic_profile: 'true',
      gsiwebsdk: '2',
    };

    return `${baseUrl}?${buildQuery(parts)}`;
  },
  parseAuthResponse: (locationHash: string) => parseOauthQuery(locationHash, true),
};
