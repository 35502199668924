import * as React from 'react';
import * as Yup from 'yup';
import { Formik } from "formik";
import { VALIDATION_RULES, getNameValidationRules } from "validation";
import { CreateTenantData, CreateTenantFormValues } from 'interfaces';
import { requiredFirstNameMessage, requiredLastNameMessage } from 'validation/messages';
import TenantForm from './components/TenantForm/TenantForm';

export interface Props {
  submitError: null | string;
  data?: CreateTenantFormValues;
  onSubmit: (data: CreateTenantData) => void;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  alias: VALIDATION_RULES.uriComponent,
  name: VALIDATION_RULES.shortName,
  admins: Yup.array().of(
    Yup.object().shape({
      email: VALIDATION_RULES.email,
      firstName: getNameValidationRules({
        label: "First name",
        requiredMessage: requiredFirstNameMessage,
      }),
      lastName: getNameValidationRules({
        label: "First name",
        requiredMessage: requiredLastNameMessage,
      }),
    }).uniqueObjectKeyInArray('User already added', 'email').required(),
  ),
});

export default class CreateEditTenantModal extends React.Component<Props> {
  onSubmit = (formData: CreateTenantFormValues) => {
    this.props.onSubmit(formData);
  };

  render() {
    const { submitError } = this.props;
    const initialValues = Object.assign({ alias: '', name: '', admins: [{ email: '', firstName: '', lastName: '' }] }, this.props.data);
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={this.onSubmit}
      >
        {form => {
          const isDisabled = !form.dirty || !form.isValid;
          return (<TenantForm isDisabled={isDisabled} form={form} submitError={submitError} />);
        }}
      </Formik>
    );
  }
}
