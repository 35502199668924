import React from 'react';
import { inject, observer } from "mobx-react";
import { STORE_INBOX } from "appConstants";
import { ConversationItem } from 'components';
import { InboxStore } from "stores";
import styles from './InboxConversations.module.scss';

export interface Props {
  [STORE_INBOX]: InboxStore;
}

@inject(STORE_INBOX)
@observer
export default class InboxConversations extends React.PureComponent<Props, any> {
  componentWillUnmount() {
    this.props[STORE_INBOX].clearCurrentRoom();
  }

  render() {
    const rooms = this.props[STORE_INBOX].sortedRooms;
    const isInboxEmpty = rooms.length === 0;

    return (
      <div className={styles.conversations}>
        <div className={styles.header}> Inbox </div>
        {
          isInboxEmpty ?
            'Inbox is empty' :
            rooms.map(room => <ConversationItem
              key={room.id}
              setCurrentRoomId={this.props[STORE_INBOX].setCurrentRoomId}
              setReadMessagesForRoom={this.props[STORE_INBOX].setReadMessagesForRoom}
              getRoom={this.props[STORE_INBOX].getRoom}
              joinRoom={this.props[STORE_INBOX].joinRoom}
              isActive={this.props[STORE_INBOX].currentRoomId === room.id}
              item={room}
            />)
        }
      </div>
    );
  }
}
