import React, { KeyboardEvent } from 'react';
import { Input } from 'components';

export interface Props {
  sendMessage: (msg: string) => void;
}

interface State {
  message: string;
}

export default class ChatInput extends React.PureComponent<Props, State> {
  state = {
    message: '',
  };

  onKeyPress = (e: KeyboardEvent) => {
    // on "enter" press
    if (e.which === 13 && this.state.message) {
      this.props.sendMessage(this.state.message);
      this.setState({ message: '' });
    }
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ message: e.target.value });

  render() {
    return (
      <Input
        placeholder="Type a message…"
        onKeyPress={this.onKeyPress}
        onChange={this.handleChange}
        value={this.state.message}
      />
    );
  }
}
