import { computed, observable, runInAction } from "mobx";
import { RootStore } from "./RootStore";
import { AsyncStatus } from "../appConstants";
import userAttributesService from "../services/userAttributesService";
import {
  TenantUserAttributesDefinitions,
  UserAttributeDefinition,
} from "../interfaces/userAttributes";

export class UserAttributesStore {
  rootStore: RootStore;
  @observable userAttributesFetchStatus: AsyncStatus = AsyncStatus.IDLE;
  @observable userAttributes: TenantUserAttributesDefinitions | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get isUserAttributesLoading(): boolean {
    return this.userAttributesFetchStatus === AsyncStatus.IDLE || this.userAttributesFetchStatus === AsyncStatus.PENDING;
  }

  @computed
  get attrsForGroupRules(): UserAttributeDefinition[] {
    if (!this.userAttributes) {
      return [];
    }

    return [...this.userAttributes.global, ...this.userAttributes.custom];
  }

  @computed
  get definitionByNameMap(): Record<string, UserAttributeDefinition> {
    if (!this.userAttributes) {
      return {};
    }
    const allAttributes = [...this.userAttributes.global, ...this.userAttributes.custom];
    return allAttributes.reduce((acc: Record<string, UserAttributeDefinition>, definition: UserAttributeDefinition) => {
      acc[definition.name] = definition;
      return acc;
    }, {});
  }

  async listUserAttributes(): Promise<void> {
    runInAction(() => this.userAttributesFetchStatus = AsyncStatus.PENDING);
    try {
      const userAttributes = await userAttributesService.retrieveUserAttributes();
      runInAction(() => {
        this.userAttributes = userAttributes;
      });
    } catch (e) {
      runInAction(() => this.userAttributesFetchStatus = AsyncStatus.FAILURE);
    } finally {
      runInAction(() => this.userAttributesFetchStatus = AsyncStatus.SUCCESS);
    }
  }
}
