import { Workspace } from 'models/Workspace';
import { workspaceAccessTypeOptions, providerOptions, UnuActions, UnuSupportActionType, UNU_SUPPORT_ACTION_LABEL_BY_TYPE } from 'appConstants';
import { UnuSupportAction } from 'interfaces';
import defaultConfig from './defaultConfig';
import makeSelectOption from '../../../../utils/makeSelectOption';

// TODO: move to utils and cover by unit test / or move logic to store
export default (item: Workspace | null) => {
  const config = (item && item.config) || defaultConfig;
  const defaultProps = defaultConfig.props;
  const { props: {
    title = defaultProps.title,
    description = defaultProps.description,
    tenantId = defaultProps.tenantId,
    clientId = defaultProps.clientId,
    sso = defaultProps.sso,
  } } = config;
  const unuSupportAction: UnuSupportAction | null | undefined = config.props.actions && config.props.actions[UnuActions.unuSupport];
  const unuSupportActionType = unuSupportAction?.type || UnuSupportActionType.NO_SUPPORT; // no support in case non-existing unuSupport action
  const provider = item ?
    providerOptions.find(opt => opt.value === item.config.props.provider) || defaultProps.provider :
    defaultProps.provider;
  const accessType = item ?
    workspaceAccessTypeOptions.find(opt => opt.value === item.accessType) || defaultProps.accessType :
    defaultProps.accessType;
  const groups = item ?
    item.groups :
    [];
  return {
    title,
    description,
    tenantId,
    clientId,
    provider,
    accessType,
    sso,
    groups,
    unuSupportActionType: makeSelectOption<UnuSupportActionType>(unuSupportActionType, UNU_SUPPORT_ACTION_LABEL_BY_TYPE[unuSupportActionType]),
    supportMessage: unuSupportAction?.publicAttributes?.welcomeMessage || '',
  };
};
