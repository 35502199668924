import * as React from 'react';
import { inject, observer } from "mobx-react";
import queryString from 'query-string';
import {
  STORE_CURRENT_GROUP,
  STORE_UI,
  CREATE_GROUP_USER_MODAL,
  FORM_MODAL_WIDTH,
  DELETE_GROUP_USER_MODAL,
  USER_MESSAGE,
  GROUP_USERS_CSV_MODAL,
  STORE_USER,
  STORE_GROUP_FILE,
  CsvUserUploadAction,
} from "appConstants";
import { GroupStore, UIStore, UserStore, GroupFileStore } from "stores";
import { RouteComponentProps } from "react-router";
import GroupUser from "models/GroupUser";
import { Table, ButtonHolder, CustomButton } from '../../../components';
import { getUserGroupColumns } from '../groupColumns';

import styles from "../Group.module.scss";

interface Props extends RouteComponentProps<{ tenantId: string, groupId: string, manageTabName: string }> {
  [STORE_CURRENT_GROUP]: GroupStore;
  [STORE_GROUP_FILE]: GroupFileStore;
  [STORE_UI]: UIStore;
  [STORE_USER]: UserStore;
}

const initialState = {
  selectedUsers: [] as GroupUser[],
};

type State = typeof initialState;

@inject(STORE_CURRENT_GROUP, STORE_GROUP_FILE, STORE_UI, STORE_USER)
@observer
export default class ActiveUsers extends React.PureComponent<Props, State> {
  state = initialState;

  private columns: TableColumn<GroupUser>[];

  constructor(props: Props) {
    super(props);
    this.columns = getUserGroupColumns();
  }

  componentDidMount() {
    const { page } = queryString.parse(this.props.location.search);
    if (page === null || Array.isArray(page)) return;
    this.props[STORE_CURRENT_GROUP].listGroupMembers(page);
  }

  handleSelectionUserChange = (users: GroupUser[]) => {
    this.setState({ selectedUsers: users });
  };

  showAddUserModal = () => {
    this.props[STORE_UI].openModal({
      componentKey: CREATE_GROUP_USER_MODAL,
      title: 'Add group user',
      eventProps: {
        onSubmit: this.props[STORE_CURRENT_GROUP].addUser,
      },
      props: {
        groupId: this.props.match.params.groupId,
      },
    });
  };

  showCsvAddModal = () => {
    this.props[STORE_UI].openModal({
      componentKey: GROUP_USERS_CSV_MODAL,
      title: 'Add users',
      props: {
        action: CsvUserUploadAction.ADD,
      },
    });
  };

  showCsvRemoveModal = () => {
    this.props[STORE_UI].openModal({
      componentKey: GROUP_USERS_CSV_MODAL,
      title: 'Remove users',
      props: {
        action: CsvUserUploadAction.REMOVE,
      },
    });
  };

  onDeleteSubmit = async () => {
    const { groupId } = this.props.match.params;
    await this.props[STORE_CURRENT_GROUP].deleteGroupUsers(groupId, this.state.selectedUsers);
    this.setState({
      selectedUsers: [],
    });
  };

  handleDeleteUsers = () => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH.MEDIUM,
      componentKey: DELETE_GROUP_USER_MODAL,
      title: "Delete User(s)?",
      bodyText: USER_MESSAGE,
      eventProps: {
        onSubmit: this.onDeleteSubmit,
      },
    });
  };

  handleDownloadCsvUsers = () => {
    const { isCsUsersDownloading, downloadCsvUsers } = this.props[STORE_GROUP_FILE];
    if (!isCsUsersDownloading) {
      downloadCsvUsers();
    }
  };

  public render() {
    const selectedUsersCount = this.state.selectedUsers.length;
    const {
      users,
      isCurrentGroupAdmin,
      isUsersLoading,
      usersPaginationConfig,
      onPageChange,
      isRuleBased
    } = this.props[STORE_CURRENT_GROUP];
    const { isCsUsersDownloading } = this.props[STORE_GROUP_FILE];
    const allowUserCheck = isCurrentGroupAdmin ?
      (user: GroupUser) => user.email !== this.props[STORE_USER].userEmail :
      undefined;
    const isExportCSVDisabled = isUsersLoading || !users.length;
    return (
      <>
        <ButtonHolder>
          {
            selectedUsersCount > 0 && (
              <CustomButton
                className={styles.delete}
                onClick={this.handleDeleteUsers}
              >
                Remove selected
              </CustomButton>
            )
          }
          {!isRuleBased && (
            <CustomButton onClick={this.showAddUserModal}>
              Add user manually
            </CustomButton>
          )}
          {!isRuleBased && (
            <CustomButton onClick={this.showCsvAddModal}>
              Add users via CSV
            </CustomButton>
          )}
          {!isRuleBased && (
            <CustomButton onClick={this.showCsvRemoveModal}>
              Remove users via CSV
            </CustomButton>
          )}
          <CustomButton
            onClick={this.handleDownloadCsvUsers}
            disabled={isExportCSVDisabled}
            isLoading={isCsUsersDownloading}
          >
            Export CSV
          </CustomButton>
        </ButtonHolder>
        <Table<GroupUser>
          rowKey="email"
          isLoading={isUsersLoading}
          isStriped
          columns={this.columns}
          selectedItems={this.state.selectedUsers}
          allowItemCheck={allowUserCheck}
          values={users}
          onSelectionChange={this.handleSelectionUserChange}
          paginationConfig={usersPaginationConfig}
          onPageChange={onPageChange}
        />
      </>
    );
  }
}
