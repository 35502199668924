export const nameRegExp = /^['`"_A-Za-zÀ-ÖØ-öø-ÿ\s.-]*$/; // Latin letters, spaces, accents, dashes, single quote or apostrophe characters with accents only
export const titleRegExp = /^[-`$/.~;!@#$[%^\]+=\\&*(),?":{}|<>'"_A-Za-zÀ-ÖØ-öø-ÿ\s\d.-]*$/; // Latin letters, spaces, accents, dashes, single quote or apostrophe characters with accents only
// Visible characters (anything except spaces and control characters) https://www.regular-expressions.info/posixbrackets.html
export const visibleCharacterRegExp = /^[\x21-\x7E\s]*$/;
export const alphaNumericRegExp = /^[a-zA-Z0-9\s!&()_\-[\]'\\|.]*$/;
export const nameSpecRegExp = /^[-`$/.~;!@#$[%^\]=\\&*(),?":{}|<>'"_\w\d ]+$/; // with spec symbols
export const digitsRegExp = /^[0-9]+$/;
// Visible characters (anything except spaces and control characters) https://www.regular-expressions.info/posixbrackets.html
export const phoneRegExp = /^[+\d]+$/;
export const ninRegExp = /^([\s]?)+[A-Z]{2}[0-9]{6}[A-Z]{1}([\s]?)+$/; // example: " AB123456C    "
