import { observable, action, computed } from 'mobx';
import webSocketService from 'services/webSocketService';
import { SsoAuthConfigResponse } from 'services/actionsService';
import { STORE_SSO, STORE_USER, WebSocketAction, ConnectionStatus, WS_TIMEOUT } from 'appConstants';
import { safeJsonParse } from 'utils/safeJsonParse';
import { WsData, AuthorizeSsoServicePayload } from 'interfaces';
import { RootStore } from './RootStore';

const WAIT_AUTH_TO_CONNECT_TIMEOUT = 500;

export class WebSocketStore {
  rootStore: RootStore;

  authId: WebSocketAction.AUTH | null = null;

  @observable connectionStatus: ConnectionStatus = ConnectionStatus.PENDING;

  @observable retryTimeoutSeconds: number | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get ssoAuthTime(): number | null {
    return this.rootStore[STORE_SSO].ssoAuthTime;
  }

  @computed get isConnectionOpen() {
    return this.connectionStatus === ConnectionStatus.OPENED;
  }

  @computed get isConnectionFailed() {
    return this.connectionStatus === ConnectionStatus.FAILED;
  }

  @computed get isReconnecting() {
    return this.connectionStatus === ConnectionStatus.RECONNECTING;
  }

  @computed get isConnectionWaiting() {
    return this.connectionStatus === ConnectionStatus.PENDING || this.connectionStatus === ConnectionStatus.RECONNECTING;
  }

  connect = () => {
    if (!this.rootStore[STORE_USER].user?.signInUserSession) {
      // wait auth to connect - refer to HomePage where we call appInit
      window.setTimeout(this.connect, WAIT_AUTH_TO_CONNECT_TIMEOUT);
      return;
    }
    const { jwtToken } = this.rootStore[STORE_USER].user.signInUserSession.accessToken;
    webSocketService.connect(jwtToken, this.onConnect, this.onMessage, this.onConnectionFailed, this.onReconnect, this.onConnectionError);
  };

  @action
  onConnect = () => {
    this.connectionStatus = ConnectionStatus.OPENED;
  };

  @action
  onReconnect = () => {
    console.info('Socket: reconnecting.');
    this.connectionStatus = ConnectionStatus.RECONNECTING;
  };

  @action
  onConnectionError = () => {
    console.info('Socket: connection error - reconnecting.');
    this.connectionStatus = ConnectionStatus.RECONNECTING;
  };

  @action
  onConnectionFailed = () => {
    console.info('Socket: connection failed.');
    webSocketService.close();
    this.connectionStatus = ConnectionStatus.FAILED;
  };

  @action
  onMessage = (e: MessageEvent) => {
    const data = safeJsonParse<WsData<AuthorizeSsoServicePayload>>(e.data);
    if (data && data.action === WebSocketAction.AUTH) {
      const { authorizeService, requiredAttributes, ssoAuthToken } = data.payload;
      if (authorizeService) {
        this.rootStore[STORE_SSO].doSamlAuthorize({
          authorizeService,
          attributes: requiredAttributes,
          ssoAuthToken,
        });
      } else {
        this.rootStore[STORE_SSO].cancelSamlAuthorize();
      }
    }
  };

  @action
  close = () => {
    webSocketService.close();
    this.connectionStatus = ConnectionStatus.CLOSED;
  };

  @action
  send = (msg: string) => {
    if (this.connectionStatus === ConnectionStatus.OPENED) {
      webSocketService.send(msg);
    }
  };

  @action
  setWsAuthConfig({ id }: SsoAuthConfigResponse) {
    this.authId = id;
  }

  tryToSendAuthNotification = (samlServiceId: string) => {
    if (this.connectionStatus === ConnectionStatus.FAILED) {
      // start fallback flow
      this.rootStore[STORE_SSO].startSsoAuthAction();
      return;
    }

    if (this.connectionStatus === ConnectionStatus.OPENED) {
      this.sendAuthNotification(samlServiceId);
    } else {
      window.setTimeout(() => this.tryToSendAuthNotification(samlServiceId), WS_TIMEOUT);
    }
  };

  sendAuthNotification(samlServiceId: string) {
    if (!this.authId) {
      console.warn('authId is not set, skip auth notification sending');
      return;
    }
    const { username } = this.rootStore[STORE_USER].user;
    webSocketService.sendAuthNotification(this.authId, samlServiceId, username);
    this.rootStore[STORE_SSO].setSsoAuthTime();
  }
}

export default WebSocketStore;
