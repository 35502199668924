import React from 'react';
import { Tab } from 'react-tabs';
import styles from './CustomTab.module.scss';

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
}

const CustomTab = ({ children, ...props }: Props) => (
  <Tab {...props} className={styles.tab}>
    {children}
  </Tab>
);

// Required field to use custom Tab
CustomTab.tabsRole = 'Tab';

export default CustomTab;
