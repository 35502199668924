import { observable, runInAction, action, computed } from "mobx";
import Auth from '@aws-amplify/auth';
import { RootStore } from "./RootStore";
import actionsService from "../services/actionsService";
import { delay } from "../utils/delay";
import CustomError from "../errors/CustomError";
import { DEFAULT_SERVER_ERROR_MESSAGE, DEFAULT_LOGIN_ERROR_MESSAGE, STORE_USER, STORE_ROUTER } from "../appConstants";

const SIGN_IN_POOLING_DELAY_SEC = process.env.REACT_APP_SIGN_IN_POOLING_DELAY_SEC || '1';
const SIGN_IN_POOLING_TIMEOUT_SEC = process.env.REACT_APP_SIGN_IN_POOLING_TIMEOUT_SEC || '40';

export class SignInStore {
  rootStore: RootStore;

  poolingStartMills?: number;

  signInPoolingDelay = window.parseInt(SIGN_IN_POOLING_DELAY_SEC, 10) * 1000;

  signInPoolingTimeoutMillis = window.parseInt(SIGN_IN_POOLING_TIMEOUT_SEC, 10) * 1000;

  email: string | null = null;

  loginToken: string | null = null;

  actionAuthToken?: string;

  @observable actionToken?: string;

  @observable errorMessage: string = '';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get hasSession() {
    if (!this.poolingStartMills) return false;
    return Date.now() - this.poolingStartMills <= this.signInPoolingTimeoutMillis;
  }

  startSignIn = async () => {
    try {
      const { actionToken, actionAuthToken } = await actionsService.initSignLogin();
      runInAction(() => {
        this.poolingStartMills = Date.now();
        this.actionToken = actionToken;
        this.actionAuthToken = actionAuthToken;
      });
      this.waitForMobileAuth();
    } catch (error) {
      this.setError(error);
    }
  };

  waitForMobileAuth = async () => {
    if (!this.hasSession) {
      this.clear();
      return;
    }
    await delay(this.signInPoolingDelay);
    const result = await this.checkSignInState();
    if (!result) return;
    if (!result.loginToken) {
      await this.waitForMobileAuth();
    } else {
      await this.passAuthChallenge();
    }
  };

  checkSignInState = async () => {
    try {
      if (!this.actionToken || !this.actionAuthToken) throw new CustomError('There are no actionToken or actionAuthToken');
      const result = await actionsService.askForLoginToken(this.actionToken, this.actionAuthToken);
      runInAction(() => {
        this.email = result.email;
        this.loginToken = result.loginToken;
      });
      return result;
    } catch (error) {
      this.setError(error);
    }
  };

  passAuthChallenge = async () => {
    try {
      if (!this.email || !this.loginToken) throw new CustomError('Auth Challenge data not provided.');
      const userData = await Auth.signIn(this.email.toLowerCase());
      const answer = JSON.stringify({ loginToken: this.loginToken });
      const user = await Auth.sendCustomChallengeAnswer(userData, answer);
      if (user.signInUserSession === null) {
        throw new Error('Wrong answer');
      } else {
        this.rootStore[STORE_USER].signIn();
        // type form state is wrong - that's why need as any
        const { from } = this.rootStore.router.location.state as any || { from: { pathname: "/" } };
        const path = from.search ? `${from.pathname}/${from.search}` : from.pathname;
        this.rootStore[STORE_ROUTER].push(path);
      }
    } catch (error) {
      this.setError(error);
    }
  };

  @action
  setError = (error: Error) => {
    console.error(error);
    if (error instanceof CustomError) {
      this.errorMessage = DEFAULT_SERVER_ERROR_MESSAGE;
    } else {
      this.errorMessage = DEFAULT_LOGIN_ERROR_MESSAGE;
    }
  };

  @action
  clear() {
    this.email = null;
    this.loginToken = null;
    this.poolingStartMills = undefined;
    this.actionAuthToken = undefined;
    this.actionToken = undefined;
    this.errorMessage = '';
  }
}

export default SignInStore;
