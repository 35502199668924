import { GetParams, PostParams, PutParams, DeleteParams } from 'interfaces';
import { HttpService } from "./HttpService";

const ACT_AS_HEADER = "Act-As";

export default class HttpTenantService extends HttpService {
  public tenantId?: string;

  setTenantId(tenantId: string) {
    this.tenantId = tenantId;
  }

  get<T>(
    path: string,
    params: GetParams = {},
    customTenant?: string,
  ) {
    const { headers = {}, ...otherParams } = params;
    const tenantId = customTenant || this.tenantId;
    return super.get<T>(`/tenants/${tenantId}${path}`,
      {
        ...otherParams,
        headers: {
          ...headers,
          [ACT_AS_HEADER]: this.tenantId as string,
        },
      });
  }

  post<T>(
    path: string,
    params: PostParams = {},
    customTenant?: string,
  ): Promise<T> {
    const { headers = {}, ...otherParams } = params;
    const tenantId = customTenant || this.tenantId;
    return super.post(`/tenants/${tenantId}${path}`,
      {
        ...otherParams,
        headers: {
          ...headers,
          [ACT_AS_HEADER]: this.tenantId as string,
        },
      });
  }

  put<T>(
    path: string,
    {
      headers = {},
      ...otherParams
    }: PutParams,
  ): Promise<T> {
    return super.put(`/tenants/${this.tenantId}${path}`,
      {
        ...otherParams,
        headers: {
          ...headers,
          [ACT_AS_HEADER]: this.tenantId as string,
        },
      });
  }

  delete<T>(
    path: string,
    params: DeleteParams = {},
  ): Promise<T> {
    const { headers = {}, ...otherParams } = params;
    return super.delete(`/tenants/${this.tenantId}${path}`,
      {
        ...otherParams,
        headers: {
          ...headers,
          [ACT_AS_HEADER]: this.tenantId as string,
        },
      });
  }
}
