import { observable, action, runInAction, computed, IObservableArray } from 'mobx';
import descriptorService from 'services/AppsDescriptorService';
import { FetchStatus, PAGINATION_FIRST_PAGE_NUMBER, STORE_UI, STORE_WORKSPACE } from 'appConstants';
import { Workspace } from 'models/Workspace';
import { RootStore } from './RootStore';
import groupsService, { GroupsPagedRequest } from "../services/groupsService";
import Group from "../models/Group";

export class CurrentWorkspaceStore {
  rootStore: RootStore;

  @observable fetchStatus = FetchStatus.IDLE;

  @observable groups: IObservableArray<Group> = observable([]);

  @observable currentWorkspace: Workspace | null = null;

  @observable isRootWorkspace = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed get isLoading(): boolean {
    return this.fetchStatus === FetchStatus.PENDING;
  }

  async listGroups({
    page = PAGINATION_FIRST_PAGE_NUMBER,
    term = '',
  }: GroupsPagedRequest = {}) {
    try {
      const { elements } = await groupsService.retrieveGroups({ page, term });
      runInAction(() => this.groups.replace(elements));
    } catch (e) {
      this.rootStore[STORE_UI].showErrorNotification();
    }
  }

  @action
  resetConfig = () => {
    this.currentWorkspace = null;
    this.isRootWorkspace = false;
    this.fetchStatus = FetchStatus.IDLE;
  };

  @action
  loadWorkspaceConfig = async (tenantId: string, workspaceId: string) => {
    runInAction(() => this.fetchStatus = FetchStatus.PENDING);
    try {
      const currentWorkspace = await this.rootStore[STORE_WORKSPACE].retrieveWorkspaceDataByItemId(tenantId, workspaceId);
      const workspaceDescriptor = await descriptorService.getWorkspaceDescriptor();
      const isRootWorkspace = workspaceDescriptor.itemId === currentWorkspace.id;
      runInAction(() => {
        this.currentWorkspace = currentWorkspace;
        this.isRootWorkspace = isRootWorkspace;
        this.fetchStatus = FetchStatus.SUCCESS;
      });
    } catch (e) {
      console.error(`Error fetching workspace: ${e}`);
      runInAction(() => {
        this.fetchStatus = FetchStatus.FAILURE;
      });
    }
  };
}

export default CurrentWorkspaceStore;
