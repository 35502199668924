import { httpClientService, HttpService, httpPublicService } from "./http";
import { WebSocketAction } from "../appConstants";

export interface StartSignInResponse {
  actionToken: string;
  actionAuthToken: string;
}

export interface SsoAuthConfigResponse {
  id: WebSocketAction.AUTH,
  retryTimeoutSeconds: number,
}

export interface AskForLoginTokenResponse {
  email: string | null;
  loginToken: string | null;
}

export interface StartSsoAuthResponse {
  actionToken: string;
}

export interface GetSsoAuthStateResponse {
  ssoAuthToken?: string | null;
  authorizeService?: boolean;
}

class ActionsService {
  http: HttpService;

  httpPublic: HttpService;

  constructor(http: HttpService, httpPublic: HttpService) {
    this.http = http;
    this.httpPublic = httpPublic;
  }

  authorizeSsoService() {
    return this.http.get<SsoAuthConfigResponse>('/api/actions/AuthorizeSsoService');
  }

  initSignLogin = () => this.httpPublic.post<StartSignInResponse>('/public/actions/SignLogin', {});

  askForLoginToken = (actionToken: string, actionAuthToken: string) => {
    return this.httpPublic.get<AskForLoginTokenResponse>(`/public/actions/SignLogin/${encodeURIComponent(actionToken)}`, {
      headers: {
        Authentication: `Bearer ${actionAuthToken}`,
      },
    });
  };

  startSsoAuth(ssoServiceId: string) {
    return this.http.post<StartSsoAuthResponse>('/actions/AuthorizeSsoService', {
      body: {
        ssoServiceId,
      },
    });
  }

  getSsoAuthState(actionToken: string) {
    // don't need encode because from BE re receive url friendly value
    return this.http.get<GetSsoAuthStateResponse>(`/actions/AuthorizeSsoService/${actionToken}`);
  }
}

export default new ActionsService(httpClientService, httpPublicService);
