import * as React from "react";
import Group from "models/Group";
import { Link } from "react-router-dom";
import { CustomButton } from "components";
import { TABLE_BUTTON_WIDTH } from 'appConstants';

export const getGroupsColumns = (showCreateModal: () => void, tenantId: string): TableColumn<Group>[] => [
  {
    title: 'Group name',
    decorator: ({ id, name }: Group) => <Link to={`/tenants/${tenantId}/manage/groups/${id}/rules`}>{name}</Link>,
    width: "50%",
  },
  {
    title: 'People',
    accessor: ({ userCount }: Group) => (userCount || 0),
  },
  {
    title: '',
    accessor: () => "",
    element: <CustomButton buttonType="link" size="xs" onClick={showCreateModal}>+Add new</CustomButton>,
    width: TABLE_BUTTON_WIDTH,
  },
];

export const getSharedGroupsColumns = (): TableColumn<Group>[] => [
  {
    title: 'Group name',
    decorator: ({ name }: Group) => name,
    width: "100%",
  },
];
