/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Input } from 'components';
import classNames from "classnames";
import styles from './SearchInput.module.scss';

export interface Props {
  handleSearchTextChange: (e: React.FormEvent<HTMLInputElement>) => void;
  title: string;
  value: string;
}

const SearchInput = ({ handleSearchTextChange, title, value }: Props) => (
  <div className={styles.searchWrapper}>
    <Input
      value={value}
      id={`search-${title}`}
      type="search"
      className={styles.search}
      placeholder="Search"
      onChange={handleSearchTextChange}
    />
    <label htmlFor={`search-${title}`} className={classNames("icon-search", styles.iconSearch)} />
  </div>);

export default SearchInput;
