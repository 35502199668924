import React from "react";
import ReactPaginate from "react-paginate";
import styles from './Pagination.module.scss';

interface Props {
  pageCount: number;
  onPageChange?: (selectedItem: { selected: number }) => void;
  forcePage?: number;
}

const Pagination = (props: Props) => {
  return (
    <ReactPaginate
      previousLabel="previous"
      nextLabel="next"
      breakLabel="..."
      pageCount={props.pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      forcePage={props.forcePage}
      onPageChange={props.onPageChange}
      containerClassName={styles.pagination}
      activeClassName={styles.active}
    />
  );
};

export default Pagination;
