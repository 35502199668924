import { HttpService, httpAdminService } from './http';
import { TenantWithAdmins } from '../interfaces';

class AdminUserService {
  http: HttpService;

  constructor(http: HttpService) {
    this.http = http;
  }

  loadTenants = (): Promise<TenantWithAdmins[]> => {
    return this.http.get<TenantWithAdmins[]>("/users/me/tenants");
  };
}

export default new AdminUserService(httpAdminService);
