import React, { useCallback } from "react";
import papaParse from "papaparse";
import { Box, Flex } from "reflexbox";
import { CustomButton, Label } from "components";
import styles from "./CsvDataButton.module.scss";

const DEFAULT_FILENAME = 'export.csv';
const downloadCSV = ({ data, filename: name }: { data: string[][], filename?: string }) => {
  const filename = name || DEFAULT_FILENAME;
  const csv = papaParse.unparse(data);
  if (csv == null) return;

  const blob = new Blob([csv]);
  const a = window.document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

interface Props {
  label: string;
  filename?: string;
  onClear?: () => void;
  data: string[][];
}

export const CsvDataButton = (props: Props) => {
  const handleDownload = useCallback(
    () => downloadCSV({ data: props.data, filename: props.filename }),
    [props.data, props.filename]
  );

  return (
    <Box mb="0.5rem">
      <Label title={props.label} />
      <Flex justifyContent="space-between" className={styles.csvDataButton}>
        <Box my="auto">
          <CustomButton type="button" buttonType="link" onClick={handleDownload}>
            {props.filename || "Download"}
          </CustomButton>
        </Box>
        <Box className={styles.clearBox}>
          <i role="button" className="icon-close" onClick={props.onClear} aria-label="clear" />
        </Box>
      </Flex>
    </Box>
  );
};
