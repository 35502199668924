import React from 'react';
import classNames from 'classnames';
import styles from './ButtonHolder.module.scss';

export interface ButtonHolderProps {
  children: React.ReactNode;
  className?: string;
}

const ButtonHolder = ({ children, className }: ButtonHolderProps) => (
  <div className={classNames(styles.buttonHolder, className)}>
    {children}
  </div>
);

export default ButtonHolder;
