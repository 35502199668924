import * as React from 'react';
import styles from './TextStyle.module.scss';

export type Variation = 'strong';

export interface Props {
  variation?: Variation;
  children?: React.ReactNode;
}

export function TextStyle({ variation, children }: Props) {
  const className = variation ? styles[variation] : undefined;
  return <span className={className}>{children || ''}</span>;
}
