/* eslint-disable no-underscore-dangle */
import firebase from "firebase/app";
import { FeatureFlags } from "../../appConstants";

// use default value to fix typescript error - https://firebase.google.com/docs/reference/js/firebase.remoteconfig.Settings#fetchtimeoutmillis
const FETCH_TIMEOUT_MILLIS_DEFAULT = 60000;

export class FirebaseConfigService {
  constructor(private remoteConfig: firebase.remoteConfig.RemoteConfig) {
    const minimumFetchIntervalMillis = process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_FETCH_INTERVAL;
    if (minimumFetchIntervalMillis) {
      this.remoteConfig.settings = {
        fetchTimeoutMillis: FETCH_TIMEOUT_MILLIS_DEFAULT,
        minimumFetchIntervalMillis: Number.parseInt(minimumFetchIntervalMillis, 10),
      };
    }

    this.remoteConfig.defaultConfig = {
      [FeatureFlags.SUPPORT_CHAT_ENABLED]: false,
      [FeatureFlags.ADD_EMPLOYER_ENABLED]: true,
    };
  }

  syncParams = (): Promise<boolean> => this.remoteConfig.fetchAndActivate();

  getFeatureState = (featureStateKey: FeatureFlags): boolean => {
    return this.remoteConfig.getValue(featureStateKey).asBoolean();
  };
}
