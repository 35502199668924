import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { STORE_USER, STORE_UI, AppPaths } from 'appConstants';
import LogInPage from 'pages/LogInPage';
import SignUpPage from 'pages/SignUpPage';
import HomePage from 'pages/HomePage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { inject, observer } from 'mobx-react';
import { UserStore, UIStore } from 'stores';
import Modal from 'containers/Modal';
import { ToastsContainer, ToastsContainerPosition } from "react-toasts";
import { Loader, ErrorBoundary } from 'components';

const PrivateRoute = ({ component, isAuthenticated, ...rest }: any) => <Route
  {...rest}
  render={props => (
    isAuthenticated ?
      React.createElement(component, props) :
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location },
        }}
      />
  )}
/>;

export interface Props {
  [STORE_USER]: UserStore;
  [STORE_UI]: UIStore;
}

@inject(STORE_USER, STORE_UI)
@observer
class App extends React.Component<Props> {
  componentDidMount() {
    this.props[STORE_USER].checkAuthState();
  }

  render() {
    const { isLoading, isAuthenticated } = this.props[STORE_USER];
    if (isLoading) {
      return <Loader isFullscreen />;
    }
    return (
      <ErrorBoundary>
        <Modal />
        <ToastsContainer lightBackground position={ToastsContainerPosition.TOP_RIGHT} store={this.props[STORE_UI].toastStore} />
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              isAuthenticated ?
                (<Redirect to="/workspace" />) :
                (<Redirect to="/login" />)
            )}
          />
          <Route exact path="/login" component={LogInPage} />
          <Route exact path="/signup" component={SignUpPage} />
          <PrivateRoute isAuthenticated={isAuthenticated} path={["/settings", "/inbox", "/workspace", "/tenants", AppPaths.SAML_SSO_LOGIN_PATH]} component={HomePage} />
          <Route component={NotFoundPage} />
        </Switch>
      </ErrorBoundary>
    );
  }
}

export default App;
