import React from 'react';
import { CustomButton, Loader } from "components";
import { RouteComponentProps } from 'react-router';
import { inject, observer } from "mobx-react";
import { STORE_UI, STORE_USER } from "appConstants";
import { UIStore, UserStore } from "stores";
import styles from './SettingsPage.module.scss';

interface Props extends RouteComponentProps<any> {
  [STORE_UI]: UIStore;
  [STORE_USER]: UserStore;
}

@inject(STORE_UI, STORE_USER)
@observer
export default class SettingsPage extends React.PureComponent<Props, any> {
  signOut = () => {
    this.props[STORE_USER].signOut();
  };

  render() {
    if (!this.props[STORE_USER].user) {
      return <Loader />;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { attributes: { email, email_verified } } = this.props[STORE_USER].user;
    return (
      <div>
        <h1>Settings</h1>
        <div className={styles.card}>
          <strong>Email(s)</strong>
          <ul>
            <li>
              <div className={styles.emailGroup}>
                <div className={styles.infoGroup}>
                  <p className={styles.email}>{email}</p>
                  { email_verified && <span className={styles.additionalInfo}>Primary</span> }
                </div>
              </div>
            </li>
            <li>
              <a
                href={process.env.REACT_APP_TERMS_LINK}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.settingsItem}
              >
                Terms & Conditions
              </a>
            </li>
          </ul>
          <div className={styles.buttonSpace}>
            <CustomButton buttonType="primary" onClick={this.signOut}>Sign out of Coll8 web</CustomButton>
          </div>
        </div>
      </div>
    );
  }
}
