/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Loader, TilesWrapper, TileItem, TileContent } from 'components';
import { RouteComponentProps } from 'react-router';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import {
  STORE_WORKSPACE,
  STORE_REPOSITORY,
  STORE_ROUTER,
  STORE_UI,
  CREATE_WORKSPACE_MODAL,
  CREATE_SERVICE_MODAL,
  FORM_MODAL_WIDTH,
  STORE_USER,
  ONBOARDING_SVC_MODAL,
} from 'appConstants';
import { RouterStore, UIStore, WorkspaceStore, RepositoryStore, UserStore } from 'stores';
import CustomLink from 'components/CustomLink';
import { WorkspaceItem } from 'models/WorkspaceItem';
import splitItemsByRowSize from 'utils/splitItemsByRowSize';
import DraggableItems from '../../components/DraggableItems';
import styles from './Workspace.module.scss';

const TILE_WIDTH = 166; // in px - use for row length calculation ($tile-item-width + 2 * $tile-item-h-margin) = 16.6rem
const DROPPABLE_ID_SEPARATOR = ":";

enum DroppableId {
  predefined = "predefined",
  available = "available",
}

const getDroppableIdData = (droppableId: string): { droppableId: DroppableId, rowIndex: number } => {
  const data = droppableId.split(DROPPABLE_ID_SEPARATOR);
  return {
    droppableId: data[0] as DroppableId,
    rowIndex: parseInt(data[1], 10),
  };
};

export interface Props extends RouteComponentProps<{ workspaceId?: string, tenantId: string }> {
  [STORE_WORKSPACE]: WorkspaceStore;
  [STORE_ROUTER]: RouterStore;
  [STORE_UI]: UIStore;
  [STORE_REPOSITORY]: RepositoryStore;
  [STORE_USER]: UserStore;
}

const initialState = {
  rowSize: 10,
};

type State = typeof initialState;

@inject(STORE_WORKSPACE, STORE_ROUTER, STORE_UI, STORE_REPOSITORY, STORE_USER)
@observer
export class Workspace extends React.Component<Props, State> {
  state = initialState;

  wrapperRef: React.RefObject<HTMLDivElement>;

  workspaceRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.workspaceRef = React.createRef();
  }

  componentDidMount = () => {
    const { workspaceId, tenantId } = this.props.match.params;
    const store = this.props[STORE_WORKSPACE];
    store.loadWorkspaceDataByAppId(tenantId, workspaceId);
    this.calcRowSize();
    window.addEventListener("resize", this.calcRowSize);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.calcRowSize);
  }

  getItemsByDroppableId(droppableId: DroppableId) {
    const store = this.props[STORE_WORKSPACE];
    return droppableId === DroppableId.predefined ? store.predefinedItems : store.additionalItems;
  }

  getRowSize() {
    let width = 0;
    if (this.wrapperRef.current) {
      width = this.wrapperRef.current.offsetWidth;
    }

    if (this.workspaceRef.current) {
      width = this.workspaceRef.current.offsetWidth;
    }
    return Math.floor(width / TILE_WIDTH);
  }

  showCreateWorkspaceModal = () => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH.LARGE,
      componentKey: CREATE_WORKSPACE_MODAL,
      title: 'Create new workspace',
      eventProps: {
        onSubmit: this.props[STORE_WORKSPACE].addWorkspace,
      },
    });
  };

  // workspaceItem used for editing workspaceItem (on tile), but it's not required for editing active workspace (by gear)
  showEditWorkspaceModal = (workspaceId: string, workspaceItem?: WorkspaceItem) => {
    const { tenantId } = this.props.match.params;
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH.LARGE,
      componentKey: CREATE_WORKSPACE_MODAL,
      title: 'Edit workspace',
      eventProps: {
        onSubmit: this.props[STORE_WORKSPACE].editWorkspace,
      },
      props: {
        workspaceId,
        editMode: true,
        workspaceItem,
        tenantId,
      },
    });
  };

  // TODO: review this usage - we pass workspaceItem but use as Repository and override id (id: workspaceItem.itemId)
  showEditServiceModal = (workspaceItem: WorkspaceItem) => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH.LARGE,
      componentKey: CREATE_SERVICE_MODAL,
      title: 'Edit Service',
      eventProps: {
        onSubmit: this.props[STORE_WORKSPACE].editServiceItem,
      },
      props: {
        editMode: true,
        item: {
          ...workspaceItem,
          config: {
            props: {
              ...workspaceItem.config,
              appUrl: workspaceItem.config.appUrl,
              iconDataUrl: workspaceItem.config.iconUrl,
            },
          },
          id: workspaceItem.itemId,
        },
      },
    });
  };

  showCreateServiceModal = async () => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH.LARGE,
      componentKey: CREATE_SERVICE_MODAL,
      title: 'Create Service',
      props: {
        shouldBeAddedToCurrentWS: true,
      },
      eventProps: {
        onSubmit: this.props[STORE_REPOSITORY].createService,
      },
    });
  };

  showOnboardingSvcModal = async (title: string, mobileAppLink?: string) => {
    this.props[STORE_UI].openModal({
      componentKey: ONBOARDING_SVC_MODAL,
      title,
      props: {
        mobileAppLink,
      },
    });
  };

  calcRowSize = () => {
    const rowSize = this.getRowSize();
    this.setState({
      rowSize,
    });
  };

  getDestinationOrder = (destinationIndex: number, highestOrderNumber: number, destinationDroppableId: DroppableId): number => {
    const destinationItems = this.getItemsByDroppableId(destinationDroppableId);
    const destinationItem = destinationItems[destinationIndex];
    // dropped to existing item
    if (destinationItem) {
      return destinationItem.order;
    }
    const lastDestinationItem = destinationItems[destinationItems.length - 1];
    // dropped to the end
    if (lastDestinationItem) {
      return highestOrderNumber + 1;
    }
    // dropped to empty list: use order of first one
    const { predefinedItems, additionalItems } = this.props[STORE_WORKSPACE];
    const defaultDestinationItem = destinationDroppableId === DroppableId.predefined ? additionalItems[0] : predefinedItems[0];
    return defaultDestinationItem.order;
  };

  handleDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) {
      return;
    }
    const { rowSize } = this.state;
    const { droppableId: sourceDroppableId, rowIndex: sourceRowIndex } = getDroppableIdData(source.droppableId);
    const { droppableId: destinationDroppableId, rowIndex: destinationRowIndex } = getDroppableIdData(destination.droppableId);
    const destinationIndex = destinationRowIndex * rowSize + destination.index;
    const sourceIndex = sourceRowIndex * rowSize + source.index;
    const sourceItems = this.getItemsByDroppableId(sourceDroppableId);
    const destinationOrder = this.getDestinationOrder(destinationIndex, this.props[STORE_WORKSPACE].highestOrderNumber, destinationDroppableId);
    const { workspaceId, order: sourceOrder } = sourceItems[sourceIndex];

    this.props[STORE_WORKSPACE].changeItemsOrder({ workspaceId, destinationOrder, sourceOrder }, destinationDroppableId !== sourceDroppableId);
  };

  onGrearClick = () => {
    const { workspaceId } = this.props.match.params;
    const id = this.props[STORE_WORKSPACE].getWorkspaceIdByAppId(workspaceId);
    this.showEditWorkspaceModal(id);
  };

  onItemUpdate = async (workspaceItem: WorkspaceItem) => {
    if (workspaceItem.predefined) {
      await this.props[STORE_WORKSPACE].updateWorkspaceItem(workspaceItem);
    } else {
      await this.props[STORE_WORKSPACE].removeItem(workspaceItem.itemId, workspaceItem.appStoreId);
    }
  };

  render() {
    const { params } = this.props.match;
    const { workspace, isLoading, isDropDisabled, additionalItems, predefinedItems } = this.props[STORE_WORKSPACE];
    const { isAdminMode } = this.props[STORE_UI];
    const { curTenantId } = this.props[STORE_USER];
    const workspaceId = workspace ? workspace.id : params.workspaceId;
    if (!workspace || isLoading) {
      // IMPORTANT! wrap loader in div to calc area width before item rendering
      return (
        <div ref={this.wrapperRef}>
          <Loader />
        </div>);
    }
    const { metadata } = workspace;
    // pass empty array in case of empty items to display default drop target
    const predefinedItemsRows = predefinedItems.length ? splitItemsByRowSize(predefinedItems, this.state.rowSize) : [[]];
    const additionalItemsRows = additionalItems.length ? splitItemsByRowSize(additionalItems, this.state.rowSize) : [[]];

    return (
      <div ref={this.workspaceRef} className={styles.workspace}>
        { isDropDisabled && <Loader isFullscreen /> }
        <h1>
          {workspace.config.props.title}
          { metadata.canEdit && (
            <i role="button" className={`icon-settings ${styles.editButton}`} onClick={this.onGrearClick} />
          )}
        </h1>
        <h2>Shown by default</h2>
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <TilesWrapper>
            {predefinedItemsRows.map((rowItems, index) => (
              <DraggableItems
                key={`${DroppableId.predefined}-${index}`}
                isAdminMode={isAdminMode}
                droppableId={`${DroppableId.predefined}${DROPPABLE_ID_SEPARATOR}${index}`}
                isDropDisabled={isDropDisabled}
                items={rowItems}
                updateWorkspaceItem={this.onItemUpdate}
                showEditServiceModal={this.showEditServiceModal}
                showEditWorkspaceModal={this.showEditWorkspaceModal}
                showOnboardingSvcModal={this.showOnboardingSvcModal}
              />
            ))}
            { metadata.canEdit && (
              <>
                <TileItem>
                  <TileItem.Content isWorkspace>
                    <CustomLink to={`/tenants/${curTenantId}/workspace/${workspaceId}/admin-services`}>
                      <TileContent
                        title="Admin services"
                        iconUrl="/images/adminServices.svg"
                      />
                    </CustomLink>
                  </TileItem.Content>
                </TileItem>
                <TileItem>
                  <TileItem.Content>
                    <button type="button" onClick={this.showCreateWorkspaceModal}>
                      <TileContent
                        title="Create workspace"
                        iconUrl="/images/createWorkspace.svg"
                      />
                    </button>
                  </TileItem.Content>
                </TileItem>
                <TileItem>
                  <TileItem.Content>
                    <button type="button" onClick={this.showCreateServiceModal}>
                      <TileContent
                        title="Create service"
                        iconUrl="/images/createService.svg"
                      />
                    </button>
                  </TileItem.Content>
                </TileItem>
                <TileItem>
                  <TileItem.Content>
                    <CustomLink to={`/tenants/${curTenantId}/workspace/${workspaceId}/more-services`}>
                      <TileContent
                        title="More services..."
                        iconUrl="/images/moreServices.svg"
                      />
                    </CustomLink>
                  </TileItem.Content>
                </TileItem>
              </>) }
          </TilesWrapper>
          <h2>Additional services users can install</h2>
          <TilesWrapper>
            {additionalItemsRows.map((rowItems, index) => (
              <DraggableItems
                key={`${DroppableId.available}-${index}`}
                droppableId={`${DroppableId.available}${DROPPABLE_ID_SEPARATOR}${index}`}
                isDropDisabled={isDropDisabled}
                isAdminMode={isAdminMode}
                items={rowItems}
                updateWorkspaceItem={this.onItemUpdate}
                showEditServiceModal={this.showEditServiceModal}
                showEditWorkspaceModal={this.showEditWorkspaceModal}
                showOnboardingSvcModal={this.showOnboardingSvcModal}
              />
            ))}
          </TilesWrapper>
        </DragDropContext>
      </div>
    );
  }
}

export default Workspace;
