import { action, IObservableArray, observable, runInAction } from "mobx";
import Group from "models/Group";
import groupsService, { GroupsPagedRequest } from "services/groupsService";
import {
  DEFAULT_SERVER_ERROR_MESSAGE,
  DELETE_GROUP_REMOVE_USERS,
  DELETE_GROUP_UNLINK_WORKSPACES,
  PAGINATION_FIRST_PAGE_NUMBER,
  STORE_UI,
} from 'appConstants';
import { RootStore } from "./RootStore";
import { GroupOwnershipType } from "../interfaces";

export enum GroupErrorCodes {
  RemoveGroupUsersLimit = 'RGUL',
  RemoveGroupConnectedWorkspaces = 'RGCW',
}

const errorMessageByCode = {
  [GroupErrorCodes.RemoveGroupUsersLimit]: DELETE_GROUP_REMOVE_USERS,
  [GroupErrorCodes.RemoveGroupConnectedWorkspaces]: DELETE_GROUP_UNLINK_WORKSPACES,
};

export class GroupsStore {
  rootStore: RootStore;

  @observable groups: IObservableArray<Group> = observable([]);

  @observable sharedGroups: IObservableArray<Group> = observable([]);

  @observable groupsPaginationConfig: PaginationConfig | null = null;

  @observable sharedGroupsPaginationConfig: PaginationConfig | null = null;

  @observable isAdminGroupsLoading = false;

  @observable isSharedGroupsLoading = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  async listAdminGroups({
    page = PAGINATION_FIRST_PAGE_NUMBER,
    ownershipType = GroupOwnershipType.ADMIN,
  }: GroupsPagedRequest = {}) {
    runInAction(() => this.isAdminGroupsLoading = true);
    try {
      const { elements, ...paginationData } = await groupsService.retrieveGroups({ page, ownershipType });
      runInAction(() => {
        this.groups.replace(elements);
        this.groupsPaginationConfig = { ...paginationData };
      });
    } catch (e) {
      this.rootStore[STORE_UI].showErrorNotification();
    } finally {
      runInAction(() => this.isAdminGroupsLoading = false);
    }
  }

  @action
  async listSharedGroups({
    page = PAGINATION_FIRST_PAGE_NUMBER,
    ownershipType = GroupOwnershipType.SHARED,
  }: GroupsPagedRequest = {}) {
    runInAction(() => this.isSharedGroupsLoading = true);
    try {
      const { elements, ...paginationData } = await groupsService.retrieveGroups({ page, ownershipType });
      runInAction(() => {
        this.sharedGroups.replace(elements);
        this.sharedGroupsPaginationConfig = { ...paginationData };
      });
    } catch (e) {
      this.rootStore[STORE_UI].showErrorNotification();
    } finally {
      runInAction(() => this.isSharedGroupsLoading = false);
    }
  }

  async deleteGroups(groups: Group[]) {
    const deletePromises = groups.map(groupToDelete => this.deleteGroup(groupToDelete));
    await Promise.all(deletePromises);
    this.listAdminGroups();
  }

  async deleteGroup(groupToDelete: Group) {
    try {
      await groupsService.deleteGroup(groupToDelete.id);
    } catch (e) {
      let message = DEFAULT_SERVER_ERROR_MESSAGE;
      if (e.request?.status === 409) {
        message = errorMessageByCode[e.request.response.code as GroupErrorCodes] || DEFAULT_SERVER_ERROR_MESSAGE;
      }
      this.rootStore[STORE_UI].showErrorNotification(`${groupToDelete.name}: ${message}`);
    }
  }

  @action.bound
  async createGroup(name: string) {
    try {
      const newGroup = await groupsService.addGroup(name);
      runInAction(() => {
        this.groups.push(newGroup);
      });
    } catch (e) {
      console.error(e);
      if (e.response.data.message) {
        this.rootStore[STORE_UI].showErrorNotification(e.response.data.message);
      }
    }
  }

  @action
  goToNextAdminGroupPage = (pageNumber: number) => this.listAdminGroups({
    page: pageNumber + 1,
    ownershipType: GroupOwnershipType.ADMIN,
  });

  @action
  goToNextSharedGroupPage = (pageNumber: number) => this.listSharedGroups({
    page: pageNumber + 1,
    ownershipType: GroupOwnershipType.SHARED,
  });

  @action
  clear() {
    this.isAdminGroupsLoading = false;
    this.isSharedGroupsLoading = false;
    this.groupsPaginationConfig = null;
    this.sharedGroupsPaginationConfig = null;
    this.groups = observable([]);
    this.sharedGroups = observable([]);
  }
}

export default GroupsStore;
