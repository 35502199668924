import Auth from '@aws-amplify/auth';
import { CLIENT_API_NAME, ADMIN_API_NAME, PUBLIC_API_NAME } from 'appConstants';

const authHeader = async () => ({ Authorization: `Bearer ${(await Auth.currentSession()).accessToken.jwtToken}` });

export default {
  Auth: {
    region: process.env.REACT_APP_GOGNITO_REGION,
    userPoolId: process.env.REACT_APP_GOGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REDIRECT_SIGN_IN,
      responseType: 'code'
    },
    authenticationFlowType: 'CUSTOM_AUTH'
  },

  API: {
    endpoints: [
      {
        name: CLIENT_API_NAME,
        endpoint: process.env.REACT_APP_CLIENT_API_URL,
        // eslint-disable-next-line
        custom_header: authHeader
      },
      {
        name: PUBLIC_API_NAME,
        endpoint: process.env.REACT_APP_CLIENT_API_URL
      },
      {
        name: ADMIN_API_NAME,
        endpoint: process.env.REACT_APP_DASHBOARD_API_URL,
        // eslint-disable-next-line
        custom_header: authHeader
      }
    ],
  },
};
