import * as React from 'react';
import classNames from "classnames";
import { observer } from "mobx-react";
import { FieldArray, FormikProps } from "formik";
import { CustomField, CustomCheckbox, CustomSelectField } from 'components';
import {
  SSOFlowOptions,
  SSOFlowInitiatorType,
  SSO_EMAIL_ATTRIBUTES,
  SAML_SSO_PROFILE_KEYS,
  SAML_SSO_PRIVATE_EMAIL_LABEL,
} from "appConstants";
import { SelectOption, ServiceFormAttributeMapping, ServiceFormValues } from 'interfaces';
import { UserAttr } from 'stores/CurrentServiceStore';
import { makeUserAttributeLabel } from 'utils/makeUserAttributeLabel';
import fileToBase64 from 'utils/fileToBase64';
import AttributeFields from './AttributeFields';
import styles from './CreateServiceModal.module.scss';
import CustomFileField from '../../../CustomFileField/CustomFileField';
import ServiceConfiguration from '../../../../models/ServiceConfiguration';
import { defaultSamlSSONameIdAttribute } from './getInitialValues';

interface Props {
  form: FormikProps<ServiceFormValues>;
  config: ServiceConfiguration | null;
  userAttrs: UserAttr[];
  isEditMode: boolean;
  scrollToBottom: () => void;
}

export interface FormAttributeMapping {
  attributeValue: SelectOption;
  name: string
}

function makeNameIdSelectOptions(attributeMappings: ServiceFormAttributeMapping[]): SelectOption[] {
  const mappingsOptions = attributeMappings
    .map(mapping => ({ value: mapping.attributeValue.value, label: mapping.attributeValue.label }))
    .filter(({ value }) => value && value !== SAML_SSO_PROFILE_KEYS.PRIVATE_EMAIL);
  return [
    {
      value: SAML_SSO_PROFILE_KEYS.PRIVATE_EMAIL,
      label: SAML_SSO_PRIVATE_EMAIL_LABEL,
    },
    ...mappingsOptions,
  ];
}

function makeSsoUserAttrsOptions(userAttrs: UserAttr[]) {
  return userAttrs.map(att => ({
    label: makeUserAttributeLabel(att),
    value: att.name,
    description: att.description,
    type: att.type
  }));
}

@observer
class SSOTab extends React.Component<Props> {
  onRemoveAssertionItem = (index: number) => {
    const attr = this.props.form.values.attributeMapping[index];
    if (attr && attr.attributeValue.value === this.props.form.values.nameIdAttribute.value) {
      // if user removed selected name identifier - revert it to default
      this.props.form.setFieldValue(
        'nameIdAttribute',
        defaultSamlSSONameIdAttribute,
      );
    }
  };

  render() {
    const { config, userAttrs, isEditMode } = this.props;
    const ssoAttributeOptions = makeSsoUserAttrsOptions(userAttrs);
    const isSsoExist = config && config.props.sso;
    const isDownloadDisabled = !isEditMode || !isSsoExist;
    const downloadUrl = config?.props?.sso?.idpMetadataUrl;
    const { form } = this.props;
    const { attributeMapping, encryptAssertion, spCertificate } = form.values;
    const ssoNameIdOptions = makeNameIdSelectOptions(attributeMapping);
    const isFlowEmpty = form.values.flowInitiator.value === SSOFlowInitiatorType.EMPTY;
    const isFlowIDP = form.values.flowInitiator.value === SSOFlowInitiatorType.IDP;
    const showEmailNotification = !attributeMapping.some(att => SSO_EMAIL_ATTRIBUTES.includes(att.attributeValue.value));
    const isCertDisabled = !encryptAssertion && !isEditMode;
    const showCertWarning = !encryptAssertion && spCertificate;
    return (
      <>
        <div className={styles.paneHolder}>
          <div className={styles.col4}>
            <CustomField
              name="flowInitiator"
              title="SSO provider"
              component={CustomSelectField}
              options={SSOFlowOptions}
              form={form}
            />
          </div>
          {isFlowIDP &&
            <div className={styles.col8}>
              <div className={styles.description}>
                IdP-Initiated flows carry a security risk and are NOT recommended.
                <br />
                Make sure you understand the risks if enabling IdP-Initiated SSO.
              </div>
            </div>}
        </div>
        {!isFlowEmpty &&
          <>
            <strong className={styles.title}>SAML Setup</strong>
            <div className={styles.paneHolder}>
              <div className={styles.col4}>
                <CustomField
                  data-testid="serviceEntityId"
                  name="entityId"
                  title="Entity ID"
                  placeholder="Enter Entity ID..."
                  form={form}
                />
              </div>
              <div className={styles.col4}>
                <CustomField
                  data-testid="serviceRedirectUrl"
                  name="ssoRedirectUrlPost"
                  title="SAML Endpoint"
                  placeholder="Enter URL..."
                  form={form}
                  onChange={form.handleChange}
                />
              </div>
              <div className={styles.col}>
                <span className={styles.label}>IdP metadata</span>
                <a
                  className={classNames(styles.downloadLink, { [styles.disabled]: isDownloadDisabled })}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={downloadUrl}
                  download
                >
                  Get Metadata
                </a>
              </div>
            </div>
            <div className={styles.paneHolder}>
              <div className={styles.col4}>
                <span className={styles.label}>Enable assertion encryption</span>
                <CustomField
                  name="encryptAssertion"
                  title="Encryption enabled"
                  component={CustomCheckbox}
                  checked={encryptAssertion}
                  form={form}
                  onChange={form.handleChange}
                />
              </div>
              <div className={styles.col4}>
                <CustomFileField
                  disabled={isCertDisabled}
                  name="spCertificate"
                  buttonText="Upload certificate"
                  form={form}
                  label="Service Encryption Certificate"
                  accept=".crt, .cer, .pem"
                  defaultFileName="certificate"
                  maxFileSize={512}
                  fileReader={fileToBase64}
                />
              </div>
            </div>
            {showCertWarning &&
              <div className={styles.paneHolder}>
                <div className={styles.col8}>
                  <div className={classNames(styles.description, styles.noSpace)}>
                    The attached certificate will be removed!
                  </div>
                </div>
              </div>}
            <div className={styles.paneHolder}>
              <div className={styles.col}>
                <strong className={styles.title}>SAML Assertion</strong>
                {showEmailNotification &&
                  <div className={styles.description}>
                    We recommend using at least one verified attribute like email to avoid identity theft
                  </div>}
              </div>
            </div>
            <FieldArray
              name="attributeMapping"
              render={arrayHelpers => (
                <AttributeFields
                  scrollToBottom={this.props.scrollToBottom}
                  arrayHelpers={arrayHelpers}
                  form={form}
                  ssoAttributeOptions={ssoAttributeOptions}
                  onRemoveAssertionItem={this.onRemoveAssertionItem}
                />
              )}
            />
            <div className={styles.paneHolder}>
              <div className={styles.col4}>
                <CustomField<SelectOption>
                  data-testid="nameIdAttribute"
                  name="nameIdAttribute"
                  title="SAML:NameID"
                  component={CustomSelectField}
                  options={ssoNameIdOptions}
                  form={form}
                  onSelectChange={form.handleChange}
                  menuPlacement="top"
                />
              </div>
            </div>
          </>}
      </>
    );
  }
}

export default SSOTab;
