import React from 'react';
import { Descriptor } from 'models/Descriptor';
import styles from './MoreAppsRow.module.scss';

interface MoreAppsRowProps {
  app: Descriptor;
  onAddApp: (appDescriptor: Descriptor) => void;
}

export default class MoreAppsRow extends React.PureComponent<MoreAppsRowProps> {
  onAddApp = () => this.props.onAddApp(this.props.app);

  render() {
    const { app } = this.props;
    const { iconUrl, title } = app.config.properties;
    return (
      <tr className={styles.row}>
        <td>
          {iconUrl && <img
            className={styles.image}
            alt={title}
            src={iconUrl}
          />}
        </td>
        <td>{title}</td>
        <td>
          <button
            type="button"
            className={styles.button}
            onClick={this.onAddApp}
          >
            add
          </button>
        </td>
      </tr>
    );
  }
}
