import React from 'react';
import { CustomButton, ButtonHolder, CustomField } from 'components';
import { Form, FormikProps, FormikHelpers } from "formik";
import useSetErrors from 'hooks/useSetErrors';

export interface Props {
  submitError: null | string;
  form: FormikProps<FormValues>;
  setErrors: FormikHelpers<FormValues>["setErrors"];
}

export interface FormValues {
  domain: string;
}

export default function CreateGroupDomainForm(props: Props) {
  const { submitError, form, setErrors } = props;
  const isDisabled = !form.dirty || !form.isValid;

  useSetErrors<FormValues>(submitError, setErrors, 'domain');

  return (
    <Form>
      <CustomField
        withSeparator
        name="domain"
        title="Domain name"
        placeholder="Add domain name"
        form={form}
        onChange={form.handleChange}
      />
      <ButtonHolder>
        <CustomButton
          disabled={isDisabled}
          buttonType="primary"
          type="submit"
        >
          Add
        </CustomButton>
      </ButtonHolder>
    </Form>
  );
}
