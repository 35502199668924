import React from 'react';
import classNames from 'classnames';
import styles from './TileItem.module.scss';

export interface TileItemProps {
  children: React.ReactNode;
  onClick?: () => void;
}

export interface TileItemContentProps {
  children: React.ReactNode;
  className?: string;
  isWorkspace?: boolean;
}

const TileItemFooter = ({ children }: { children: React.ReactNode }) => <>{children}</>;
const TileItemContent = ({ children, isWorkspace, className }: TileItemContentProps) => (
  <div className={classNames(styles.item, { [styles.workspace]: isWorkspace }, className)}>
    {children}
  </div>
);

class TileItem extends React.Component<TileItemProps> {
  static Content = TileItemContent;

  static Footer = TileItemFooter;

  render() {
    return (
      <div className={styles.holder} onClick={this.props.onClick} role="button">
        {this.props.children}
      </div>
    );
  }
}

TileItem.Content = TileItemContent;
TileItem.Footer = TileItemFooter;

export default TileItem;
