import React, { InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './Switch.module.scss';

const Switch = ({ className, checked, ...props }: InputHTMLAttributes<HTMLInputElement>) => (
  <label htmlFor={props.id}>
    <div className={classNames(styles.switch, className, { [styles.checked]: checked })}>
      <input
        {...props}
        checked={checked}
        hidden
        type="checkbox"
      />
    </div>
  </label>);

export default Switch;
