import * as Yup from 'yup';
import { UserAttributeDefinition, UserAttributeDefinitionType } from 'interfaces/userAttributes';
import { AttributeNameOption } from './types';
import { SelectOption } from '../../../../interfaces';
import { MembershipRuleOperatorType } from '../../../../appConstants';

export const buildValidationSchema = (definitionByNameMap: Record<string, UserAttributeDefinition>) => Yup.object().shape({
  rules: Yup.array().of(
    Yup.object().shape({
      left: Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      }).nullable().required('Rule attribute is required'),
      jsonPath: Yup.string().nullable()
        .when('left', {
          is: (option: AttributeNameOption) => option?.data?.type === UserAttributeDefinitionType.COMPOSITE,
          then: Yup.string()
            .hasAttrDefinitionForPath('JSON path must contain attribute name part', definitionByNameMap)
            .required('JSON path is required')
        }),
      operator: Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      }).nullable().required('Please select operator'),
      right: Yup.string()
        .when('operator', {
          is: (operator: SelectOption<MembershipRuleOperatorType>) => (
            operator?.value === MembershipRuleOperatorType.EQUALS ||
            operator?.value === MembershipRuleOperatorType.ANY ||
            operator?.value === MembershipRuleOperatorType.NONE
          ),
          then: Yup.string().required('Rule value is required')
        }).nullable(),
      csvData: Yup.array().of(Yup.string().required()).nullable()
        .when('operator', {
          is: (operator: SelectOption<MembershipRuleOperatorType>) => (
            operator?.value === MembershipRuleOperatorType.IN ||
            operator?.value === MembershipRuleOperatorType.NOT_IN
          ),
          then: Yup.array().of(
            Yup.string().required()
          ).required('Please upload one column csv file').nullable(false)
        }),
      logicalOperator: Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      }).nullable()
    })
  )
});
