import * as React from 'react';
import { inject, observer } from "mobx-react";
import queryString from 'query-string';
import { Select, Table, Loader } from 'components';
import ApproveUser from 'models/ApproveUser';
import { RouteComponentProps } from "react-router";
import {
  STORE_CURRENT_GROUP,
  STORE_UI,
  APPROVE_ALL_GROUP_USERS_MODAL,
  FORM_MODAL_WIDTH,
} from "appConstants";
import { GroupStore, UIStore } from "stores";
import { UserGroupApprovalMethod, SelectOption, UserGroupApprovalStatus } from 'interfaces';
import { getUsersToApproveGroupColumns } from "../groupColumns";
import styles from "../Group.module.scss";

const options: SelectOption<UserGroupApprovalMethod>[] = [
  { value: UserGroupApprovalMethod.MANUAL, label: "Require approval" },
  { value: UserGroupApprovalMethod.SEMI_AUTOMATIC, label: "Semi-automatic approval" },
  { value: UserGroupApprovalMethod.AUTOMATIC, label: "Add automatically" },
];

const approvalDescriptionByMethodMap = {
  [UserGroupApprovalMethod.MANUAL]: "You will need to approve users with a matching domain manually below",
  [UserGroupApprovalMethod.SEMI_AUTOMATIC]: "Approve automatically if first name and last name match company email - ie. firstname.lastname@company.com. Otherwise require manual approval.",
  [UserGroupApprovalMethod.AUTOMATIC]: "No manual approval will be required - everyone with a matching domain will be added to the group",
};

export interface Props extends RouteComponentProps<{ tenantId: string, groupId: string, manageTabName: string }> {
  [STORE_CURRENT_GROUP]: GroupStore;
  [STORE_UI]: UIStore;
}

const initialState = {
  selectedApproveUsers: [] as ApproveUser[],
};

type State = typeof initialState;

@inject(STORE_CURRENT_GROUP, STORE_UI)
@observer
export default class ApproveUsers extends React.PureComponent<Props, State> {
  state = initialState;

  private columns: TableColumn<ApproveUser>[];

  constructor(props: Props) {
    super(props);
    this.columns = getUsersToApproveGroupColumns(this.handleApproveSelected, this.handleRejectSelected);
  }

  componentDidMount() {
    const { page } = queryString.parse(this.props.location.search);
    if (page === null || Array.isArray(page)) return;
    this.props[STORE_CURRENT_GROUP].loadUsersToApprove(page);
  }

  get curApprovalValue() {
    const { currentGroup } = this.props[STORE_CURRENT_GROUP];
    if (!currentGroup) {
      return undefined;
    }
    return options.find(option => option.value === currentGroup.approvalMethod);
  }

  handleSelectionChange = (users: ApproveUser[]) => this.setState({ selectedApproveUsers: users });

  handleMethodChange = (option: SelectOption<UserGroupApprovalMethod>) => {
    this.handleGroupApprovalMethodChange(option.value);
  };

  handleApproveSelected = (user: ApproveUser) => {
    this.props[STORE_CURRENT_GROUP].modifyUserApprovalStatus([...this.state.selectedApproveUsers, user], UserGroupApprovalStatus.APPROVED);
  };

  handleRejectSelected = (user: ApproveUser) => {
    this.props[STORE_CURRENT_GROUP].modifyUserApprovalStatus([...this.state.selectedApproveUsers, user], UserGroupApprovalStatus.REJECTED);
  };

  handleGroupApprovalMethodChange = (approvalMethod: UserGroupApprovalMethod) => {
    const { activeGroup, usersToApprove } = this.props[STORE_CURRENT_GROUP];
    if (
      activeGroup.approvalMethod !== UserGroupApprovalMethod.AUTOMATIC &&
      approvalMethod === UserGroupApprovalMethod.AUTOMATIC &&
      usersToApprove.length) {
      // in case we changed to automatic
      this.props[STORE_UI].openModal({
        componentKey: APPROVE_ALL_GROUP_USERS_MODAL,
        title: 'Approve all',
        width: FORM_MODAL_WIDTH.MEDIUM,
        eventProps: {
          onSubmit: this.onApproveAllByMethodChange,
        },
      });
    } else {
      this.props[STORE_CURRENT_GROUP].updateGroup({ approvalMethod });
    }
  };

  onApproveAllByMethodChange = async () => {
    await this.props[STORE_CURRENT_GROUP].updateGroup({ approvalMethod: UserGroupApprovalMethod.AUTOMATIC });
    this.props[STORE_CURRENT_GROUP].loadUsersToApprove();
  };

  public render() {
    const {
      currentGroup,
      usersToApprovePaginationConfig,
      usersToApprove,
      isUsersToApproveLoading,
      onPageChange,
    } = this.props[STORE_CURRENT_GROUP];
    if (!currentGroup || isUsersToApproveLoading) {
      return <Loader />;
    }
    const { approvalMethod } = currentGroup;
    return (
      <div className={styles.approvalCnt}>
        <strong>Approval method</strong>
        <div className={styles.approvalLegendCnt}>
          <div>
            <Select className={styles.approvalMethodSelect} options={options} value={this.curApprovalValue} onChange={this.handleMethodChange} />
          </div>
          <div>
            {approvalDescriptionByMethodMap[approvalMethod]}
          </div>
        </div>
        <Table<ApproveUser>
          className={styles.approveUsersTable}
          rowKey="email"
          isStriped
          paginationConfig={usersToApprovePaginationConfig}
          onSelectionChange={this.handleSelectionChange}
          values={usersToApprove}
          columns={this.columns}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}
