import { observable, computed, action, runInAction, IObservableArray } from 'mobx';
import {
  AuthState,
  FetchStatus,
  STORE_ROUTER,
  STORE_UI,
  STORE_INBOX,
  GLOBAL_TENANT_ID,
  AppStoreType,
  DEFAULT_SERVER_ERROR_MESSAGE,
  WRONG_CODE_ERROR_MESSAGE,
  STORE_REMOTE_CONFIG,
  CODE_ALREADY_SEND_MY_EMPLOYER_MESSAGE,
} from 'appConstants';
import Auth from '@aws-amplify/auth';
import adminUserService from 'services/AdminUserService';
import userService from 'services/UserService';
import localStorageService from 'services/LocalStoreService';
import { ServerError } from 'errors';
import { httpTenantService } from '../services/http';
import Tenant from '../models/Tenant';
import { RootStore } from './RootStore';

export const EMAIL_IS_IN_USE_ERROR_CODE = 'CAUSREU01';

function sortTenants(a: Tenant, b: Tenant) {
  if (!a.path) return -1;
  if (!b.path) return 1;
  return a.path.length - b.path.length;
}

interface AuthError {
  message: string;
}

export class UserStore {
  rootStore: RootStore;

  @observable authState: AuthState = AuthState.LOADING;

  @observable authError?: AuthError | null = null;

  @observable fetchStatus = FetchStatus.IDLE;

  @observable user: any = null;

  @observable currentTenant: Tenant | null = null;

  @observable tenants: IObservableArray<Tenant> = observable([]);

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get hasAdminAccess(): boolean {
    return this.currentTenant !== null;
  }

  @computed
  get isGlobalTenant(): boolean {
    return this.currentTenant !== null && this.currentTenant.id === GLOBAL_TENANT_ID;
  }

  @computed
  get curTenantId(): string {
    if (!this.currentTenant) {
      throw new Error("No tenant.");
    }
    return this.currentTenant.id;
  }

  @computed
  get curTenantRootWSId(): string {
    if (!this.currentTenant) {
      throw new Error("No tenant.");
    }
    return this.currentTenant.rootWorkspaceId;
  }

  @computed get isAuthenticated(): boolean {
    return this.authState === AuthState.SIGNED_IN;
  }

  @computed get isLoading(): boolean {
    return this.authState === AuthState.LOADING;
  }

  @computed get isAppInitialized(): boolean {
    return this.fetchStatus === FetchStatus.SUCCESS;
  }

  @action
  async appInit() {
    runInAction(() => this.fetchStatus = FetchStatus.PENDING);
    try {
      await this.checkAuthState();
      await this.loadTenants();
      await this.rootStore[STORE_REMOTE_CONFIG].sync();
      if (this.currentTenant && this.rootStore[STORE_UI].isAdminMode) {
        this.rootStore[STORE_ROUTER].goToTenantUrl();
      }
      await this.rootStore[STORE_INBOX].matrixLogin();
      runInAction(() => this.fetchStatus = FetchStatus.SUCCESS);
    } catch (error) {
      console.error(error);
      runInAction(() => this.fetchStatus = FetchStatus.FAILURE);
    }
  }

  @action
  async checkAuthState() {
    try {
      const user = await Auth.currentAuthenticatedUser();

      runInAction(() => {
        this.user = user;
        this.authState = AuthState.SIGNED_IN;
      });
    } catch (error) {
      runInAction(() => {
        this.user = null;
        this.authState = AuthState.SIGN_IN;
        this.authError = null;
        this.currentTenant = null;
      });
    }
  }

  @action
  async signOut(authError?: AuthError) {
    localStorageService.removeCurrentTenant();
    await this.rootStore[STORE_INBOX].matrixLogout();
    await Auth.signOut();
    runInAction(() => {
      this.user = null;
      this.authState = AuthState.SIGN_IN;
      this.authError = authError;
      this.currentTenant = null;
    });
    localStorageService.removeCurrentTenant();
    this.rootStore[STORE_ROUTER].push('/login');
  }

  @action
  signIn = () => {
    this.authState = AuthState.SIGNED_IN;
    this.rootStore[STORE_UI].isAdminMode = false;
  };

  loadTenants = async () => {
    try {
      const tenants = await adminUserService.loadTenants();
      if (!tenants.length) {
        return;
      }
      this.setTenants(tenants);
    } catch (error) {
      // do nothing - user cannot be admin
    }
  };

  @computed get userGivenName(): string | null {
    return this.user ?
      this.user.attributes.given_name || this.user.attributes.email :
      null;
  }

  @computed get userEmail(): string | null {
    return this.user ?
      this.user.attributes.email :
      null;
  }

  @action
  setTenants(tenants: Tenant[]) {
    if (!tenants.length) {
      throw new Error("No tenants.");
    }
    const orderedTenants = tenants.sort(sortTenants);
    this.tenants.replace(orderedTenants);
    const prevTenantId = localStorageService.getCurrentTenant();
    const tenantId = prevTenantId || orderedTenants[0].id;
    this.setCurrentTenant(tenantId);
  }

  @action
  removeTenant = (id: string) => {
    const tenants = this.tenants.find(t => t.id === id);
    if (tenants) {
      this.tenants.remove(tenants);
    }
  };

  @action
  setCurrentTenant = (id: string) => {
    const tenant = this.tenants.find(t => t.id === id);
    if (!tenant) {
      throw new Error(`Cannot find tenant with id=${id}`);
    }
    this.currentTenant = tenant;
    httpTenantService.setTenantId(id);
    localStorageService.setCurrentTenant(id);
    if (this.rootStore[STORE_UI].isAdminMode) {
      this.rootStore[STORE_ROUTER].switchTenantUrl();
    }
  };

  getAppStoreIdByType = (appStoreType: AppStoreType): string => {
    if (!this.currentTenant) {
      throw new Error("No tenant current.");
    }
    return this.currentTenant.stores[appStoreType];
  };

  sendEmail = async (email: string) => {
    try {
      await userService.sendEmail(email);
    } catch (error) {
      let message = DEFAULT_SERVER_ERROR_MESSAGE;
      if (error.response &&
          error.response?.status === 400 &&
          error.response?.data?.code !== EMAIL_IS_IN_USE_ERROR_CODE) {
        message = CODE_ALREADY_SEND_MY_EMPLOYER_MESSAGE;
      }
      throw new ServerError(message);
    }
  };

  @action
  verifyCode = async (code: string) => {
    try {
      const { assignedToWorkspaceId } = await userService.verifyCode(code);
      const navigateUrl = assignedToWorkspaceId ? `/workspace/${assignedToWorkspaceId}` : `/workspace`;
      this.rootStore[STORE_UI].closeModal();
      this.rootStore[STORE_ROUTER].push(navigateUrl);
    } catch (e) {
      console.error(e);
      // TODO: add error handler (ONA-1143)
      const errorMessage = e.response.status === 400 ?
        WRONG_CODE_ERROR_MESSAGE :
        DEFAULT_SERVER_ERROR_MESSAGE;
      throw new ServerError(errorMessage);
    }
  };
}

export default UserStore;
