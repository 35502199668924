import * as React from "react";
import { DEFAULT_DATE_FORMAT } from 'appConstants';
import { FieldProps } from "formik";
import DatePicker from "react-datepicker";
import styles from "./CustomDatePicker.module.scss";
import "react-datepicker/dist/react-datepicker.css";

interface Props extends FieldProps {
  initialMonth?: Date;
  placeholder?: string;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

class CustomDatePicker extends React.Component<Props> {
  onChange = (day: Date): void => {
    const { form, field } = this.props;
    const date = day ? new Date(Date.UTC(day.getFullYear(), day.getMonth(), day.getDate())) : null;
    form.setFieldValue(field.name, date);
  };

  // should be passed as func
  getDayClass = () => styles.day;

  render() {
    const { placeholder, initialMonth, onBlur, field } = this.props;
    return (
      <div className={styles.field}>
        <DatePicker
          openToDate={initialMonth}
          dateFormat={DEFAULT_DATE_FORMAT}
          placeholderText={placeholder}
          showYearDropdown
          yearDropdownItemNumber={8}
          selected={field.value}
          onChange={this.onChange}
          onBlur={onBlur}
          wrapperClassName={styles.wrapper}
          calendarClassName={styles.calendar}
          popperClassName={styles.popper}
          dayClassName={this.getDayClass}
        />
      </div>
    );
  }
}

export default CustomDatePicker;
