import { WorkspaceDescriptor } from 'models/WorkspaceDescriptor';
import { Descriptor } from 'models/Descriptor';
import { ROOT_WORKSPACE_ID } from 'appConstants';
import { HttpService, httpClientService } from './http';

class AppsDescriptorService {
  http: HttpService;

  constructor(http: HttpService) {
    this.http = http;
  }

  getWorkspaceDescriptor = (id: string | null = null, ids: string[] = []): Promise<WorkspaceDescriptor> => {
    const appId = id === null ?
      ROOT_WORKSPACE_ID :
      id;
    const queryStringParameters = ids.length ? { installedItems: ids.toString() } : {};
    return this.http.get<WorkspaceDescriptor>(`/apps/${appId}/releases/latest`, { queryStringParameters });
  };

  getAvailableApps = (appId: string): Promise<Descriptor[]> => {
    return this.http.get<Descriptor[]>(`/apps/${appId}/releases/latest/items`);
  };
}

export default new AppsDescriptorService(httpClientService);
