import { History } from 'history';
import {
  RouterStore as BaseRouterStore,
  syncHistoryWithStore,
} from 'mobx-react-router';
import { RootStore } from './RootStore';
import { STORE_USER, AppPaths } from '../appConstants';

export class RouterStore extends BaseRouterStore {
  rootStore: RootStore;

  tenantsBase = "tenants";

  onlyClientPaths = [AppPaths.SAML_SSO_LOGIN_PATH];

  onlyAdminPaths = [AppPaths.MANAGE_PATH, AppPaths.REPOSITORIES_PATH];

  sharedPaths = [AppPaths.INBOX_PATH, AppPaths.SETTING_PATH];

  constructor(rootStore: RootStore, history?: History) {
    super();
    this.rootStore = rootStore;
    if (history) {
      this.history = syncHistoryWithStore(history, this);
    }
  }

  get locationArray() {
    // example: '/wokspace/settings' ==> ["", "wokspace", "settings"] ==> ["wokspace", "settings"]
    return this.location.pathname.split("/").splice(1);
  }

  get isTenantUrl() {
    return this.locationArray[0] === this.tenantsBase;
  }

  get isOnlyClientUrl() {
    return this.onlyClientPaths.some(path => this.location.pathname.indexOf(path) !== -1);
  }

  get isSharedUrl() {
    return this.sharedPaths.some(path => this.locationArray[0] === path);
  }

  get isOnlyAdminUrl() {
    return this.onlyAdminPaths.some(path => this.locationArray[0] === this.tenantsBase && this.locationArray[2] === path);
  }

  get tenantPath() {
    const { currentTenant } = this.rootStore[STORE_USER];
    if (!currentTenant) {
      return "";
    }
    return `/${this.tenantsBase}/${currentTenant.id}`;
  }

  goToTenantUrl() {
    const { currentTenant } = this.rootStore[STORE_USER];
    if (this.isTenantUrl || this.isOnlyClientUrl || this.isSharedUrl || !currentTenant) return;
    this.push(`${this.tenantPath}/${AppPaths.WORKSPACE}`);
  }

  /**
   * Switch tenant url in case user tenant switch or in case admin mode and active tenant
   * Skip switch in case there we already have the same active tenant
   */
  switchTenantUrl() {
    // need to check location change because ReactSelect onChange fire event without actual change
    if (this.history.location.pathname.indexOf(`${this.tenantPath}/`) === -1 && !this.isOnlyClientUrl) {
      this.push(`${this.tenantPath}/workspace`);
    }
  }

  goToUserUrl() {
    if (!this.isSharedUrl) {
      this.push(`/${AppPaths.WORKSPACE}`);
    }
  }
}

export default RouterStore;
