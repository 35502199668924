import * as React from 'react';
import { inject, observer } from "mobx-react";
import { Formik } from "formik";
import { Loader } from 'components';
import { Repository } from "models/Repository";
import { STORE_UI, STORE_CURRENT_SERVICE } from "appConstants";
import { UIStore, CurrentServiceStore } from "stores";
import { ServiceData, ServiceFormValues } from 'interfaces';
import getInitialValues from './getInitialValues';
import CreateServiceForm from './CreateServiceForm';
import { VALIDATION_SCHEMA } from './validation';

export interface Props {
  item?: Repository;
  editMode?: boolean;
  onSubmit: (
    serviceData: ServiceData,
    shouldPublishToUnu: boolean,
    item?: Repository, shouldBeAddedToCurrentWS?: boolean
  ) => Promise<void>;
  [STORE_UI]: UIStore;
  [STORE_CURRENT_SERVICE]: CurrentServiceStore;
  // used on workspace page to add service after creation
  shouldBeAddedToCurrentWS?: boolean;
  submitError: null | string;
}

@inject(STORE_CURRENT_SERVICE, STORE_UI)
@observer
export default class CreateServiceModal extends React.Component <Props> {
  protected tabHolder = React.createRef<HTMLDivElement>();

  componentDidMount() {
    if (this.props.editMode && this.props.item) {
      this.props[STORE_CURRENT_SERVICE].loadServiceConfig(this.props.item.id);
    }
    this.props[STORE_CURRENT_SERVICE].loadUserAttributes();
  }

  componentWillUnmount() {
    this.props[STORE_CURRENT_SERVICE].resetConfig();
  }

  render() {
    const { isLoading, config } = this.props[STORE_CURRENT_SERVICE];
    const initialValues = getInitialValues(this.props.item, config);
    if (isLoading) {
      return <Loader />;
    }
    return (
      <Formik<ServiceFormValues>
        initialValues={initialValues}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={() => {}}
        validateOnMount
      >
        {form => (
          <CreateServiceForm<ServiceFormValues>
            {...this.props}
            form={form}
          />
        )}
      </Formik>
    );
  }
}
