import React from 'react';
import { LoginSignupHolder } from 'components';
import QRCodeSignIn from 'containers/QRCodeSignIn/QRCodeSignIn';

class LogInPage extends React.PureComponent {
  render() {
    return (
      <LoginSignupHolder>
        <QRCodeSignIn />
      </LoginSignupHolder>
    );
  }
}

export default LogInPage;
