/* eslint-disable no-underscore-dangle */
import React from 'react';
import { MatrixMessage, SelectOption } from 'interfaces';
import classNames from 'classnames';
import { SYSTEM_MESSAGE_USER_ID } from 'appConstants';
import { timeFormat } from 'utils/timeFormat';
import { safeJsonParse } from 'utils/safeJsonParse';
import { CustomButton, Avatar } from 'components';
import styles from './Message.module.scss';

export interface Props {
  msg: MatrixMessage;
  userId: string;
  username: string;
  sendMessage: (text: string) => void;
}

interface PromptData {
  prompt: string;
  buttons: SelectOption[];
}

export default class Message extends React.PureComponent<Props, any> {
  onButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => this.props.sendMessage(e.currentTarget.value);

  render() {
    const { msg, userId, username } = this.props;
    const isCurrentUserMessage = msg.user._id === userId;
    const isSystemMessage = msg.user._id === SYSTEM_MESSAGE_USER_ID;
    const time = timeFormat(msg.createdAt);
    const promptData = safeJsonParse<PromptData>(msg.text);

    return (
      <div className={classNames(
        {
          [styles.systemMsg]: isSystemMessage,
        }, styles.msg,
      )}
      >
        <Avatar
          msg={msg}
          isCurrentUserMessage={isCurrentUserMessage}
          username={username}
          className={styles.image}
        />
        <div className={styles.text}>
          {!isSystemMessage &&
            <div>
              <span className={styles.userName}>
                {isCurrentUserMessage ? username : msg.user.name}
              </span>
              <span className={styles.time}>{time}</span>
            </div>}
          {
            promptData && promptData.buttons ?
              <>
                {promptData.prompt}
                <div className={styles.buttons}>
                  {promptData.buttons.map(button => (
                    <CustomButton
                      buttonType="primary"
                      size="xs"
                      key={button.value}
                      value={button.value}
                      onClick={this.onButtonClick}
                    >
                      {button.label}
                    </CustomButton>
                  ))}
                </div>
              </> :
              <>{msg.text}</>
          }
        </div>
      </div>
    );
  }
}
