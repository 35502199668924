/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from "react";
import ImageCrop from "react-avatar-editor";
import { TILE_IMAGE_DESIGN_WIDTH, TILE_IMAGE_RATIO, RETINA_SCALE_FACTOR } from "appConstants";
import validateFileUpload from "utils/validateFileUpload";
import styles from "./ImageInput.module.scss";
import CustomButton from "../CustomButton/CustomButton";

// real sizes multiplied by RETINA_SCALE_FACTOR, to support retina display and save them on upload
const IMAGE_UPLOAD_MAX_WIDTH = TILE_IMAGE_DESIGN_WIDTH * RETINA_SCALE_FACTOR;
const IMAGE_UPLOAD_MAX_HEIGHT = IMAGE_UPLOAD_MAX_WIDTH * TILE_IMAGE_RATIO;

interface Props {
  onFileLoaded: (data: { iconName: string, iconDataUrl: string }) => void;
  onFileCleared: () => void;
  onShowNotification: (message: string) => void;
  iconDataUrl: string | null;
  onFileChanged: () => void;
}

interface State {
  iconFile: File | null;
  iconName: string | null;
  iconFileProps: {
    position: { x: number, y: number },
    scale: number,
    width: number,
    height: number
  } | null
}

export default class ImageInput extends React.Component<Props, State> {
  protected fileInput : React.RefObject<HTMLInputElement> = React.createRef();

  protected editor : ImageCrop | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      iconFile: null,
      iconName: null,
      iconFileProps: null,
    };
  }

  handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateFileUpload(e)
      .then(({ iconName, iconFile }) => {
        this.setState({
          iconFile,
          iconName,
          iconFileProps: {
            width: IMAGE_UPLOAD_MAX_WIDTH,
            height: IMAGE_UPLOAD_MAX_HEIGHT,
            position: { x: 0.5, y: 0.5 },
            scale: 1,
          },
        });
        this.props.onFileChanged();
      })
      .catch((message: any) => message && this.props.onShowNotification(message));
  };

  /**
   * Required to handle same file re-submission
   * @param event
   */
  handleFileClick = () => {
    if (this.fileInput.current) {
      this.fileInput.current.value = "";
    }
  };

  handleSave = () => {
    if (!this.editor) {
      return;
    }

    const { iconName } = this.state;

    if (!iconName) {
      return;
    }

    const iconDataUrl = this.editor.getImageScaledToCanvas().toDataURL();

    this.setState({ iconFile: null });
    this.props.onFileLoaded({ iconName, iconDataUrl });
  };

  handleScale = (e: React.ChangeEvent<HTMLInputElement>) => {
    const scale = parseFloat(e.target.value);
    if (this.state.iconFileProps) {
      // eslint-disable-next-line react/no-access-state-in-setstate
      this.setState({ ...this.state, iconFileProps: { ...this.state.iconFileProps, scale } });
    }
  };

  handlePositionChange = (position: { x: number, y: number }) => {
    if (this.state.iconFileProps) {
      // eslint-disable-next-line react/no-access-state-in-setstate
      this.setState({ ...this.state, iconFileProps: { ...this.state.iconFileProps, position } });
    }
  };

  clearData = (e: React.MouseEvent) => {
    e.preventDefault();
    this.props.onFileCleared();
  };

  setEditorRef = (editor: ImageCrop | null) => {
    if (editor) this.editor = editor;
  };

  render() {
    const { iconDataUrl } = this.props;
    const { iconFile, iconFileProps } = this.state;

    if (iconFile && iconFileProps) {
      const { scale, position } = iconFileProps;

      return (
        <div className={styles.frame}>
          <label>Image</label>
          <ImageCrop
            ref={this.setEditorRef}
            className={styles.iconCanvas}
            image={iconFile}
            width={IMAGE_UPLOAD_MAX_WIDTH}
            height={IMAGE_UPLOAD_MAX_HEIGHT}
            border={0}
            color={[206, 212, 218, 1]} // RGBA
            scale={scale}
            position={position}
            onPositionChange={this.handlePositionChange}
          />
          <input
            name="scale"
            type="range"
            onChange={this.handleScale}
            min="0.6"
            max="3"
            step="0.1"
            defaultValue="1"
          />
          <CustomButton color="primary" onClick={this.handleSave}>Finish crop</CustomButton>
        </div>
      );
    }

    return (
      <div className={styles.holder}>
        <input
          className={styles.hidden}
          id="fileInput"
          type="file"
          ref={this.fileInput}
          onChange={this.handleFileChange}
          onClick={this.handleFileClick}
        />
        <label>Image</label>
        <div className={styles.container}>
          {iconDataUrl && <img className={styles.icon} src={iconDataUrl} alt="icon" /> }

          <div className={styles.actions}>
            {!iconDataUrl && <label htmlFor="fileInput" className={styles.link}>add new</label>}
            {iconDataUrl && (
              <>
                <label htmlFor="fileInput" className={styles.link}>change</label>
                <label className={styles.link} onClick={this.clearData}>delete</label>
              </>)}
          </div>
        </div>
      </div>
    );
  }
}
