// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-app-polyfill/ie11';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'core-js/features/symbol';
// unorm for using String.prototype.normalize() in IE (used inside matrix-sdk)
import 'unorm';
import React from 'react';
import ReactDOM from 'react-dom';
import 'index.scss';
// extend yup by custom methods
import 'validation/methods';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import { Router, Route } from 'react-router';
import { createBrowserHistory } from 'history';
import { RootStore } from 'stores';
import * as serviceWorker from 'serviceWorker';
import App from 'containers/App';
import awsExports from 'aws-exports';
import { Env } from './appConstants';

if (process.env.NODE_ENV === Env.DEV) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
  const { worker } = require('./_mocks/browser');
  worker.start();
}

// enable MobX strict mode
configure({ enforceActions: "observed" });
// configure Amplify and Auth
Amplify.configure(awsExports);
Auth.configure(awsExports);

// prepare MobX stores
export const history = createBrowserHistory();
const rootStore = new RootStore(history);

const RootProvider =
  <Provider {...rootStore}>
    <Router history={history}>
      <Route path="/" component={App} />
    </Router>
  </Provider>;

ReactDOM.render(RootProvider, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (process.env.NODE_ENV !== Env.DEV) {
  // removed to support msw in dev mode - https://mswjs.io/docs/getting-started/integrate/browser
  serviceWorker.unregister();
}
