/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Alert } from 'components';
import Auth from '@aws-amplify/auth';
import { UserStore, SignInStore } from 'stores';
import { QRCode } from 'react-qrcode-logo';
import styles from './QRCodeSignIn.module.scss';
import { STORE_USER, STORE_SIGN_IN } from '../../appConstants';

Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });
const GREEN_BLUE_COLOR = '#28d7a1'; // equal to $green-blue in scss variables

interface DefaultProps {
  [STORE_USER]: UserStore;
  [STORE_SIGN_IN]: SignInStore;
}

interface Props extends Partial<DefaultProps> {}

function QRCodePlaceholder(props: { onClick: () => void }) {
  return (
    <div className={styles.qrCodePlaceholder}>
      <div role="button" className={styles.qrCodePlaceholderCircle} onClick={props.onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#fff" fill={GREEN_BLUE_COLOR} strokeLinecap="round" strokeLinejoin="round">
          <path d="M19.95 11a8 8 0 1 0 -.5 4m.5 5v-5h-5" />
        </svg>
        <span>Click to reload QR code</span>
      </div>
    </div>
  );
}

@inject(STORE_USER, STORE_SIGN_IN)
@observer
class QRCodeSignIn extends React.PureComponent<Props> {
  componentWillUnmount = () => {
    this.props[STORE_SIGN_IN]!.clear();
  };

  render() {
    const { actionToken, startSignIn, errorMessage } = this.props[STORE_SIGN_IN]!;

    return (
      <div className={styles.form}>
        <h1>Use Coll8 on your computer</h1>
        <ol className={styles.steps}>
          <li>Open Coll8 on your mobile</li>
          <li>Tap the camera/QR icon on bottom nav</li>
          <li>Scan the code below</li>
        </ol>
        <div className={styles.qrCodeHolder}>
          {!actionToken && (<QRCodePlaceholder onClick={startSignIn} />)}
          <QRCode value={actionToken} logoImage="/images/qr-code-logo.svg" />
        </div>
        <p>
          New to Coll8?
          <Link to="signup"> Sign up</Link>
        </p>
        {errorMessage && <Alert message={errorMessage} />}
      </div>
    );
  }
}

export default QRCodeSignIn;
