export enum CsvProcessingStatus {
  STARTED = 'started',
  IN_PROGRESS = 'inProgress',
  SUCCEED = 'succeed',
  FAILED = 'failed',
}

export enum CsvUserUploadAction {
  ADD = 'add',
  REMOVE = 'remove',
}

export enum MembershipRuleOperatorType {
  EQUALS = "equals",
  AND = "and",
  OR = "or",
  IN = "in",
  NOT_IN = "notIn",
  ANY = "any",
  NONE = "none",
  SOME = "some"
}

export const GROUP_RULE_OPERATOR_TITLE = {
  [MembershipRuleOperatorType.EQUALS]: "Equals",
  [MembershipRuleOperatorType.AND]: "And",
  [MembershipRuleOperatorType.OR]: "Or",
  [MembershipRuleOperatorType.IN]: "In",
  [MembershipRuleOperatorType.NOT_IN]: "Not In",
  [MembershipRuleOperatorType.ANY]: "Any",
  [MembershipRuleOperatorType.NONE]: "None",
  [MembershipRuleOperatorType.SOME]: "Some",
};

export enum MembershipRuleLogicalOperatorType {
  AND = "and",
  OR = "or",
}

export const GROUP_RULE_LOGICAL_OPERATOR_TITLE = {
  [MembershipRuleLogicalOperatorType.AND]: 'And',
  [MembershipRuleLogicalOperatorType.OR]: 'Or'
};
export interface MembershipRuleVariableExpression {
  variable: string;
}
export interface MembershipRuleValueExpression {
  value: string | string[];
}
export declare type MembershipRuleExpression = (
  MembershipRuleOperatorExpression | MembershipRuleVariableExpression | MembershipRuleValueExpression
);
export declare type MembershipRuleOperatorExpression = {
  [key in MembershipRuleOperatorType]?: {
    left: MembershipRuleExpression;
    right: MembershipRuleExpression;
  };
};
