import React, { MouseEvent } from 'react';
import { ItemDescriptor } from 'models/ItemDescriptor';
import { firebaseAnalyticsService } from 'services/firebase';
import { AppStoreItemType, AppPaths, UnuActions, AnalyticsEventType } from 'appConstants';
import { TileItem, TileContent } from 'components';
import classNames from "classnames";
import ContextMenuWrapper, { ContextMenuProps } from './ContextMenuWrapper';
import { UnuSupportAction } from '../../interfaces';
import styles from './WorkspaceDescriptorItem.module.scss';
import TileContentWrapper from '../TileContentWrapper/TileContentWrapper';

export interface Props {
  item: ItemDescriptor;
  wsHostTenantId: string;
  removeInstalledApp: (appId: string) => void;
  sendFeedback: (itemId: string, title: string, action: UnuSupportAction) => void;
  showOnboardingSvcModal: (title: string, mobileAppLink?: string) => void
}

const eventTypeByItemTypeMap = {
  [AppStoreItemType.WORKSPACE]: AnalyticsEventType.SELECT_WORKPSACE,
  [AppStoreItemType.MICRO_APP]: AnalyticsEventType.SELECT_SERVICE,
};

interface State { contextMenu: boolean }

const ContextMenuHolder = ({ hasContextMenu, children, ...props }: ContextMenuProps & { hasContextMenu: boolean }) => (
  hasContextMenu ? (
    <ContextMenuWrapper {...props}>{children}</ContextMenuWrapper>
  ) : <>{children}</>
);

export default class WorkspaceDescriptorItem extends React.Component<Props, State> {
  removeItem = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const { item: { appId } } = this.props;
    this.props.removeInstalledApp(appId);
  };

  sendFeedback = () => {
    const { actions, title } = this.props.item.config.properties;
    const unuSupportAction = actions && actions[UnuActions.unuSupport];
    if (unuSupportAction) {
      this.props.sendFeedback(this.props.item.appId, title, unuSupportAction);
    }
  };

  onItemClick = () => {
    const { itemId, type, config, tenantId } = this.props.item;
    const eventType = eventTypeByItemTypeMap[type];
    firebaseAnalyticsService.logEvent(eventType, {
      value: config.properties.title,
      title: config.properties.title,
      itemId,
      tenantId,
      wsHostTenantId: this.props.wsHostTenantId
    });
  };

  handleOnboardingSvcClick = () => this.props.showOnboardingSvcModal(
    this.props.item.config.properties.title,
    this.props.item.config.properties.appUrl,
  );

  render() {
    const { item } = this.props;
    const { config: { properties: { sso } } } = item;
    let appLink = item.config.properties.appUrl;

    if (sso && sso.samlServiceId && !sso.shouldInitFlow && item.config.properties.appUrl) {
      appLink = `${AppPaths.SAML_SSO_LOGIN_PATH}${encodeURIComponent(sso.samlServiceId)}?appUrl=${encodeURIComponent(item.config.properties.appUrl)}`;
    }

    const isWorkspace = item.type === AppStoreItemType.WORKSPACE;
    const hasContextMenu = !item.predefined;
    const { actions } = item.config.properties;
    const unuSupportAction = actions && actions[UnuActions.unuSupport];

    return (
      <ContextMenuHolder hasContextMenu={hasContextMenu} appId={item.appId} removeItem={this.removeItem}>
        <TileItem onClick={this.onItemClick}>
          {isWorkspace ?
            (
              <TileItem.Content isWorkspace>
                <TileContentWrapper
                  appId={item.appId}
                  itemType={item.type}
                  appStoreId={item.appStoreId}
                  isAdminMode={false}
                  itemId={item.itemId}
                  appUrl={appLink}
                  onOnboardingSvcClick={this.handleOnboardingSvcClick}
                >
                  <TileContent
                    description={item.config.properties.description}
                    title={item.config.properties.title}
                    iconUrl={item.config.properties.iconUrl}
                  />
                </TileContentWrapper>
              </TileItem.Content>) :
            (
              <TileItem.Content>
                <TileContentWrapper
                  appId={item.appId}
                  itemType={item.type}
                  appStoreId={item.appStoreId}
                  isAdminMode={false}
                  itemId={item.itemId}
                  appUrl={appLink}
                  onOnboardingSvcClick={this.handleOnboardingSvcClick}
                >
                  <TileContent
                    description={item.config.properties.description}
                    title={item.config.properties.title}
                    iconUrl={item.config.properties.iconUrl}
                  />
                </TileContentWrapper>
              </TileItem.Content>)}
          {unuSupportAction && (
            <i
              role="button"
              aria-label={unuSupportAction.name}
              onClick={this.sendFeedback}
              className={classNames("icon-conversations", styles.feedbackButton)}
            />
          )}
        </TileItem>
      </ContextMenuHolder>
    );
  }
}
