import { MoreApps } from 'containers';
import MyEmployer from '../../containers/MyEmployer';
import CreateGroupDomainModal from './CreateGroupDomainModal/CreateGroupDomainModal';
import DeleteConfirmationModal from './DeleteConfirmationModal/DeleteConfirmationModal';
import CreateWorkspaceModal from './CreateItemModals/CreateWorkspaceModal/CreateWorkspaceModal';
import CreateGroupModal from "./CreateGroupModal/CreateGroupModal";
import CreateGroupUserModal from './CreateGroupUserModal/CreateGroupUserModal';
import CsvModal from './CsvModal/CsvModal';
import CreateServiceModal from './CreateItemModals/CreateServiceModal/CreateServiceModal';
import CreateEditTenantModal from "./CreateEditTenantModal/CreateEditTenantModal";
import SupportModal from "./SupportModal/SupportModal";
import ApproveAllUsersModal from "./ApproveAllUsersModal/ApproveAllUsersModal";
import {
  ADD_MICRO_APP_MODAL,
  APPROVE_ALL_GROUP_USERS_MODAL,
  CREATE_EDIT_TENANT_MODAL,
  CREATE_GROUP_DOMAIN_MODAL,
  CREATE_GROUP_MODAL,
  CREATE_GROUP_USER_MODAL,
  CREATE_SERVICE_MODAL,
  CREATE_WORKSPACE_MODAL,
  DELETE_GROUP_MODAL,
  DELETE_GROUP_USER_MODAL,
  DELETE_REPOSITORY_MODAL,
  GROUP_USERS_CSV_MODAL,
  MY_EPMLOYER_MODAL,
  SUPPORT_MODAL,
  ONBOARDING_SVC_MODAL,
  DELETE_GROUP_TENANTS_MODAL,
  SHARE_GROUP_TENANT_MODAL,
} from '../../appConstants';
import OnboardingSvcModal from './OnboardingSvcModal/OnboardingSvcModal';
import ShareGroupTenantModal from "./ShareGroupTenantModal/ShareGroupTenantModal";

export default {
  [CREATE_SERVICE_MODAL]: CreateServiceModal,
  [CREATE_WORKSPACE_MODAL]: CreateWorkspaceModal,
  [DELETE_REPOSITORY_MODAL]: DeleteConfirmationModal,
  [DELETE_GROUP_MODAL]: DeleteConfirmationModal,
  [DELETE_GROUP_USER_MODAL]: DeleteConfirmationModal,
  [DELETE_GROUP_TENANTS_MODAL]: DeleteConfirmationModal,
  [CREATE_GROUP_MODAL]: CreateGroupModal,
  [CREATE_GROUP_DOMAIN_MODAL]: CreateGroupDomainModal,
  [SHARE_GROUP_TENANT_MODAL]: ShareGroupTenantModal,
  [GROUP_USERS_CSV_MODAL]: CsvModal,
  [CREATE_GROUP_USER_MODAL]: CreateGroupUserModal,
  [ADD_MICRO_APP_MODAL]: MoreApps,
  [MY_EPMLOYER_MODAL]: MyEmployer,
  [CREATE_EDIT_TENANT_MODAL]: CreateEditTenantModal,
  [SUPPORT_MODAL]: SupportModal,
  [APPROVE_ALL_GROUP_USERS_MODAL]: ApproveAllUsersModal,
  [ONBOARDING_SVC_MODAL]: OnboardingSvcModal,
};
