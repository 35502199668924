import * as React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router';
import {
  STORE_UI,
  AppPaths,
  STORE_USER,
} from 'appConstants';
import WorkspaceDescriptor from 'containers/WorkspaceDescriptor';
import Workspace from 'containers/Workspace';
import { UIStore, UserStore } from 'stores';
import MoreServices from 'containers/MoreServices';
import InboxPage from 'pages/InboxPage';
import SettingsPage from 'pages/SettingsPage';
import Group from "containers/Group";
import AdminServicesPage from "pages/AdminServicesPage";
import Groups from "containers/Groups";
import SsoAuthorizePage from "../SsoAuthorizePage/SsoAuthorizePage";
import { NotFoundPage } from '../NotFoundPage';
import Repository from "../../containers/Repository";
import Tenants from "../../containers/Tenants";

const AdminRoute = ({ component, isAdminMode, routeProps, ...rest }: any) => <Route
  {...rest}
  render={props => (
    isAdminMode ?
      React.createElement(component, { ...props, routeProps }) :
      <Redirect
        to={{
          pathname: '/workspace',
          state: { from: props.location },
        }}
      />
  )}
/>;

export interface Props {
  [STORE_UI]: UIStore;
  [STORE_USER]: UserStore;
  // passing ref for scrolling area for synchronizing of infinity scroll
  homeRef: React.RefObject<HTMLDivElement>;
}

class HomePageRoutes extends React.Component<Props> {
  protected homeMain = React.createRef<HTMLDivElement>();

  render() {
    const { isAdminMode } = this.props[STORE_UI];
    const { isGlobalTenant } = this.props[STORE_USER];
    const { homeRef } = this.props;

    return (
      <Switch>
        <Route exact path="/workspace/:workspaceId?" component={withRouter(WorkspaceDescriptor)} />
        <Route exact path="/settings" component={SettingsPage} />
        <Route exact path="/inbox" component={InboxPage} />
        <Route
          exact
          path={`${AppPaths.SAML_SSO_LOGIN_PATH}:samlServiceId`}
          component={withRouter(SsoAuthorizePage)}
        />
        <AdminRoute isAdminMode={isAdminMode} exact path="/tenants/:tenantId/workspace/:workspaceId/admin-services" component={withRouter(AdminServicesPage)} routeProps={{ homeRef }} />
        <AdminRoute isAdminMode={isAdminMode} path="/tenants/:tenantId/manage/groups/:groupId/:manageTabName?" component={Group} />
        <AdminRoute isAdminMode={isAdminMode} path="/tenants/:tenantId/repositories" component={withRouter(Repository)} routeProps={{ homeRef }} />
        <AdminRoute isAdminMode={isAdminMode} path="/tenants/:tenantId/manage/groups" component={withRouter(Groups)} />
        {isGlobalTenant && <AdminRoute isAdminMode={isAdminMode} path="/tenants/:tenantId/manage/tenants" component={withRouter(Tenants)} />}
        <AdminRoute isAdminMode={isAdminMode} exact path="/tenants/:tenantId/workspace/:workspaceId?" component={withRouter(Workspace)} />
        <AdminRoute isAdminMode={isAdminMode} exact path="/tenants/:tenantId/workspace/:workspaceId/more-services" component={withRouter(MoreServices)} routeProps={{ homeRef }} />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}

export default HomePageRoutes;
