import React from 'react';
import { inject, observer } from "mobx-react";
import { STORE_INBOX, STORE_USER } from "appConstants";
import { InboxStore, UserStore } from "stores";
import { ChatHolder } from 'components';
import InboxConversations from 'containers/InboxConversations/InboxConversations';

interface Props {
  [STORE_INBOX]: InboxStore;
  [STORE_USER]: UserStore;
}

@inject(STORE_INBOX, STORE_USER)
@observer
export default class InboxPage extends React.PureComponent<Props, any> {
  render() {
    return (
      <>
        <InboxConversations inboxStore={this.props[STORE_INBOX]} />
        <ChatHolder inboxStore={this.props[STORE_INBOX]} user={this.props[STORE_USER]} />
      </>
    );
  }
}
