import jp from 'jsonpath';
import { GroupRule } from "interfaces/group";
import {
  GROUP_RULE_LOGICAL_OPERATOR_TITLE,
  GROUP_RULE_OPERATOR_TITLE,
  MembershipRuleExpression,
  MembershipRuleLogicalOperatorType,
  MembershipRuleOperatorExpression,
  MembershipRuleOperatorType,
  MembershipRuleValueExpression,
  MembershipRuleVariableExpression
} from "../../appConstants";
import { UserAttributeDefinition, UserAttributeDefinitionType } from "../../interfaces/userAttributes";
import makeSelectOption from "../../utils/makeSelectOption";

export type LeftRightExpression = {
  left: MembershipRuleExpression;
  right: MembershipRuleExpression;
};

function isMembershipRuleOperatorType(operator: any): operator is MembershipRuleOperatorType {
  return Object.values(MembershipRuleOperatorType).includes(operator);
}

function isString(value: any): value is string {
  return typeof value === 'string';
}

function isStrings(values: any[] | any): values is string[] {
  return Array.isArray(values) && typeof values[0] === 'string';
}

// function isVariableExpression(expr: MembershipRuleExpression): expr is MembershipRuleVariableExpression {
//   return 'variable' in expr;
// }

function isValueExpression(expr: MembershipRuleExpression): expr is MembershipRuleValueExpression {
  return 'value' in expr;
}

function isLogicalOperator(logicalOperator: any): logicalOperator is MembershipRuleLogicalOperatorType {
  return Object.values(MembershipRuleLogicalOperatorType).includes(logicalOperator);
}

export class GroupRulesService {
  getValuesFromCsv(data: string[][]): string[] {
    return data.map(item => item[0]);
  }

  getCsvDataFromValues(values: string[]): string[][] {
    return values.map(item => [item, '']);
  }

  getAttributeName(path: string): string {
    // The function parses json path and returns the value of parent non-root path component
    // f.e. if path = '$.address.streetAddress', the function returns: 'address'
    // This works even if '$.' is missing in json path
    const pathComponents = jp.parse(path);
    // 1. Trying to find an attribute name in the paths with "member" operation - like 'address' or '$.address'
    // or
    // 2. Trying to find an attribute name in the paths with "subscript" operation: $['tesco:schemes']
    const parentComponent = pathComponents.find((component: any) => component.scope === 'child' && (
      component.expression.type === 'identifier' ||
      component.expression.type === 'string_literal'
    ));
    if (!parentComponent) {
      throw new Error(`Invalid JSONPath expression ${path}: can not find an attribute name`);
    }
    return parentComponent.expression.value;
  }

  buildRuleExpression(rule: GroupRule): MembershipRuleOperatorExpression {
    const value = rule.csvData ? this.getValuesFromCsv(rule.csvData) : rule.right;
    if (!rule.operator) {
      throw new Error('Operator is required');
    }
    return {
      [rule.operator.value]: {
        left: {
          variable: rule.jsonPath || rule.left?.value as string
        },
        right: isString(value) || isStrings(value) ? ({
          value
        }) : value
      }
    };
  }

  buildLogicalRuleExpression(leftRule: GroupRule, rightRule: GroupRule): MembershipRuleOperatorExpression {
    const logicalOperator = rightRule.logicalOperator?.value;
    if (!isLogicalOperator(logicalOperator)) {
      throw new Error("Can't build logical expression without operator.");
    }
    return {
      [logicalOperator]: {
        left: this.buildRuleExpression(leftRule),
        right: this.buildRuleExpression(rightRule)
      }
    };
  }

  buildGroupRule(
    definitionByNameMap: Record<string, UserAttributeDefinition>,
    expression: LeftRightExpression,
    operator: MembershipRuleOperatorType,
    logicalOperator: MembershipRuleLogicalOperatorType | null = null
  ): GroupRule {
    const variable = (expression.left as MembershipRuleVariableExpression).variable as string;
    /**
     * if we have definition for variable - it's not JSON path
     */
    const attrName = definitionByNameMap[variable] ? variable : this.getAttributeName(variable);
    const definition = definitionByNameMap[attrName];
    const isLeftJsonPath = definition?.type === UserAttributeDefinitionType.COMPOSITE && !definitionByNameMap[variable];
    if (!definition) {
      throw new Error("Can't build group rule: there is no definition.");
    }
    const right = isValueExpression(expression.right) ? expression.right.value : expression.right;
    return {
      operator: makeSelectOption(operator, GROUP_RULE_OPERATOR_TITLE[operator]),
      // eslint-disable-next-line no-nested-ternary
      right: isString(right) ? right : (isStrings(right) ? '' : right as MembershipRuleExpression),
      left: makeSelectOption(
        attrName,
        definition.displayName
      ),
      csvData: isStrings(right) ? this.getCsvDataFromValues(right) : null,
      jsonPath: isLeftJsonPath ? variable : '',
      logicalOperator: logicalOperator ?
        makeSelectOption(logicalOperator, GROUP_RULE_LOGICAL_OPERATOR_TITLE[logicalOperator]) :
        null,
    };
  }

  /**
   * Process UI rules presentation to rules expression for BE request
   * @param rules - group rules array
   * @returns logical expression tree
   */
  toRequest(rules: GroupRule[]): MembershipRuleOperatorExpression | undefined {
    if (!rules?.length) {
      throw new Error("Can't process empty rules list.");
    }
    if (rules.length > 2) {
      // TODO: more than 3 rules support was excluded from scope of ONA-4330
      throw new Error("Can't process more than two rules.");
    }
    if (rules.length === 1) {
      // case without logical operator
      return rules.reduce((acc: MembershipRuleOperatorExpression, rule: GroupRule) => {
        const expression = this.buildRuleExpression(rule);
        return Object.assign(acc, expression);
      }, {});
    }
    // rules with logical operators
    return this.buildLogicalRuleExpression(rules[0], rules[1]);
  }

  toForm(
    definitionByNameMap: Record<string, UserAttributeDefinition>,
    expressions: MembershipRuleOperatorExpression
  ): GroupRule[] {
    const rules: GroupRule[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [operator, expression] of Object.entries(expressions)) {
      if (isLogicalOperator(operator)) {
        const leftExpression = expression?.left as MembershipRuleOperatorExpression;
        const leftOperator = Object.keys(leftExpression)[0] as MembershipRuleOperatorType;
        const leftOperatorExpression = leftExpression[leftOperator] as LeftRightExpression;

        const rightExpression = expression?.right as MembershipRuleOperatorExpression;
        const rightOperator = Object.keys(rightExpression)[0] as MembershipRuleOperatorType;
        const rightOperatorExpression = rightExpression[rightOperator] as LeftRightExpression;
        rules.push(
          this.buildGroupRule(definitionByNameMap, leftOperatorExpression, leftOperator),
          this.buildGroupRule(definitionByNameMap, rightOperatorExpression, rightOperator, operator),
        );
      } else if (isMembershipRuleOperatorType(operator)) {
        // we have single rule
        rules.push(
          this.buildGroupRule(definitionByNameMap, expression as LeftRightExpression, operator)
        );
      }
    }
    return rules;
  }
}
