import * as React from "react";
import classNames from "classnames";
import styles from "./FormFlexContainer.module.scss";

type Props = {
  className?: string;
  children: React.ReactChild[] | React.ReactChild
};

/**
 * @deprecated
 * use reflexbox instead
 */
export default (props: Props) => (
  <div className={classNames(styles.container, props.className)}>
    {props.children}
  </div>
);
