import { WorkspaceItem } from "../models/WorkspaceItem";

export default function splitItemsByRowSize(items: WorkspaceItem[], rowSize: number): WorkspaceItem[][] {
  const count = Math.ceil(items.length / rowSize);
  const rows: WorkspaceItem[][] = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < count; i++) {
    rows.push(
      items.slice(i * rowSize, i * rowSize + rowSize),
    );
  }
  return rows;
}
