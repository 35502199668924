import { UnuSupportAction } from "../interfaces";
import { UnuSupportActionType, UNU_SUPPORT_ACTION_NAME } from "../appConstants";

export class UnuActionsService {
  createSupportAction = (type: UnuSupportActionType, welcomeMessage: string): UnuSupportAction => ({
    type,
    name: UNU_SUPPORT_ACTION_NAME,
    publicAttributes: {
      welcomeMessage,
    },
  });
}
