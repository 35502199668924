import * as React from 'react';
import { TabList, TabPanel, Tabs } from 'react-tabs';
import debounce from 'lodash.debounce';
import { toJS } from "mobx";
import {
  CustomTab,
  CustomField,
  ImageInput,
  CustomButton,
  CustomSelectField,
  CustomAutocomplete,
  FormFlexContainer,
} from 'components';
import SupportTab from 'containers/SupportTab/SupportTab';
import { FormikProps } from 'formik';
import {
  STORE_UI,
  STORE_CURRENT_WORKSPACE,
  workspaceAccessTypeOptions,
  WorkspaceAccessType,
  PAGINATION_FIRST_PAGE_NUMBER,
  DEBOUNCE_DELAY,
} from 'appConstants';
import { inject, observer } from "mobx-react";
import { WorkspaceFormValues } from 'interfaces';
import { getIconNameFromUrl } from "utils/getIconNameFromUrl";
import workspaceFormToConfig from 'services/dataMappers/workspaceFormToConfig';
import SsoFields from './SsoFields';
import { Props as ModalProps } from './CreateWorkspaceModal';
import createItemTabsWrapper from '../CreateItemTabsWrapper';
import styles from './CreateWorkspaceModal.module.scss';

interface FormProps extends ModalProps {
  form: FormikProps<WorkspaceFormValues>;
  imageInputRef: React.RefObject<ImageInput>;
  onTabSelect: () => void;
}

const initialState = {
  iconDataUrl: null as null | string,
  iconName: null as null | string,
  resizingMode: false as boolean,
  imageChanged: false as boolean,
};

type State = typeof initialState;

@inject(STORE_UI, STORE_CURRENT_WORKSPACE)
@observer
class CreateWorkspaceForm extends React.Component <FormProps, State> {
  state = initialState;

  handleGroupsInputChange = debounce((text: string) => {
    this.props[STORE_CURRENT_WORKSPACE].listGroups({ page: PAGINATION_FIRST_PAGE_NUMBER, term: text });
  }, DEBOUNCE_DELAY);

  componentDidMount() {
    const { currentWorkspace } = this.props[STORE_CURRENT_WORKSPACE];
    if (currentWorkspace) {
      const { iconUrl, iconDataUrl } = currentWorkspace.config.props;
      if (iconUrl && iconDataUrl) {
        const iconName = getIconNameFromUrl(iconUrl);
        this.setState({ iconName, iconDataUrl });
      }
    }
  }

  onSubmit = () => {
    const { groups } = this.props.form.values;
    const { currentWorkspace } = this.props[STORE_CURRENT_WORKSPACE];
    const { iconDataUrl: dataUrl, iconName: name } = this.state;
    // as group update happens one by one, we need to provide separate groups to add/remove
    const groupIdsToAdd = groups.map(group => group.id);
    const groupsIds = currentWorkspace && currentWorkspace.groups ?
      currentWorkspace.groups.map(group => group.id) :
      [];
    const groupIdsToRemove = groupsIds.filter(groupId => !(groupIdsToAdd.includes(groupId)));
    const config = workspaceFormToConfig(this.props.form.values, currentWorkspace, dataUrl, name);
    // for existing workspace (edit mode), itemId should be passed as edit param
    this.props.onSubmit(
      config,
      { groupIdsToAdd, groupIdsToRemove },
      currentWorkspace ? currentWorkspace.id : undefined,
      this.props.workspaceItem,
    );
  };

  onFileChanged = () => {
    this.setState({ resizingMode: true, imageChanged: true });
  };

  onFileLoaded = ({ iconName, iconDataUrl }: { iconName: string; iconDataUrl: string }) => {
    this.setState({ iconName, iconDataUrl, resizingMode: false, imageChanged: true });
  };

  resetIconData = () => {
    this.setState({ iconName: null, iconDataUrl: null, imageChanged: true });
  };

  onShowNotification = (message: string) => {
    this.props[STORE_UI].showMessageNotification(message);
  };

  render() {
    const { form, imageInputRef, onTabSelect } = this.props;
    const { isRootWorkspace } = this.props[STORE_CURRENT_WORKSPACE];
    const availableGroups = toJS(this.props[STORE_CURRENT_WORKSPACE].groups);
    const { iconDataUrl, resizingMode } = this.state;
    const isDisabled = resizingMode || !((this.state.imageChanged || form.dirty) && form.isValid);
    const groupSuggestions = availableGroups.filter(gr => !form.values.groups.some(addedGroup => addedGroup.id === gr.id));

    return (
      <Tabs onSelect={onTabSelect}>
        <TabList>
          <CustomTab>Settings</CustomTab>
          <CustomTab>Access</CustomTab>
          <CustomTab>Support</CustomTab>
        </TabList>
        <div className={styles.tabContent}>
          <TabPanel>
            <FormFlexContainer>
              <div>
                <ImageInput
                  ref={imageInputRef}
                  iconDataUrl={iconDataUrl}
                  onShowNotification={this.onShowNotification}
                  onFileLoaded={this.onFileLoaded}
                  onFileCleared={this.resetIconData}
                  onFileChanged={this.onFileChanged}
                />
              </div>
              <div>
                <CustomField
                  name="title"
                  title="Name"
                  placeholder="Add name..."
                  form={form}
                />
                <CustomField
                  name="description"
                  title="Short description"
                  placeholder="Add description"
                  form={form}
                />
              </div>
            </FormFlexContainer>
          </TabPanel>
          <TabPanel>
            <FormFlexContainer>
              <div>
                <CustomField
                  name="accessType"
                  title="Access type"
                  component={CustomSelectField}
                  options={workspaceAccessTypeOptions}
                  form={form}
                  isDisabled={isRootWorkspace}
                />
                {form.values.accessType.value === WorkspaceAccessType.PRIVATE &&
                <CustomAutocomplete
                  handleInputChange={this.handleGroupsInputChange}
                  label="User groups with access"
                  tags={form.values.groups}
                  suggestions={groupSuggestions}
                  form={form}
                  name="groups"
                  placeholder=""
                />}
              </div>
              <div>
                <SsoFields form={form} isRootWorkspace={isRootWorkspace} />
              </div>
            </FormFlexContainer>
          </TabPanel>
          <TabPanel>
            <SupportTab<WorkspaceFormValues>
              form={form}
            />
          </TabPanel>
        </div>
        <div className={styles.buttonRow}>
          <CustomButton type="submit" onClick={this.onSubmit} buttonType="primary" disabled={isDisabled}>Save</CustomButton>
        </div>
      </Tabs>
    );
  }
}

const WrappedComponent = createItemTabsWrapper(CreateWorkspaceForm);

export default WrappedComponent;
