/**
 * Build query from key-value object
 *
 * Example:
 * Given: {
 *   foo: 'bar',
 *   'z-index': 0,
 * }
 * Returns: foo=bar&z-index=0
 * @param parts key-value map of values
 * @returns built query
 */
export const buildQuery = (parts: KV) : string => encodeURI(
  Object.entries(parts)
    .map(part => part.join('='))
    .join('&'),
);

/**
 * Parse Oauth query into KV-map
 *
 * @param query auth query, example: #code=123456&store
 * @param sliceFirst is auth query started with "?" or "#"
 *
 * @returns query params map;
 */
export const parseOauthQuery = (query: string, sliceFirst: boolean): KV => decodeURIComponent(query)
  .slice(sliceFirst ? 1 : 0)
  .split('&')
  .reduce((memo:KV, item:string) => {
    const [title, value] = item.split('=');
    // eslint-disable-next-line no-param-reassign
    memo[title] = value;
    return memo;
  }, {});

/**
 * Get current url for redirects
 *
 * @param location Window location object
 *
 * @returns current page url to use with auth providers
 */
export const getCurrentOrigin = (location: Location) => `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ''}`;
