export const CHANGE_ORDER_ERROR = "Change order error. Please contact administrator.";
export const DOMAINS_REMOVE_MESSAGE = "Please confirm you want to remove the domain(s) from the Group";
export const GROUPS_REMOVE_MESSAGE = "The group will be removed from all private workspace of the tenant(s). The users in the group will loose access to the related private workspaces";
export const EMPTY_CERT_ERROR = "Please attach the certificate";

// workspaces
export const ADD_WORKSPACE_ITEM_ERROR = "Add workspace item failed.";
export const REMOVE_WORKSPACE_ITEM_ERROR = "Remove workspace item failed.";

// releases
export const CREATE_RELEASE_ERROR = "Create release failed";
export const SERVICE_RELEASE_SUCCESS = "Service release successful";
export const WORKSPACE_RELEASE_SUCCESS = "Workspace release successful";

// services
export const CREATE_SERVICE_SUCCESS = "Service successfully created";
export const UPDATE_SERVICE_SUCCESS = "Service successfully updated";

// tenants
export const TENANT_DELETE_CONFIRMATION_MESSAGE = "Please confirm you want to delete the tenant";
export const TENANT_LOADING_SERVER_ERROR_MESSAGE = "Something went wrong while fetching shared tenants";

// share group with tenant
export const TENANT_SHARE_DEFAULT_ERROR_MESSAGE = "Admin group cannot be shared.";
export const TENANT_SHARE_SERVER_ERROR_MESSAGE = "No tenant with such name. Please check the name of the tenant and try again.";
export const TENANT_SHARE_DUPLICATE_ERROR_MESSAGE = "Tenant name has already been added.";

// general
export const DEFAULT_MESSAGE = "Are you sure that you want to make this changes?";
export const DEFAULT_SERVER_ERROR_MESSAGE = "Something went wrong...";
export const WRONG_CODE_ERROR_MESSAGE = "Sorry, the code is wrong. Please check the code for typos.";

// groups and users
export const GROUP_MESSAGE = "Please confirm you want to delete the group(s)";
export const USER_MESSAGE = "Please confirm you want to remove the user(s) from the Group";
export const CSV_USERS_SUCCESSFULLY_PROCESSED = "Users from CSV file were successfully processed.";
export const DELETE_GROUP_UNLINK_WORKSPACES = "Please unlink the group from all private workspaces first";
export const DELETE_GROUP_REMOVE_USERS = "Group with users count more than 5000 can't be removed, please remove users first";
export const GROUP_WAS_UPDATED_MESSAGE = "Group was updated successfully";
export const DIRTY_FORM_LEAVING_WARN = "You have unsaved changes! If you leave, your changes will be lost.";

// login
export const DEFAULT_LOGIN_ERROR_MESSAGE = "There was an error on attempt to login. Please try again later.";

// Add employer
export const CODE_ALREADY_SEND_MY_EMPLOYER_MESSAGE = "The service is busy now. Please try in three minutes.";
