import { computed, observable, runInAction } from "mobx";
import Catch from "catch-decorator";
import { RootStore } from "./RootStore";
import { firebaseConfigService as fbConfigService } from "../services/firebase";
import { FeatureFlags } from "../appConstants";
import { catchXhr } from "../services/errorService";

export class RemoteConfigStore {
  rootStore: RootStore;

  @observable featureStates: { [key: string]: boolean } = {};

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed get isAddEmployerEnabled(): boolean {
    return this.featureStates[FeatureFlags.ADD_EMPLOYER_ENABLED] === true;
  }

  @computed get isSupportChatEnabled(): boolean {
    return this.featureStates[FeatureFlags.SUPPORT_CHAT_ENABLED] === true;
  }

  @Catch(Error, catchXhr)
  async sync() {
    await fbConfigService.syncParams();
    const supportChatFeatureState = fbConfigService.getFeatureState(FeatureFlags.SUPPORT_CHAT_ENABLED);
    const addEmployerFeatureState = fbConfigService.getFeatureState(FeatureFlags.ADD_EMPLOYER_ENABLED);
    runInAction(() => {
      this.featureStates[FeatureFlags.SUPPORT_CHAT_ENABLED] = supportChatFeatureState;
      this.featureStates[FeatureFlags.ADD_EMPLOYER_ENABLED] = addEmployerFeatureState;
    });
  }
}

export default RemoteConfigStore;
