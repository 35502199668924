import { HttpService, httpTenantService } from "./http";
import Group from '../models/Group';
import { GroupOwnershipType } from "../interfaces";

export interface GroupsPagedRequest extends PagedRequest {
  ownershipType?: GroupOwnershipType
}

class GroupsService {
  http: HttpService;

  constructor(http: HttpService) {
    this.http = http;
  }

  retrieveGroups = (params: GroupsPagedRequest): Promise<PagedResponse<Group>> => {
    return this.http.get<PagedResponse<Group>>(`/groups`, { queryStringParameters: params });
  };

  addGroup = (name: string): Promise<Group> => {
    return this.http.post<Group>(`/groups`, { body: { name } });
  };

  deleteGroup = (groupId: string): Promise<void> => {
    return this.http.delete<void>(`/groups/${groupId}`);
  };
}

export default new GroupsService(httpTenantService);
