import React from 'react';
import { CustomButton, ButtonHolder, CustomField } from 'components';
import { Form, FormikProps, FormikHelpers } from "formik";
import GroupUser from 'models/GroupUser';

export interface Props {
  submitError: null | string;
  form: FormikProps<GroupUser>;
  setErrors: FormikHelpers<GroupUser>["setErrors"];
}

export default function CreateGroupUserForm(props: Props) {
  const { form } = props;
  const isDisabled = !form.dirty || !form.isValid;

  return (
    <Form>
      <CustomField
        withSeparator
        name="email"
        title="Email"
        placeholder="Enter email..."
        form={form}
        onChange={form.handleChange}
      />
      <CustomField
        withSeparator
        name="firstName"
        title="First name"
        placeholder="Enter first name..."
        form={form}
        onChange={form.handleChange}
      />
      <CustomField
        withSeparator
        name="lastName"
        title="Last name"
        placeholder="Enter last name..."
        form={form}
        onChange={form.handleChange}
      />
      <ButtonHolder>
        <CustomButton
          disabled={isDisabled}
          buttonType="primary"
          type="submit"
        >
          Add to group
        </CustomButton>
      </ButtonHolder>
    </Form>);
}
