import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';
import { Loader, CustomButton, CustomField } from 'components';
import { VALIDATION_RULES } from 'validation';
import { STORE_USER } from 'appConstants';
import { UserStore } from 'stores';
import styles from './MyEmployer.module.scss';

export interface MyEmployerProps {
  [STORE_USER]: UserStore;
}

const getValidation = (withCodeValidation: boolean) => Yup.object().shape({
  email: VALIDATION_RULES.email,
  code: withCodeValidation ? VALIDATION_RULES.code : Yup.string(),
});

interface FormValues {
  email: string;
  code: string;
}

const initialState = { isActiveCodeScreen: false };
type State = typeof initialState;

@inject(STORE_USER)
@observer
export class MyEmployer extends React.Component<MyEmployerProps, State> {
  readonly state: State = initialState;

  onNext = async ({ email }: FormValues, actions: FormikHelpers<FormValues>) => {
    actions.setStatus();
    try {
      await this.props[STORE_USER].sendEmail(email);
      this.setState({ isActiveCodeScreen: true });
    } catch (e) {
      actions.setErrors({ email: e.message });
    } finally {
      actions.setSubmitting(false);
    }
  };

  onVerify = async ({ code }: FormValues, actions: FormikHelpers<FormValues>) => {
    actions.setStatus();
    try {
      await this.props[STORE_USER].verifyCode(code);
    } catch (e) {
      actions.setErrors({ code: e.message });
    } finally {
      actions.setSubmitting(false);
    }
  };

  render() {
    const { isActiveCodeScreen } = this.state;
    const validationSchema = getValidation(this.state.isActiveCodeScreen);
    return (
      <>
        <h1>{isActiveCodeScreen ? 'Verify email' : 'Add employer'}</h1>
        <p>
          {isActiveCodeScreen ?
            'We have emailed you a verification code. Please check your email, including spam folders, and enter the code below' :
            'To connect to your workplace, please enter your company email below.'}
        </p>
        <Formik
          initialValues={{ email: '', code: '' }}
          validationSchema={validationSchema}
          onSubmit={isActiveCodeScreen ? this.onVerify : this.onNext}
        >
          {form => {
            if (form.isSubmitting) {
              return (<Loader />);
            }
            return (
              <Form>
                {isActiveCodeScreen &&
                  <CustomField
                    className={styles.input}
                    name="code"
                    title="Code"
                    placeholder="verification code..."
                    form={form}
                    onChange={form.handleChange}
                  />}
                {!isActiveCodeScreen &&
                  <CustomField
                    className={styles.input}
                    name="email"
                    title="Email"
                    placeholder="Enter email"
                    form={form}
                    onChange={form.handleChange}
                  />}
                <CustomButton
                  disabled={!form.dirty || form.isSubmitting}
                  buttonType="primary"
                  type="submit"
                  role="button"
                  fullWidth
                >
                  Next
                </CustomButton>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default MyEmployer;
