import React from 'react';
import styles from './LoginSignupHolder.module.scss';

const LoginSignupHolder = ({ children } : { children: React.ReactNode; }) => {
  return (
    <div className={styles.loginWrapper}>
      <div className={styles.loginHolder}>
        <img
          alt="Coll8 Top"
          src="/images/coll8-top.png"
          srcSet="/images/coll8-top@2x.png 2x, /images/coll8-top@3x.png 3x"
        />
        <div className={styles.contentHolder}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default LoginSignupHolder;
