// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/remote-config";
import firebaseConfig from 'firebaseConfig';
import { FirebaseConfigService } from "./FirebaseConfigService";

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const firebaseAnalyticsService = firebase.analytics();
export const firebaseConfigService = new FirebaseConfigService(firebase.remoteConfig());
