import * as React from 'react';
import { inject, observer } from "mobx-react";
import { Table, CustomButton, ButtonHolder, TextStyle, Loader } from 'components';
import { UIStore, GroupStore } from 'stores';
import { RouteComponentProps } from "react-router";
import {
  STORE_UI,
  FORM_MODAL_WIDTH,
  DELETE_GROUP_USER_MODAL,
  DOMAINS_REMOVE_MESSAGE,
  STORE_CURRENT_GROUP,
  CREATE_GROUP_DOMAIN_MODAL,
} from "appConstants";
import { getDomainsGroupColumns } from '../groupColumns';
import styles from "../Group.module.scss";

export interface TableDomains {
  domain: string;
}

interface Props extends RouteComponentProps<{ tenantId: string, groupId: string, manageTabName: string }> {
  [STORE_UI]: UIStore;
  [STORE_CURRENT_GROUP]: GroupStore;
}

const initialState = {
  selectedDomains: [] as TableDomains[],
};

type State = typeof initialState;

@inject(STORE_UI, STORE_CURRENT_GROUP)
@observer
export default class Domains extends React.PureComponent<Props, State> {
  state = initialState;

  private columns: TableColumn<TableDomains>[];

  constructor(props: Props) {
    super(props);
    this.columns = getDomainsGroupColumns();
  }

  handleSelectionDomainsChange = (domains: TableDomains[]) => {
    this.setState({ selectedDomains: domains });
  };

  handleDeleteDomains = () => {
    this.handleDeleteDomainsClick(this.state.selectedDomains);
  };

  handleDeleteDomainsClick = (selectedDomains: TableDomains[]) => {
    const { groupId } = this.props.match.params;
    const domains = selectedDomains.map(d => d.domain);
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH.MEDIUM,
      componentKey: DELETE_GROUP_USER_MODAL,
      title: "Delete Domain(s)?",
      bodyText: DOMAINS_REMOVE_MESSAGE,
      eventProps: {
        onSubmit: () => this.props[STORE_CURRENT_GROUP].deleteGroupDomains(groupId, domains),
      },
    });
  };

  showDomainModal = () => {
    this.props[STORE_UI].openModal({
      componentKey: CREATE_GROUP_DOMAIN_MODAL,
      title: 'Add new domain name',
      eventProps: {
        onSubmit: this.props[STORE_CURRENT_GROUP].addDomain,
      },
      props: {
        groupId: this.props.match.params.groupId,
      },
    });
  };

  public render() {
    const { domains, isGroupLoaded } = this.props[STORE_CURRENT_GROUP];
    const { selectedDomains } = this.state;
    const selectedDomainsCount = selectedDomains.length;
    const tableDomains = domains.map(d => ({ domain: d }));

    return (
      <>
        <div>
          <TextStyle variation="strong">Domain names</TextStyle>
          <br />
          Users who have a validated email address using domain below will be added to the group
        </div>
        {!isGroupLoaded ? (
          <Loader />
        ) : (
          <>
            <ButtonHolder>
              {selectedDomainsCount > 0 && (
                <CustomButton
                  className={styles.delete}
                  onClick={this.handleDeleteDomains}
                >
                  Remove selected
                </CustomButton>
              )}
              <CustomButton onClick={this.showDomainModal}>
                Add domain
              </CustomButton>
            </ButtonHolder>
            <Table<TableDomains>
              rowKey="domain"
              isStriped
              columns={this.columns}
              values={tableDomains}
              onSelectionChange={this.handleSelectionDomainsChange}
            />
          </>
        )}
      </>
    );
  }
}
