import React from 'react';
import classNames from "classnames";
import { Box } from 'reflexbox';
import { Field, FormikProps } from "formik";
import { CustomButton, Alert } from 'components';
import styles from './CustomFileField.module.scss';

export enum FileFieldVariant {
  BUTTON = 'button',
  INPUT_LINK = 'inputLink'
}
interface Props {
  name: string;
  form: FormikProps<any>;
  label: string;
  buttonText: string;
  onChange?: (value: any, file: File) => void;
  fieldVariant?: FileFieldVariant;
  fileReader?: (file: File) => Promise<unknown>,
  disabled?: boolean;
  // formats for upload, example: accept=".csv"
  accept?: string;
  defaultFileName?: string;
  // file size restriction in KB
  maxFileSize?: number;
}

const fieldVariants = {
  [FileFieldVariant.BUTTON]: styles.button,
  [FileFieldVariant.INPUT_LINK]: styles.inputLink,
};

const initialState = {
  fileName: null as null | string,
};

type State = typeof initialState;

class CustomFileField extends React.Component<Props, State> {
  state = initialState;

  onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { maxFileSize, form, name } = this.props;
    if (!event.currentTarget.files || !event.currentTarget.files.length) {
      return;
    }
    const file = event.currentTarget.files[0];
    if (maxFileSize && file.size > maxFileSize * 1024) {
      form.setErrors({ [name]: `Max file size is ${maxFileSize} KB` });
      return;
    }
    try {
      const fileName = file.name;
      this.setState({ fileName });
      const value = this.props.fileReader ? await this.props.fileReader(file) : file;
      form.setFieldValue(name, value);
      if (typeof this.props.onChange === 'function') {
        this.props.onChange(value, file);
      }
    } catch (error) {
      const messages = Array.isArray(error) ? error.map(({ message }) => message) : [error.message];
      form.setErrors({ [name]: messages.join(',') });
    }
  };

  onRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
    // any button click inside formik invoke validation call
    event.preventDefault();
    this.setState({ fileName: null });
    this.props.form.setFieldValue(this.props.name, '');
  };

  renderInput = () => {
    const { fieldVariant = FileFieldVariant.BUTTON } = this.props;

    return (this.props.form.values[this.props.name] && !this.props.disabled) ? (
      <Box className={styles.removeBtnHolder}>
        <span className={styles.fileName}>{this.state.fileName || this.props.defaultFileName || "file"}</span>
        <CustomButton buttonType="link" size="s" onClick={this.onRemove}> Remove </CustomButton>
      </Box>
    ) : (
      <div className={classNames({ [styles.isDisabled]: this.props.disabled }, fieldVariants[fieldVariant])}>
        {this.props.buttonText}
        <input
          disabled={this.props.disabled}
          className={styles.input}
          onChange={this.onChange}
          name={this.props.name}
          type="file"
          accept={this.props.accept}
        />
      </div>
    );
  };

  render() {
    const { name, label, form, ...props } = this.props;
    const errorMessage = form.errors[name];
    return (
      <div className={styles.inputHolder}>
        <label htmlFor={name} className={styles.label}>
          {label}
        </label>
        <br />
        <Field
          {...props}
          id={name}
          component={this.renderInput}
          name={name}
        />
        {errorMessage && <Alert message={errorMessage as string} />}
      </div>
    );
  }
}

export default CustomFileField;
