import React from 'react';
import styles from "./Label.module.scss";

type Props = Partial<{
  title: string;
  labelIcon: React.ReactNode;
  withSeparator: boolean;
}>;

const Label = ({ title, labelIcon, withSeparator }: Props) => (
  <span className={styles.label}>
    {title}
    {labelIcon}
    {withSeparator && ':'}
  </span>);

export default Label;
