import * as React from 'react';
import { Link } from 'react-router-dom';
import makeLinkUrl from '../../utils/makeLinkUrl';

export interface Props {
  to: string;
  className?: string;
  children: React.ReactNode;
}

export default function CustomLink(props: Props) {
  const to = makeLinkUrl(props.to);
  return (<Link to={to} className={props.className}>{props.children}</Link>);
}
