import * as React from 'react';
import { CustomButton, ButtonHolder } from 'components';

export interface Props {
  closeModal: () => void;
  onSubmit: () => void;
}

export default ({ closeModal, onSubmit }: Props) => (
  <>
    <p>
      If you change from manual to automatic approval, everyone in the previous manual queue will also be approved.
    </p>
    <ButtonHolder>
      <CustomButton
        onClick={closeModal}
      >
        Cancel
      </CustomButton>
      <CustomButton
        buttonType="primary"
        onClick={onSubmit}
      >
        Yes, approve all
      </CustomButton>
    </ButtonHolder>
  </>);
