import * as React from "react";
import { RouteComponentProps, Prompt } from "react-router-dom";
import { GroupStore, UIStore, UserAttributesStore } from "stores";
import { inject, observer } from "mobx-react";
import { DIRTY_FORM_LEAVING_WARN, STORE_CURRENT_GROUP, STORE_UI, STORE_USER_ATTRIBUTES } from "appConstants";
import { CustomButton, Loader } from "components";
import { FieldArray, Form, Formik } from "formik";
import { GroupRule, RulesFormValues } from "interfaces/group";
import { makeUserAttributeLabel } from "utils/makeUserAttributeLabel";
import makeSelectOption from "utils/makeSelectOption";
import { hasUnSupportedRules, makeInitialRule, Rules } from './Rules';
import { buildValidationSchema } from "./validation";
import { AttrNameOptionData } from "./types";
import styles from "./MembershipRules.module.scss";

interface Props extends RouteComponentProps<{ tenantId: string, groupId: string, manageTabName: string }> {
  [STORE_UI]: UIStore;
  [STORE_USER_ATTRIBUTES]: UserAttributesStore;
  [STORE_CURRENT_GROUP]: GroupStore;
}

const emptyRules = [
  makeInitialRule()
];

function getInitialValues(rules: GroupRule[] = emptyRules) {
  return {
    rules
  };
}
@inject(STORE_UI, STORE_USER_ATTRIBUTES, STORE_CURRENT_GROUP)
@observer
export default class MembershipRules extends React.PureComponent<Props> {
  render() {
    const { attrsForGroupRules, isUserAttributesLoading, definitionByNameMap } = this.props[STORE_USER_ATTRIBUTES];
    const { isGroupLoaded, clearRules } = this.props[STORE_CURRENT_GROUP];
    const attributeNameOptions = attrsForGroupRules.map(
      attribute => makeSelectOption<string, AttrNameOptionData>(
        attribute.name,
        makeUserAttributeLabel(attribute),
        {
          type: attribute.type
        }
      )
    );
    const validationSchema = buildValidationSchema(definitionByNameMap);

    return (
      <>
        {isUserAttributesLoading || !isGroupLoaded ? (
          <Loader />
        ) : (
          <>
            <Formik<RulesFormValues>
              initialValues={getInitialValues(
                this.props[STORE_CURRENT_GROUP].groupRules
              )}
              onSubmit={this.props[STORE_CURRENT_GROUP].applyRules}
              validationSchema={validationSchema}
              validateOnMount
            >
              {form => (
                <Form>
                  <FieldArray
                    name="rules"
                    render={arrayHelpers => (
                      <Rules
                        arrayHelpers={arrayHelpers}
                        attributeNameOptions={attributeNameOptions}
                        form={form}
                        clearRules={clearRules}
                      />
                    )}
                  />
                  <div className={styles.btnSubmitAttributes}>
                    <CustomButton
                      buttonType="primary"
                      type="submit"
                      disabled={!form.isValid || hasUnSupportedRules(form.values.rules) || !form.dirty}
                    >
                      Save
                    </CustomButton>
                  </div>
                  <Prompt
                    when={form.dirty}
                    message={DIRTY_FORM_LEAVING_WARN}
                  />
                </Form>
              )}
            </Formik>
          </>
        )}
      </>
    );
  }
}
