import * as React from 'react';
import { ImageInput } from 'components';
import { Form } from 'formik';
import { FormikProps } from 'formik';

interface Props<T> {
  form: FormikProps<T>;
}

export default (WrappedComponent: React.ComponentType<any>) => {
  class CreateItemTabsWrapper<T> extends React.Component<Props<T>> {
    protected imageInput = React.createRef<ImageInput>();

    onTabSelect = () => {
      const fileLoader = this.imageInput.current;
      if (fileLoader) {
        fileLoader.handleSave();
      }
    };

    render() {
      return (
        <Form>
          <WrappedComponent {...this.props} imageInputRef={this.imageInput} onTabSelect={this.onTabSelect} />
        </Form>
      );
    }
  }

  return CreateItemTabsWrapper;
};
