import * as React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { STORE_INBOX, STORE_USER } from 'appConstants';
import { observer } from 'mobx-react';
import { InboxStore, UserStore } from 'stores';
import styles from './Sidebar.module.scss';

interface Props {
  isAdminMode: boolean;
  [STORE_INBOX]: InboxStore;
  [STORE_USER]: UserStore;
}

@observer
class Sidebar extends React.PureComponent<Props, any> {
  render() {
    const { isAdminMode } = this.props;
    const { hasAdminAccess } = this.props[STORE_USER];
    const { totalUnreadAndInvited } = this.props[STORE_INBOX];
    const workspaceLink = isAdminMode && hasAdminAccess ? `/tenants/${this.props[STORE_USER].curTenantId}/workspace` : "/workspace";
    return (
      <div className={styles.homeSideBar}>
        <Link to={workspaceLink} className={styles.logo}>
          <img src="/images/logo.svg" alt="Coll8" />
        </Link>
        <ul className={styles.navigation}>
          <li>
            <NavLink activeClassName={styles.active} to={workspaceLink}>
              <i className="icon-dashboard" />
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={styles.active} to="/inbox">
              <i className="icon-conversations" />
              {!!totalUnreadAndInvited && <span className={styles.number}>{totalUnreadAndInvited}</span>}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={styles.active} to="/settings">
              <i className="icon-settings" />
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}

export default Sidebar;
