import React from "react";
import { Select } from 'components';
import { FieldProps } from "formik";
import { SelectOption } from 'interfaces';

interface Props<TOptionValue = string, TOptionData = undefined> extends FieldProps {
  onSelectChange?: (name: string, value: SelectOption<TOptionValue, TOptionData>) => void;
  className?: string;
  disabled?: boolean
}

class CustomSelectField<
  TOptionValue = string,
  TOptionData = undefined> extends React.Component <Props<TOptionValue, TOptionData>> {
  onSelectChange = (val: SelectOption<TOptionValue, TOptionData>) => {
    const { field, form, onSelectChange } = this.props;
    form.setFieldTouched(field.name);
    form.setFieldValue(field.name, val);
    onSelectChange && onSelectChange(field.name, val);
  };

  render() {
    const { className, onSelectChange, disabled, ...props } = this.props;
    return (
      <Select<TOptionValue, TOptionData>
        {...props}
        isDisabled={disabled}
        onChange={this.onSelectChange}
      />
    );
  }
}

export default CustomSelectField;
