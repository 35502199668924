import React from 'react';
import { TileItem, TileContent, CustomButton } from 'components';
import { Workspace } from 'models/Workspace';
import { observer } from 'mobx-react';
import styles from '../Repository.module.scss';

export interface Props {
  item: Workspace;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void
}

@observer
export default class RepositoryWorkspaceItem extends React.Component<Props> {
  handleEdit = () => this.props.onEdit(this.props.item.id);

  handleDelete = () => this.props.onDelete(this.props.item.id);

  render() {
    const { item } = this.props;

    return (
      <TileItem key={item.id}>
        <TileItem.Content isWorkspace>
          <TileContent
            description={item.config.props.description}
            title={item.config.props.title}
            iconUrl={item.config.props.iconUrl}
          />
        </TileItem.Content>
        <TileItem.Footer>
          <div className={styles.buttonHolder}>
            <CustomButton
              size="s"
              buttonType="link"
              onClick={this.handleEdit}
            >
              Edit
            </CustomButton>
            <CustomButton
              size="s"
              buttonType="linkAlt"
              onClick={this.handleDelete}
            >
              Delete
            </CustomButton>
          </div>
        </TileItem.Footer>
      </TileItem>
    );
  }
}
