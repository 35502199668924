import { HttpService } from "./HttpService";
import HttpTenantService from "./HttpTenantService";
import { CLIENT_API_NAME, ADMIN_API_NAME, PUBLIC_API_NAME } from "../../appConstants";

export { HttpService } from "./HttpService";
export { default as HttpTenantService } from "./HttpTenantService";

export const httpPublicService = new HttpService(PUBLIC_API_NAME);
export const httpClientService = new HttpService(CLIENT_API_NAME);
export const httpAdminService = new HttpService(ADMIN_API_NAME);
export const httpTenantService = new HttpTenantService(ADMIN_API_NAME);
