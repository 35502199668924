import * as Yup from 'yup';
import { SSOAttributeType, SSOFlowOptions, SsoProviderType, UnuSupportActionType } from 'appConstants';
import { SSO_VALIDATION_ERROR_LIST } from './ssoRules';
import { SelectOption } from '../interfaces';
import {
  max70SymbolsMessage,
  latinAndOthersMessage,
  requiredErrorMessage,
  alphaNumericErrorMessage,
  urlFormatErrorMessage,
  twoSymbolsErrorMessage,
  latinLettersShortErrorMessage,
  urlFormatShortErrorMessage,
  emptyFieldErrorMessage,
  latinAndSymbolsOnlyMessage,
} from './messages';
import { nameRegExp, digitsRegExp, alphaNumericRegExp, nameSpecRegExp, visibleCharacterRegExp, titleRegExp } from './constants';

const hasSamlSsoFlow = (flowInitiator: SelectOption) => (
  typeof flowInitiator.value === 'string' && // need typeof check for unit testing purpose (get option.value = undefined here)
  flowInitiator.value !== SSOFlowOptions[0].value
);

export const VALIDATION_RULES = {
  required: Yup.string().required(requiredErrorMessage),
  email: Yup.string()
    .trim()
    .min(3, 'Min length of the field is 3 characters')
    .email('The filed must be in EMAIL format. Please check and edit to fit the format')
    .required(requiredErrorMessage),
  code: Yup.string()
    .required('Please input verification code')
    .matches(digitsRegExp, 'Please input digits only'),
  domain: Yup.string()
    .domain()
    .required("Please input domain name"),
  notEmptyString: (message: string): Yup.StringSchema<string> => Yup.string().required(message),
  name: Yup.string()
    .trim()
    .required(requiredErrorMessage)
    .min(3, 'Min length of the field is 3 character')
    .max(48, 'Max length of the field is 48 character') // eslint-disable-next-line
    .matches(alphaNumericRegExp, alphaNumericErrorMessage),
  shortName: Yup.string()
    .trim()
    .required(requiredErrorMessage)
    .min(3, 'Min length of the field is 3 character')
    .max(16, 'Max length of the field is 16 character') // eslint-disable-next-line
    .matches(alphaNumericRegExp, alphaNumericErrorMessage),
  title: Yup.string()
    .trim()
    .required(requiredErrorMessage)
    .min(3, 'Min length of the field is 3 character')
    .max(24, 'Max length of the field is 24 character') // eslint-disable-next-line
    .matches(titleRegExp, latinAndSymbolsOnlyMessage),
  uriComponent: Yup.string()
    .required(requiredErrorMessage)
    .matches(/^[^!@#$%^&*(),.?":{}|<>]*$/, 'The field must be alpha-numeric without special symbols'),
  description: Yup.string()
    .trim()
    .min(3, 'Min length of the field is 3 character')
    .max(48, 'Max length of the field is 48 character') // eslint-disable-next-line
    .matches(titleRegExp, latinAndSymbolsOnlyMessage),
  url: Yup.string()
    .trim()
    .required(requiredErrorMessage)
    .min(3, 'Min length of the field is 3 characters')
    .url(urlFormatErrorMessage),
  sso: Yup.boolean(),
  provider: Yup.object({
    label: Yup.string(),
    value: Yup.string(),
  })
    .when('sso', {
      is: true,
      then: Yup.object({
        label: Yup.string().required(),
        value: Yup.string().required(),
      }).required(requiredErrorMessage),
    }),
  clientId: Yup.string()
    .when('sso', {
      is: true,
      then: Yup.string().required(requiredErrorMessage),
    }),
  tenantId: Yup.string().nullable()
    .when(['sso', 'provider'], {
      is: (sso, provider) => (sso && provider.value === SsoProviderType.MICROSOFT),
      then: Yup.string().required(requiredErrorMessage).nullable(),
    }),
  entityId: Yup.string()
    .when(
      "flowInitiator", {
        is: flowInitiator => hasSamlSsoFlow(flowInitiator),
        then: Yup.string()
          .strict(true)
          .required(SSO_VALIDATION_ERROR_LIST.FIELD_NOT_EMPTY)
          .max(256, "256 symbols max.")
          .trim()
          .nullable(false),
      },
    ),
  attributeMapping: Yup.array()
    .when(
      "flowInitiator", {
        is: flowInitiator => hasSamlSsoFlow(flowInitiator),
        then: Yup.array()
          .of(
            Yup.object().shape({
              name: Yup.string()
                .strict(true)
                .min(2, twoSymbolsErrorMessage)
                .max(25, 'Twenty five symbols maximum')
                .matches(nameSpecRegExp, latinLettersShortErrorMessage)
                .required(SSO_VALIDATION_ERROR_LIST.FIELD_NOT_EMPTY),
              attributeValue: Yup.object().shape({
                value: Yup.string().strict(true).required(SSO_VALIDATION_ERROR_LIST.FIELD_NOT_EMPTY),
              }),
              description: Yup.string()
                .strict(true)
                .max(180, 'Max length of the field is 180 characters'),
              required: Yup.boolean().required(),
              compositeAttributePath: Yup.string()
                .when("attributeType", {
                  is: attributeType => attributeType === SSOAttributeType.COMPOSITE,
                  then: Yup.string().required('Please input JSON path')
                })
            }),
          ),
      },
    ),
  nameIdAttribute: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required(),
  }),
  ssoRedirectUrlPost: Yup.string()
    .when(
      "flowInitiator", {
        is: flowInitiator => hasSamlSsoFlow(flowInitiator),
        then: Yup.string()
          .strict(true)
          .required(SSO_VALIDATION_ERROR_LIST.FIELD_NOT_EMPTY)
          .matches(nameSpecRegExp, latinLettersShortErrorMessage)
          .trim()
          .url(urlFormatShortErrorMessage)
          .max(256, "256 symbols max.")
          .nullable(false),
      },
    ),
  supportMessage: Yup.string()
    .when(
      "unuSupportActionType", {
        is: (option: SelectOption<UnuSupportActionType>) => (
          typeof option.value === 'string' && // need typeof check for unit testing purpose (get option.value = undefined here)
          option.value !== UnuSupportActionType.NO_SUPPORT
        ),
        then: Yup.string()
          .trim()
          .required("This field cannot be empty. Please submit your welcome message or restore default one.")
          .matches(visibleCharacterRegExp, latinLettersShortErrorMessage)
          .min(3, "This field is limited with min 3 symbols.")
          .max(280, "This field is limited with 280 symbols."),
      },
    ),
};

interface NameRulesOptions {
  minSymbolsMessage: string;
  lettersMessage: string;
  requiredMessage: string;
  label: string;
}

export function getNameValidationRules({
  minSymbolsMessage = twoSymbolsErrorMessage,
  lettersMessage = latinAndOthersMessage,
  requiredMessage = emptyFieldErrorMessage,
  label = '',
}: Partial<NameRulesOptions>): Yup.StringSchema<string> {
  return Yup.string()
    .strict(true)
    .min(2, minSymbolsMessage)
    .max(70, max70SymbolsMessage)
    .matches(nameRegExp, lettersMessage)
    .label(label)
    .required(requiredMessage)
    .nullable(false);
}
