import React, { ReactNode } from 'react';
import classNames from "classnames";
import styles from "../Popup.module.scss";

type Props = { children: ReactNode, className?: string };

const PopupActionsList: React.SFC<Props> = (props: Props) => (
  <ul className={classNames(styles.list, props.className)}>
    {props.children}
  </ul>
);

export default PopupActionsList;
