/* eslint-disable react/no-array-index-key */
import React from "react";
import { CustomButton } from 'components';
import { FormikProps, FieldArrayRenderProps } from 'formik';
import { ServiceFormValues } from 'interfaces';
import { emptyAttribute } from './getInitialValues';
import AttributeField, { AttributeSelectOption } from './AttributeField';

interface Props {
  form: FormikProps<ServiceFormValues>;
  ssoAttributeOptions: AttributeSelectOption[];
  arrayHelpers: FieldArrayRenderProps;
  scrollToBottom: () => void;
  onRemoveAssertionItem: (index: number) => void;
}

export default class AttributeFields extends React.Component<Props> {
  onAdd = () => {
    this.props.arrayHelpers.push(emptyAttribute);
    // to calculate the height of the tab after adding new line
    setTimeout(this.props.scrollToBottom, 0);
  };

  render() {
    const { form, ssoAttributeOptions, arrayHelpers } = this.props;
    const isAddingAvailable = form.values.attributeMapping.length < ssoAttributeOptions.length;
    return (
      <>
        {form.values.attributeMapping.map((attr, i) => (
          <AttributeField
            key={`attribute-${i}`}
            index={i}
            attribute={attr}
            ssoAttributeOptions={ssoAttributeOptions}
            form={form}
            arrayHelpers={arrayHelpers}
            onRemoveAssertionItem={this.props.onRemoveAssertionItem}
          />
        ))}
        {isAddingAvailable &&
          <CustomButton buttonType="link" size="s" onClick={this.onAdd}>
            + Add new
          </CustomButton>}
      </>
    );
  }
}
