/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-shadow */
import * as React from 'react';
import { Droppable, Draggable } from "react-beautiful-dnd";
import { WorkspaceItem as WorkspaceItemComponent } from "components";
import { Props as WorkspaceItemProps } from 'components/WorkspaceItem/WorkspaceItem';
import { WorkspaceItem } from '../../models/WorkspaceItem';
import styles from "./DraggableItems.module.scss";

export interface Props extends Pick<
WorkspaceItemProps,
'updateWorkspaceItem' | 'showEditServiceModal' | 'showEditWorkspaceModal' | 'showOnboardingSvcModal' | 'isAdminMode'
> {
  droppableId: string;
  items: WorkspaceItem[];
  isDropDisabled?: boolean;
}

export default class DraggableItems extends React.Component<Props> {
  static defaultProps = {
    isDropDisabled: false,
  };

  public render() {
    const {
      updateWorkspaceItem,
      droppableId,
      showEditWorkspaceModal,
      showEditServiceModal,
      items,
      isDropDisabled,
    } = this.props;
    return (
      <Droppable droppableId={droppableId} direction="horizontal" isDropDisabled={isDropDisabled}>
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps} className={styles.droppable}>
            {
                items.map((item, index) => (
                  <Draggable draggableId={item.id} index={index} key={item.id}>
                    {provided => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <WorkspaceItemComponent
                          key={item.id}
                          isAdminMode={this.props.isAdminMode}
                          showEditWorkspaceModal={showEditWorkspaceModal}
                          showEditServiceModal={showEditServiceModal}
                          predefined={item.predefined}
                          item={item}
                          updateWorkspaceItem={updateWorkspaceItem}
                          showOnboardingSvcModal={this.props.showOnboardingSvcModal}
                        />
                      </div>
                    )}
                  </Draggable>))

              }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}
