import React from 'react';
import { getFirstLetter } from 'utils/getFirstLetter';
import { MatrixMessage } from 'interfaces';

interface Props {
  isCurrentUserMessage: boolean;
  username: string;
  msg: MatrixMessage;
  className: string;
}

const Avatar = ({ isCurrentUserMessage, username, msg, className }: Props) => {
  // for current user "msg.user.name" will return ID-like name, so we have to use name from UserStore
  const avatarLetter = isCurrentUserMessage ?
    getFirstLetter(username) :
    getFirstLetter(msg.user.name);
  return (
    <div className={className}>
      {avatarLetter}
    </div>
  );
};

export default Avatar;
