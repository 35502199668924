import React from 'react';
import classNames from 'classnames';
import { getFirstLetter } from 'utils/getFirstLetter';
import { MatrixRoom } from 'interfaces';
import { MembershipStatus } from 'appConstants';
import { CustomButton } from 'components';
import styles from './ConversationItem.module.scss';

interface Props {
  item: MatrixRoom;
  setCurrentRoomId: (id: string) => void;
  joinRoom: (id: string) => void;
  setReadMessagesForRoom: () => void;
  getRoom: (id: string) => void;
  isActive: boolean;
}

export default class ConversationItem extends React.Component <Props> {
  onClick = () => {
    const { id } = this.props.item;
    this.props.setCurrentRoomId(id);
    this.props.setReadMessagesForRoom();
    this.props.getRoom(id);
  };

  onAccept = () => {
    const { id } = this.props.item;
    this.props.joinRoom(id);
  };

  render() {
    const { item: { img, lastMessage, time, name, unreadCount }, isActive } = this.props;
    const isInvite = this.props.item.membershipStatus === MembershipStatus.INVITE;
    const itemClickHandler = isInvite ? undefined : this.onClick;
    return (
      <div
        role="button"
        className={classNames(
          {
            [styles.isActive]: isActive,
            [styles.clickable]: !isInvite,
          }, styles.item,
        )}
        onClick={itemClickHandler}
      >
        <div style={{ backgroundImage: `url(${img})` }} className={styles.image}>{getFirstLetter(name)}</div>
        <div className={styles.text}>
          <span className={styles.name}>
            <span className={styles.title}>
              {name}
              {!!unreadCount && <span className={styles.count}>{unreadCount}</span>}
            </span>
            <div className={styles.time}>{time}</div>
          </span>
          <span className={styles.message}>{lastMessage}</span>
        </div>
        {isInvite &&
          <CustomButton
            buttonType="primary"
            size="xxs"
            className={styles.btn}
            onClick={this.onAccept}
          >
            Accept
          </CustomButton>}
      </div>
    );
  }
}
