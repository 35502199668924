import { action, observable, runInAction, computed, IObservableArray, toJS } from "mobx";
import Catch from "catch-decorator";
import Group from "models/Group";
import GroupUser from "models/GroupUser";
import ApproveUser from "models/ApproveUser";
import { groupRulesService, groupService } from "services";
import {
  STORE_UI,
  STORE_ROUTER,
  GroupType,
  DEFAULT_SERVER_ERROR_MESSAGE,
  PAGINATION_FIRST_PAGE_NUMBER,
  FetchStatus,
  TENANT_SHARE_SERVER_ERROR_MESSAGE,
  TENANT_LOADING_SERVER_ERROR_MESSAGE,
  TENANT_SHARE_DUPLICATE_ERROR_MESSAGE,
  TENANT_SHARE_DEFAULT_ERROR_MESSAGE,
  MembershipRuleOperatorExpression,
  GROUP_WAS_UPDATED_MESSAGE,
  AsyncStatus,
} from 'appConstants';
import { ServerError } from 'errors';
import { GroupRule, RulesFormValues, UserGroupApprovalMethod, UserGroupApprovalStatus } from "interfaces/group";
import { RootStore } from "./RootStore";
import uniqueByKey from "../utils/uniqueByKey";
import { catchXhr, catchServerError } from "../services/errorService";
import SharedTenants from "../models/SharedTenants";
import CustomError from "../errors/CustomError";

type UpdateGroupData = {
  approvalMethod: UserGroupApprovalMethod;
  membershipRule?: MembershipRuleOperatorExpression;
};

export class GroupStore {
  rootStore: RootStore;

  @observable groupsPaginationConfig: PaginationConfig | null = null;
  @observable users: IObservableArray<GroupUser> = observable([]);
  @observable usersPaginationConfig: PaginationConfig | null = null;
  @observable usersFetchStatus: FetchStatus = FetchStatus.IDLE;
  @observable usersToApproveFetchStatus: FetchStatus = FetchStatus.IDLE;
  @observable usersToApprove: IObservableArray<ApproveUser> = observable([]);
  @observable usersToApprovePaginationConfig: PaginationConfig | null = null;
  @observable domains: string[] = [];
  @observable sharedTenantNames: string[] = [];
  @observable tenantsPaginationConfig: PaginationConfig | null = null;
  @observable currentGroup: Group | null = null;
  @observable groupFetchStatus: AsyncStatus = AsyncStatus.IDLE;
  @observable sharedTenantsFetchStatus: AsyncStatus = AsyncStatus.IDLE;
  @observable isUploadLoading = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed get
  isGroupLoaded(): boolean {
    return this.groupFetchStatus === AsyncStatus.SUCCESS;
  }

  @computed get
  isSharedTenantsLoaded(): boolean {
    return this.sharedTenantsFetchStatus === AsyncStatus.SUCCESS;
  }

  @computed get
  isRuleBased(): boolean {
    return !!this.currentGroup?.membershipRule;
  }

  @computed get
  sharedTenants(): SharedTenants[] {
    if (!this.sharedTenantNames) {
      return [];
    }

    return this.sharedTenantNames.map(name => ({ name }));
  }

  @computed get
  isCurrentGroupAdmin(): boolean {
    if (!this.currentGroup) {
      return false;
    }
    return this.currentGroup.type === GroupType.ADMIN;
  }

  @computed get activeGroup(): Group {
    if (!this.currentGroup) {
      throw new Error("No current group.");
    }
    return this.currentGroup;
  }

  @computed
  get groupRules(): GroupRule[] | undefined {
    if (!this.currentGroup) {
      throw new Error("No current group.");
    }
    const { membershipRule } = this.currentGroup;
    return membershipRule ? groupRulesService.toForm(
      this.rootStore.userAttributesStore.definitionByNameMap,
      toJS(membershipRule) // need to have pure array for isStrings check
    ) : undefined;
  }

  @computed get isUsersLoading(): boolean {
    return this.usersFetchStatus === FetchStatus.IDLE || this.usersFetchStatus === FetchStatus.PENDING;
  }

  @computed get isUsersToApproveLoading(): boolean {
    return this.usersToApproveFetchStatus === FetchStatus.IDLE || this.usersToApproveFetchStatus === FetchStatus.PENDING;
  }

  async loadGroup(groupId: string): Promise<void> {
    runInAction(() => this.groupFetchStatus = AsyncStatus.PENDING);
    try {
      const currentGroup = await groupService.loadGroup(groupId);
      runInAction(() => {
        this.currentGroup = currentGroup;
        this.domains = currentGroup.domains;
        this.groupFetchStatus = AsyncStatus.SUCCESS;
      });
    } catch (e) {
      runInAction(() => this.groupFetchStatus = AsyncStatus.FAILURE);
    }
  }

  @action
  onPageChange = (selectedItem: { selected: number }): void => {
    const { history, location } = this.rootStore[STORE_ROUTER];
    history.push({
      pathname: location.pathname,
      search: `?page=${selectedItem.selected + 1}`,
    });
  };

  clearRules = (): Promise<void> => {
    const { approvalMethod, membershipRule } = this.activeGroup;
    if (!membershipRule) {
      // if we clear group which doesn't contain rules
      return Promise.resolve();
    }
    return this.updateGroup({
      approvalMethod
    });
  };

  applyRules = ({ rules }: RulesFormValues): Promise<void> => {
    const membershipRule = groupRulesService.toRequest(rules);
    const { approvalMethod } = this.activeGroup;
    return this.updateGroup({
      approvalMethod,
      membershipRule
    });
  };

  async updateGroup(data: UpdateGroupData): Promise<void> {
    try {
      const group = await groupService.updateGroup(this.activeGroup.id, data);
      runInAction(() => {
        this.currentGroup = group;
      });
      this.rootStore[STORE_UI].showMessageNotification(
        GROUP_WAS_UPDATED_MESSAGE
      );
    } catch (err) {
      const message = err.response?.data?.message || DEFAULT_SERVER_ERROR_MESSAGE;
      this.rootStore[STORE_UI].showErrorNotification(message);
    }
  }

  loadUsersToApprove = async (page: number | string = PAGINATION_FIRST_PAGE_NUMBER): Promise<void> => {
    try {
      runInAction(() => this.usersToApproveFetchStatus = FetchStatus.PENDING);
      const { elements, ...paginationData } = await groupService.loadUsersToApprove({ groupId: this.activeGroup.id, page });
      runInAction(() => {
        this.usersToApprove.replace(elements);
        this.usersToApprovePaginationConfig = paginationData;
        runInAction(() => this.usersToApproveFetchStatus = FetchStatus.SUCCESS);
      });
    } catch (error) {
      console.error(error);
      runInAction(() => this.usersToApproveFetchStatus = FetchStatus.FAILURE);
    }
  };

  @Catch(Error, catchXhr)
  modifyUserApprovalStatus = async (
    users: ApproveUser[],
    status: UserGroupApprovalStatus
  ): Promise<void> => {
    const approvalStatuses = users.map(({ id }) => ({ id, status }));
    // user can check or can't check row where he calls context menu - if he checks and calls context menu we need to remove duplicates
    const uniqueApprovalStatuses = uniqueByKey(approvalStatuses, 'id');
    await groupService.modifyApprovals(this.activeGroup.id, uniqueApprovalStatuses);
    await this.loadUsersToApprove();
  };

  @action.bound
  @Catch(Error, catchServerError)
  async addUser(groupId: string, groupUser: GroupUser): Promise<void> {
    const user = await groupService.addUser(groupId, groupUser);
    runInAction(() => {
      this.users.push(user);
    });
  }

  @action.bound
  async addDomain(groupId: string, domain: string): Promise<void> {
    try {
      await groupService.addDomain(groupId, domain);
      runInAction(() => {
        this.domains.push(domain);
      });
    } catch (e) {
      console.error(e);
      throw new ServerError(e.response.data.message);
    }
  }

  @action.bound
  async shareGroupWithTenant(groupId: string, tenantName: string): Promise<void> {
    try {
      await groupService.shareGroupWithTenant(groupId, tenantName);
      runInAction(() => {
        if (!this.sharedTenantNames.includes(tenantName)) {
          this.sharedTenantNames.push(tenantName);
        } else {
          throw new CustomError(TENANT_SHARE_DUPLICATE_ERROR_MESSAGE);
        }
      });
    } catch (e) {
      if (e.response && (e.response.status === 400 || e.response.status === 404)) {
        throw new ServerError(TENANT_SHARE_SERVER_ERROR_MESSAGE);
      } else if (e instanceof CustomError) {
        throw new ServerError(e.message);
      } else {
        throw new ServerError(TENANT_SHARE_DEFAULT_ERROR_MESSAGE);
      }
    }
  }

  @Catch(Error, catchXhr)
  async unshareGroupTenants(groupId: string, tenants: string[]): Promise<void> {
    const unsharePromises = tenants.map(tenantToUnshare => groupService.unshareTenant(groupId, tenantToUnshare));
    await Promise.all(unsharePromises);
    this.loadTenantsForSharing(groupId);
  }

  @Catch(Error, catchXhr)
  async deleteGroupUsers(groupId: string, users: GroupUser[]): Promise<void> {
    const emails = users.map(({ email }) => email);
    const deletePromises = emails.map(email => groupService.deleteUser(groupId, email));
    await Promise.all(deletePromises);
    this.listGroupMembers();
  }

  @Catch(Error, catchXhr)
  async deleteGroupDomains(groupId: string, domains: string[]): Promise<void> {
    await groupService.deleteDomains(groupId, domains);
    this.loadGroup(groupId);
  }

  @Catch(Error, catchXhr)
  goToNextGroupUserPage = (pageNumber: number): Promise<void> => this.listGroupMembers(pageNumber + 1);

  listGroupMembers = async (page: number | string = PAGINATION_FIRST_PAGE_NUMBER): Promise<void> => {
    try {
      runInAction(() => this.usersFetchStatus = FetchStatus.PENDING);
      const groupId = this.activeGroup.id;
      const { elements, ...paginationData } = await groupService.retrieveGroupUsers({ groupId, page });
      runInAction(() => {
        this.users.replace(elements);
        this.usersPaginationConfig = { ...paginationData };
        this.usersFetchStatus = FetchStatus.SUCCESS;
      });
    } catch (e) {
      console.error(`Error fetching groups: ${e}`);
      this.usersFetchStatus = FetchStatus.FAILURE;
    }
  };

  async loadTenantsForSharing(groupId: string, { page = PAGINATION_FIRST_PAGE_NUMBER }: PagedRequest = {}): Promise<void> {
    runInAction(() => this.sharedTenantsFetchStatus = AsyncStatus.PENDING);
    try {
      const { elements, ...paginationData } = await groupService.loadTenants(groupId, { page });
      runInAction(() => {
        this.sharedTenantNames = elements;
        this.tenantsPaginationConfig = paginationData;
        this.sharedTenantsFetchStatus = AsyncStatus.SUCCESS;
      });
    } catch (e) {
      this.rootStore[STORE_UI].showErrorNotification(TENANT_LOADING_SERVER_ERROR_MESSAGE);
      runInAction(() => this.sharedTenantsFetchStatus = AsyncStatus.FAILURE);
    }
  }

  goToNextSharedTenantsGroupPage = (
    groupId: string,
    pageNumber: number
  ): Promise<void> => this.loadTenantsForSharing(groupId, {
    page: pageNumber + 1,
  });

  clearGroup(): void {
    runInAction(() => {
      this.currentGroup = null;
      this.domains = [];
      this.groupFetchStatus = AsyncStatus.IDLE;
      this.sharedTenantsFetchStatus = AsyncStatus.IDLE;
    });
  }
}

export default GroupStore;
