import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { TileContent, TileItem, TilesWrapper } from "components";
import { STORE_ROUTER, STORE_USER } from "appConstants";
import { inject, observer } from "mobx-react";
import { RouterStore } from "stores/RouterStore";
import { UserStore } from "stores";
import styles from "./AdminServicesPage.module.scss";
import CustomLink from "../../components/CustomLink";

export interface Props extends RouteComponentProps<any> {
  [STORE_ROUTER]: RouterStore;
  [STORE_USER]: UserStore;
  tenantId: string;
  routeProps: { homeRef: React.RefObject<HTMLDivElement> }
}

@inject(STORE_ROUTER, STORE_USER)
@observer
export class AdminServicesPage extends React.Component<Props> {
  public render() {
    const { tenantId } = this.props.match.params;
    const { isGlobalTenant } = this.props[STORE_USER];

    return (
      <>
        <div className={styles.topPanel}>
          <h1>Admin Services</h1>
        </div>
        <TilesWrapper>
          <TileItem>
            <TileItem.Content>
              <CustomLink to={`/tenants/${tenantId}/repositories`}>
                <TileContent
                  title="Repository"
                  description="Manage services and workspaces"
                  iconUrl="/images/tiles/repositoryTile.svg"
                />
              </CustomLink>
            </TileItem.Content>
          </TileItem>
          <TileItem>
            <TileItem.Content>
              <CustomLink to={`/tenants/${tenantId}/manage/groups`}>
                <TileContent
                  title="Groups"
                  description="Manage your groups"
                  iconUrl="/images/tiles/groupsTile.svg"
                />
              </CustomLink>
            </TileItem.Content>
          </TileItem>
          {isGlobalTenant &&
          <TileItem>
            <TileItem.Content>
              <CustomLink to={`/tenants/${tenantId}/manage/tenants`}>
                <TileContent
                  title="Tenants"
                  description="Manage your tenants"
                  iconUrl="/images/tiles/tenantsTile.svg"
                />
              </CustomLink>
            </TileItem.Content>
          </TileItem>}
        </TilesWrapper>
      </>
    );
  }
}

export default AdminServicesPage;
