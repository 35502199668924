import * as React from 'react';
import { WorkspaceServiceItem } from 'interfaces';
import { CustomButton } from 'components';

export interface Props {
  item: WorkspaceServiceItem;
  className: string;
  onClick: (item: WorkspaceServiceItem) => void;
  disabled?: boolean;
}

export class ActionButton extends React.Component<Props> {
  handleClick = () => {
    this.props.onClick(this.props.item);
  };

  render() {
    const { item, children, ...otherProps } = this.props;
    return (
      <CustomButton
        {...otherProps}
        fullWidth
        onClick={this.handleClick}
      >
        {children}
      </CustomButton>);
  }
}

export default ActionButton;
