import * as React from "react";
import { ALLOWED_ICON_TYPES, MAX_ICON_SIZE_BYTES } from "../appConstants";

function bytesToSize(bytes: any) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (!bytes) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  // eslint-disable-next-line no-restricted-properties
  return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`;
}

const VALIDATION_LIST: { check(icon: any, file: any): boolean, error: string }[] = [
  {
    check: (icon, file) => file.size < MAX_ICON_SIZE_BYTES,
    error: `Sorry, max file size is: ${bytesToSize(MAX_ICON_SIZE_BYTES)}`,
  },
  {
    check: (icon, file) => ALLOWED_ICON_TYPES.includes(file.type.split("/")[1]),
    error: `Sorry, we can use the following types of images for icons:: ${ALLOWED_ICON_TYPES}`,
  },
];
interface IconValidationResult {iconName: string, iconDataUrl: string, iconFile: File, iconProps: { width: number, height: number } }

export default (e: React.ChangeEvent<HTMLInputElement>): Promise<IconValidationResult> => {
  return new Promise((resolve, reject) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const icon = new Image();
      const fr = new FileReader();
      fr.onload = () => {
        if (fr.result && typeof fr.result === 'string') {
          icon.src = fr.result;
        }
      };
      fr.readAsDataURL(file);
      icon.onload = () => {
        const result = VALIDATION_LIST.reduce((acc, item) => {
          if (!acc.valid) return acc;
          if (!item.check(icon, file)) {
            acc.valid = false;
            acc.error = item.error;
          }
          return acc;
        }, { valid: true, error: null as null | string });

        if (!result.valid) {
          reject(result.error);
        }
        resolve({ iconName: file.name, iconDataUrl: icon.src, iconFile: file, iconProps: { width: icon.width, height: icon.height } });
      };
    } else {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject(null);
    }
  });
};
