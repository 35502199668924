import * as React from 'react';
import classNames from 'classnames';
import { RouteComponentProps } from 'react-router';
import Sidebar from 'containers/Sidebar/Sidebar';
import { Header, Loader } from 'components';
import { inject, observer } from 'mobx-react';
import { DescriptorStore, RouterStore, UIStore, UserStore, WorkspaceStore, InboxStore, WebSocketStore } from 'stores';
import {
  STORE_DESCRIPTOR,
  STORE_WEBSOCKET,
  STORE_ROUTER,
  STORE_UI,
  STORE_USER,
  STORE_WORKSPACE,
  STORE_INBOX,
} from 'appConstants';
import isHeaderVisible from 'utils/isHeaderVisible';
import HomePageRoutes from './HomePageRoutes';
import styles from './HomePage.module.scss';

export interface Props extends RouteComponentProps<any>{
  [STORE_UI]: UIStore;
  [STORE_USER]: UserStore;
  [STORE_ROUTER]: RouterStore;
  [STORE_DESCRIPTOR]: DescriptorStore;
  [STORE_WORKSPACE]: WorkspaceStore;
  [STORE_INBOX]: InboxStore;
  [STORE_WEBSOCKET]: WebSocketStore;
}

@inject(STORE_UI, STORE_USER, STORE_ROUTER, STORE_DESCRIPTOR, STORE_WORKSPACE, STORE_INBOX, STORE_WEBSOCKET)
@observer
class HomePage extends React.PureComponent<Props> {
  protected homeMain = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.props[STORE_USER].appInit();
  }

  componentWillUnmount() {
    this.props[STORE_WEBSOCKET].close();
  }

  render() {
    const { addRelease } = this.props[STORE_WORKSPACE];
    const { isAdminMode } = this.props[STORE_UI];
    const { currentTenant, isAppInitialized } = this.props[STORE_USER];
    const hasAdminModeAndTenant = currentTenant !== null && isAdminMode;
    const visibleHeader = isHeaderVisible(this.props.match.path);

    if (!isAppInitialized) {
      return <Loader isFullscreen />;
    }

    return (
      <div className={styles.homeWrapper}>
        <Sidebar isAdminMode={isAdminMode} inboxStore={this.props[STORE_INBOX]} user={this.props[STORE_USER]} />
        <div className={styles.homeContent}>
          {visibleHeader &&
            <Header
              uiStore={this.props[STORE_UI]}
              user={this.props[STORE_USER]}
              router={this.props[STORE_ROUTER]}
              publish={addRelease}
            />}
          <div className={classNames({ [styles.noHeader]: !visibleHeader }, styles.homeMain)} ref={this.homeMain}>
            {
              (hasAdminModeAndTenant || !isAdminMode) || !isAppInitialized ? (
                <HomePageRoutes
                  uiStore={this.props[STORE_UI]}
                  user={this.props[STORE_USER]}
                  homeRef={this.homeMain}
                />
              ) : (
                <Loader />
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
