/* eslint-disable no-underscore-dangle */
import sdk from 'matrix-js-sdk';
import { logger } from 'matrix-js-sdk/lib/logger';
import {
  MatrixCredentials,
  MatrixTimelineEvent,
  MatrixMember,
  MatrixRoomFullInfo,
} from 'interfaces';
import { MembershipStatus, PendingEventOrdering, Env } from 'appConstants';
import { HttpService } from './http';

// disable logger for matrix
if (process.env.NODE_ENV !== Env.DEV) {
  logger.disableAll();
}

const MATRIX_MESSAGE_TYPE = 'm.text';

export interface StartClientOptions {
  pendingEventOrdering: PendingEventOrdering
}

export class MatrixService {
  private client: any = null;

  private http: HttpService;

  constructor(http: HttpService) {
    this.http = http;
  }

  login = async (): Promise<MatrixCredentials> => {
    const matrixCredentials = await this.http.post<MatrixCredentials>('/users/me/matrix/login', {
      body: {},
    });

    this.client = sdk.createClient({
      baseUrl: matrixCredentials.matrixServerUrl,
      accessToken: matrixCredentials.accessToken,
      userId: matrixCredentials.matrixUserId,
    });
    return matrixCredentials;
  };

  logout = async () => {
    this.stopSyncMatrix();
    if (this.client) {
      this.client.removeAllListeners("sync");
      this.client.removeAllListeners("RoomMember.membership");
      this.client.removeAllListeners("Room.timeline");
      await this.client.logout();
    }
    this.client = null;
  };

  startClient(opts?: StartClientOptions) {
    return this.client.startClient(opts);
  }

  onInvite = (getRoomsList: () => void, matrixCredentials: MatrixCredentials | null) => {
    this.client.on("RoomMember.membership", (event: MatrixTimelineEvent, member: MatrixMember) => {
      if (matrixCredentials && member.membership === MembershipStatus.INVITE && member.userId === matrixCredentials.matrixUserId) {
        setTimeout(() => {
          getRoomsList();
        });
      }
    });
  };

  onSync = (getRoomsList: () => void) => {
    this.client.on("sync", (state: string | null) => {
      // eslint-disable-next-line default-case
      switch (state) {
        case "ERROR":
          console.error("matrix error");
          this.stopSyncMatrix();
          break;
        case "SYNCING":
          break;
        case "PREPARED":
          getRoomsList();
          break;
      }
    });
  };

  stopSyncMatrix() {
    try {
      if (this.client && this.client._syncApi) {
        this.client._syncApi.stop();
      }
    } catch (e) {
      console.error(e);
    }
  }

  onSyncTimeline = (currentRoomId: string | null, cb: (event: MatrixTimelineEvent, room: MatrixRoomFullInfo) => void) => {
    this.client.on("Room.timeline", (event: MatrixTimelineEvent, room: MatrixRoomFullInfo) => {
      if (event.event.type === "m.room.member" || room.roomId === currentRoomId) {
        this.client.sendReadReceipt(event);
      }
      cb(event, room);
    });
  };

  getRooms = () => this.client.getRooms();

  joinRoom = (roomId: string) => this.client.joinRoom(roomId);

  leaveRoom = (roomId: string) => this.client.leave(roomId);

  getRoom = (roomId: string) => this.client.getRoom(roomId);

  createRoom = (options: any) => this.client.createRoom(options);

  sendReadReceipt = (event: MatrixTimelineEvent) => this.client.sendReadReceipt(event);

  sendMessage = (body: string, currentRoomId: string | null) => {
    const content = { body, msgtype: MATRIX_MESSAGE_TYPE };
    return this.client.sendEvent(currentRoomId, "m.room.message", content);
  };
}
