export enum UserAttributeDefinitionType {
  PRIMITIVE = 'primitive',
  STRING_ARRAY = 'stringArray',
  COMPOSITE = 'composite',
}

export type TenantUserAttributesDefinitions = {
  global: UserAttributeDefinition[];
  custom: UserAttributeDefinition[];
};

export interface UserAttributeDefinition {
  id: string;
  name: string;
  adminTenantId: string;
  type: UserAttributeDefinitionType;
  displayName: string;
  variableName: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  archived: boolean;
}
