/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { inject, observer } from 'mobx-react';
import ReactModal from 'react-modal';
import {
  STORE_UI,
  FORM_MODAL_WIDTH,
} from 'appConstants';
import componentsMap from 'components/Modals';
import UIStore from 'stores/UIStore';
import styles from './Modal.module.scss';

export interface ModalProps {
  [STORE_UI]: UIStore;
}

interface ComponentsMap {
  [key: string]: typeof React.Component | React.ElementType
}

@inject(STORE_UI)
@observer
export default class Modal extends React.Component <ModalProps> {
  static defaultProps = { [STORE_UI]: (null as unknown) as UIStore };

  render() {
    const { modal, isModalShown, closeModal, submitModal, serverError } = this.props[STORE_UI];
    if (!modal || !modal.componentKey) {
      return null;
    }
    const {
      title,
      bodyText,
      componentKey,
      width = FORM_MODAL_WIDTH.XSMALL,
      minHeight,
      props,
    } = modal;
    const CurrentComponent = (componentsMap as ComponentsMap)[componentKey];

    return (
      <ReactModal
        {...props}
        className={styles.modal}
        isOpen={isModalShown}
        onRequestClose={closeModal}
        shouldFocusAfterRender={false}
        overlayClassName={styles.modalOverlay}
        ariaHideApp={false}
        style={{ content: { width, minHeight } }}
      >
        <button
          type="button"
          title="close"
          className={styles.closeButton}
          onClick={closeModal}
        />
        {title && <h2>{title}</h2>}
        <CurrentComponent
          closeModal={closeModal}
          submitError={serverError}
          {...props}
          onSubmit={submitModal}
          bodyText={bodyText}
        />
      </ReactModal>
    );
  }
}
