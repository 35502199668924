import { Repository } from 'models/Repository';
import { HttpService, httpTenantService } from './http';
import ServiceConfiguration from "../models/ServiceConfiguration";
import ServiceRelease from "../models/ServiceRelease";
import { UserAttributeDefinition } from '../interfaces/userAttributes';

type TenantUserAttributesDefinitions = {
  global: UserAttributeDefinition[];
  custom: UserAttributeDefinition[];
};

class RepositoryService {
  http: HttpService;

  constructor(http: HttpService) {
    this.http = http;
  }

  retrieveRepositories = (params: PagedRequest) => {
    return this.http.get<PagedResponse<Repository>>('/microapps', { queryStringParameters: params });
  };

  addRepository = (body: any) => {
    return this.http.post<Repository>('/microapps', { body });
  };

  removeService = (appId: string) => {
    return this.http.delete(`/microapps/${appId}`);
  };

  removeWorkspace = (workspaceId: string) => {
    return this.http.delete(`/workspaces/${workspaceId}`);
  };

  loadConfig = (appId: string) => {
    const id = encodeURIComponent(appId);
    return this.http.get<ServiceConfiguration>(`/microapps/${id}/config`);
  };

  addConfig = (appId: string, config: any) => {
    return this.http.post<ServiceConfiguration>(`/microapps/${appId}/config`, { body: {
      config,
    } });
  };

  editConfig = (appId: string, config: any) => {
    return this.http.put<ServiceConfiguration>(`/microapps/${appId}/config`, { body: {
      config,
    } });
  };

  addRelease = (appId: string, appStoreId: string) => {
    return this.http.post<ServiceRelease>(`/microapps/${appId}/releases`, { body: {
      appStoreId,
    } });
  };

  loadAvailableUserAttrs = (): Promise<TenantUserAttributesDefinitions> => {
    return this.http.get<TenantUserAttributesDefinitions>('/userAttributes');
  };
}

export default new RepositoryService(httpTenantService);
