import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import debounce from 'lodash.debounce';
import { Tabs, TabList, TabPanel } from 'react-tabs';
import { CustomTab, TilesWrapper, Loader, SearchInput } from 'components';
import { inject, observer } from 'mobx-react';
import { STORE_SERVICES, AppStoreType, STORE_WORKSPACE, STORE_ROUTER, DEBOUNCE_DELAY, NO_ITEMS_TEXT } from 'appConstants';
import { ServicesStore, WorkspaceStore, RouterStore } from 'stores';
import { WorkspaceServiceItem as WorkspaceServiceItemI } from 'interfaces';
import WorkspaceServiceItem from 'components/WorkspaceServiceItem/WorkspaceServiceItem';
import InfiniteScroll from 'react-infinite-scroller';
import styles from "./MoreServices.module.scss";

const REPOSITORY_STORE_TAB_INDEX = 0;

export interface Props extends RouteComponentProps<any> {
  [STORE_SERVICES]: ServicesStore,
  [STORE_WORKSPACE]: WorkspaceStore,
  [STORE_ROUTER]: RouterStore;
  // passing ref for scrolling area for synchronizing of infinity scroll
  routeProps: { homeRef: React.RefObject<HTMLDivElement> }
}

const initialState = {
  searchText: '',
  activeTabIndex: REPOSITORY_STORE_TAB_INDEX,
};

type State = typeof initialState;

@inject(STORE_SERVICES, STORE_WORKSPACE)
@observer
export class MoreServices extends React.Component<Props, State> {
  state = initialState;

  loadItemsOnSearchChange = debounce((searchText: string) => {
    const { workspaceId } = this.props.match.params;
    const storeType = this.state.activeTabIndex === REPOSITORY_STORE_TAB_INDEX ?
      AppStoreType.repository :
      AppStoreType.unu;
    this.props[STORE_SERVICES].loadNextRepoServices(storeType, workspaceId, this.state.searchText, true);
  }, DEBOUNCE_DELAY);

  componentDidMount() {
    const { workspaceId } = this.props.match.params;
    this.props[STORE_SERVICES].load(AppStoreType.repository, workspaceId);
  }

  handleSearchTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    const searchText = e.currentTarget.value;
    this.setState({ searchText });
    this.loadItemsOnSearchChange(searchText);
  };

  handleAddItem = (appStoreType: AppStoreType, service: WorkspaceServiceItemI) => {
    return this.props[STORE_SERVICES].addToWorkspaceItems(appStoreType, service);
  };

  handleRemoveItem = (appStoreType: AppStoreType, service: WorkspaceServiceItemI) => {
    return this.props[STORE_SERVICES].removeFromWorkspaceItems(appStoreType, service);
  };

  renderServices = (services: WorkspaceServiceItemI[], appStoreType: AppStoreType) => {
    const { getIsAssignStatePending } = this.props[STORE_SERVICES];

    return (
      <TilesWrapper>
        {
          services.length === 0 &&
          <div className={styles.emptyPlaceholder}>{NO_ITEMS_TEXT}</div>
        }
        {services.map(service => (
          <WorkspaceServiceItem
            key={`${service.item.id}-${service.item.revision}`}
            isAssignStatePending={getIsAssignStatePending(service.item.id)}
            item={service}
            appStoreType={appStoreType}
            onAddItem={this.handleAddItem}
            onRemoveItem={this.handleRemoveItem}
          />
        ))}
      </TilesWrapper>);
  };

  loadNextRepoServices = () => {
    const { workspaceId } = this.props.match.params;
    this.props[STORE_SERVICES].loadNextRepoServices(AppStoreType.repository, workspaceId, this.state.searchText);
  };

  loadNextUnuServices = () => {
    const { workspaceId } = this.props.match.params;
    this.props[STORE_SERVICES].loadNextRepoServices(AppStoreType.unu, workspaceId, this.state.searchText);
  };

  onTabSelect = (tabIndex: number) => {
    this.setState({ searchText: '', activeTabIndex: tabIndex });
    const { workspaceId } = this.props.match.params;
    const storeType = tabIndex === REPOSITORY_STORE_TAB_INDEX ?
      AppStoreType.repository :
      AppStoreType.unu;
    this.props[STORE_SERVICES].loadNextRepoServices(storeType, workspaceId, '', true);
  };

  getScrollParent = () => this.props.routeProps.homeRef.current;

  public render() {
    const store = this.props[STORE_SERVICES];
    const repositoryServices = store.services[AppStoreType.repository];
    const unuServices = store.services[AppStoreType.unu];
    const hasMoreRepoServices = !!store.infinityTokenConfigs[AppStoreType.repository];
    const hasMoreUnuServices = !!store.infinityTokenConfigs[AppStoreType.unu];
    const { isLoading } = store;
    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        <div className={styles.topPanel}>
          <h1>More Services</h1>
          <SearchInput value={this.state.searchText} handleSearchTextChange={this.handleSearchTextChange} title="service" />
        </div>
        <Tabs onSelect={this.onTabSelect}>
          <TabList>
            <CustomTab>My repository</CustomTab>
            <CustomTab>Coll8 store</CustomTab>
          </TabList>
          <TabPanel>
            <InfiniteScroll
              loadMore={this.loadNextRepoServices}
              hasMore={hasMoreRepoServices}
              loader={<Loader />}
              useWindow={false}
              getScrollParent={this.getScrollParent}
            >
              {this.renderServices(repositoryServices, AppStoreType.repository)}
            </InfiniteScroll>
          </TabPanel>
          <TabPanel>
            <InfiniteScroll
              loadMore={this.loadNextUnuServices}
              hasMore={hasMoreUnuServices}
              loader={<Loader />}
              useWindow={false}
              getScrollParent={this.getScrollParent}
            >
              {this.renderServices(unuServices, AppStoreType.unu)}
            </InfiniteScroll>
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

export default MoreServices;
