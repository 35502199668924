import * as React from 'react';
import { CustomButton } from 'components';
import styles from './DeleteConfirmationModal.module.scss';

export interface Props {
  closeModal: () => void;
  onSubmit: (id: string) => void;
  id: string;
  bodyText?: string;
  withLoading?: boolean;
}

interface State {
  isLoading: boolean
}

export default class DeleteConfirmationModal extends React.Component<Props, State> {
  state = {
    isLoading: false,
  };

  onSubmit = () => {
    const { id, onSubmit } = this.props;
    return onSubmit(id);
  };

  onSubmitWithLoading = async () => {
    this.setState({ isLoading: true });
    await this.onSubmit();
    // close modal automatically - don't need to set isLoading = false
  };

  render() {
    const { closeModal, bodyText, withLoading } = this.props;
    const onSubmit = withLoading ? this.onSubmitWithLoading : this.onSubmit;

    return (
      <>
        <p>{bodyText}</p>
        <div className={styles.buttonHolder}>
          <CustomButton
            onClick={closeModal}
          >
            Cancel
          </CustomButton>
          <CustomButton
            buttonType="primary"
            isLoading={this.state.isLoading}
            onClick={onSubmit}
          >
            Yes, delete
          </CustomButton>
        </div>
      </>);
  }
}
