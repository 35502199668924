import { action, observable, runInAction, computed } from "mobx";
import Catch from "catch-decorator";
import { groupService } from "services";
import {
  STORE_UI,
  FetchStatus,
  STORE_CURRENT_GROUP,
  CsvProcessingStatus,
  CsvUserUploadAction,
  CSV_USERS_SUCCESSFULLY_PROCESSED,
  STORE_ROUTER,
} from 'appConstants';
import { RootStore } from "stores";
import downloadFileByUrl from "../../utils/downloadFileByUrl";
import parseCsvFilenameFromS3Link from "../../utils/parseFilenameFromS3Link";
import { catchXhr } from "../../services/errorService";
import uploadFileToS3 from "../../utils/uploadFileToS3";
import { delay } from "../../utils/delay";
import getCurrentPage from "../../utils/getCurrentPage";

const DEFAULT_CSV_USERS_FILENAME = 'users.csv';
const UPLOAD_STATUS_CHECK_TIMEOUT = 1000;

export class GroupFileStore {
  rootStore: RootStore;

  objectKey: string | null = null;

  uploadUrl: string | null = null;

  uploadStatusDelay = UPLOAD_STATUS_CHECK_TIMEOUT;

  uploadActionToService = {
    [CsvUserUploadAction.ADD]: groupService.checkUserListAddStatus,
    [CsvUserUploadAction.REMOVE]: groupService.checkUserListRemoveStatus,
  };

  @observable csvUsersDownloadUrlFetchStatus: FetchStatus = FetchStatus.IDLE;

  @observable uploadUrlFetchStatus: FetchStatus = FetchStatus.IDLE;

  @observable csvProcessingStatus: CsvProcessingStatus | null = null;

  @observable csvUploadStatus: FetchStatus = FetchStatus.IDLE;

  @observable csvUploadError: string | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed get groupId(): string {
    const groupId = this.rootStore[STORE_CURRENT_GROUP].currentGroup?.id;
    if (!groupId) throw new Error('No current group');
    return groupId;
  }

  @computed get isCsUsersDownloading(): boolean {
    return this.csvUsersDownloadUrlFetchStatus === FetchStatus.PENDING;
  }

  @computed get isUploadUrlLoading(): boolean {
    return this.uploadUrlFetchStatus === FetchStatus.PENDING;
  }

  @computed get isCsvUploading(): boolean {
    return this.csvUploadStatus === FetchStatus.PENDING;
  }

  isProcessingCompleted(status: CsvProcessingStatus | null): boolean {
    return status === CsvProcessingStatus.FAILED || status === CsvProcessingStatus.SUCCEED;
  }

  @Catch(Error, catchXhr)
  checkCSVProcessingStatus = async (
    groupId: string,
    objectKey: string,
    uploadAction: CsvUserUploadAction
  ): Promise<{
    status: CsvProcessingStatus,
    error: string | null
  }> => {
    let error: string | null = null;
    let status: CsvProcessingStatus | null = null;
    const uploadProgressAction = this.uploadActionToService[uploadAction];
    if (uploadProgressAction) {
      ({ status, error } = await uploadProgressAction(groupId, objectKey));
    } else {
      throw new Error('Unsupported upload action');
    }
    runInAction(() => {
      this.csvProcessingStatus = status;
      if (status === CsvProcessingStatus.SUCCEED) {
        this.csvUploadStatus = FetchStatus.SUCCESS;
        this.rootStore[STORE_UI].closeModal();
        this.rootStore[STORE_UI].showMessageNotification(CSV_USERS_SUCCESSFULLY_PROCESSED);
      }
      if (status === CsvProcessingStatus.FAILED) {
        this.csvUploadStatus = FetchStatus.FAILURE;
        this.csvUploadError = error;
      }
    });
    return {
      status,
      error,
    };
  };

  delayProcessingStatusCheck = async (
    groupId: string,
    objectKey: string,
    uploadAction: CsvUserUploadAction
  ): Promise<void> => {
    await delay(this.uploadStatusDelay);
    const { status } = await this.checkCSVProcessingStatus(groupId, objectKey, uploadAction);
    if (!this.isProcessingCompleted(status)) {
      await this.delayProcessingStatusCheck(groupId, objectKey, uploadAction);
    }
    if (status === CsvProcessingStatus.SUCCEED || status === CsvProcessingStatus.FAILED) {
      const curPage = getCurrentPage(this.rootStore[STORE_ROUTER].location.search);
      this.rootStore[STORE_CURRENT_GROUP].listGroupMembers(curPage);
    }
  };

  @action
  uploadFile = async (file: File, uploadAction: CsvUserUploadAction): Promise<void> => {
    try {
      if (!this.uploadUrl || !this.objectKey) throw new Error(`Cannot upload file.`);
      this.csvUploadStatus = FetchStatus.PENDING;
      await uploadFileToS3(this.uploadUrl, file);
      // start processing here
      await this.checkCSVProcessingStatus(this.groupId, this.objectKey, uploadAction);
      // start checking status
      await this.delayProcessingStatusCheck(this.groupId, this.objectKey, uploadAction);
    } catch (error) {
      runInAction(() => this.csvUploadStatus = FetchStatus.FAILURE);
      this.rootStore[STORE_UI].showErrorNotification();
    }
  };

  @action
  setS3Metadata = async (): Promise<void> => {
    this.uploadUrlFetchStatus = FetchStatus.PENDING;
    try {
      const { uploadUrl, objectKey } = await groupService.getUploadUrl();
      runInAction(() => {
        this.objectKey = objectKey;
        this.uploadUrl = uploadUrl;
        this.uploadUrlFetchStatus = FetchStatus.SUCCESS;
      });
    } catch (error) {
      runInAction(() => this.uploadUrlFetchStatus = FetchStatus.FAILURE);
      this.rootStore[STORE_UI].showErrorNotification();
    }
  };

  @action
  downloadCsvUsers = async (): Promise<void> => {
    try {
      runInAction(() => this.csvUsersDownloadUrlFetchStatus = FetchStatus.PENDING);
      const { downloadUrl } = await groupService.getCsvPreSignUpUrl(this.groupId);
      runInAction(() => {
        this.csvUsersDownloadUrlFetchStatus = FetchStatus.SUCCESS;
      });
      const filename = parseCsvFilenameFromS3Link(downloadUrl) || DEFAULT_CSV_USERS_FILENAME;
      downloadFileByUrl(downloadUrl, filename);
    } catch (err) {
      console.error(err);
      runInAction(() => this.csvUsersDownloadUrlFetchStatus = FetchStatus.FAILURE);
      this.rootStore[STORE_UI].showErrorNotification();
    }
  };

  @action
  reset = (): void => {
    this.csvUploadError = null;
    this.objectKey = null;
    this.uploadUrl = null;
    this.csvUsersDownloadUrlFetchStatus = FetchStatus.IDLE;
    this.uploadUrlFetchStatus = FetchStatus.IDLE;
    this.csvProcessingStatus = null;
    this.csvUploadStatus = FetchStatus.IDLE;
  };
}

export default GroupFileStore;
