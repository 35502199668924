/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState } from "react";
import papaParse from 'papaparse';
import { Flex, Box } from 'reflexbox';
import { FormikProps } from 'formik';
import { CustomButton, CustomField, CustomFileField, CustomSelectField } from "components";
import { SelectOption } from "interfaces";
import { GroupRule, RulesFormValues } from "interfaces/group";
import {
  GROUP_RULE_OPERATOR_TITLE,
  MembershipRuleOperatorType
} from "../../../../appConstants";
import makeSelectOption from "../../../../utils/makeSelectOption";
import { UserAttributeDefinitionType } from "../../../../interfaces/userAttributes";
import { AttributeNameOption } from "./types";
import { FileFieldVariant } from "../../../../components/CustomFileField/CustomFileField";
import { CsvDataButton } from "../../../../components/form/CsvDataButton/CsvDataButton";
import styles from "./MembershipRules.module.scss";

export const booleanOperatorsOptions: SelectOption<MembershipRuleOperatorType>[] = [
  makeSelectOption(
    MembershipRuleOperatorType.EQUALS,
    GROUP_RULE_OPERATOR_TITLE[MembershipRuleOperatorType.EQUALS]
  ),
  makeSelectOption(
    MembershipRuleOperatorType.NOT_IN,
    GROUP_RULE_OPERATOR_TITLE[MembershipRuleOperatorType.NOT_IN]
  ),
  makeSelectOption(
    MembershipRuleOperatorType.IN,
    GROUP_RULE_OPERATOR_TITLE[MembershipRuleOperatorType.IN]
  ),
];

function getBooleanOperatorsByAttrType(attrType?: UserAttributeDefinitionType) {
  if (attrType === UserAttributeDefinitionType.STRING_ARRAY) {
    return [
      makeSelectOption(
        MembershipRuleOperatorType.ANY,
        GROUP_RULE_OPERATOR_TITLE[MembershipRuleOperatorType.ANY]
      ),
      makeSelectOption(
        MembershipRuleOperatorType.NONE,
        GROUP_RULE_OPERATOR_TITLE[MembershipRuleOperatorType.NONE]
      )
    ];
  }
  return booleanOperatorsOptions;
}

const DEFAULT_FILE_NAME = 'export.csv';

interface Props {
  form: FormikProps<RulesFormValues>;
  onRemove: (index: number) => void;
  attributeNameOptions: AttributeNameOption[];
  index: number;
  rule: GroupRule;
  isRemoveShown: boolean;
}

function getIsComposite(type?: UserAttributeDefinitionType) {
  return type === UserAttributeDefinitionType.COMPOSITE;
}

function getIsCsvInput(operator: MembershipRuleOperatorType): boolean {
  return operator === MembershipRuleOperatorType.IN || operator === MembershipRuleOperatorType.NOT_IN;
}

function getIsInput(operator: MembershipRuleOperatorType): boolean {
  return (
    operator === MembershipRuleOperatorType.EQUALS ||
    operator === MembershipRuleOperatorType.ANY ||
    operator === MembershipRuleOperatorType.NONE
  );
}

function getAttrTypeByName(
  attributeNameOptions: AttributeNameOption[],
  attrName?: string
): UserAttributeDefinitionType | undefined {
  const option = attributeNameOptions.find(({ value }) => value === attrName);
  return option?.data?.type;
}

const readCsvFile = async (file: File) => {
  return new Promise((resolve, reject) => papaParse.parse(file, {
    delimiter: ",",
    skipEmptyLines: true,
    error: reject,
    complete: (result: papaParse.ParseResult<string>) => (result.errors.length ? reject(result.errors) : resolve(result.data))
  }));
};

export const Rule = ({ form, onRemove, attributeNameOptions, index, rule, isRemoveShown }: Props) => {
  const attrDefinitionType = getAttrTypeByName(attributeNameOptions, rule.left?.value);
  const [operatorsOptions, setOperatorsOptions] = useState(
    getBooleanOperatorsByAttrType(attrDefinitionType)
  );
  const [isComposite, setIsComposite] = useState(
    getIsComposite(attrDefinitionType)
  );

  const [filename, setFilename] = useState(DEFAULT_FILE_NAME);

  const handleFileChange = useCallback(
    (value: any, file: File) => setFilename(file.name),
    []
  );

  const handleCsvClear = useCallback(
    () => form.setFieldValue(`rules.${index}.csvData`, null),
    [form, index]
  );

  const handleRemove = useCallback(
    () => onRemove(index), [onRemove, index]
  );

  const handleAttributeSelect = useCallback(
    (field: string, value: AttributeNameOption, shouldValidate?: boolean) => {
      const attrType = value.data?.type;
      setIsComposite(
        getIsComposite(value.data?.type)
      );
      setOperatorsOptions(
        getBooleanOperatorsByAttrType(attrType)
      );
      if (attrType !== attrDefinitionType) {
        form.setFieldValue(`rules.${index}.operator`, null);
      }
      form.setFieldValue(field, value, shouldValidate);
    },
    [form, attrDefinitionType, index]
  );

  const handleOperatorSelect = useCallback(
    (field: string, value: SelectOption<MembershipRuleOperatorType>, shouldValidate?: boolean) => {
      if (!getIsCsvInput(value.value)) {
        form.setFieldValue(`rules.${index}.csvData`, null);
      }
      if (!getIsInput(value.value)) {
        form.setFieldValue(`rules.${index}.right`, '');
      }
      form.setFieldValue(field, value, shouldValidate);
    },
    [form, index]
  );

  return (
    <Flex key={`operator-${index}`}>
      <Box py={2} px={3} pl={0} width={2 / 6}>
        <CustomField<AttributeNameOption>
          data-testid={`rules.${index}.left`}
          name={`rules.${index}.left`}
          title="Attribute"
          placeholder="Please select"
          component={CustomSelectField}
          options={attributeNameOptions}
          form={form}
          onSelectChange={handleAttributeSelect}
        />
        {isComposite && (
        <Box pt={2}>
          <CustomField
            data-testid={`rules.${index}.jsonPath`}
            name={`rules.${index}.jsonPath`}
            placeholder="Please input JSON path..."
            form={form}
          />
        </Box>
        )}
      </Box>
      <Box py={2} px={3} width={1 / 6}>
        <CustomField
          data-testid={`rules.${index}.operator`}
          name={`rules.${index}.operator`}
          title="Operator"
          placeholder="Please select"
          component={CustomSelectField}
          options={operatorsOptions}
          form={form}
          onSelectChange={handleOperatorSelect}
        />
      </Box>
      <Box py={2} px={3} width={2 / 6}>
        {(rule.operator === null || getIsInput(rule.operator.value)) && (
        <CustomField
          data-testid={`rules.${index}.right`}
          name={`rules.${index}.right`}
          title="Value"
          placeholder="Please input value"
          form={form}
        />
        )}
        {rule.operator && getIsCsvInput(rule.operator.value) && (
          rule.csvData ? (
            <CsvDataButton
              label="Value"
              data={rule.csvData}
              filename={filename}
              onClear={handleCsvClear}
            />
          ) : (
            <CustomFileField
              name={`rules.${index}.csvData`}
              fieldVariant={FileFieldVariant.INPUT_LINK}
              buttonText="Upload"
              fileReader={readCsvFile}
              onChange={handleFileChange}
              form={form}
              label="Value"
              accept=".csv"
            />
          )
        )}
      </Box>
      <Box width={1 / 6}>
        {(index || isRemoveShown) && (
          <CustomButton className={styles.removeRuleBtn} type="button" buttonType="link" onClick={handleRemove}>
            Remove
          </CustomButton>
        )}
      </Box>
    </Flex>
  );
};
