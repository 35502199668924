import React from "react";
import classNames from "classnames";
import ReactSelect from 'react-select';
import { Props as SelectProps } from 'react-select/creatable';
import { SelectOption } from 'interfaces';
import styles from "./Select.module.scss";

interface P<T, TData = undefined> extends Omit<SelectProps<SelectOption<T, TData>>, 'onChange'> {
  onChange?: (option: SelectOption<T, TData>) => void;
}

/*
  react-select supports different types of options (like an array of options for multiselect or null value)
  to simplify work with change event, decided override this type, to work only with {value: string; label: string;} type
*/
type OnChangeType<T, TData = undefined> = (
  option: SelectOption<T, TData> | null | undefined | ReadonlyArray<SelectOption<T, TData>>
) => void;

function Select<T = string, TData = undefined>({ className, onChange, ...props }: P<T, TData>) {
  return (
    <ReactSelect
      {...props}
      onChange={onChange as OnChangeType<T, TData>}
      className={classNames(styles.select, className)}
      classNamePrefix="selectHolder"
    />
  );
}

export default Select;
