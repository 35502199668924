import { Workspace } from 'models/Workspace';
import { WorkspaceItem } from 'models/WorkspaceItem';
import { CreateWorkspaceRequest } from 'interfaces';
import WorkspaceRelease from 'models/WorkspaceRelease';
import { httpTenantService, HttpTenantService } from './http';

class WorkspaceService {
  httpTenant: HttpTenantService;

  constructor(http: HttpTenantService) {
    this.httpTenant = http;
  }

  loadWorkspace = (tenantId: string, workspaceId: string): Promise<Workspace> => {
    return this.httpTenant.get<Workspace>(`/workspaces/${workspaceId}`, {}, tenantId);
  };

  retrieveAllWorkspaces = (params: PagedRequest): Promise<PagedResponse<Workspace>> => {
    return this.httpTenant.get<PagedResponse<Workspace>>(`/workspaces`, { queryStringParameters: params });
  };

  updateWorkspaceItem = (newWorkspaceItem: WorkspaceItem): Promise<WorkspaceItem> => {
    return this.httpTenant.put<WorkspaceItem>(
      `/workspaces/${newWorkspaceItem.workspaceId}/items/${newWorkspaceItem.id}`,
      { body: newWorkspaceItem },
    );
  };

  updateItemOrder = (newOrder: number, oldOrder: number, workspaceId: string, isPredefinedChanged: boolean): Promise<WorkspaceItem[]> => {
    return this.httpTenant.put<WorkspaceItem[]>(
      `/workspaces/${workspaceId}/items/reorder`,
      { body: { newOrder, oldOrder, isPredefinedChanged },
      },
    );
  };

  addWorkspace = (body: CreateWorkspaceRequest): Promise<Workspace> => {
    return this.httpTenant.post<Workspace>('/workspaces', { body });
  };

  editWorkspace = (workspaceId: string, body: CreateWorkspaceRequest): Promise<Workspace> => {
    return this.httpTenant.put<Workspace>(`/workspaces/${workspaceId}`, { body });
  };

  addGroup = (workspaceId: string, groupId: string) => {
    return this.httpTenant.post<Workspace>(`/workspaces/${workspaceId}/groups/${groupId}`);
  };

  deleteGroup = (workspaceId: string, groupId: string) => {
    return this.httpTenant.delete<void>(`/workspaces/${workspaceId}/groups/${groupId}`);
  };

  addRelease = (workspaceId: string, appStoreId: string) => {
    return this.httpTenant.post<WorkspaceRelease>(`/workspaces/${workspaceId}/releases`, { body: { appStoreId } });
  };

  addItem = (data: Pick<WorkspaceItem, "appStoreId" | "itemId" | "predefined" | "type" | "workspaceId">): Promise<WorkspaceItem> => {
    const { workspaceId } = data;
    return this.httpTenant.post<WorkspaceItem>(`/workspaces/${workspaceId}/items`, { body: data });
  };

  removeItem = (workspaceId: string, id: string): Promise<boolean> => {
    return this.httpTenant.delete<boolean>(`/workspaces/${workspaceId}/items/${id}`);
  };
}

export default new WorkspaceService(httpTenantService);
