import * as Yup from 'yup';
import { VALIDATION_RULES } from 'validation';

export const VALIDATION_SCHEMA = Yup.object().shape({
  title: VALIDATION_RULES.title,
  description: VALIDATION_RULES.description,
  appUrl: VALIDATION_RULES.url,
  entityId: VALIDATION_RULES.entityId,
  ssoRedirectUrlPost: VALIDATION_RULES.ssoRedirectUrlPost,
  attributeMapping: VALIDATION_RULES.attributeMapping,
  supportMessage: VALIDATION_RULES.supportMessage,
  nameIdAttribute: VALIDATION_RULES.nameIdAttribute,
});
