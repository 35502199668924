import * as React from 'react';
import { Loader, Table, Pagination } from 'components';
import { UIStore, TenantsStore } from 'stores';
import {
  DELETE_GROUP_MODAL,
  STORE_UI,
  TENANT_DELETE_CONFIRMATION_MESSAGE,
  FORM_MODAL_WIDTH,
  STORE_TENANTS,
  CREATE_EDIT_TENANT_MODAL,
} from "appConstants";
import { observer, inject } from "mobx-react";
import { TenantWithAdmins } from "interfaces";
import { RouteComponentProps } from "react-router";
import { getTenantsColumns } from "./TenantsColumns";
import styles from './Tenants.module.scss';

export interface Props extends RouteComponentProps<{ tenantId: string, groupId: string }> {
  [STORE_TENANTS]: TenantsStore;
  [STORE_UI]: UIStore;
}

const TENANT_MODAL_WIDTH = FORM_MODAL_WIDTH.XMEDIUM;

@inject(STORE_TENANTS, STORE_UI)
@observer
class Tenants extends React.Component<Props, any> {
  componentDidMount() {
    this.props[STORE_TENANTS].loadTenants();
  }

  showDeleteModal = (id: string) => {
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH.MEDIUM,
      componentKey: DELETE_GROUP_MODAL,
      title: "Delete tenant?",
      bodyText: TENANT_DELETE_CONFIRMATION_MESSAGE,
      eventProps: {
        onSubmit: () => this.props[STORE_TENANTS].deleteTenant(id),
      },
    });
  };

  showCreateModal = () => {
    this.props[STORE_UI].openModal({
      componentKey: CREATE_EDIT_TENANT_MODAL,
      width: TENANT_MODAL_WIDTH,
      title: "Add tenant",
      eventProps: {
        onSubmit: this.props[STORE_TENANTS].createTenant,
      },
    });
  };

  showUpdateModal = (id: string) => {
    const tenant = this.props[STORE_TENANTS].all.find((t: TenantWithAdmins) => t.id === id);
    if (!tenant) return;
    this.props[STORE_UI].openModal({
      componentKey: CREATE_EDIT_TENANT_MODAL,
      width: TENANT_MODAL_WIDTH,
      title: "Edit tenant",
      props: {
        data: {
          id: tenant.id,
          alias: tenant.alias,
          name: tenant.name,
          admins: [...tenant.admins],
        },
      },
      eventProps: {
        onSubmit: this.props[STORE_TENANTS].updateTenant,
      },
    });
  };

  onPageChange = (selectedItem: { selected: number }) => {
    this.props[STORE_TENANTS].goToNextTenantPage(selectedItem.selected);
  };

  render() {
    const { all: tenants, isLoading, tenantsPaginationConfig } = this.props[STORE_TENANTS];
    const columns = getTenantsColumns(this.showCreateModal, this.showUpdateModal, this.showDeleteModal);

    return (
      <>
        <h1>Tenants</h1>
        <div className={styles.container}>
          {isLoading ? (
            <Loader />
          ) : (
            <Table<TenantWithAdmins> hasCheckboxes={false} columns={columns} values={tenants} className={styles.table} />
          )}
          {tenantsPaginationConfig && tenantsPaginationConfig.pageCount > 1 &&
          <Pagination
            pageCount={tenantsPaginationConfig.pageCount}
            onPageChange={this.onPageChange}
          />}
        </div>
      </>
    );
  }
}

export default Tenants;
