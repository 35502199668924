import * as React from 'react';
import { FormikProps } from 'formik';
import { inject, observer } from 'mobx-react';
import { CustomField, CustomSelectField, FormFlexContainer } from 'components';
import makeSelectOption from '../../utils/makeSelectOption';
import {
  UnuSupportActionType,
  STORE_REMOTE_CONFIG,
  UNU_SUPPORT_ACTION_LABEL_BY_TYPE,
} from '../../appConstants';
import { RemoteConfigStore } from '../../stores';
import styles from "./SupportTab.module.scss";
import { UnuSupportFormValues } from '../../interfaces';

interface DefaultProps {
  [STORE_REMOTE_CONFIG]: RemoteConfigStore
}

interface Props<T> extends Partial<DefaultProps> {
  form: FormikProps<T>;
}

const defaultSupportTypeOptions = [
  makeSelectOption(UnuSupportActionType.NO_SUPPORT, UNU_SUPPORT_ACTION_LABEL_BY_TYPE[UnuSupportActionType.NO_SUPPORT]),
  makeSelectOption(UnuSupportActionType.STATIC, UNU_SUPPORT_ACTION_LABEL_BY_TYPE[UnuSupportActionType.STATIC]),
];

export const UNU_SUPPORT_ACTION_LEGEND_BY_TYPE = {
  [UnuSupportActionType.NO_SUPPORT]: '',
  [UnuSupportActionType.STATIC]: 'Automated support message is a single read-only message to users who click “Contact support”.',
  [UnuSupportActionType.SUPPORT_CHAT]: 'Automated support message is a single read-only message to users who click “Contact support”. Plus option to start chat with Coll8 support.',
};

export function SupportTab<T extends UnuSupportFormValues>(props: Props<T>) {
  const { unuSupportActionType } = props.form.values;
  // need it to fix @inject mobx types
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { isSupportChatEnabled } = props[STORE_REMOTE_CONFIG]!;
  const supportTypeOptions = isSupportChatEnabled ?
    defaultSupportTypeOptions.concat(
      makeSelectOption<UnuSupportActionType>(UnuSupportActionType.SUPPORT_CHAT, UNU_SUPPORT_ACTION_LABEL_BY_TYPE[UnuSupportActionType.SUPPORT_CHAT]),
    ) : defaultSupportTypeOptions;

  return (
    <FormFlexContainer className={styles.supportTab}>
      <div>
        <CustomField
          name="unuSupportActionType"
          title="Choose support type"
          component={CustomSelectField}
          options={supportTypeOptions}
          form={props.form}
        />
        {unuSupportActionType.value !== UnuSupportActionType.NO_SUPPORT && (
          <CustomField
            className={styles.supportTextInput}
            containerClassName={styles.supportText}
            form={props.form}
            name="supportMessage"
            component="textarea"
            title="Show static support message"
            placeholder="Enter support message here - could contain phone number or email to existing support channel…"
            onChange={props.form.handleChange}
          />
        )}
      </div>
      <div>
        <p className={styles.legend}>{UNU_SUPPORT_ACTION_LEGEND_BY_TYPE[unuSupportActionType.value]}</p>
      </div>
    </FormFlexContainer>
  );
}

export default inject(STORE_REMOTE_CONFIG)(observer(SupportTab));
