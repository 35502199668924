import React from 'react';
import { LoginSignupHolder } from 'components';
import styles from './SignUpPage.module.scss';

class SignUpPage extends React.PureComponent<any, any> {
  render() {
    return (
      <LoginSignupHolder>
        <button type="button" className={styles.backButton} onClick={this.props.history.goBack}>
          <i className="icon-back" />
        </button>
        <h1>Sign up</h1>
        <p className={styles.description}>
          Download Coll8 on your mobile and register.
        </p>
        <div className={styles.buttons}>
          <a href="https://apps.apple.com/us/app/coll8/id1466928663?l=en&ls=1">
            <img
              alt="app store"
              src="images/appStore.png"
              srcSet="images/appStore@2x.png 2x, images/appStore@3x.png 3x"
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.landg.unuapp">
            <img
              alt="google play"
              src="images/googlePlay.png"
              srcSet="images/googlePlay@2x.png 2x, images/googlePlay@3x.png 3x"
            />
          </a>
        </div>
      </LoginSignupHolder>
    );
  }
}

export default SignUpPage;
