export enum UnuActions {
  unuSupport = 'unuSupport',
}

export enum UnuSupportActionType {
  NO_SUPPORT = '',
  STATIC = 'static',
  SUPPORT_CHAT = 'matrix-chatbot',
}

export const UNU_SUPPORT_ACTION_NAME = "Get Support";

export const UNU_SUPPORT_ACTION_LABEL_BY_TYPE = {
  [UnuSupportActionType.NO_SUPPORT]: 'No support',
  [UnuSupportActionType.STATIC]: 'Show static support message',
  [UnuSupportActionType.SUPPORT_CHAT]: 'Show static support message and chat',
};
