import React, { MouseEvent } from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import styles from './WorkspaceDescriptorItem.module.scss';

export interface ContextMenuProps {
  children: React.ReactNode;
  removeItem: (e: MouseEvent) => void;
  appId: string;
}

const ContextMenuWrapper = ({
  children,
  removeItem,
  appId,
}: ContextMenuProps) => (
  <>
    <ContextMenuTrigger attributes={{ className: styles.contextMenuTrigger }} id={`contextMenu-${appId}`}>
      {children}
    </ContextMenuTrigger>
    <ContextMenu className={styles.contextMenu} id={`contextMenu-${appId}`}>
      <MenuItem onClick={removeItem}>
        Remove
      </MenuItem>
    </ContextMenu>
  </>);

export default ContextMenuWrapper;
