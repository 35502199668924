import * as React from "react";
import { FormikProps } from "formik";
import classNames from "classnames";
import { CustomField, FormFlexContainer } from "components";
import styles from "../TenantForm.module.scss";
import { CreateTenantFormValues } from "../../../../../../interfaces";

type Props = {
  index: number;
  form: FormikProps<CreateTenantFormValues>;
  onRemove: (index: number) => void;
};

export default class AdminRow extends React.PureComponent<Props, any> {
  handleRemove = () => {
    this.props.onRemove(this.props.index);
  };

  render() {
    const { index, form } = this.props;
    const isOnlyOneAdmin = form.values.admins.length === 1;
    return (
      <FormFlexContainer className={classNames(styles.columnsCnt, styles.adminRow)}>
        <>
          <CustomField
            className={styles.field}
            withSeparator
            name={`admins.${index}.email`}
            placeholder="Enter admin email..."
            form={form}
          />
          <CustomField
            className={styles.field}
            withSeparator
            name={`admins.${index}.firstName`}
            placeholder="Enter admin first name..."
            form={form}
          />
          <CustomField
            className={styles.field}
            withSeparator
            name={`admins.${index}.lastName`}
            placeholder="Enter admin last name..."
            form={form}
          />
          {!isOnlyOneAdmin && <i role="button" aria-label="Remove" className="icon-close" onClick={this.handleRemove} />}
        </>
      </FormFlexContainer>);
  }
}
