import { observable, action, runInAction, computed } from 'mobx';
import { STORE_ROUTER, AppStoreItemType } from 'appConstants';
import descriptorService from 'services/AppsDescriptorService';
import ssoProviderService from "services/ssoProviderService";
import { WorkspaceDescriptor } from 'models/WorkspaceDescriptor';
import { ItemDescriptor } from 'models/ItemDescriptor';
import localStorageService from 'services/LocalStoreService';
import { Descriptor } from 'models/Descriptor';
import { RootStore } from './RootStore';

export const SSO_SIGN_IN_POPUP_TITLE = "Sign in into your account";
const sortByOrder = (a: Descriptor, b: Descriptor) => a.order - b.order;

export class DescriptorStore {
  rootStore: RootStore;

  @observable workspaceDescriptor: WorkspaceDescriptor | null = null;

  @observable isLoading = false;

  @observable isNotExist = false;

  @observable availableApps: Descriptor[] | null = null;

  @observable installedAppIds: string[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  async getWorkspaceDescriptor(workspaceId: string | null = null) {
    runInAction(() => {
      this.isLoading = true;
      this.isNotExist = false;
    });

    try {
      const apps: string[] = localStorageService.getInstalledAppsByWorkspaceId(workspaceId);
      const descriptor = await descriptorService.getWorkspaceDescriptor(workspaceId, apps);
      const configProps = descriptor.config.properties;
      if (configProps.sso) {
        await ssoProviderService.handleSsoAuth(configProps);
        runInAction(() => {
          this.workspaceDescriptor = descriptor;
        });
      } else if (!configProps.sso) {
        runInAction(() => {
          this.workspaceDescriptor = descriptor;
        });
      }
      return this.workspaceDescriptor;
    } catch (err) {
      if (err.request && err.request.status === 404) {
        runInAction(() => this.isNotExist = true);
      }
      if (err.request && err.request.status === 403) {
        this.rootStore[STORE_ROUTER].history.push('/');
      }
      console.error(err);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  @computed get isMoreServicesVisible(): boolean {
    if (!this.workspaceDescriptor) {
      return false;
    }
    return this.workspaceDescriptor.canAddItems && (!this.availableToInstallApps || this.availableToInstallApps.length !== 0);
  }

  @computed get sortedApps(): ItemDescriptor[] {
    if (!this.workspaceDescriptor) {
      return [];
    }
    const { predefinedApps, installedApps } = this.workspaceDescriptor;
    const sortedPredefinedApps: ItemDescriptor[] = predefinedApps
      .map(a => ({ ...a, predefined: true }))
      .sort(sortByOrder);
    const sortedInstalledApps: ItemDescriptor[] = installedApps
      .map(a => ({ ...a, predefined: false }))
      .sort(sortByOrder);
    // sorting rule: predefined apps, optional apps, "moreApp" (all sorted by order inside each group)
    const sortedApps = sortedPredefinedApps
      .concat(sortedInstalledApps)
      .filter(app => (app.type === AppStoreItemType.MICRO_APP ? app.config.properties.appUrl : true)); // appUrl required only for microApp
    return sortedApps;
  }

  @action
  async getAvailableApps(workspaceId: string) {
    runInAction(() => this.isLoading = true);
    return descriptorService.getAvailableApps(workspaceId)
      .then(availableApps => {
        runInAction(() => {
          this.availableApps = availableApps;
          this.isLoading = false;
        });
        return availableApps;
      })
    // TODO: add error handler (ONA-1143)
      .catch(console.error);
  }

  @action
  getInstalledApps = (workspaceId: string) => {
    this.installedAppIds = localStorageService.getInstalledAppsByWorkspaceId(workspaceId);
  };

  @action
  resetAvailableApps = () => {
    this.availableApps = null;
  };

  @computed get availableToInstallApps(): Descriptor[] | null {
    return this.availableApps ?
      this.availableApps.filter(app => !this.installedAppIds.includes(app.appId)) :
      null;
  }

  @action
  addInstalledApp = (appDescriptor: Descriptor, workspaceId: string) => {
    if (!this.workspaceDescriptor) {
      this.installedAppIds = [];
      return;
    }
    localStorageService.addInstalledApp(appDescriptor.appId, workspaceId);
    this.installedAppIds = localStorageService.getInstalledAppsByWorkspaceId(workspaceId);
    runInAction(() => {
      if (this.workspaceDescriptor) {
        this.workspaceDescriptor.installedApps.push(appDescriptor);
      }
    });
  };

  @action
  removeInstalledApp = (appId: string, workspaceId: string) => {
    if (!this.workspaceDescriptor) {
      this.installedAppIds = [];
      return;
    }
    localStorageService.removeInstalledApp(appId, workspaceId);
    this.installedAppIds = localStorageService.getInstalledAppsByWorkspaceId(workspaceId);
    const index = this.workspaceDescriptor.installedApps.findIndex(appDescriptor => appDescriptor.appId === appId);
    this.workspaceDescriptor.installedApps.splice(index, 1);
  };

  @action
  cleanAvailableApps = () => {
    this.availableApps = [];
  };

  @action
  cleanDescriptor = () => {
    this.workspaceDescriptor = null;
  };
}

export default DescriptorStore;
