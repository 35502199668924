import * as React from 'react';
import { inject, observer } from "mobx-react";
import { Table, CustomButton, ButtonHolder, Pagination, Loader } from 'components';
import { UIStore, GroupStore } from 'stores';
import { RouteComponentProps } from "react-router";
import {
  STORE_UI,
  FORM_MODAL_WIDTH,
  DELETE_GROUP_TENANTS_MODAL,
  STORE_CURRENT_GROUP,
  GROUPS_REMOVE_MESSAGE,
  SHARE_GROUP_TENANT_MODAL,
} from "appConstants";
import SharedTenants from "models/SharedTenants";
import { getShareGroupColumns } from '../groupColumns';
import styles from "../Group.module.scss";

interface Props extends RouteComponentProps<{ tenantId: string, groupId: string, manageTabName: string }> {
  [STORE_UI]: UIStore;
  [STORE_CURRENT_GROUP]: GroupStore;
}

const initialState = {
  selectedTenants: [] as SharedTenants[],
};

type State = typeof initialState;

@inject(STORE_UI, STORE_CURRENT_GROUP)
@observer
export default class ShareGroup extends React.PureComponent<Props, State> {
  state = initialState;

  private readonly columns: TableColumn<SharedTenants>[];

  constructor(props: Props) {
    super(props);
    this.columns = getShareGroupColumns();
  }

  handleSelectionTenantsChange = (tenants: SharedTenants[]) => {
    this.setState({ selectedTenants: tenants });
  };

  handleUnshareTenants = () => {
    this.handleUnshareGroupsClick(this.state.selectedTenants);
  };

  showShareModal = () => {
    this.props[STORE_UI].openModal({
      componentKey: SHARE_GROUP_TENANT_MODAL,
      width: FORM_MODAL_WIDTH.SMALL,
      title: 'Share the group with a tenant',
      eventProps: {
        onSubmit: this.props[STORE_CURRENT_GROUP].shareGroupWithTenant,
      },
      props: {
        groupId: this.props.match.params.groupId,
      },
    });
  };

  handleUnshareGroupsClick = (selectedTenants: SharedTenants[]) => {
    const { groupId } = this.props.match.params;
    const tenants = selectedTenants.map(t => t.name);
    this.props[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH.MEDIUM,
      componentKey: DELETE_GROUP_TENANTS_MODAL,
      title: "Unshare with tenant(s)?",
      bodyText: GROUPS_REMOVE_MESSAGE,
      eventProps: {
        onSubmit: async () => {
          await this.props[STORE_CURRENT_GROUP].unshareGroupTenants(groupId, tenants);
          this.setState({ selectedTenants: [] });
        },
      },
    });
  };

  onPageSharedTenantsGroupsChange = (selectedItem: { selected: number }) => {
    const { groupId } = this.props.match.params;
    this.props[STORE_CURRENT_GROUP].goToNextSharedTenantsGroupPage(groupId, selectedItem.selected);
  };

  public render() {
    const { sharedTenants, tenantsPaginationConfig, isSharedTenantsLoaded } = this.props[STORE_CURRENT_GROUP];
    const { selectedTenants } = this.state;
    const selectedTenantsCount = selectedTenants.length;

    return (
      <>
        {!isSharedTenantsLoaded ? (
          <Loader />
        ) : (
          <>
            <ButtonHolder>
              {selectedTenantsCount > 0 && (
                <CustomButton
                  className={styles.delete}
                  onClick={this.handleUnshareTenants}
                >
                  Unshare group with selected
                </CustomButton>
              )}
              <CustomButton onClick={this.showShareModal}>
                Share group
              </CustomButton>
            </ButtonHolder>
            <Table<SharedTenants>
              rowKey="name"
              isStriped
              columns={this.columns}
              values={sharedTenants}
              onSelectionChange={this.handleSelectionTenantsChange}
              selectedItems={undefined}
            />
            {tenantsPaginationConfig && tenantsPaginationConfig.pageCount > 1 &&
            <Pagination
              pageCount={tenantsPaginationConfig.pageCount}
              onPageChange={this.onPageSharedTenantsGroupsChange}
            />}
          </>
        )}
      </>
    );
  }
}
