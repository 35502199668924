import * as React from "react";
import Tenant from "models/Tenant";
import { TenantWithAdmins } from "interfaces";
import { CustomButton } from "components";
import { TABLE_BUTTON_WIDTH } from 'appConstants';
import TenantsActions from './TenantsActions';

type Accessor<T = TenantWithAdmins> = (x: T) => (JSX.Element | string);

export const getTenantsColumns = (
  showCreateModal: () => void,
  showUpdateModal: (id: string) => void,
  showDeleteModal: (id: string) => void,
): TableColumn<TenantWithAdmins, Accessor>[] => [
  {
    title: 'Tenant name',
    decorator: ({ name }: Tenant) => name,
    width: '15%',
  },
  {
    title: 'Tenant id',
    accessor: ({ alias }: Tenant) => alias,
    width: '15%',
  },
  {
    title: 'Admin',
    accessor: ({ admins }: TenantWithAdmins) => {
      if (admins.length === 1) {
        return admins[0].email;
      }
      if (admins.length === 2) {
        return `${admins[0].email}, ${admins[1].email}`;
      }
      // fix for case of single admin ability to remove himself via delete user functionality
      return admins[0] ? `${admins[0].email} and ${admins.length - 1} others` : '';
    },
  },
  {
    title: '',
    accessor: (tenant: TenantWithAdmins) => <TenantsActions
      tenant={tenant}
      showUpdateModal={showUpdateModal}
      showDeleteModal={showDeleteModal}
    />,
    element: <CustomButton buttonType="link" size="xs" onClick={showCreateModal}>+Add new</CustomButton>,
    width: TABLE_BUTTON_WIDTH,
  },
];
