export const MATRIX_BOT_TOPIC = 'feedback about apps';
export const MATRIX_WELCOME_MESSAGE = 'Welcome to chat...';
export const MATRIX_INVITATION_MESSAGE = '(invitation to room)';
export const MATRIX_INVITATION_ACCEPTED_MESSAGE = 'invitation accepted';
export const MATRIX_FEEDBACK_BEGINNING_MESSAGE = 'Hi, I would like to send my feedback about';

export enum PendingEventOrdering {
  DETACHED = 'detached',
  CHRONOLOGICAL = 'chronological',
}
