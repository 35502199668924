/* eslint-disable react/no-array-index-key */
import React from 'react';
import { observer } from "mobx-react";
import { CustomCheckbox } from 'components';
import styles from '../../Table.module.scss';

export interface Props<T> {
  index: number;
  hasCheckboxes: boolean;
  checkboxDisabled?: boolean;
  checked: boolean;
  onChange: (index: number) => void;
  value: T,
  columns: TableColumn<T>[]
}

@observer
export class TableRow<T> extends React.Component<Props<T>, any> {
  handleChange = () => {
    this.props.onChange(this.props.index);
  };

  render() {
    const { checked, value, columns, hasCheckboxes = true, checkboxDisabled = false } = this.props;

    return (
      <tr>
        {hasCheckboxes && (
          <td className={styles.checkboxCell}>
            <CustomCheckbox
              className={styles.checkbox}
              disabled={checkboxDisabled}
              checked={!!checked}
              onChange={this.handleChange}
            />
          </td>)}
        {columns.map(({ accessor, decorator }, index) => (
          <td key={index}>
            {decorator && decorator(value)}
            {accessor && accessor(value)}
          </td>
        ))}
      </tr>);
  }
}
