import { SelectOption } from 'interfaces';

interface NameIdValue {
  name: string;
  id: string;
  [key: string]: any;
}

const getOptionFromEntity = (value: NameIdValue): SelectOption => ({ label: value.name, value: value.id });

export default getOptionFromEntity;
