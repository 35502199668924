import * as React from 'react';
import { QRCode } from 'react-qrcode-logo';
import styles from './OnboardingSvcModal.module.scss';

export interface Props {
  mobileAppLink: string
}

export default ({ mobileAppLink }: Props) => (
  <div className={styles.qrCodeWrapper}>
    <span>Please scan the code with QR code reader in Coll8 App.</span>
    <QRCode value={mobileAppLink} logoImage="/images/qr-code-logo.svg" />
  </div>
);
