import { buildQuery, parseOauthQuery } from "../../../utils/url";

const TOKEN_NAME = "id_token";

export default {
  tokenName: TOKEN_NAME,
  getAuthUrl: (clientId: string, location: Location, providerSpecificData?: { tenantId: string }) => {
    const tenantId = providerSpecificData && providerSpecificData.tenantId ? providerSpecificData.tenantId : "common";
    const basicUrl = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize`;
    const parts:KV = {
      client_id: clientId,
      response_type: 'code id_token',
      response_mode: 'fragment',
      scope: 'openid profile',
      redirect_uri: process.env.REACT_APP_SSO_LANDING_ORIGIN as string,
      nonce: new Date().getTime().toString(),
    };

    return `${basicUrl}?${buildQuery(parts)}`;
  },
  parseAuthResponse: (locationHash: string) => parseOauthQuery(locationHash, true),
};
