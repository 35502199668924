import * as Yup from 'yup';
import { VALIDATION_RULES } from 'validation';

export const VALIDATION_SCHEMA = Yup.object().shape({
  title: VALIDATION_RULES.title,
  description: VALIDATION_RULES.description,
  sso: VALIDATION_RULES.sso,
  provider: VALIDATION_RULES.provider,
  clientId: VALIDATION_RULES.clientId,
  tenantId: VALIDATION_RULES.tenantId,
  supportMessage: VALIDATION_RULES.supportMessage,
});
