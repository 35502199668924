import * as React from "react";
import { FieldArrayRenderProps } from "formik";
import { CustomButton } from "components";

type Props = {
  arrayHelpers: FieldArrayRenderProps
};

export class AddAdminButton extends React.PureComponent<Props> {
  handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.props.arrayHelpers.push({
      email: "",
      firstName: "",
      lastName: "",
    });
  };

  render() {
    return (
      <CustomButton buttonType="link" size="xs" onClick={this.handleClick}>
        + Add another admin
      </CustomButton>);
  }
}
