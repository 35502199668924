import React from "react";
import { RouteComponentProps } from "react-router";
import { observer, inject } from "mobx-react";
import {
  Loader,
  SSOSamlResponseForm,
  NotFound,
  LoginSignupHolder,
} from "components";
import { SSOStore, WebSocketStore } from "stores";
import { STORE_DEVICES, STORE_SSO, STORE_WEBSOCKET } from "appConstants";
import { SsoAuthorizeAwaiter } from './SsoAuthorizeAwaiter';
import styles from "./SsoAuthorizePage.module.scss";
import { DevicesStore } from "../../stores/DevicesStore";

interface Props extends RouteComponentProps<any> {
  [STORE_SSO]: SSOStore;
  [STORE_WEBSOCKET]: WebSocketStore;
  [STORE_DEVICES]: DevicesStore;
}

@inject(STORE_SSO, STORE_WEBSOCKET, STORE_DEVICES)
@observer
class SsoAuthorizePage extends React.PureComponent<Props> {
  componentDidMount = async () => {
    const { match, location } = this.props;
    this.props[STORE_WEBSOCKET].connect();
    await this.props[STORE_DEVICES].loadUserDevices();
    this.props[STORE_SSO].getUrlParams(match.params.samlServiceId, location.search);
    this.props[STORE_SSO].doSamlRequest();
    await this.props[STORE_SSO].retrieveSsoAuthConfig();

    if (this.props[STORE_SSO].isAuthAllowed && this.props[STORE_DEVICES].hasDeviceToNotify) {
      this.props[STORE_WEBSOCKET].tryToSendAuthNotification(match.params.samlServiceId);
    }
  };

  handleResend = () => {
    const { match } = this.props;
    if (this.props[STORE_SSO].isAuthAllowed) {
      if (this.props[STORE_WEBSOCKET].isConnectionOpen) {
        this.props[STORE_WEBSOCKET].sendAuthNotification(match.params.samlServiceId);
      } else {
        // send over http
        this.props[STORE_SSO].startSsoAuthAction();
      }
    }
  };

  render() {
    const ssoStore = this.props[STORE_SSO];
    const { hasDeviceToNotify } = this.props[STORE_DEVICES];

    if (this.props[STORE_SSO].isLoading || this.props[STORE_DEVICES].isLoading) {
      return <Loader isPadded />;
    }

    if (this.props[STORE_SSO].samlAuthResponseForm) {
      // inject and submit form
      return <SSOSamlResponseForm samlForm={this.props[STORE_SSO].samlAuthResponseForm} />;
    }

    if (this.props[STORE_SSO].samlReqResponseForm) {
      // inject and submit form
      return <SSOSamlResponseForm samlForm={this.props[STORE_SSO].samlReqResponseForm} />;
    }

    // for case then we don't get service from database
    if (ssoStore.isSamlRequestFailed) {
      return <NotFound />;
    }

    return (
      <div className={styles.container}>
        <LoginSignupHolder>
          {
            !ssoStore.isSamlAuthorizeCancelled ? (
              <SsoAuthorizeAwaiter
                authAllowedFrom={ssoStore.authAllowedFrom}
                hasDeviceToNotify={hasDeviceToNotify}
                onResend={this.handleResend}
              />
            ) : (
              <p className={styles.deniedText}>The access to the service was denied...</p>
            )
          }
        </LoginSignupHolder>
      </div>
    );
  }
}

export default SsoAuthorizePage;
