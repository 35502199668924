import * as React from 'react';
import {
  Loader,
} from 'components';
import { Formik } from 'formik';
import { STORE_UI, STORE_CURRENT_WORKSPACE } from 'appConstants';
import { Workspace } from 'models/Workspace';
import { inject, observer } from "mobx-react";
import { WorkspaceItem } from 'models/WorkspaceItem';
import { UIStore, CurrentWorkspaceStore } from "stores";
import { GroupDiff, WorkspaceFormValues } from 'interfaces';
import getInitialValues from './initialValues';
import { VALIDATION_SCHEMA } from './validation';
import CreateWorkspaceForm from './CreateWorkspaceForm';

export interface Props {
  // workspaceId and tenantId for edit mode only
  workspaceId?: string;
  tenantId?: string;
  workspaceItem?: WorkspaceItem;
  onSubmit: (workspace: Workspace, groups: GroupDiff, id?: string, workspaceItem?: WorkspaceItem) => void;
  [STORE_UI]: UIStore,
  [STORE_CURRENT_WORKSPACE]: CurrentWorkspaceStore;
}

@inject(STORE_UI, STORE_CURRENT_WORKSPACE)
@observer
export default class CreateWorkspaceModal extends React.Component <Props> {
  componentDidMount() {
    this.props[STORE_CURRENT_WORKSPACE].listGroups();
    const { tenantId, workspaceId } = this.props;
    if (workspaceId && tenantId) {
      this.props[STORE_CURRENT_WORKSPACE].loadWorkspaceConfig(tenantId, workspaceId);
    }
  }

  componentWillUnmount() {
    this.props[STORE_CURRENT_WORKSPACE].resetConfig();
  }

  render() {
    const { currentWorkspace, isLoading } = this.props[STORE_CURRENT_WORKSPACE];
    const initialValues = getInitialValues(currentWorkspace);
    if (isLoading) {
      return <Loader />;
    }

    return (
      <Formik<WorkspaceFormValues>
        initialValues={initialValues}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={() => {}}
      >
        {form => {
          if (form.isSubmitting) {
            return (<Loader />);
          }
          return (
            <CreateWorkspaceForm<WorkspaceFormValues>
              {...this.props}
              form={form}
            />
          );
        }}
      </Formik>
    );
  }
}
