import * as React from 'react';
import classNames from "classnames";
import { observer } from "mobx-react";
import objectPath from 'object-path';
import { FormikProps } from "formik";
import { Tabs, TabList, TabPanel } from 'react-tabs';
import { CustomField, CustomTab, ImageInput, Alert } from 'components';
import SupportTab from 'containers/SupportTab/SupportTab';
import { getIconNameFromUrl } from "utils/getIconNameFromUrl";
import {
  STORE_CURRENT_SERVICE,
  STORE_UI,
  EMPTY_CERT_ERROR,
} from "appConstants";
import { SelectOption, ServiceFormValues } from 'interfaces';
import CreateServiceFooter from './CreateServiceFooter';
import { Props as ModalProps } from './CreateServiceModal';
import createItemTabsWrapper from '../CreateItemTabsWrapper';
import styles from './CreateServiceModal.module.scss';
import SSOTab from './SSOTab';

interface FormProps extends ModalProps {
  form: FormikProps<ServiceFormValues>;
  imageInputRef: React.RefObject<ImageInput>;
  onTabSelect: () => void;
}

export interface FormAttributeMapping {
  attributeValue: SelectOption;
  name: string
}

const initialState: State = {
  iconDataUrl: null,
  iconName: null,
  resizingMode: false,
  imageChanged: false,
};

interface State {
  iconDataUrl: null | string,
  iconName: null | string,
  resizingMode: boolean,
  imageChanged: boolean,
}

@observer
class CreateServiceForm extends React.Component <FormProps, State> {
  protected tabHolder = React.createRef<HTMLDivElement>();

  state = initialState;

  componentDidMount() {
    const { config } = this.props[STORE_CURRENT_SERVICE];
    if (config) {
      const { iconUrl, iconDataUrl } = config.props;
      if (iconUrl && iconDataUrl) {
        const iconName = getIconNameFromUrl(iconUrl);
        this.setState({ iconName, iconDataUrl });
      }
    }
  }

  resetIconData = () => {
    this.setState({ iconName: null, iconDataUrl: null, imageChanged: true });
  };

  onFileLoaded = ({ iconName, iconDataUrl }: { iconName: string; iconDataUrl: string }) => {
    this.setState({ iconName, iconDataUrl, resizingMode: false, imageChanged: true });
  };

  scrollToBottom = () => {
    const tabHolder = this.tabHolder.current;
    if (tabHolder) {
      tabHolder.scrollTop = tabHolder.scrollHeight;
    }
  };

  onShowNotification = (message: string) => {
    this.props[STORE_UI].showMessageNotification(message);
  };

  onFileChanged = () => {
    this.setState({ resizingMode: true, imageChanged: true });
  };

  onPublish = () => this.submitForm(true);

  onSubmit = () => this.submitForm(false);

  submitForm = async (shouldPublishToUnu: boolean) => {
    const { shouldBeAddedToCurrentWS, form } = this.props;
    form.setSubmitting(true);
    const { values } = form;
    // additional validation on submit
    if (values.encryptAssertion && !values.spCertificate) {
      form.setErrors({ spCertificate: EMPTY_CERT_ERROR });
      return;
    }
    const { iconDataUrl, iconName } = this.state;
    const samlServiceId = objectPath.get(this.props.item, "config.props.sso.samlServiceId") || "";
    await this.props.onSubmit({
      formValues: values,
      iconDataUrl,
      iconName,
      samlServiceId,
    }, shouldPublishToUnu, this.props.item, shouldBeAddedToCurrentWS);
    form.setSubmitting(false);
  };

  render() {
    const { config } = this.props[STORE_CURRENT_SERVICE];
    const { userAttrs } = this.props[STORE_CURRENT_SERVICE];
    const { iconDataUrl, resizingMode } = this.state;
    const isEditMode = !!this.props.item;
    const { form, submitError, imageInputRef, onTabSelect } = this.props;
    const isSaveDisabled = resizingMode || !((this.state.imageChanged || form.dirty) && form.isValid);
    const isPublishDisabled = resizingMode || !form.isValid;

    return (
      <Tabs onSelect={onTabSelect}>
        <TabList>
          <CustomTab>Settings</CustomTab>
          <CustomTab>Single Sign-On</CustomTab>
          <CustomTab>Support</CustomTab>
        </TabList>
        <div className={styles.tabContent} ref={this.tabHolder}>
          {submitError && <Alert message={submitError} />}
          <TabPanel>
            <div className={styles.paneHolder}>
              <div className={classNames(styles.col, styles.centered)}>
                <ImageInput
                  ref={imageInputRef}
                  iconDataUrl={iconDataUrl}
                  onShowNotification={this.onShowNotification}
                  onFileLoaded={this.onFileLoaded}
                  onFileCleared={this.resetIconData}
                  onFileChanged={this.onFileChanged}
                />
              </div>
              <div className={styles.col}>
                <CustomField
                  data-testid="serviceName"
                  name="title"
                  title="Name"
                  placeholder="Enter a name"
                  form={form}
                  onChange={form.handleChange}
                />
                <CustomField
                  name="description"
                  title="Description"
                  placeholder="Enter a description"
                  form={form}
                  onChange={form.handleChange}
                />
                <CustomField
                  data-testid="serviceLink"
                  name="appUrl"
                  title="Url"
                  placeholder="Enter an url"
                  form={form}
                  onChange={form.handleChange}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <SSOTab
              form={form}
              config={config}
              userAttrs={userAttrs}
              isEditMode={isEditMode}
              scrollToBottom={this.scrollToBottom}
            />
          </TabPanel>
          <TabPanel>
            <SupportTab<ServiceFormValues>
              form={form}
            />
          </TabPanel>
        </div>
        <CreateServiceFooter
          onPublish={this.onPublish}
          onSubmit={this.onSubmit}
          isSaveDisabled={isSaveDisabled || form.isSubmitting}
          isUnuPublishDisabled={isPublishDisabled || form.isSubmitting}
        />
      </Tabs>
    );
  }
}

const WrappedComponent = createItemTabsWrapper(CreateServiceForm);

export default WrappedComponent;
