import React from 'react';
import { WorkspaceItem as WorkspaceItemInterface } from 'models/WorkspaceItem';
import { AppStoreItemType } from 'appConstants';
import { TileItem, TileContent, CustomButton } from 'components';
import { observer } from 'mobx-react';
import styles from './WorkspaceItem.module.scss';
import TileContentWrapper from '../TileContentWrapper/TileContentWrapper';

export interface Props {
  item: WorkspaceItemInterface;
  isAdminMode: boolean;
  predefined?: boolean;
  updateWorkspaceItem: (item: WorkspaceItemInterface) => Promise<void>;
  showEditServiceModal: (item: WorkspaceItemInterface) => void;
  showEditWorkspaceModal: (id: string, item?: WorkspaceItemInterface) => void;
  showOnboardingSvcModal: (title: string, mobileAppLink?: string) => void
}

interface State { contextMenu: boolean }

@observer
export default class WorkspaceItem extends React.Component<Props, State> {
  updateWorkspaceItem = () => this.props.updateWorkspaceItem(this.props.item);

  handleOnboardingSvcClick = () => this.props.showOnboardingSvcModal(
    this.props.item.config.title,
    this.props.item.config?.appUrl,
  );

  editHandler = () => {
    if (this.props.item.type === AppStoreItemType.WORKSPACE) {
      this.props.showEditWorkspaceModal(this.props.item.itemId, this.props.item);
      return;
    }
    if (this.props.item.type === AppStoreItemType.MICRO_APP) {
      this.props.showEditServiceModal(this.props.item);
    }
  };

  render() {
    const { item, isAdminMode } = this.props;
    const { metadata } = item;
    const isWorkspace = item.type === AppStoreItemType.WORKSPACE;

    return (
      <TileItem>
        <TileItem.Content isWorkspace={isWorkspace}>
          <TileContentWrapper
            appStoreId={item.appStoreId}
            appUrl={item.config.appUrl}
            itemId={item.itemId}
            isAdminMode={isAdminMode}
            onOnboardingSvcClick={this.handleOnboardingSvcClick}
            itemType={item.type}
            adminTenantId={item.adminTenantId}
          >
            <TileContent
              description={item.config.description}
              title={item.config.title}
              iconUrl={item.config.iconUrl}
            />
          </TileContentWrapper>
        </TileItem.Content>
        <TileItem.Footer>
          <div className={styles.buttonHolder}>
            { metadata.canDelete && (
              <CustomButton
                size="s"
                buttonType="linkAlt"
                onClick={this.updateWorkspaceItem}
              >
                Remove
              </CustomButton>)}
            { metadata.canEdit && (
              <CustomButton
                size="s"
                buttonType="link"
                onClick={this.editHandler}
              >
                Edit
              </CustomButton>)}
          </div>
        </TileItem.Footer>
      </TileItem>
    );
  }
}
