import API from '@aws-amplify/api';
import { GetParams, PostParams, PutParams, DeleteParams, ApiName, PatchParams } from 'interfaces';

export class HttpService {
  apiName: ApiName;

  constructor(apiName: ApiName) {
    this.apiName = apiName;
  }

  get<T>(path: string, params?: GetParams): Promise<T> {
    return API.get(this.apiName, path, params);
  }

  // TODO think over about moving content type to options
  post<T>(
    path: string,
    {
      body,
      queryStringParameters = {},
      contentType = "application/json",
      headers = {},
      withCredentials,
      response,
    }: PostParams = {},
  ): Promise<T> {
    return API.post(this.apiName, path, {
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      queryStringParameters,
      body,
      responseType: contentType,
      withCredentials,
      response,
    });
  }

  put<T>(
    path: string,
    {
      body,
      queryStringParameters = {},
      headers = {},
    }: PutParams,
  ): Promise<T> {
    return API.put(this.apiName, path, {
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      queryStringParameters,
      body,
    });
  }

  patch<T>(
    path: string,
    {
      body,
      queryStringParameters = {},
      headers = {},
      contentType = "application/json",
    }: PatchParams,
  ): Promise<T> {
    return API.patch(this.apiName, path, {
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      queryStringParameters,
      responseType: contentType,
      body,
    });
  }

  delete<T>(path: string, params?: DeleteParams): Promise<T> {
    return API.del(this.apiName, path, params);
  }
}
