import React from "react";
import { CustomField, CustomSelectField, CustomCheckbox } from 'components';
import { FormikProps } from 'formik';
import { providerOptions, SsoProviderType } from 'appConstants';
import { WorkspaceFormValues } from "interfaces";

interface Props {
  form: FormikProps<WorkspaceFormValues>;
  isRootWorkspace?: boolean;
}

const microsoftOption = providerOptions.find(opt => opt.value === SsoProviderType.MICROSOFT);

const SsoFields = ({ form, isRootWorkspace }: Props) => (
  <>
    <CustomField
      name="sso"
      title="SSO enabled"
      component={CustomCheckbox}
      checked={form.values.sso}
      form={form}
      onChange={form.handleChange}
      disabled={isRootWorkspace}
    />
    {form.values.sso &&
      <>
        <CustomField
          name="provider"
          title="Provider"
          placeholder="Please select provider"
          component={CustomSelectField}
          options={providerOptions}
          form={form}
          onSelect={form.setFieldValue}
        />
        <CustomField
          name="clientId"
          title="Client ID"
          placeholder="Enter Client ID..."
          form={form}
          onChange={form.handleChange}
        />
        {form.values.provider === microsoftOption &&
          <CustomField
            name="tenantId"
            title="Tenant ID"
            placeholder="Enter Tenant ID..."
            form={form}
            onChange={form.handleChange}
          />}
      </>}
  </>
);

export default SsoFields;
