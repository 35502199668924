import { HttpService } from "./http";

export interface UserDeviceData {
  deviceId: string;
  notificationsEnabled: boolean;
}

export class DeviceService {
  constructor(private http: HttpService) {}

  getUserDevices = (): Promise<UserDeviceData[]> => this.http.get('/users/me/devices');
}
