import GroupUser from "models/GroupUser";
import * as React from "react";
import { TABLE_BUTTON_WIDTH } from 'appConstants';
import { TextStyle } from "components";
import SharedTenants from "models/SharedTenants";
import ApproveUsersActions, { ApproveRejectFunc } from "./components/ApproveUsersActions";
import ApproveUser from "../../models/ApproveUser";
import { TableDomains } from "./components/Domains";

export const getUserGroupColumns = () => [
  {
    title: 'First name',
    accessor: ({ firstName }: GroupUser) => <TextStyle variation="strong">{firstName}</TextStyle>,
    width: '18%',
  },
  {
    title: 'Last name',
    accessor: ({ lastName }: GroupUser) => <TextStyle variation="strong">{lastName}</TextStyle>,
    width: '18%',
  },
  {
    title: 'Email',
    accessor: ({ email }: GroupUser) => email || '<no email>',
  },
  {
    title: '',
    accessor: () => "",
    width: TABLE_BUTTON_WIDTH,
  },
];

export const getUsersToApproveGroupColumns = (onApprove: ApproveRejectFunc, onReject: ApproveRejectFunc) => [
  {
    title: 'First name',
    accessor: ({ firstName }: GroupUser) => <TextStyle variation="strong">{firstName}</TextStyle>,
    width: '10%',
  },
  {
    title: 'Last name',
    accessor: ({ lastName }: GroupUser) => <TextStyle variation="strong">{lastName}</TextStyle>,
    width: '10%',
  },
  {
    title: 'Email',
    accessor: ({ email }: GroupUser) => email || '<no email>',
  }, {
    title: 'Action',
    accessor: (user: ApproveUser) => (
      <ApproveUsersActions
        user={user}
        onApprove={onApprove}
        onReject={onReject}
      />
    ),
    width: TABLE_BUTTON_WIDTH,
  },
];

export const getDomainsGroupColumns = () => [{
  title: 'Domain name',
  accessor: ({ domain }: TableDomains) => <TextStyle variation="strong">{domain}</TextStyle>,
}, {
  title: '',
  accessor: () => "",
  width: TABLE_BUTTON_WIDTH,
}];

export const getShareGroupColumns = () => [{
  title: 'Tenant name',
  accessor: ({ name }: SharedTenants) => <TextStyle variation="strong">{name}</TextStyle>,
}, {
  title: '',
  accessor: () => "",
  width: TABLE_BUTTON_WIDTH,
}];
