import React from 'react';
import classNames from 'classnames';
import styles from './Spinner.module.scss';

type Props = {
  className?: string
};

export const Spinner = (props: Props) => (
  <div className={classNames(styles.spinner, props.className)}>
    <div className={styles.bounce1} />
    <div className={styles.bounce2} />
    <div className={styles.bounce3} />
  </div>
);
