import { ToastsStore } from 'react-toasts';
import { DEFAULT_SERVER_ERROR_MESSAGE } from '../appConstants';
import { ServerError } from '../errors';

export interface AxiosError {
  response: {
    status: number,
    data: {
      message: string
    }
  }
}

export const catchXhr = (err: Error) => {
  console.error(err);
  ToastsStore.error(DEFAULT_SERVER_ERROR_MESSAGE);
};

export function catchServerError(err: AxiosError) {
  if ((err.response?.status === 400 || err.response?.status === 409) && err.response?.data) {
    const text = err.response.data?.message;
    ToastsStore.error(text); // show toast
    throw new ServerError(text); // throw for modal
  } else {
    ToastsStore.error(DEFAULT_SERVER_ERROR_MESSAGE);
  }
}
