import React, { useCallback } from "react";
import { Box, Flex } from "reflexbox";
import { MembershipRuleExpression } from "appConstants";
import { CustomButton, Label } from "components";
import styles from "./MembershipRules.module.scss";

interface Props {
  expression: MembershipRuleExpression;
  onRemove: (index: number) => void;
  isRemoveShown?: boolean;
  index: number;
}

export const FallbackRule = ({ expression, index, onRemove, isRemoveShown }: Props) => {
  const handleRemove = useCallback(
    () => onRemove(index), [onRemove, index]
  );

  return (
    <Flex className={styles.fallbackRule}>
      <Box width={5 / 6} pr={3}>
        <Box>
          <Label title="Attribute" />
        </Box>
        <textarea
          data-testid={`rules.${index}.expression`}
          disabled
          rows={7}
          defaultValue={JSON.stringify(expression, null, 2)}
        />
      </Box>
      <Box width={1 / 6}>
        <Flex>
          {(index || isRemoveShown) && (
            <CustomButton className={styles.removeRuleBtn} type="button" buttonType="link" onClick={handleRemove}>
              Remove
            </CustomButton>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};
