import { useEffect } from "react";
import { FormikHelpers, FormikErrors } from "formik";

const useSetErrors = <T>(
  error: string | null,
  setErrors: FormikHelpers<T>["setErrors"],
  field: keyof T,
): void => {
  useEffect(() => {
    if (error) {
      setErrors({ [field]: error } as FormikErrors<T>);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, setErrors, field]);
};

export default useSetErrors;
