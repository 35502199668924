import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './CustomButton.module.scss';
import Loader from '../Loader/Loader';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  fullWidth?: boolean;
  buttonType?: 'default' | 'primary' | 'secondary' | 'link' | 'linkAlt';
  size?: 'm' | 's' | 'xs' | 'xxs';
}

const CustomButton = ({
  children,
  className,
  fullWidth,
  disabled,
  buttonType = 'default',
  isLoading = false,
  size = 'm',
  ...props
}: ButtonProps) => {
  const onClick = !isLoading ? props.onClick : undefined;
  return (
    <button
      type="button"
      {...props}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        {
          [styles.small]: size === 's',
          [styles.xsmall]: size === 'xs',
          [styles.xxsmall]: size === 'xxs',
          [styles.primary]: buttonType === 'primary',
          [styles.secondary]: buttonType === 'secondary',
          [styles.link]: buttonType === 'link',
          [styles.linkAlt]: buttonType === 'linkAlt',
          [styles.fullWidth]: fullWidth,
          [styles.disabled]: disabled,
        },
        styles.button,
        className,
      )}
    >
      { isLoading ? React.createElement(Loader, { className: styles.loader }) : children}
    </button>
  );
};

export default CustomButton;
