import React from 'react';
import { AppStoreItemType } from '../../appConstants';
import makeAppId from '../../utils/makeAppId';
import CustomLink from '../CustomLink';

function getWorkspaceLink(appId: string, isAdminMode: boolean, tenantId?: string) {
  const path = `/workspace/${encodeURIComponent(appId)}`;
  return tenantId && isAdminMode ? `/tenants/${tenantId}${path}` : path;
}

interface TileContentWrapperProps {
  itemType: AppStoreItemType;
  itemId: string;
  appId?: string;
  appStoreId: string;
  appUrl?: string;
  isAdminMode: boolean;
  adminTenantId?: string;
  onOnboardingSvcClick: () => void
}

export const TileContentWrapper: React.FC<TileContentWrapperProps> = ({
  itemId,
  appStoreId,
  appUrl,
  appId,
  isAdminMode,
  adminTenantId,
  itemType,
  onOnboardingSvcClick,
  children,
}) => {
  if (itemType === AppStoreItemType.WORKSPACE) {
    const wsItemId = appId || makeAppId(itemId, appStoreId);
    const url = getWorkspaceLink(wsItemId, isAdminMode, adminTenantId);
    return <CustomLink to={url}>{children}</CustomLink>;
  }

  if (itemType === AppStoreItemType.MICRO_APP) {
    if (!appUrl) {
      throw new Error("appUrl is required for service");
    }
    const isOnboardingSvc = appUrl.includes(process.env.REACT_APP_ONBOARDING_SVC_BASE_URL as string);
    return isOnboardingSvc ? (
      <span role="button" data-testid="onboardingSvcTile" onClick={onOnboardingSvcClick}>
        {children}
      </span>
    ) : (
      <a href={appUrl} target="_blank" rel="noopener noreferrer">
        {children}
      </a>);
  }

  // unsupported type
  return null;
};

export default TileContentWrapper;
