import React from 'react';
import * as Yup from 'yup';
import { Formik } from "formik";
import { VALIDATION_RULES } from "validation";
import { emptyTenantNameErrorMessage } from "validation/messages";
import ShareGroupTenantForm, { FormValues } from './ShareGroupTenantForm';

export interface Props {
  submitError: null | string;
  onSubmit: (groupId:string, email: string) => void | string;
  groupId: string;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  tenantId: VALIDATION_RULES.notEmptyString(emptyTenantNameErrorMessage),
});

const initialValues = { tenantId: '' };

export default function ShareGroupTenantModal(props: Props) {
  const { submitError } = props;

  const onSubmit = ({ tenantId }: FormValues) => {
    props.onSubmit(props.groupId, tenantId);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {form => (
        <ShareGroupTenantForm
          form={form}
          submitError={submitError}
          setErrors={form.setErrors}
        />
      )}
    </Formik>
  );
}
