import { workspaceAccessTypeOptions } from 'appConstants';

export default {
  props: {
    title: '',
    description: '',
    tenantId: '',
    clientId: '',
    sso: false,
    groups: [],
    accessType:
    workspaceAccessTypeOptions[1],
    provider: undefined,
    actions: {},
  },
};
