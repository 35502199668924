import * as React from 'react';
import classNames from "classnames";
import { observer } from 'mobx-react';
import { AppStoreType, AppStoreItemType } from 'appConstants';
import TileItem from 'components/TileItem/TileItem';
import { WorkspaceServiceItem as WorkspaceServiceItemI } from "interfaces";
import { TileContent } from 'components';
import ActionButton from './components/ActionButton';

import styles from "./WorkspaceServiceItem.module.scss";

export interface Props {
  appStoreType: AppStoreType;
  item: WorkspaceServiceItemI;
  isAssignStatePending: boolean;
  onAddItem: (appStoreType: AppStoreType, service: WorkspaceServiceItemI) => void;
  onRemoveItem: (appStoreType: AppStoreType, service: WorkspaceServiceItemI) => void;
}

@observer
export class WorkspaceServiceItem extends React.Component<Props> {
  handleItemAdd = () => {
    this.props.onAddItem(this.props.appStoreType, this.props.item);
  };

  handleItemRemove = () => {
    this.props.onRemoveItem(this.props.appStoreType, this.props.item);
  };

  public render() {
    const { item, isAdded, type } = this.props.item;
    const isWorkspace = type === AppStoreItemType.WORKSPACE;
    return (
      <TileItem>
        <TileItem.Content className={styles.tileItemContent} isWorkspace={isWorkspace}>
          {isAdded && (<i className={classNames("icon-checkmark", styles.iconCheckMark)} />)}
          <TileContent
            description={item.config.props.description}
            title={item.config.props.title}
            iconUrl={item.config.props.iconUrl}
          />
        </TileItem.Content>
        <TileItem.Footer>
          {isAdded ? (
            <ActionButton
              disabled={this.props.isAssignStatePending}
              className={styles.removeBtn}
              item={this.props.item}
              onClick={this.handleItemRemove}
            >
              Remove
            </ActionButton>) : (
              <ActionButton
                disabled={this.props.isAssignStatePending}
                className={styles.addBtn}
                item={this.props.item}
                onClick={this.handleItemAdd}
              >
                Add
              </ActionButton>)}
        </TileItem.Footer>
      </TileItem>
    );
  }
}

export default WorkspaceServiceItem;
