/* eslint-disable react/no-array-index-key */
import React, { useCallback } from "react";
import { CustomButton, CustomField, CustomSelectField, } from "components";
import { Flex, Box } from 'reflexbox';
import { FormikProps, FieldArrayRenderProps } from 'formik';
import { GroupRule, RulesFormValues } from "interfaces/group";
import { SelectOption } from "interfaces";
import { GROUP_RULE_LOGICAL_OPERATOR_TITLE, MembershipRuleLogicalOperatorType, MembershipRuleOperatorType } from "appConstants";
import { Rule } from './Rule';
import makeSelectOption from "../../../../utils/makeSelectOption";
import { AttributeNameOption } from "./types";
import { FallbackRule } from "./FallbackRule";
import { groupRulesService } from "../../../../services";

const MAX_SUPPORTED_RULES_COUNT = 2;

const logicalOperatorOptions: SelectOption<MembershipRuleLogicalOperatorType>[] = [
  makeSelectOption(
    MembershipRuleLogicalOperatorType.AND,
    GROUP_RULE_LOGICAL_OPERATOR_TITLE[MembershipRuleLogicalOperatorType.AND]
  ),
  makeSelectOption(
    MembershipRuleLogicalOperatorType.OR,
    GROUP_RULE_LOGICAL_OPERATOR_TITLE[MembershipRuleLogicalOperatorType.OR]
  ),
];

export function makeInitialRule(logicalOperator: SelectOption<MembershipRuleLogicalOperatorType> | null = null): GroupRule {
  return {
    left: null,
    operator: null,
    jsonPath: '',
    csvData: null,
    right: '',
    logicalOperator
  };
}
/**
 * Check do we support this operator on UI
 * If no - switch to fallback version with disabled editing
 * @param params - rule operator
 */
function getIsSupportedOperator(operator?: MembershipRuleOperatorType): boolean {
  if (!operator) return true;
  return [
    MembershipRuleOperatorType.EQUALS,
    MembershipRuleOperatorType.NOT_IN,
    MembershipRuleOperatorType.IN,
    MembershipRuleOperatorType.ANY,
    MembershipRuleOperatorType.NONE
  ].includes(operator);
}

export function hasUnSupportedRules(rules: GroupRule[]): boolean {
  return rules.some(rule => !getIsSupportedOperator(rule.operator?.value));
}

function getIsInitialRule(rule: GroupRule) {
  return rule.left === null && rule.operator === null;
}

function getIsRulesRemoveShown(rules: GroupRule[], curRule: GroupRule) {
  return !getIsInitialRule(curRule) && rules.length === 1;
}

interface Props {
  form: FormikProps<RulesFormValues>;
  arrayHelpers: FieldArrayRenderProps;
  attributeNameOptions: AttributeNameOption[];
  clearRules: () => Promise<void>;
}

export const Rules = ({ form, arrayHelpers, attributeNameOptions, clearRules }: Props) => {
  const handleAddNew = useCallback(
    () => arrayHelpers.push(makeInitialRule(
      makeSelectOption(
        MembershipRuleLogicalOperatorType.AND,
        GROUP_RULE_LOGICAL_OPERATOR_TITLE[MembershipRuleLogicalOperatorType.AND]
      )
    )), [arrayHelpers]
  );

  const handleRemove = useCallback(
    async (index: number) => {
      arrayHelpers.remove(index);
      if (index === 0) {
        arrayHelpers.push(makeInitialRule());
        await clearRules();
      }
    },
    [arrayHelpers, clearRules]
  );

  return (
    <div>
      {form.values.rules.map((rule, index) => (
        <React.Fragment key={index}>
          {
          rule.logicalOperator && (
            <Flex key={`operator-${index}`}>
              <Box width={2 / 6} />
              <Box pt="3.9rem" pb="0" px={3} width={1 / 6}>
                <CustomField
                  data-testid={`rules.${index}.logicalOperator`}
                  name={`rules.${index}.logicalOperator`}
                  component={CustomSelectField}
                  options={logicalOperatorOptions}
                  form={form}
                  onSelect={form.setFieldValue}
                />
              </Box>
              <Box width={3 / 6} />
            </Flex>
          )
        }
          {
          getIsSupportedOperator(rule.operator?.value) ? (
            <Rule
              key={`rule-${index}`}
              form={form}
              attributeNameOptions={attributeNameOptions}
              isRemoveShown={getIsRulesRemoveShown(form.values.rules, rule)}
              onRemove={handleRemove}
              rule={rule}
              index={index}
            />
          ) : (
            <FallbackRule
              key={`fallback-rule-${index}`}
              isRemoveShown={getIsRulesRemoveShown(form.values.rules, rule)}
              onRemove={handleRemove}
              index={index}
              expression={groupRulesService.buildRuleExpression(rule)}
            />
          )
        }
        </React.Fragment>))}

      {
        form.values.rules.length < MAX_SUPPORTED_RULES_COUNT && (
          <Flex>
            <Box width={2 / 6} />
            <Box width={1 / 6} textAlign="center">
              <CustomButton type="button" buttonType="link" onClick={handleAddNew}>
                + Add new
              </CustomButton>
            </Box>
            <Box width={3 / 6} />
          </Flex>
        )
        }
    </div>);
};
