import { HttpService, httpTenantService } from './http';
import { TenantUserAttributesDefinitions } from "../interfaces/userAttributes";

class UserAttributesService {
  http: HttpService;
  constructor(http: HttpService) {
    this.http = http;
  }

  retrieveUserAttributes = (): Promise<TenantUserAttributesDefinitions> => {
    return this.http.get<TenantUserAttributesDefinitions>(`/userAttributes`);
  };
}

export default new UserAttributesService(httpTenantService);
