import React from 'react';
import { CustomButton, ButtonHolder, CustomField } from 'components';
import { Form, FormikProps, FormikHelpers } from "formik";
import useSetErrors from 'hooks/useSetErrors';

export interface Props {
  submitError: null | string;
  form: FormikProps<FormValues>;
  setErrors: FormikHelpers<FormValues>["setErrors"];
}

export interface FormValues {
  tenantId: string;
}

export default function ShareGroupTenantForm(props: Props) {
  const { submitError, form, setErrors } = props;
  const isDisabled = !form.dirty || !form.isValid;

  useSetErrors<FormValues>(submitError, setErrors, 'tenantId');

  return (
    <Form>
      <CustomField
        withSeparator
        name="tenantId"
        title="Tenant name"
        placeholder="Add tenant name"
        autoFocus
        form={form}
        onChange={form.handleChange}
      />
      <ButtonHolder>
        <CustomButton
          disabled={isDisabled}
          buttonType="primary"
          type="submit"
        >
          Share
        </CustomButton>
      </ButtonHolder>
    </Form>
  );
}
