import React from 'react';
import Countdown from 'react-countdown';
import { observer } from 'mobx-react';
import { Spinner, CustomButton } from '../../components';
import styles from "./SsoAuthorizePage.module.scss";

type Props = {
  onResend: () => void;
  authAllowedFrom?: number;
  hasDeviceToNotify: boolean;
};

@observer
export class SsoAuthorizeAwaiter extends React.PureComponent<Props> {
  countDownRenderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      return (
        <CustomButton
          className={styles.resend}
          type="button"
          buttonType="link"
          onClick={this.props.onResend}
        >
          Resend
        </CustomButton>
      );
    }
    return (
      <span className={styles.countdown}>
        Wait {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </span>
    );
  };

  render() {
    const { authAllowedFrom, hasDeviceToNotify } = this.props;
    return (
      <div className={styles.awaiter}>
        {
          hasDeviceToNotify ? (
            <h1>We sent an approval request to your mobile</h1>
          ) : (
            <span className={styles.nothingToNotify}>Push notifications for Coll8 are OFF on your mobile devices.
              Please turn push notifications ON and try again to be able to authorize access to SSO services.
            </span>
          )
        }
        <img className={styles.logo} src="/images/sso-auth-awaiter.svg" alt="Approve request in your mobile" />
        <Spinner className={styles.spinner} />
        {
          hasDeviceToNotify && (
            <div className={styles.bottomText}>
              Not receiving the notification? &nbsp;
              <Countdown key={`${authAllowedFrom}`} date={authAllowedFrom} renderer={this.countDownRenderer} />
            </div>
          )
        }
      </div>
    );
  }
}
