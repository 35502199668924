import { SelectOption } from "interfaces";
import { MembershipRuleExpression, MembershipRuleLogicalOperatorType, MembershipRuleOperatorType } from "appConstants";
import GroupUser from "../models/GroupUser";

export interface GroupRule {
  left: SelectOption<string> | null;
  operator: SelectOption<MembershipRuleOperatorType> | null;
  jsonPath: string;
  csvData: string[][] | null;
  right: string | MembershipRuleExpression;
  logicalOperator: SelectOption<MembershipRuleLogicalOperatorType> | null;
}

export interface RulesFormValues {
  rules: GroupRule[]
}

export enum UserGroupApprovalMethod {
  MANUAL = "manual",
  AUTOMATIC = "automatic",
  SEMI_AUTOMATIC = "semi-automatic",
}

export enum UserGroupApprovalStatus {
  APPROVED = "approved",
  REJECTED = "rejected",
}

export interface RetrieveUsersRequest extends PagedRequest {
  groupId: string;
}

export type AdminUser = Pick<GroupUser, "email" | "firstName" | "lastName">;

export enum GroupOwnershipType {
  ADMIN = 'admin',
  SHARED = 'shared',
}
