import { IObservableArray, observable, runInAction } from "mobx";
import { AsyncStatus } from "../appConstants";
import { deviceService } from "../services";
import { UserDeviceData } from "../services/DeviceService";
import { RootStore } from "./RootStore";

export class DevicesStore {
  rootStore: RootStore;
  @observable devices: IObservableArray<UserDeviceData> = observable([]);
  @observable loadDevicesAsyncStatus: AsyncStatus = AsyncStatus.IDLE;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  get isLoading(): boolean {
    return this.loadDevicesAsyncStatus === AsyncStatus.PENDING;
  }

  // if we haven't got devices by some reason - go to positive flow
  get hasDeviceToNotify(): boolean {
    return this.loadDevicesAsyncStatus === AsyncStatus.SUCCESS ?
      this.devices.some(({ notificationsEnabled }) => notificationsEnabled) :
      true;
  }

  loadUserDevices = async (): Promise<void> => {
    runInAction(() => {
      this.loadDevicesAsyncStatus = AsyncStatus.PENDING;
    });
    try {
      const devices = await deviceService.getUserDevices();
      runInAction(() => {
        this.devices.replace(devices);
        this.loadDevicesAsyncStatus = AsyncStatus.SUCCESS;
      });
    } catch (error) {
      runInAction(() => {
        this.loadDevicesAsyncStatus = AsyncStatus.FAILURE;
      });
    }
  };
}
