import React from 'react';
import classNames from "classnames";
import { Popup } from 'components';
import PopupActionsList from '../../../components/Popup/components/PopupActionsList';
import ApproveUser from '../../../models/ApproveUser';

export type ApproveRejectFunc = (user: ApproveUser) => void;

export interface ApproveUsersActionsProps {
  user: ApproveUser,
  onApprove: ApproveRejectFunc,
  onReject: ApproveRejectFunc,
}

class ApproveUsersActions extends React.Component<ApproveUsersActionsProps> {
  handleApprove = () => this.props.onApprove(this.props.user);

  handleReject = () => this.props.onReject(this.props.user);

  popupTrigger = () => <i className={classNames("icon-dots-horizontal")} />;

  render() {
    return (
      <Popup trigger={this.popupTrigger}>
        <PopupActionsList>
          <li>
            <button
              type="button"
              onClick={this.handleApprove}
            >
              Approve
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={this.handleReject}
            >
              Reject
            </button>
          </li>
        </PopupActionsList>
      </Popup>
    );
  }
}

export default ApproveUsersActions;
