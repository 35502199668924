import { SelectOption } from "../interfaces";

// we need to keep it here due to default name identifier configuration
export const SAML_SSO_PRIVATE_EMAIL_LABEL = 'Private email';
export const SAML_SSO_PROFILE_KEYS = {
  FIRST_NAME: "firstName",
  MIDDLE_NAME: "midName",
  LAST_NAME: "lastName",
  PRIVATE_MOBILE: "privateMobile",
  PRIVATE_EMAIL: "privateEmail",
  WORK_MOBILE: "workMobile",
  WORK_EMAIL: "workEmail",
  WORK_COMPANY_ID: "companyId",
  WORK_EMPLOYEE_ID: "employeeId",
  DATE_OF_BIRTH: "dob",
  NATIONAL_INSURANCE_NUMBER: "nin",
  PAYROLL_ID: "payrollId",
} as const;

export enum SSOFlowInitiatorType {
  EMPTY = '',
  IDP = 'IDP',
  SP = 'SP',
}

export enum SSOAttributeType {
  COMPOSITE = 'composite',
  PRIMITIVE = 'primitive'
}

export const SSOFlowOptions: SelectOption[] = [
  {
    label: 'None', value: SSOFlowInitiatorType.EMPTY,
  },
  {
    label: 'SAML2 IdP initiated', value: SSOFlowInitiatorType.IDP,
  },
  {
    label: 'SAML2 SP initiated', value: SSOFlowInitiatorType.SP,
  },
];
