import * as React from "react";
import classNames from "classnames";
import objectPath from "object-path";
import { Field, FieldConfig, FormikProps } from "formik";
import { CustomCheckbox, Alert, Label, Input } from 'components';
import { SelectOption } from 'interfaces';
import styles from "./CustomField.module.scss";

interface Props<TOption> extends FieldConfig {
  name: string;
  disabled?: boolean;
  title?: string;
  className?: string;
  containerClassName?: string;
  placeholder?: string;
  form: FormikProps<any>;
  withSeparator?: boolean;
  options?: TOption[];
  [x:string]: any;
  labelIcon?: React.ReactNode;
  // custom event on change
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // for select component onChange should be replaced to onSelectChange, as component works with value (not with event)
  onSelectChange?: (name: string, value: TOption) => void;
}

class CustomField<TOption = SelectOption> extends React.Component <Props<TOption>> {
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // changed event value before using change cb
    e.target.value = e.target.value.trimLeft();
    if (this.props.onChange) {
      this.props.onChange(e);
      return;
    }
    this.props.form.handleChange(e);
  };

  onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    // changed event value on blur
    e.target.value = e.target.value.trimRight();
    this.handleChange(e);
    this.props.form.setFieldTouched(this.props.name);
  };

  render() {
    const {
      name,
      title,
      labelIcon,
      placeholder,
      form,
      onChange,
      withSeparator,
      component,
      className,
      containerClassName,
      ...props
    } = this.props;
    // for checkbox label should be moved inside element and hidden on Field level
    const hiddenLabel = !title || component === CustomCheckbox;
    const value = objectPath.get(form.values, name);
    const error = objectPath.get<{ value: string } | string>(form.errors, name);
    const touched = objectPath.get(form.touched, name);
    // for select component error stored in error.value
    const errorMessage = error && !(typeof error === 'string') ? error.value : error;
    const hasError = errorMessage && touched;
    // for backward compatibility - containerClassName is preferable here.
    const cntClassName = containerClassName || className;
    return (
      <div className={classNames(
        styles.field,
        { [styles.hasError]: hasError },
        cntClassName,
      )}
      >
        <label htmlFor={name}>
          {!hiddenLabel && (
            <Label title={title} labelIcon={labelIcon} withSeparator={withSeparator} />
          )}
          <Field
            {...props}
            className={className}
            id={name}
            component={component || Input}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={this.handleChange}
            onBlur={this.onBlur}
            title={title}
          />
        </label>
        {hasError && <Alert message={errorMessage as string} />}
      </div>
    );
  }
}

export default CustomField;
