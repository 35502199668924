import React from 'react';
import classNames from "classnames";
import styles from "./Alert.module.scss";

interface Props {
  message: string;
}

const Alert = ({ message }: Props) => {
  return (
    <div className={styles.alert}>
      <i className={classNames("icon-info", styles.exclamation)} />
      {message}
    </div>
  );
};

export default Alert;
