/* eslint-disable react/no-array-index-key */
import * as React from "react";
import { FormikProps, Form, FieldArray } from "formik";
import { FormFlexContainer, CustomButton, CustomField, Alert, Label } from "components";
import { AdminUser, CreateTenantFormValues } from "interfaces";
import styles from "./TenantForm.module.scss";
import { AddAdminButton } from "./components/AddAdminButton";
import AdminRow from "./components/AdminRow";

const SAVE_TENANT_ERROR = "It looks like there is such tenant name or tenant ID. Please try to change either one";

interface Props {
  form: FormikProps<CreateTenantFormValues>;
  isDisabled: boolean | undefined;
  submitError: null | string;
}

const initialState = {
  admins: [] as AdminUser[],
};

type State = typeof initialState;

export default class TenantForm extends React.Component<Props, State> {
  state = initialState;

  handleAdminRemove = (index: number) => {
    const admins = this.props.form.values.admins.slice();
    admins.splice(index, 1);
    this.props.form.setFieldValue("admins", admins);
  };

  handleTenantNameChange = (e: React.ChangeEvent<any>) => {
    this.props.form.handleChange(e);
    const name = e.target.value || "";
    const alias = name.toLowerCase().replace(/[^a-z]/mig, "-");
    this.props.form.setFieldValue("alias", alias);
  };

  render() {
    const { form, isDisabled, submitError } = this.props;
    const { values } = form;
    return (
      <Form className={styles.form}>
        <FormFlexContainer className={styles.columnsCnt}>
          <div>
            <CustomField
              className={styles.field}
              tabIndex="0"
              withSeparator
              name="name"
              title="Tenant name"
              placeholder="Enter name..."
              form={form}
              onChange={this.handleTenantNameChange}
            />
          </div>
          <div>
            <div className={styles.tenantPrefixText}>coll8.co/t/</div>
          </div>
          <div>
            <CustomField
              className={styles.field}
              tabIndex="-1"
              withSeparator
              name="alias"
              title="Tenant ID"
              form={form}
            />
          </div>
        </FormFlexContainer>
        {submitError && <Alert message={SAVE_TENANT_ERROR} />}
        <h3>Admins</h3>
        <div>
          <FormFlexContainer className={styles.columnsCnt}>
            <div>
              <Label title="Email" />
            </div>
            <div>
              <Label title="First name" />
            </div>
            <div>
              <Label title="Last name" />
            </div>
          </FormFlexContainer>
          <FieldArray
            name="admins"
            render={arrayHelpers => (
              <div>
                {
                values.admins.map((admin: AdminUser, index: number) => {
                  return (
                    <AdminRow
                      key={`admin-${index}`}
                      index={index}
                      form={form}
                      onRemove={this.handleAdminRemove}
                    />
                  );
                })
              }
                <AddAdminButton arrayHelpers={arrayHelpers} />
              </div>
            )}
          />
        </div>
        <div>
          <CustomButton
            disabled={isDisabled}
            buttonType="primary"
            type="submit"
          >
            Save
          </CustomButton>
        </div>
      </Form>);
  }
}
