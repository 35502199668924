import React from 'react';
import classNames from "classnames";
import { Popup } from 'components';
import PopupActionsList from 'components/Popup/components/PopupActionsList';
import styles from "./Tenants.module.scss";
import Tenant from '../../models/Tenant';

export interface TenantsActionsProps {
  showUpdateModal: (id: string) => void,
  showDeleteModal: (id: string) => void,
  tenant: Tenant
}

class TenantsActions extends React.Component<TenantsActionsProps> {
  handleDeleteClick = () => this.props.showDeleteModal(this.props.tenant.id);

  handleEditClick = () => this.props.showUpdateModal(this.props.tenant.id);

  popupTrigger = () => <i className={classNames("icon-dots-horizontal", styles.button)} />;

  render() {
    const { canEdit, canDelete } = this.props.tenant.metadata;
    return (
      <Popup trigger={this.popupTrigger}>
        <PopupActionsList>
          <li>
            <button
              type="button"
              disabled={!canEdit}
              onClick={this.handleEditClick}
            >
              Edit
            </button>
          </li>
          <li>
            <button
              type="button"
              disabled={!canDelete}
              onClick={this.handleDeleteClick}
            >
              Delete
            </button>
          </li>
        </PopupActionsList>
      </Popup>
    );
  }
}

export default TenantsActions;
