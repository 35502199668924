import lscache from 'lscache';
import { InstalledApps } from 'models/InstalledApps';
import { ROOT_WORKSPACE_ID } from 'appConstants';

const INSTALLED_APPS = 'installedApps';
const CURRENT_TENANT = 'currentTenant';
const ADMIN_MODE = 'adminMode';

class LocalStorageService {
  getInstalledApps = (): InstalledApps => {
    const installedApps: InstalledApps = lscache.get(INSTALLED_APPS);
    if (!installedApps) {
      return {};
    }
    return installedApps;
  };

  getInstalledAppsByWorkspaceId = (workspaceId: string | null): string[] => {
    const installedApps = this.getInstalledApps();
    const id = workspaceId || ROOT_WORKSPACE_ID;
    const appIds = installedApps[id] ? installedApps[id] : [];
    return appIds;
  };

  addInstalledApp = (appId: string, workspaceId: string | null) => {
    const installedApps = this.getInstalledApps();
    const id = workspaceId || ROOT_WORKSPACE_ID;
    const workspaceApps = installedApps[id];
    if (workspaceApps) {
      const apps = workspaceApps.includes(appId) ?
        workspaceApps :
        [...workspaceApps, appId];
      installedApps[id] = apps;
    } else {
      installedApps[id] = [appId];
    }
    lscache.set(INSTALLED_APPS, installedApps);
  };

  removeInstalledApp = (appId: string, workspaceId: string | null) => {
    const installedApps = this.getInstalledApps();
    const id = workspaceId || ROOT_WORKSPACE_ID;
    const workspaceApps = installedApps[id];
    if (workspaceApps) {
      const apps = workspaceApps.filter(currentAppId => currentAppId !== appId);
      installedApps[id] = apps;
    } else {
      installedApps[id] = [];
    }
    lscache.set(INSTALLED_APPS, installedApps);
  };

  setAdminMode = (mode: boolean) => lscache.set(ADMIN_MODE, mode);

  getAdminMode = () => lscache.get(ADMIN_MODE);

  setCurrentTenant = (id: string) => lscache.set(CURRENT_TENANT, id);

  removeCurrentTenant = () => lscache.remove(CURRENT_TENANT);

  getCurrentTenant = () => lscache.get(CURRENT_TENANT);

  setItem = (key: string, value: any) => lscache.set(key, value);

  getItem = (key: string) => lscache.get(key);
}

export default new LocalStorageService();
