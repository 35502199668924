import * as Yup from 'yup';
import { SAML_SSO_PROFILE_KEYS } from 'appConstants';
import { SsoValidationRules } from 'interfaces';
import endOfDay from 'date-fns/endOfDay';
import isValid from 'date-fns/isValid';
import { nameRegExp, ninRegExp, phoneRegExp } from './constants';
import {
  emptyFieldErrorMessage,
  twoSymbolsErrorMessage,
  latinLettersErrorMessage,
  max70SymbolsMessage,
} from './messages';

const SSO_DEFAULT_FIELD_LABEL = "This field";

export const SSO_VALIDATION_ERROR_LIST = {
  FIELD_NOT_EMPTY: emptyFieldErrorMessage,
  THREE_SYMBOLS_MINIMUM: 'Three symbols min.',
};

const middleNameValidationRule = Yup.string()
  .strict(true)
  .min(2, twoSymbolsErrorMessage)
  .max(70, max70SymbolsMessage)
  .label(SSO_DEFAULT_FIELD_LABEL)
  .matches(nameRegExp, latinLettersErrorMessage)
  .required(SSO_VALIDATION_ERROR_LIST.FIELD_NOT_EMPTY);

const mobileValidationRule = Yup.string()
  .trim()
  .strict(true)
  .min(3, SSO_VALIDATION_ERROR_LIST.THREE_SYMBOLS_MINIMUM)
  .max(12, "Twelve symbols max.")
  .matches(phoneRegExp, "You can use digits and \"+\" only.")
  .label(SSO_DEFAULT_FIELD_LABEL)
  .required(SSO_VALIDATION_ERROR_LIST.FIELD_NOT_EMPTY)
  .nullable(false);

const nonEmptyMaxMinStringValidationRule = Yup.string()
  .strict(true)
  .min(3, SSO_VALIDATION_ERROR_LIST.THREE_SYMBOLS_MINIMUM)
  .max(70, max70SymbolsMessage)
  .label(SSO_DEFAULT_FIELD_LABEL)
  .required(SSO_VALIDATION_ERROR_LIST.FIELD_NOT_EMPTY)
  .nullable(false);

const emailValidationRule = Yup.string()
  .strict(true)
  .required(SSO_VALIDATION_ERROR_LIST.FIELD_NOT_EMPTY)
  .email("Does not fit email format x@y.z")
  .max(255, "255 symbols max.")
  .lowercase("All symbols have to be in lowercase.")
  .trim()
  .label(SSO_DEFAULT_FIELD_LABEL)
  .nullable(false);

const optionValidationRule = Yup.object().shape({
  label: Yup.string(),
  value: Yup.string(),
});

export const SSO_VALIDATION_RULES: SsoValidationRules = {
  [SAML_SSO_PROFILE_KEYS.FIRST_NAME]: optionValidationRule,
  [SAML_SSO_PROFILE_KEYS.MIDDLE_NAME]: middleNameValidationRule,
  [SAML_SSO_PROFILE_KEYS.LAST_NAME]: optionValidationRule,
  [SAML_SSO_PROFILE_KEYS.PRIVATE_MOBILE]: mobileValidationRule,
  [SAML_SSO_PROFILE_KEYS.PRIVATE_EMAIL]: Yup.object().shape({
    label: Yup.string(),
    value: emailValidationRule,
  }),
  [SAML_SSO_PROFILE_KEYS.WORK_MOBILE]: mobileValidationRule,
  [SAML_SSO_PROFILE_KEYS.WORK_EMAIL]: Yup.object().shape({
    label: Yup.string(),
    value: emailValidationRule,
  }),
  [SAML_SSO_PROFILE_KEYS.WORK_COMPANY_ID]: nonEmptyMaxMinStringValidationRule,
  [SAML_SSO_PROFILE_KEYS.WORK_EMPLOYEE_ID]: nonEmptyMaxMinStringValidationRule,
  [SAML_SSO_PROFILE_KEYS.DATE_OF_BIRTH]: Yup.lazy((val: string | null): Yup.Schema<any> => (isValid(val) ?
    Yup.date()
      .max(endOfDay(new Date()), "The date cannot be in the future.")
      .label(SSO_DEFAULT_FIELD_LABEL) :
    Yup.string()
      .nullable()
      .required("Invalid date format")
  )),
  [SAML_SSO_PROFILE_KEYS.NATIONAL_INSURANCE_NUMBER]: Yup.string()
    .strict(true)
    .matches(ninRegExp, "The format is incorrect. For example: AB123456C.")
    .label(SSO_DEFAULT_FIELD_LABEL)
    .required(SSO_VALIDATION_ERROR_LIST.FIELD_NOT_EMPTY)
    .nullable(false),
  [SAML_SSO_PROFILE_KEYS.PAYROLL_ID]: nonEmptyMaxMinStringValidationRule,
};
