import { HttpService, httpTenantService } from "./http";
import { CreateTenantData, CreatedTenant, TenantWithAdmins } from "../interfaces";

class TenantsService {
  http: HttpService;

  constructor(http: HttpService) {
    this.http = http;
  }

  retrieveTenants(params: PagedRequest): Promise<PagedResponse<TenantWithAdmins>> {
    return this.http.get<PagedResponse<TenantWithAdmins>>("/tenants", { queryStringParameters: params });
  }

  createTenant(data: CreateTenantData & { id: string }): Promise<CreatedTenant> {
    return this.http.post<CreatedTenant>("/tenants", { body: data });
  }

  updateTenant(id: string, data: CreateTenantData): Promise<CreatedTenant> {
    return this.http.put<CreatedTenant>(`/tenants/${id}`, { body: data });
  }

  deleteTenant(id: string): Promise<void> {
    return this.http.delete<void>(`/tenants/${id}`);
  }
}

export default new TenantsService(httpTenantService);
