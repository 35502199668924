import { HttpService, httpClientService } from './http';
import { SSOSamlRequestResponse, SSOSamlAuthorizeAttributes } from '../interfaces';

export interface SsoSamlAuthorize {
  relayState: string,
  samlRequest: string,
  authorizeService: boolean,
  attributes?: SSOSamlAuthorizeAttributes[],
  samlServiceId: string,
  ssoAuthToken?: string // can't exist with mobile less then 2.5
}

class SsoService {
  http: HttpService;

  constructor(http: HttpService) {
    this.http = http;
  }

  doSamlRequest(
    relayState: string,
    samlRequest: string,
    samlServiceId: string,
  ): Promise<HttpResponse<SSOSamlRequestResponse | string>> {
    return this.http.post('/sso/saml/request', {
      body: {
        samlRequest: decodeURIComponent(samlRequest),
        samlServiceId: decodeURIComponent(samlServiceId),
        relayState,
      },
      response: true,
    });
  }

  doSamlAuthorize({
    relayState,
    samlRequest,
    authorizeService,
    attributes,
    samlServiceId,
    ssoAuthToken,
  }: SsoSamlAuthorize): Promise<string> {
    return this.http.post<string>('/sso/saml/authorize', {
      body: {
        relayState,
        samlRequest: decodeURIComponent(samlRequest),
        authorizeService,
        attributes,
        samlServiceId,
        ssoAuthToken,
      },
      contentType: "text/html",
    });
  }
}

export default new SsoService(httpClientService);
