import React from 'react';
import styles from './TileContent.module.scss';

export interface Props {
  title: string;
  iconUrl: string;
  description?: string;
}

const TileContent = ({ title, iconUrl, description }: Props) => (
  <>
    <div className={styles.itemImage} style={{ backgroundImage: `url(${iconUrl})` }} />
    <div className={styles.text}>
      <span className={styles.itemTitle}>{title}</span>
      <span className={styles.description}>{description}</span>
    </div>
  </>);

export default TileContent;
