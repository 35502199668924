import { VerifyCodeResponse } from 'interfaces';
import { HttpService, httpClientService } from './http';

class UserService {
  http: HttpService;

  constructor(http: HttpService) {
    this.http = http;
  }

  sendEmail = (email: string) => {
    return this.http.post<string>('/users/me/email', { body: {
      email,
    } });
  };

  verifyCode = (verifyCode: string) => {
    return this.http.post<VerifyCodeResponse>('/users/me/email/verify', { body: {
      verifyCode,
    } });
  };
}

export default new UserService(httpClientService);
